import store from '@/store';
import http from '@http';
import { vm } from '@main';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

const DocumentView = () => import(/* webpackChunkName: "documents-module" */ '@views/Documents/DocumentsView');

/**
 * folders - everything in the Documents module;
 * files - everything inside HACCP + Audits
 * @type {{folders: *[], selected_place_id: (number|null), files: {equipment_plan: *[], audit: *[], placeId: null, waste_tare_pest_control_plan: *[], location_plan: *[], flow_diagram: *[], moving_routes: *[], premises_plan: *[], water_and_sewage_plan: *[]}, sideMenu: *[]}}
 */
const state = {
    folders: [],
    selected_place_id: +localStorage.getItem('selected_place_id') || null,
    files: {
        placeId: null,
        // HACCP plan
        flow_diagram: [],
        location_plan: [],
        water_and_sewage_plan: [],
        premises_plan: [],
        equipment_plan: [],
        moving_routes: [],
        waste_tare_pest_control_plan: [],
        audit: [],
    },
    sideMenu: [],
};

const mutations = {
    updateFolders(state, data) {
        state.folders = data;
    },

    updateFiles(state, { type, files }) {
        Vue.set(state.files, { type, files });
    },

    updateFolder(state, data) {
        const index = state.folders.findIndex((f) => f.id === data.id);
        if (index !== -1) {
            Vue.set(state.folders[index], { data });
        }
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ dispatch }) {
            if (!store.getters['shared-access/isSharedAccess']) {
                dispatch('getSelectedPlaceFolders');
            }
        },
    },

    async getSelectedPlaceFolders({ rootGetters, commit }) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/folders`);
            commit('updateFolders', data);
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async getFolderDetails({ rootGetters, commit }, folderId) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/folders/${folderId}`);
            commit('updateFiles', { type: data.type, files: data.files });
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async getSelectedPlaceFilesByType({ rootGetters, commit }, type) {
        try {
            if (!rootGetters.selectedPlaceId) {
                return [];
            }

            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/files`, { params: { type } });
            commit('updateFiles', { type, files: data.files });

            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async addFolder({ rootGetters, dispatch }, data) {
        try {
            const res = await http.post(`/api/places/${rootGetters.selectedPlaceId}/folders`, data);
            dispatch('getSelectedPlaceFolders');
            return res;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async updateFolderDetails({ rootGetters, commit, dispatch }, folder) {
        try {
            const { data } = await http.put(`/api/places/${rootGetters.selectedPlaceId}/folders/${folder.id}`, folder);
            commit('updateFolder', { data });
            dispatch('getSelectedPlaceFolders');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async putFileToFolder({ rootGetters, dispatch }, file) {
        try {
            const { data } = await http.put(`/api/places/${rootGetters.selectedPlaceId}/files/${file.id}`, {
                folder_id: file.folder_id,
            });
            dispatch('getSelectedPlaceFolders');
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async deleteFolder({ rootGetters, dispatch }, folderId) {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/folders/${folderId}`);
            dispatch('getSelectedPlaceFolders');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async deleteFile({ dispatch }, data) {
        try {
            await http.delete(`/api/files/${data.id}`);
            dispatch('getSelectedPlaceFolders');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },
};

const getters = {
    getSelectedPlaceFiles: (state) => {
        return state.files.placeId === state.selected_place_id ? state.files : [];
    },

    getDocumentsSideMenu(state) {
        return state.sideMenu;
    },

    getSelectedPlaceFolders: (state) => {
        return state.folders;
    },

    sideMenu: (state) => {
        const documentsSectionDivider = [
            {
                title: 'DOCUMENTS',
                type: 'contentSeparator',
                noOrderNumber: true,
            },
        ];
        const list = state.folders?.map((item) => ({
            title: item.name,
            to: {
                path: `/documents/` + encodeURIComponent(item.name),
                query: {
                    id: item.id,
                },
            },
            component: DocumentView,
            meta: {
                title: item.name,
            },
            params: {
                folder: item.name,
            },
            children: item.sub_folders.map((page) => ({
                title: page.name,
                to: {
                    path: `/documents/${encodeURIComponent(item.name)}/${encodeURIComponent(page.name)}`,
                    query: {
                        id: page.id,
                    },
                },
                component: DocumentView,
                meta: {
                    id: page.id,
                    title: page.name,
                },
            })),
        }));
        const settingsSectionDivider = [
            {
                title: 'SETTINGS',
                type: 'contentSeparator',
                noOrderNumber: true,
            },
        ];
        const shareManageDocumentsLinks = [
            {
                title: 'Share documents',
                to: { name: 'share-accesses' },
            },
            {
                title: 'Manage folders',
                to: {
                    name: 'manage-folders',
                },
            },
        ];
        return cloneDeep([
            ...settingsSectionDivider,
            ...shareManageDocumentsLinks,
            ...documentsSectionDivider,
            ...list,
        ]);
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
