import Vue from 'vue';
import http from '@http';

import fileService from '@services/file.service';

import {
    ADD_FILE_LOADING,
    CLEAR_BASE64,
    DELETE_FILE,
    DOWNLOAD_FILE,
    FETCH_BASE64,
    REMOVE_BASE64,
    REMOVE_FILE_LOADING,
    RENAME_VTA_DOC,
    SAVE_BASE64,
    SHOW_OR_DOWNLOAD_FILE,
    START_FILE_LOADING,
    UPDATE_PROGRESS,
    UPLOAD_FILE,
} from '@store/file/constants';
import { UPDATE_PLACE } from '@store/company/place/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

import {
    blobToBase64,
    getCurrentDateTime,
    isImageExtensionByName,
    isPdfExtensionByName,
} from '@services/helper.service';

import Path from '@services/path.service';
import { GET_AND_DELETE_FROM_ARCHIVE, REMOVE_FROM_ARCHIVE, SAVE_TO_ARCHIVE } from '@store/local-archive/constants';
import { UPDATE_DEVICE } from '@store/devices/constants';

const state = {
    files: [],

    loading: new Path(),
};

const mutations = {
    [SAVE_BASE64]: async (state, fileData) => {
        if (fileData.blob && (typeof fileData.blob !== 'string' || !fileData.blob.includes('base64,'))) {
            fileData.base64 = await blobToBase64(fileData.blob);
            delete fileData.blob;
        }

        if (!fileData.base64) {
            throw TypeError("Can't find file content!");
        }

        const file = state.files.find((file) => file.id && file.id === fileData.id);
        if (file) {
            state.files[state.files.indexOf(file)] = fileData;
        } else {
            state.files.push(fileData);
        }
    },
    [REMOVE_BASE64]: (state, id) => {
        const file = state.files.find((file) => file.id && file.id === id);
        if (file) {
            state.files.splice(state.files[state.files.indexOf(file)], 1);
        }
    },
    [CLEAR_BASE64]: (state) => {
        state.files = [];
    },
    [ADD_FILE_LOADING](state, data) {
        state.loading.push(data.path, {
            ...data,
            progress: 0,
        });
    },
    [REMOVE_FILE_LOADING](state, path) {
        state.loading.remove(path);
    },
    [UPDATE_PROGRESS](state, { path, value }) {
        const obj = state.loading.get(path);

        Vue.set(obj, 'progress', value);
    },
};

const actions = {
    [RENAME_VTA_DOC]({ commit }, { id, filename, type }) {
        http.put(`api/vta_docs/document_file/${id}`, { filename }).then(() => {
            commit(RENAME_VTA_DOC, { id, filename, type });
        });
    },
    [FETCH_BASE64]({ commit, getters }, fileId) {
        const file = getters.files.find((file) => file.id === fileId);
        if (file) {
            return file;
        }

        commit(START_VIEW_LOADER);
        return fileService
            .getFileBase64(fileId)
            .then((fileData) => {
                const split = fileData.name.split('.');
                const fileType = isImageExtensionByName(fileData.name) ? 'image' : 'application';
                fileData.base64 = `data:${fileType}/${split[split.length - 1] || 'png'};base64,${fileData.base64}`;
                fileData.id = fileId;
                commit(SAVE_BASE64, fileData);

                return fileData;
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },
    [UPLOAD_FILE]({ commit, getters }, { type, fileData, onProgress, foodDeviceId, metadata, onPaper }) {
        return fileService
            .uploadFile(
                getters.selectedPlaceId,
                type,
                {
                    file_base64_encode: fileData.data,
                    filename: fileData.name,
                    size: fileData.size,
                    type: fileData.type,
                    file_subject: foodDeviceId && 'foodDevice',
                    file_subject_id: foodDeviceId,
                    metadata: metadata,
                    on_paper: onPaper ? 1 : 0,
                },
                {
                    onUploadProgress: onProgress,
                }
            )
            .then((res) => {
                res.base64 = fileData.data;
                res.name = res.filename;
                commit(SAVE_BASE64, res);

                const selectedPlace = { ...getters.selectedPlace };
                if (Array.isArray(selectedPlace.files[type])) {
                    selectedPlace.files[type].push(res);
                } else {
                    selectedPlace.files[type] = res;
                }
                commit(UPDATE_PLACE, selectedPlace);

                if (type === 'others') {
                    const foodDevice = getters.getDeviceById(foodDeviceId);
                    foodDevice.updated_at = getCurrentDateTime();
                    commit(UPDATE_DEVICE, { id: foodDeviceId, data: foodDevice });
                }

                return res;
            });
    },
    [DELETE_FILE]({ commit, getters, dispatch }, { type, fileId }) {
        const path = ['place', type, fileId];

        commit(REMOVE_BASE64, fileId);
        const selectedPlace = { ...getters.selectedPlace };
        if (Array.isArray(selectedPlace.files[type])) {
            const index = selectedPlace.files[type].findIndex((file) => file.id === fileId);

            commit(SAVE_TO_ARCHIVE, {
                value: {
                    index,
                    file: selectedPlace.files[type][index],
                    type,
                },
                path,
            });

            selectedPlace.files[type].splice(index, 1);
        } else {
            selectedPlace.files[type] = null;
        }
        commit(UPDATE_PLACE, selectedPlace);

        return fileService
            .deleteFile(fileId)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);

                return null;
            })
            .catch(() => {
                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, file, type }) => {
                    selectedPlace.files[type].splice(index, 0, file);
                    commit(UPDATE_PLACE, selectedPlace);
                });
            });
    },
    [SHOW_OR_DOWNLOAD_FILE]({ dispatch }, file) {
        if (isImageExtensionByName(file.filename) || isPdfExtensionByName(file.filename)) {
            return dispatch(FETCH_BASE64, file.id).then((res) => {
                return res;
            });
        } else {
            return fileService.downloadFile(file, true);
        }
    },
    [DOWNLOAD_FILE]({ commit }, file) {
        commit(START_VIEW_LOADER);
        return fileService.downloadFile(file, true).finally(() => commit(STOP_VIEW_LOADER));
    },
    [START_FILE_LOADING]({ commit }, { file, path, uploader }) {
        return new Promise((resolve, reject) => {
            const loadingState = {
                reader: new FileReader(),
                path,
                file,
                uploader,
            };

            loadingState.reader.onerror = reject;

            loadingState.reader.onprogress = (e) => {
                commit(UPDATE_PROGRESS, {
                    path: loadingState.path,
                    value: parseInt((e.loaded / e.total) * 10, 10),
                });
            };

            loadingState.reader.onload = (e) => {
                return loadingState
                    .uploader({
                        file: {
                            data: e.target.result,
                            name: loadingState.file.name,
                            type: loadingState.file.type,
                            size: loadingState.file.size ? loadingState.file.size / Math.pow(1000, 2) : null,
                        },
                        onProgress: (e) => {
                            commit(UPDATE_PROGRESS, {
                                path: loadingState.path,
                                value: 10 + parseInt((e.loaded * 90) / e.total),
                            });
                        },
                    })
                    .then((res) => {
                        resolve(res);

                        return res;
                    })
                    .catch((err) => {
                        reject(err);

                        return err;
                    })
                    .finally(() => {
                        commit(REMOVE_FILE_LOADING, loadingState.path);
                    });
            };

            loadingState.reader.readAsDataURL(loadingState.file);

            commit(ADD_FILE_LOADING, loadingState);
        });
    },
};

const getters = {
    files: (state) => {
        return state.files;
    },

    getFileById: (state, getters) => (id) => {
        const file = getters.files.find((file) => file.id === id);
        if (file) {
            return file;
        }

        return null;
    },

    getLoadingFilesByPath: (state) => (path) => {
        const files = state.loading.get(path);

        if (files) {
            const keys = Object.keys(files);

            if (keys && keys.length) {
                return keys.map((key) => {
                    return files[key];
                });
            }

            return [];
        }

        return [];
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
