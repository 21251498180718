import { devicesService } from '@common/services/devices.service';
import fileService from '@common/services/file.service';

import {
    ADD_DEVICES,
    DELETE_DEVICE,
    GET_CUSTOM_FOLDER_FILES,
    IMPORT_DEVICES,
    INIT_DEVICES,
    INIT_DEVICES_TYPES,
    INSERT_DEVICE,
    PUSH_DEVICE,
    REMOVE_ATTACHMENT,
    RENAME_FOLDER,
    SETUP_FOLDER,
    SET_DEVICES_DATA,
    SET_TYPES,
    UPDATE_DEVICE,
    UPDATE_DEVICES,
    UPLOAD_ATTACHMENT,
} from '@store/devices/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

import { getCurrentDateTime } from '@services/helper.service';
import { OTHER } from '@common/constants';
import { LOG_ERROR } from '@store/common/constants';
import { GET_AND_DELETE_FROM_ARCHIVE, REMOVE_FROM_ARCHIVE, SAVE_TO_ARCHIVE } from '@store/local-archive/constants';

export const actions = {
    async [INIT_DEVICES]({ commit, dispatch, getters }) {
        if (getters.selectedPlaceId) {
            if (getters.hasDevices) {
                return getters.devicesList;
            }

            return devicesService.getDevices(getters.selectedPlaceId).then((res) => {
                commit(SET_DEVICES_DATA, res);
                commit(INIT_DEVICES);

                return res;
            });
        } else {
            dispatch(LOG_ERROR, `Can't find selectedPlaceId: ${getters.selectedPlaceId}`);
        }

        return [];
    },
    async [INIT_DEVICES_TYPES]({ commit, getters }) {
        if (getters.devicesTypes.length) {
            return getters.devicesTypes;
        }

        return devicesService.getAllTypes().then((res) => {
            commit(SET_TYPES, res);

            return res;
        });
    },
    [DELETE_DEVICE]({ commit, dispatch, getters }, id) {
        const path = ['devices', 'delete', 'device', id];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index: getters.getDeviceIndexById(id),
                data: getters.getDeviceById(id),
            },
            path,
        });

        commit(DELETE_DEVICE, id);
        return devicesService
            .deleteDevice(id)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch(() => {
                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(INSERT_DEVICE, { index, data });
                });
            });
    },
    async [ADD_DEVICES]({ commit, getters }, data) {
        const requests = [];

        data.map((item) => {
            const device = createDevice(item);

            if (item.newDevice) {
                requests.push(devicesService.addDevice(getters.selectedPlaceId, device));
            }
        });

        if (requests.length) {
            commit(START_VIEW_LOADER);
            return Promise.all(requests)
                .then((res) => {
                    res.map((item) => {
                        commit(UPDATE_DEVICE, { id: item.id, data: item });
                    });

                    return res;
                })
                .finally(() => commit(STOP_VIEW_LOADER));
        } else {
            return Promise.resolve();
        }
    },
    async [UPDATE_DEVICES]({ commit, getters }, data) {
        const requests = [];

        data.map((item) => {
            const device = createDevice(item);

            if (item.newDevice) {
                requests.push(devicesService.addDevice(getters.selectedPlaceId, device));
            } else if (item.hasUpdated) {
                requests.push(devicesService.updateDevice(item.id, device));
            }
        });

        if (requests.length) {
            commit(START_VIEW_LOADER);
            return Promise.all(requests)
                .then((res) => {
                    res.map((item) => {
                        commit(UPDATE_DEVICE, { id: item.id, data: item });
                    });

                    return {
                        res,
                    };
                })
                .finally(() => commit(STOP_VIEW_LOADER));
        } else {
            return Promise.resolve();
        }
    },
    [UPLOAD_ATTACHMENT]({ dispatch, getters }, { deviceId, file }) {
        const device = getters.getDeviceById(deviceId);

        const fileToPush = {
            type: file.type.split('/')[0],
            filename: file.name,
            ext: file.name.split('.').pop(),
            base64: file.data,
        };

        device.files.push(fileToPush);
        device.hasUpdated = true;

        return dispatch(UPDATE_DEVICES, [device]).catch(() => {
            const index = device.files.indexOf(fileToPush);
            device.files.splice(index, 1);
        });
    },
    [REMOVE_ATTACHMENT]({ commit, getters }, { deviceId, attachmentId }) {
        return devicesService.removeAttachment(getters.selectedPlaceId, attachmentId).then((res) => {
            const deviceData = { ...getters.getDeviceById(deviceId) };
            const attachmentIndex = deviceData.files.findIndex((attachment) => +attachment.id === +attachmentId);
            deviceData.files.splice(attachmentIndex, 1);
            commit(UPDATE_DEVICE, { id: deviceId, data: deviceData });
            return res;
        });
    },
    [IMPORT_DEVICES]({ commit, getters }, data) {
        return devicesService.importDevices(getters.selectedPlaceId, data).then((res) => {
            res.map((item) => {
                commit(UPDATE_DEVICE, { id: item.id, data: item });
            });
            return res;
        });
    },
    [SETUP_FOLDER]({ getters, commit }) {
        commit(START_VIEW_LOADER);

        devicesService
            .addDevice(getters.selectedPlaceId, {
                name: `Folder ${getCurrentDateTime()}`,
                schedule: 'once_a_day',
                device_type_id: OTHER, // Other device type
            })
            .then((res) => {
                commit(PUSH_DEVICE, res);
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },
    [RENAME_FOLDER]({ commit }, { value, deviceId }) {
        commit(START_VIEW_LOADER);

        devicesService
            .updateDevice(deviceId, {
                name: value,
            })
            .then((res) => {
                if (!res) {
                    throw new Error("Something went wrong and I can't get res value");
                }

                commit(UPDATE_DEVICE, {
                    id: deviceId,
                    data: res,
                });

                return res;
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },
    [GET_CUSTOM_FOLDER_FILES]({ getters, commit }, deviceId) {
        commit(START_VIEW_LOADER);

        return fileService
            .getFilesFoodDeviceFiles(getters.selectedPlaceId, deviceId)
            .finally(() => commit(STOP_VIEW_LOADER));
    },
};

function createDevice(data) {
    const res = {
        name: data.name,
        schedule: data.measurement_schedule.schedule,
        on_paper: data.on_paper,
        storage_id: +data.storage_id === -1 ? null : data.storage_id,
        device_type_id: +data.device_type_id,
        sensor_id: data.sensor_id ? data.sensor_id : null,
        provider_email: data.provider_email,
        userGroups: data.userGroups,
        file_list: data.files || [],
        device_settings: {},
    };

    if (res.storage_id === null) {
        res.device_settings.lower_limit = data.device_settings && data.device_settings.lower_limit;
        res.device_settings.upper_limit = data.device_settings && data.device_settings.upper_limit;
    }

    if (data.device_settings) {
        res.device_settings = data.device_settings;
    }

    return res;
}
