import { vm } from '@/main';
import Vue from 'vue';
import moment from 'moment';
import { DateValue } from '@common/types';

let truncate = Vue.filter('truncate', function (value, length) {
    length = length || 35;
    if (!value || typeof value !== 'string') {
        return '';
    }
    if (value.length <= length) {
        return value;
    }
    return value.substring(0, length) + '...';
});

let round = Vue.filter('round', function (value, decimals) {
    if (value >= 0 && value !== null) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return 0;
});

let toUpper = Vue.filter('toUpper', function (value) {
    return String(value).toUpperCase();
});

let striphtml = Vue.filter('striphtml', function (value) {
    let div = document.createElement('div');
    div.innerHTML = value;
    let text = div.textContent || div.innerText || '';
    return text;
});

export default {
    truncate,
    round,
    toUpper,
    striphtml,
    positiveOrZero: Vue.filter('positiveOrZero', (value) => {
        return value > 0 ? value : 0;
    }),

    valueOrDash: Vue.filter('valueOrDash', (value) => {
        if (!value) {
            return '-';
        }

        return value;
    }),

    printNumTwoDeci: Vue.filter('printNumTwoDeci', (value) => {
        if (isNaN(value) || `${value}`?.trim() === '') {
            return '0.00';
        }

        const valueFragments = `${+value}`.split('.');

        if (valueFragments.length === 2) {
            if (valueFragments[1].length === 1) {
                return `${value}0`;
            } else if (valueFragments[1].length === 2) {
                return `${value}`;
            } else {
                return `${valueFragments[0]}.${valueFragments[1].slice(0, 2)}`;
            }
        } else {
            return `${value}.00`;
        }
    }),

    twoDeci: Vue.filter('twoDeci', (value) => {
        if (!value) {
            return '';
        }

        const res = Math.round(value * 100) / 100;

        if (res) {
            return res;
        } else {
            return '';
        }
    }),

    threeDeci: Vue.filter('threeDeci', (value) => {
        if (!value) {
            return '';
        }

        const res = Math.round(value * 1000) / 1000;

        if (res) {
            return res;
        } else {
            return '';
        }
    }),

    percentage: Vue.filter('percentage', (value, delimiterValue) => {
        if (!Number.isFinite(+value)) {
            return '';
        }
        if (delimiterValue) {
            // If the percent is lower than <delimiterValue> only then 2 decimals are shown
            return value < delimiterValue ? `${Math.round(value * 100) / 100}%` : `${Math.round(value * 10) / 10}%`;
        }
        return `${value}%`;
    }),

    gram: Vue.filter('gram', (value) => {
        if (!value) {
            return '';
        }

        return `${value} ${vm.$t('g')}`;
    }),

    asArray: Vue.filter('asArray', (value) => {
        if (!value) {
            return [];
        }

        if (Array.isArray(value)) {
            return value;
        }

        return [value];
    }),

    formatDate: Vue.filter('formatDate', (date) => {
        return new DateValue(date).dateFormat;
    }),

    formatDateTime: Vue.filter('formatDateTime', (date) => {
        return new DateValue(date).dateTimeFormat;
    }),

    timeAgo: Vue.filter('timeAgo', function (date) {
        // TODO: FD-5566 replace it with `new DateValue()`. Think how to do it :)
        try {
            if (date) {
                if (!moment(String(date), `${this.$store.getters.selectedCompanyDateFormat} HH:mm`, true).isValid()) {
                    return moment(String(date)).lang(vm.$i18n.locale).fromNow();
                }

                return date;
            }

            return date;
        } catch (e) {
            return date;
        }
    }),

    localeOrSomething: Vue.filter('localeOrSomething', function (value) {
        if (value) {
            if (value[vm.$i18n.locale]) {
                return value[vm.$i18n.locale];
            }

            if (typeof value == 'string') {
                return value;
            }

            const keys = Object.keys(value);
            if (keys && keys.length) {
                const keys = Object.keys(value);
                if (keys.length) {
                    for (const key of keys) {
                        if (value[key]) {
                            return value[key];
                        }
                    }
                }
            }
        }
        return value;
    }),

    formatDateTimeToSend: Vue.filter('formatDateTimeToSend', function (date) {
        // TODO: FD-5566 get rid of it, depricated

        return new DateValue(date).dateTimeFormat;
    }),

    commaValueTransform: Vue.filter('commaValueTransform', (name, index, last) => {
        if (index >= 0 && typeof name === 'string') {
            if (index !== 0) {
                name = name.toLowerCase();
            }
            if (last) {
                name = name.trim() + ', ';
            }
        }

        return name;
    }),

    toLower: Vue.filter('toLower', (value) => {
        return value.toLowerCase().trim();
    }),

    toFixed: Vue.filter('toFixed', (value, decimals = 8) => {
        if (!isNaN(value)) {
            return parseFloat(value.toFixed(decimals));
        }
        return value;
    }),
};
