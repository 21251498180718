import { DEVICES, FILE_SIZE_5MB } from '@common/constants';
import commonService from '@services/common.service';
import { capitalizeFLetter, getShortProductionUnits } from '@services/helper.service';
import { isoLanguages } from '@common/isoLanguages';
import http from '@services/http/http.service';
import {
    FETCH_ADDITIVE_USAGES,
    FETCH_BUSINESS_ACTIVITIES,
    FETCH_CLAIMS_LIST,
    FETCH_COUNTRIES,
    FETCH_CURRENCIES,
    FETCH_CUSTOMER_GROUPS,
    FETCH_ESUBSTANCES_LIST,
    FETCH_FOOD_GROUP_CATEGORIES,
    FETCH_IP_COUNTRY,
    FETCH_LANGUAGES,
    FETCH_LOCATION_TYPES,
    FETCH_NEW_TIME_UNITS,
    FETCH_PACKAGING_LIST,
    FETCH_PRODUCTION_UNITS,
    FETCH_PRODUCT_GROUPS,
    FETCH_SHORT_PRODUCTION_UNITS,
    FETCH_TECHNOLOGIST_PRICE,
    FETCH_TIME_UNITS,
    LOG_ERROR,
    SET_ADDITIVE_USAGES,
    SET_BUSINESS_ACTIVITIES,
    SET_CLAIMS_LIST,
    SET_COUNTRIES,
    SET_CURRENCIES,
    SET_CURRENT_PAGE,
    SET_CUSTOMER_GROUPS,
    SET_ESUBSTANCES_LIST,
    SET_FOOD_GROUP_CATEGORIES,
    SET_IP_COUNTRY,
    SET_IS_MOBILE,
    SET_LANGUAGES,
    SET_LOCATION_TYPES,
    SET_NEW_TIME_UNITS,
    SET_PACKAGING_LIST,
    SET_PER_PAGE,
    SET_PRICES_FOR_COUNTRY,
    SET_PRODUCTION_UNITS,
    SET_PRODUCT_GROUPS,
    SET_SHORT_PRODUCTION_UNITS,
    SET_TIME_UNITS,
    STORE_PROGRESS,
} from '@store/common/constants';
import Vue from 'vue';

const state = {
    countries: [],
    states: [],
    languages: [],
    currencies: [],
    businessActivities: [],
    locationTypes: [],
    customerGroups: [],
    productGroups: [],
    timeUnits: [],
    productionUnits: [],
    foodGroupCategories: [],
    esubstancesList: [],
    additiveUsages: [],
    packagingList: [],
    claimsList: [],
    ipCountry: null,
    shortProductionUnits: [],
    priceList: [],
    isMobile: false,
    perPage: 10,
    currentPage: 1,
    newTimeUnits: [],
};

const mutations = {
    [SET_COUNTRIES](state, countries) {
        state.countries = countries;
    },
    [SET_NEW_TIME_UNITS](state, timeUnits) {
        state.newTimeUnits = timeUnits;
    },
    [SET_LANGUAGES](state, languages) {
        state.languages = languages;
    },
    [SET_CURRENCIES](state, currencies) {
        state.currencies = currencies;
    },
    [SET_BUSINESS_ACTIVITIES](state, businessActivities) {
        state.businessActivities = businessActivities;
    },
    [SET_LOCATION_TYPES](state, locationTypes) {
        state.locationTypes = locationTypes;
    },
    [SET_CUSTOMER_GROUPS](state, customerGroups) {
        state.customerGroups = customerGroups;
    },
    [SET_TIME_UNITS](state, timeUnits) {
        state.timeUnits = timeUnits;
    },
    [SET_PRODUCTION_UNITS](state, productionUnits) {
        state.productionUnits = productionUnits;
    },
    [SET_FOOD_GROUP_CATEGORIES](state, foodGroupCategories) {
        state.foodGroupCategories = foodGroupCategories;
    },
    [SET_PRODUCT_GROUPS](state, productGroups) {
        state.productGroups = productGroups;
    },
    [SET_ESUBSTANCES_LIST](state, data) {
        state.esubstancesList = data;
    },
    [SET_ADDITIVE_USAGES](state, data) {
        state.additiveUsages = data;
    },
    [SET_PACKAGING_LIST](state, data) {
        state.packagingList = data;
    },
    [SET_CLAIMS_LIST](state, data) {
        state.claimsList = data;
    },
    [SET_IP_COUNTRY](state, data) {
        state.ipCountry = data;
    },
    [SET_SHORT_PRODUCTION_UNITS](state, data) {
        state.shortProductionUnits = data;
    },
    [SET_PRICES_FOR_COUNTRY](state, data) {
        state.priceList.push(data);
    },
    [SET_IS_MOBILE](state, isMobile) {
        state.isMobile = isMobile;
    },

    [SET_PER_PAGE](state, value) {
        if (Number.isFinite(value)) {
            state.perPage = value;
        } else {
            throw new TypeError('Value should be a Number');
        }
    },

    [SET_CURRENT_PAGE](state, value) {
        if (Number.isFinite(value)) {
            state.currentPage = value;
        } else {
            throw new TypeError('Value should be a Number');
        }
    },
    setCountryStates(state, data) {
        state.states = data;
    },
};

const actions = {
    [FETCH_COUNTRIES]({ commit, state }) {
        if (state.countries.length) {
            return state.countries;
        }

        return commonService.getCountries().then((countries) => {
            commit(SET_COUNTRIES, countries);
            return countries;
        });
    },
    [FETCH_LANGUAGES]({ commit, state }) {
        if (state.languages.length) {
            return state.languages;
        }

        return commonService.getLanguages().then((languages) => {
            const processedLanguages = languages.map((code) => {
                const name = isoLanguages[code]
                    ? capitalizeFLetter(isoLanguages[code]?.nativeName)
                    : code.toUpperCase();

                return {
                    key: code,
                    name,
                };
            });

            commit(SET_LANGUAGES, processedLanguages);
            return processedLanguages;
        });
    },
    [FETCH_CURRENCIES]({ commit, state }) {
        if (state.currencies.length) {
            return state.currencies;
        }
        return commonService.getCurrencies().then((currencies) => {
            commit(SET_CURRENCIES, currencies);
            return currencies;
        });
    },
    [FETCH_BUSINESS_ACTIVITIES]({ commit, state }) {
        if (state.businessActivities.length) {
            return state.businessActivities;
        }

        return commonService.getBusinessActivities().then((businessActivities) => {
            commit(SET_BUSINESS_ACTIVITIES, businessActivities);
            return businessActivities;
        });
    },
    [FETCH_LOCATION_TYPES]({ commit, state }) {
        if (state.businessActivities.length) {
            return state.businessActivities;
        }

        return commonService.getLocationTypes().then((locationTypes) => {
            commit(SET_LOCATION_TYPES, locationTypes);
            return locationTypes;
        });
    },
    [FETCH_CUSTOMER_GROUPS]({ commit, state }) {
        if (state.customerGroups.length) {
            return state.customerGroups;
        }

        return commonService.getCustomerGroups().then((customerGroups) => {
            commit(SET_CUSTOMER_GROUPS, customerGroups);
            return customerGroups;
        });
    },
    [FETCH_TIME_UNITS]({ commit, state }) {
        if (state.timeUnits.length) {
            return state.timeUnits;
        }

        return commonService.getTimeUnits().then((timeUnits) => {
            commit(SET_TIME_UNITS, timeUnits);
            return timeUnits;
        });
    },
    [FETCH_NEW_TIME_UNITS]({ commit }) {
        return http.get('/api/classifiers/new-time-units').then(({ data }) => commit(SET_NEW_TIME_UNITS, data));
    },
    [FETCH_PRODUCTION_UNITS]({ commit, state }) {
        if (commonService.getProductionUnits.inProgress) {
            return;
        }

        if (state.productionUnits.length) {
            return state.productionUnits;
        }

        commonService.getProductionUnits.inProgress = true;

        return commonService
            .getProductionUnits()
            .then((productionUnits) => {
                commit(SET_PRODUCTION_UNITS, productionUnits);
                return productionUnits;
            })
            .finally(() => {
                delete commonService.getProductionUnits.inProgress;
            });
    },
    [FETCH_FOOD_GROUP_CATEGORIES]({ commit, state }) {
        if (state.foodGroupCategories.length) {
            return state.foodGroupCategories;
        }

        return commonService.getFoodGroupCategories().then((foodGroupCategories) => {
            commit(SET_FOOD_GROUP_CATEGORIES, foodGroupCategories);
            return foodGroupCategories;
        });
    },
    [FETCH_PRODUCT_GROUPS]({ commit, state }) {
        if (state.productGroups.length) {
            return state.productGroups;
        }

        return commonService.getProductGroups().then((productGroups) => {
            commit(SET_PRODUCT_GROUPS, productGroups);

            return productGroups;
        });
    },
    [FETCH_ESUBSTANCES_LIST]({ commit, state }) {
        if (state.esubstancesList.length) {
            return state.esubstancesList;
        }

        return commonService.getEsubstancesList().then((res) => {
            commit(SET_ESUBSTANCES_LIST, res);

            return res;
        });
    },
    [FETCH_ADDITIVE_USAGES]({ commit, state }) {
        if (state.additiveUsages.length) {
            return state.additiveUsages;
        }

        return commonService.getAdditiveUsages().then((res) => {
            commit(SET_ADDITIVE_USAGES, res);

            return res;
        });
    },
    [FETCH_PACKAGING_LIST]({ commit, state }) {
        if (state.packagingList.length) {
            return state.packagingList;
        }

        return commonService.getPackagingList().then((res) => {
            commit(SET_PACKAGING_LIST, res);

            return res;
        });
    },
    [FETCH_CLAIMS_LIST]({ commit, state }) {
        if (state.claimsList.length) {
            return state.claimsList;
        }

        return commonService.getClaimsList().then((res) => {
            commit(SET_CLAIMS_LIST, res);

            return res;
        });
    },
    [FETCH_IP_COUNTRY]({ commit, state }) {
        if (state.ipCountry) {
            return state.ipCountry;
        }

        return commonService.getIpCountry().then((res) => {
            commit(SET_IP_COUNTRY, res);

            return res;
        });
    },
    [STORE_PROGRESS](_, data) {
        // TODO this is not called out three times anymore, needs refacto
        let body = {};

        if (data.sessionId) {
            if (data.about) {
                body = {
                    type: 'user',
                    sess_id: data.sessionId,
                    name: data.about?.name,
                    email: data.about?.email,
                    code: data.about?.coupon,
                };
            }

            if (data.company) {
                body = {
                    type: 'company',
                    sess_id: data.sessionId,
                    name: data.company?.name,
                    email: data.company?.email,
                    code: data.company?.regCode,
                    vat: data.company?.vatCode,
                    address: data.company?.address,
                };
            }

            if (data.verification) {
                body = {
                    type: 'user',
                    sess_id: data.sessionId,
                    phone: `${data.verification?.phoneCode?.phone_code} ${data.verification?.phone}`,
                };
            }

            return commonService.storeProgress(body).then((res) => {
                return res;
            });
        }
    },
    [FETCH_SHORT_PRODUCTION_UNITS]({ commit, state }) {
        commit(SET_SHORT_PRODUCTION_UNITS, getShortProductionUnits(state.productionUnits));
    },
    [FETCH_TECHNOLOGIST_PRICE]({ commit, getters }) {
        const countryId = getters.ipCountry && getters.ipCountry.id;
        const priceList = getters.priceList.find((list) => list.countryId === countryId);
        if (priceList) {
            return priceList;
        } else {
            return commonService.fetchTechnologistPrice(countryId).then((res) => {
                commit(SET_PRICES_FOR_COUNTRY, {
                    countryId,
                    price_list: res.price_list,
                });
                return res;
            });
        }
    },
    [LOG_ERROR](_, message) {
        console.error(message);
        if (Vue.rollbar) {
            Vue.rollbar.error(message);
        }
    },
    async setCountryStates(state, countryId) {
        const countryStates = await http.get('/api/classifiers/states', { params: { country_id: countryId } });
        state.commit('setCountryStates', countryStates.data);
    },
};

const getters = {
    foodGroupCategories(state) {
        return state.foodGroupCategories;
    },
    countries(state) {
        return state.countries;
    },
    getCountryCodeById: (state, getters) => (id) => getters.countries.find((i) => i.id === id),
    countriesCodes(state) {
        const res = {};
        state.countries.forEach((i) => {
            if (!res[i.phone_code]) {
                res[i.phone_code] = [];
            }

            res[i.phone_code].push(i);
        });
        return Object.keys(res)
            .map((key) => {
                return {
                    ...res[key][0],
                    countries: res[key].map((i) => i.id),
                };
            })
            .sort((a, b) => {
                if (a.phone_code.toString() > b.phone_code.toString()) {
                    return 1;
                } else if (a.phone_code.toString() < b.phone_code.toString()) {
                    return -1;
                }

                return 0;
            });
    },
    availableLanguages: (state) => state.languages,
    currencies: (state) => state.currencies,
    countryStates: (state) => state.states,
    businessActivities: (state) => {
        return state.businessActivities;
    },
    locationTypes: (state) => {
        return state.locationTypes;
    },
    customerGroups: (state) => {
        return state.customerGroups;
    },
    timeUnits: (state) => {
        return state.timeUnits;
    },
    productionUnits(state) {
        return state.productionUnits;
    },
    productGroups(state) {
        return state.productGroups;
    },
    esubstancesList(state) {
        return state.esubstancesList;
    },
    additiveUsages(state) {
        return state.additiveUsages;
    },
    packagingList(state) {
        return state.packagingList;
    },
    claimsList(state) {
        return state.claimsList || [];
    },
    ipCountry(state) {
        return state.ipCountry || null;
    },
    shortProductionUnits(state) {
        return state.shortProductionUnits;
    },
    isMobileView(state) {
        return state.isMobile;
    },
    scheduleOptions: () => (typeId) => {
        switch (typeId) {
            case DEVICES.ANALYSES:
            case DEVICES.CALIBRATION:
            case DEVICES.MAINTENANCE: {
                return [
                    'once_in_2_months',
                    'once_in_month',
                    'once_in_quartal',
                    '2 times in a year',
                    'once_in_year',
                    'not_specified',
                ];
            }
            case DEVICES.PEST_CONTROL: {
                return ['once_in_2_months', 'once_in_month', 'once_in_quartal', 'once_in_year', 'not_specified'];
            }
            case DEVICES.CLEANING_AND_DISINFECTION_TASKS: {
                return [
                    'twice_a_day',
                    'once_a_day',
                    'once_a_week',
                    'other',
                    'once_in_month',
                    'once_in_quartal',
                    'not_specified',
                ];
            }
            case DEVICES.SUPERVISORY_TASKS:
            case DEVICES.TASKS: {
                return [
                    'twice_a_day',
                    'once_a_day',
                    'once_a_week',
                    'other',
                    'once_in_month',
                    'once_in_quartal',
                    '2 times in a year',
                    'not_specified',
                ];
            }
            default: {
                return ['twice_a_day', 'once_a_day', 'once_a_week', 'other', 'not_specified'];
            }
        }
    },
    priceList(state) {
        return state.priceList;
    },
    currentPage(state) {
        return +state.currentPage || 1;
    },
    perPage(state) {
        return +state.perPage || 10;
    },

    FILE_SIZE_5MB() {
        return FILE_SIZE_5MB;
    },
    getNewTimeUnits(state) {
        return state.newTimeUnits;
    },
    getProductionTimeUnits(state) {
        return state.newTimeUnits.filter((unit) => !['minute', 'second'].includes(unit.name.toLowerCase()));
    },
    getNewTimeUnitsWMY(state) {
        const WEEK = 5;
        const MONTH = 6;
        const YEAR = 7;

        return state.newTimeUnits.filter((unit) => [WEEK, MONTH, YEAR].includes(unit.id));
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
