// Mutations
export const SET_CORRECTING_ACTIONS = 'SET_CORRECTING_constants';
export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';
export const UPDATE_MEASUREMENT = 'UPDATE_MEASUREMENT';
export const UPDATE_MEASUREMENT_ATTACHMENTS = 'UPDATE_MEASUREMENT_ATTACHMENTS';
export const UPDATE_MEASUREMENT_ITEM = 'monitoring/UPDATE_MEASUREMENT_ITEM';
export const CLEAR_MEASUREMENTS = 'CLEAR_MEASUREMENTS';
export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_MONITORING_PER_PAGE = 'SET_MONITORING_PER_PAGE';
export const RETURN_MEASUREMENT_ITEM = 'monitoring/RETURN_MEASUREMENT_ITEM';
export const RESET_MONITORING = 'monitoring/RESET_MONITORING';
export const SET_SEARCH_QUERY = 'monitoring/SET_SEARCH_QUERY';
export const SET_SORT_BY = 'monitoring/SET_SORT_BY';

// Actions
export const INIT_MONITORING = 'INIT_MONITORING';
export const INIT_CORRECTING_ACTIONS = 'INIT_CORRECTING_constants';
export const INIT_DEVICE = 'monitoring/INIT_DEVICE';
export const FETCH_MEASUREMENTS = 'FETCH_MEASUREMENTS';
export const FETCH_ALL_MEASUREMENTS_FOR_PERIOD = 'FETCH_ALL_MEASUREMENTS_FOR_PERIOD';
export const UPDATE_MEASUREMENTS = 'UPDATE_MEASUREMENTS';
export const UPLOAD_ATTACHMENT = 'UPLOAD_IMAGE_FOR_MEASUREMENT';
export const DOWNLOAD_ATTACHMENT = 'monitoring/DOWNLOAD_ATTACHMENT';
export const APPLY_MEASUREMENT_CHANGES = 'monitoring/APPLY_MEASUREMENT_CHANGES';
export const RESET_MONITORING_AND_DEPENDENCIES = 'monitoring/RESET_MONITORING_AND_DEPENDENCIES';
export const SEARCH_QUERY = 'monitoring/SEARCH_QUERY';
export const FILTER_REQUEST = 'monitoring/FILTER_REQUEST';
export const ADD_SERVICE_PROVIDER = 'monitoring/ADD_SERVICE_PROVIDER';
export const CREATE_NEW_ACCOMPLISHED_TASK = 'monitoring/tasks/CREATE_NEW_ACCOMPLISHED_TASK';
export const GET_IS_VALID_TEMPERATURE = 'monitoring/GET_IS_VALID_TEMPERATURE';

// Both
export const ADD_NEW_MEASUREMENT = 'ADD_NEW_MEASUREMENT';
export const DELETE_MEASUREMENT = 'DELETE_MEASUREMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';

// Constants
export const SORT_BY = {
    ALL: 'all',
    INCORRECT: 'incorrect',
};
