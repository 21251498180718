import http from '@http';
import {
    ADD_ADDITIVES_ATTACHMENT,
    ADD_CUSTOM_INGREDIENT_TO_LIST,
    ADD_INGREDIENTS_ATTACHMENT,
    ADD_PACKAGING_ATTACHMENT,
    ADD_PRICE_TO_INGREDIENT,
    ADD_PROPERTIES_IMAGE,
    CREATE_CUSTOM_INGREDIENT,
    CREATE_PRODUCT,
    DELETE_ADDITIVES,
    DELETE_ADDITIVES_ATTACHMENT,
    DELETE_CUSTOM_INGREDIENT,
    DELETE_CUSTOM_INGREDIENT_ATTACHMENT,
    DELETE_INGREDIENT,
    DELETE_INGREDIENT_ATTACHMENT,
    DELETE_PACKAGING_ATTACHMENT,
    DELETE_PRICE_FROM_INGREDIENT,
    DELETE_PRODUCT,
    DELETE_PRODUCT_HAZARDS,
    DELETE_PROPERTIES_ATTACHMENT,
    DOWNLOAD_PACKAGE_INFO_PDF,
    DOWNLOAD_PRODUCTION_SHEET_PDF,
    DOWNLOAD_PRODUCTS_CSV,
    DOWNLOAD_PRODUCT_INGREDIENTS_CSV,
    DOWNLOAD_TECH_CARD_PDF,
    DOWNLOAD_TECH_DESC_PDF,
    FETCH_PRODUCT,
    FETCH_PRODUCTS,
    FETCH_PRODUCT_PUBLIC,
    GET_CUSTOM_INGREDIENT,
    GET_INGREDIENT_PRICE_LIST,
    GET_PRODUCT_IMAGE_BASE64,
    GET_PRODUCT_IMAGE_BASE64_PUBLIC,
    GET_SUB_PRODUCT,
    INIT_CUSTOM_INGREDIENTS_LIST,
    INIT_GROUPS,
    INIT_INSTRUCTIONS,
    INIT_PRODUCTION,
    INIT_PRODUCT_GENERAL,
    INIT_PRODUCT_INGREDIENTS,
    INIT_PRODUCT_PACKAGE,
    REMOVE_PRODUCT_INSTRUCTION,
    RETURN_ADDITIVES_ATTACHMENT_ITEM,
    RETURN_INGREDIENT_ATTACHMENT_ITEM,
    RETURN_PACKAGE_ATTACHMENT_ITEM,
    RETURN_PROPERTY_ATTACHMENT_ITEM,
    SEARCH_FOR_INGREDIENTS,
    SEARCH_INGREDIENT_TO_ADD_PRICE,
    SET_ADDITIVES,
    SET_CUSTOM_INGREDIENTS,
    SET_CUSTOM_INGREDIENTS_LIST,
    SET_INGREDIENT,
    SET_INGREDIENTS,
    SET_INGREDIENT_PRICE_LIST,
    SET_PRODUCT,
    SET_PRODUCTS,
    SET_PRODUCT_INGREDIENTS,
    UPDATE_ADDITIVES,
    UPDATE_ADDITIVES_ATTACHMENTS,
    UPDATE_CUSTOM_INGREDIENT,
    UPDATE_INGREDIENT,
    UPDATE_INGREDIENT_ATTACHMENTS,
    UPDATE_PACKAGING,
    UPDATE_PACKAGING_ATTACHMENTS,
    UPDATE_PREPARATION,
    UPDATE_PRICE_TO_INGREDIENT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_INSTRUCTIONS,
} from '@views/Production/store/constants';
import {
    FETCH_ADDITIVE_USAGES,
    FETCH_CLAIMS_LIST,
    FETCH_CUSTOMER_GROUPS,
    FETCH_ESUBSTANCES_LIST,
    FETCH_PACKAGING_LIST,
    FETCH_PRODUCTION_UNITS,
    FETCH_PRODUCT_GROUPS,
    FETCH_TIME_UNITS,
    LOG_ERROR,
} from '@store/common/constants';
import { INIT_STORAGES } from '@store/storage/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { productionService } from '@services/production.service';
import { GET_AND_DELETE_FROM_ARCHIVE, REMOVE_FROM_ARCHIVE, SAVE_TO_ARCHIVE } from '@store/local-archive/constants';
import { vm } from '@/main';
import Vue from 'vue';
import { download, isImageExtensionByName } from '@services/helper.service';
import { attachmentsService } from '@services/attachments.service';
import productService from '@views/Product/services/product.service';
import {
    addDefaultNamesToCustomIngredient,
    deleteCustomIngredientAttachments,
    deleteIngredientByTypeFn,
    setIngredientsByType,
    updateCustomIngredient,
} from '@views/Production/store/helper';

export default {
    async [REMOVE_PRODUCT_INSTRUCTION]({ rootGetters, commit, dispatch }, { productId, hazard }) {
        const { data } = await http.delete(
            `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/hazards/${hazard.id}`
        );

        commit(DELETE_PRODUCT_HAZARDS, { productId, hazardId: hazard.id });

        await dispatch('plan/fetchPlanData', {
            isPlanChanged: true,
        });

        return data;
    },
    async [INIT_INSTRUCTIONS]({ getters }, { productId }) {
        const res = await http.put(`api/module/production/products/${productId}/add-fsp-table`, {
            placeId: getters.selectedPlaceId,
        });

        return res?.data;
    },

    [INIT_PRODUCTION]({ dispatch }) {
        return Promise.all([dispatch(FETCH_PRODUCTS)]);
    },

    [INIT_GROUPS]({ dispatch }) {
        return Promise.all([dispatch(FETCH_PRODUCT_GROUPS), dispatch(FETCH_CUSTOMER_GROUPS)]);
    },

    [INIT_PRODUCT_GENERAL]({ dispatch }) {
        return Promise.all([dispatch(FETCH_PRODUCTS), dispatch(FETCH_PRODUCT_GROUPS), dispatch(FETCH_CUSTOMER_GROUPS)]);
    },

    [INIT_PRODUCT_INGREDIENTS]({ dispatch }) {
        return Promise.all([
            dispatch(FETCH_PRODUCTION_UNITS),
            dispatch(FETCH_ESUBSTANCES_LIST),
            dispatch(FETCH_ADDITIVE_USAGES),
        ]);
    },

    [INIT_PRODUCT_PACKAGE]({ dispatch }) {
        return Promise.all([
            dispatch(FETCH_PRODUCTION_UNITS),
            dispatch(INIT_STORAGES),
            dispatch(FETCH_TIME_UNITS),
            dispatch(FETCH_PACKAGING_LIST),
            dispatch(FETCH_CLAIMS_LIST),
        ]);
    },

    [FETCH_PRODUCTS]({ commit, getters }) {
        if (getters.hasProducts) {
            return Promise.resolve();
        }

        if (!getters.selectedCompanyId || !getters.selectedPlaceId) {
            return Promise.resolve();
        }

        commit(START_VIEW_LOADER);

        return productionService
            .getAll(getters.selectedCompanyId, getters.selectedPlaceId)
            .then((res) => {
                commit(SET_PRODUCTS, res);
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    [FETCH_PRODUCT]({ commit, getters }, productId) {
        commit(START_VIEW_LOADER);
        return productionService
            .get(productId, getters.selectedPlaceId)
            .then((res) => {
                commit(SET_PRODUCT, { data: res });
                return res;
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    [GET_SUB_PRODUCT](_, productId) {
        return productionService.get(productId);
    },

    [CREATE_PRODUCT]({ commit, getters }, data) {
        const body = {
            name: data.name,
            code: data.code,
            brand: data.brand,
            served_food: data.isServedFood,
            traceability: data.traceability,
        };

        if (data.locationType === 'place') {
            body.places = [getters.selectedPlaceId];
        } else if (data.locationType === 'company') {
            body.company_id = getters.selectedCompanyId;
        }

        commit(START_VIEW_LOADER);
        if (data.isCopy) {
            body.duplicate_from = data.copyFrom.id;

            return productionService
                .duplicate(body)
                .then((res) => {
                    if (res) {
                        commit(CREATE_PRODUCT, res);

                        return res;
                    }

                    return Promise.reject();
                })
                .finally(() => commit(STOP_VIEW_LOADER));
        } else {
            body.product_group_id = data.product_group_id;
            body.customer_group_id = data.customer_group_id;

            return productionService
                .create(getters.selectedCompanyId, body)
                .then((res) => {
                    if (res) {
                        commit(CREATE_PRODUCT, res);

                        return res;
                    }

                    return Promise.reject();
                })
                .finally(() => commit(STOP_VIEW_LOADER));
        }
    },

    [SET_INGREDIENTS]({ commit }, { productId, data }) {
        return setIngredientsByType(commit, productId, data, false);
    },

    [SET_CUSTOM_INGREDIENTS]({ commit }, { productId, data }) {
        setIngredientsByType(commit, productId, data, true);
    },

    [SET_PRODUCT_INGREDIENTS]({ commit }, { productId, data }) {
        setIngredientsByType(commit, productId, data, false, true);
    },

    [ADD_INGREDIENTS_ATTACHMENT]({ commit }, { ingredientId, productId, file, onProgress }) {
        return productionService
            .uploadIngredientAttachment(ingredientId, file, {
                onUploadProgress: onProgress,
            })
            .then((res) => {
                commit(UPDATE_INGREDIENT_ATTACHMENTS, {
                    productId,
                    ingredientId: ingredientId,
                    file_list: res.file_list,
                });
                return res;
            });
    },

    [DELETE_INGREDIENT]({ commit }, { productId, ingredientId }) {
        return deleteIngredientByTypeFn(commit, productId, ingredientId);
    },

    async [SET_ADDITIVES]({ commit }, { productId, data }) {
        const toCreate = [];
        const toUpdate = [];

        data.forEach((item) => {
            const data = {
                esubstance_id: item.esubstance && item.esubstance.id,
                net_amount: item.net_amount,
                bruto_amount: item.bruto_amount,
                production_unit: item.production_unit && item.production_unit.id,
                additive_usage_id: item.additive_usage && item.additive_usage.id,
                file_list: item.file_list,
            };

            if (item.newItem) {
                toCreate.push(data);
            } else if (item.hasUpdated) {
                toUpdate.push({
                    id: item.id,
                    ...data,
                });
            }
        });

        const requests = [];

        if (toCreate.length) {
            requests.push(
                productionService.setEsubstance(productId, {
                    esubstance: toCreate,
                })
            );
        }

        if (toUpdate.length) {
            requests.push(
                productionService.updateEsubstance(productId, {
                    esubstances: toUpdate,
                })
            );
        }

        if (requests.length) {
            return Promise.all(requests).then((res) => {
                const data = [];

                res.forEach((item) => {
                    if (item.length) {
                        data.push(...item);
                    } else {
                        data.push(item);
                    }
                });

                commit(UPDATE_ADDITIVES, { productId, additivesList: data });

                return res;
            });
        }
    },

    async [DELETE_ADDITIVES]({ commit }, { productId, esubstanceId }) {
        if (productId && esubstanceId) {
            return productionService.deleteEsubstance(productId, esubstanceId).then(() => {
                commit(DELETE_ADDITIVES, { productId, esubstanceId });
            });
        }
    },
    [UPDATE_PRODUCT_INSTRUCTIONS]({ getters, commit }, { processes, productId }) {
        commit(START_VIEW_LOADER);
        return http
            .put(`/api/module/production/products/${productId}?placeId=${getters.selectedPlaceId}`, { processes })
            .then(({ data }) => {
                commit(UPDATE_PRODUCT, data);
                return data;
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },
    [UPDATE_PRODUCT]({ commit, dispatch, getters }, { id, data }) {
        const product = getters.getProductById(id);

        const body = {
            name: data.name,
            product_group_id: data.product_group_id,
            customer_group_id: data.customer_group_id,
            code: data.code,
            brand: data.brand,
            served_food: data.isServedFood,
            traceability: data.traceability,
        };

        if (!data.product_group_id && product.product_group) {
            data.product_group_id = product.product_group.id;
        }
        if (!data.customer_group_id && product.customer_group) {
            data.customer_group_id = product.customer_group.id;
        }
        if (data.netTotal) {
            body.net_total = data.netTotal.value;
            body.net_total_unit_id = (data.netTotal.unit && data.netTotal.unit.id) || null;
        }

        if (data.sales_price) {
            body.sales_price = data.sales_price;
        }

        if (data.locationType === 'place') {
            body.places = [getters.selectedPlaceId];
            body.company_id = null;
        } else if (data.locationType === 'company') {
            body.company_id = getters.selectedCompanyId;
            body.places = null;
        } else if (data.locationType) {
            dispatch(LOG_ERROR, `Product locationType must be place or company, it was: ${data}`);
        }

        commit(START_VIEW_LOADER);
        return productionService
            .update(id, body)
            .then((res) => {
                commit(UPDATE_PRODUCT, res);
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [UPDATE_PREPARATION]({ commit }, { id, data }) {
        commit(START_VIEW_LOADER);
        return productionService
            .updatePreparation(id, data)
            .then((res) => {
                commit(UPDATE_PREPARATION, { productId: id, data: res });
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [UPDATE_PACKAGING]({ commit }, { id, data }) {
        commit(START_VIEW_LOADER);
        return productionService
            .updatePackaging(id, data)
            .then((res) => {
                commit(UPDATE_PACKAGING, { productId: id, data: res });
                return res;
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [DELETE_PRODUCT]({ commit, dispatch, getters }, id) {
        const path = ['production', 'delete', 'product', id];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index: getters.getProductIndexById(id),
                data: getters.getProductById(id),
            },
            path,
        });
        commit(DELETE_PRODUCT, id);

        return productionService
            .delete(id)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch(() => {
                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(SET_PRODUCT, { index, data });
                });
            });
    },

    [SEARCH_FOR_INGREDIENTS]({ getters }, { query, productId }) {
        return productionService
            .searchIngredients(getters.selectedCompanyId, getters.selectedPlaceId, query, vm.$i18n.locale, productId)
            .then((res) => {
                if (!res) {
                    if (Vue.rollbar) {
                        Vue.rollbar.error("Can't get res in SEARCH_FOR_INGREDIENTS");
                    }

                    return [];
                }

                return [
                    {
                        shownCategory: 'products',
                        category: 'products',
                        ingredients:
                            (Array.isArray(res.products) &&
                                res.products.map((product) => {
                                    product.product = true;
                                    return {
                                        id: product.id,
                                        name: product.name,
                                        product: true,
                                        cost_price: product.cost_price,
                                    };
                                })) ||
                            [],
                    },
                    {
                        shownCategory: 'custom_ingredients',
                        category: 'custom_ingredients',
                        ingredients:
                            (Array.isArray(res.custom_ingredients) &&
                                res.custom_ingredients.map((ing) => {
                                    ing.custom = true;
                                    return ing;
                                })) ||
                            [],
                    },
                    {
                        shownCategory: 'ingredients',
                        category: 'ingredients',
                        ingredients: res.ingredients || [],
                    },
                ];
            });
    },

    [DELETE_INGREDIENT_ATTACHMENT]({ commit, dispatch, getters }, { productId, ingredientId, attachmentId }) {
        const { data, index } = getters.getIngredientsAttachment(productId, ingredientId, attachmentId);
        const path = ['production', productId, 'ingredient', ingredientId, 'attachment', attachmentId];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index,
                data,
            },
            path,
        });
        commit(DELETE_INGREDIENT_ATTACHMENT, { productId, ingredientId, attachmentId });

        return productionService
            .deleteAttachment(attachmentId)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch((err) => {
                dispatch(LOG_ERROR, `productionService.deleteAttachment (ingredient) error: ${err}`);

                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(RETURN_INGREDIENT_ATTACHMENT_ITEM, { productId, ingredientId, index, data });
                });
            });
    },

    [ADD_ADDITIVES_ATTACHMENT]({ commit }, { additiveId, productId, file, onProgress }) {
        return productionService
            .uploadAdditiveAttachment(additiveId, file, {
                onUploadProgress: onProgress,
            })
            .then((res) => {
                commit(UPDATE_ADDITIVES_ATTACHMENTS, {
                    productId,
                    additiveId: additiveId,
                    file_list: res.file_list,
                });
            });
    },

    [DELETE_ADDITIVES_ATTACHMENT]({ commit, getters, dispatch }, { productId, additivesId, attachmentId }) {
        const { data, index } = getters.getAdditivesAttachment(productId, additivesId, attachmentId);
        const path = ['production', productId, 'additives', additivesId, 'attachment', attachmentId];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index,
                data,
            },
            path,
        });
        commit(DELETE_ADDITIVES_ATTACHMENT, { productId, additivesId, attachmentId });

        return productionService
            .deleteAttachment(attachmentId)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch((err) => {
                dispatch(LOG_ERROR, `productionService.deleteAttachment (additives) error: ${err}`);

                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(RETURN_ADDITIVES_ATTACHMENT_ITEM, { productId, additivesId, index, data });
                });
            });
    },

    [ADD_PROPERTIES_IMAGE]({ commit }, { productId, file, onProgress }) {
        return productionService
            .addImage(productId, file, {
                onUploadProgress: onProgress,
            })
            .then((res) => {
                commit(ADD_PROPERTIES_IMAGE, { productId, files: res.files, thumbnail: res.thumbnail });
            });
    },

    async [GET_PRODUCT_IMAGE_BASE64](_, attachment) {
        if (!isImageExtensionByName(attachment.filename)) {
            return null;
        }

        return attachmentsService.fetchAttachmentContent(attachment.id).then((res) => {
            const urlCreator = URL || webkitURL; // eslint-disable-line
            const base64 = urlCreator.createObjectURL(res.data);
            attachment.base64 = base64;
            return base64;
        });
    },

    async [GET_PRODUCT_IMAGE_BASE64_PUBLIC](_, { attachment, token }) {
        if (!isImageExtensionByName(attachment.filename)) {
            return null;
        }

        return attachmentsService.fetchAttachmentPublicContent(attachment.id, token).then((res) => {
            const urlCreator = URL || webkitURL; // eslint-disable-line
            const base64 = urlCreator.createObjectURL(res.data);
            attachment.base64 = base64;
            return base64;
        });
    },

    [DOWNLOAD_TECH_CARD_PDF](_, productId) {
        return productionService.downloadTechCardPDF(productId, vm.$i18n.locale).then((res) => {
            download(`technological-card_${productId}.pdf`, res.data, 'application/pdf');
        });
    },

    [DOWNLOAD_TECH_DESC_PDF](_, productId) {
        return productionService.downloadTechDescPDF(productId, vm.$i18n.locale).then((res) => {
            download(`technological-desc_${productId}.pdf`, res.data, 'application/pdf');
        });
    },

    [DOWNLOAD_PACKAGE_INFO_PDF](_, productId) {
        return productionService.downloadPackageInfoPDF(productId, vm.$i18n.locale).then((res) => {
            download(`package-info_${productId}.pdf`, res.data, 'application/pdf');
        });
    },

    [DOWNLOAD_PRODUCTION_SHEET_PDF](_, data) {
        return productionService.downloadProductionSheetPDF(data, vm.$i18n.locale).then((res) => {
            download(`production-sheet_${data.productId}.pdf`, res.data, 'application/pdf');
        });
    },

    [DELETE_PROPERTIES_ATTACHMENT]({ commit, getters, dispatch }, { productId, id }) {
        const { data, index } = getters.getPropertiesAttachment(productId, id);
        const path = ['production', productId, 'properties', 'attachment', id];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index,
                data,
            },
            path,
        });

        commit(DELETE_PROPERTIES_ATTACHMENT, { productId, attachmentId: id });

        return productionService
            .deleteAttachment(id)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch((err) => {
                dispatch(LOG_ERROR, `productionService.deleteAttachment (properties) error: ${err}`);

                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(RETURN_PROPERTY_ATTACHMENT_ITEM, { productId, index, data });
                });
            });
    },

    [ADD_PACKAGING_ATTACHMENT]({ commit }, { productId, packagingId, file, onProgress }) {
        return productionService
            .addPackagingAttachment(packagingId, file, {
                onUploadProgress: onProgress,
            })
            .then((res) => {
                commit(UPDATE_PACKAGING_ATTACHMENTS, { productId, data: res.files });
            });
    },

    [DELETE_PACKAGING_ATTACHMENT]({ commit, getters, dispatch }, { productId, attachmentId }) {
        const { data, index } = getters.getPackageAttachment(productId, attachmentId);
        const path = ['production', productId, 'package', 'attachment', attachmentId];

        commit(SAVE_TO_ARCHIVE, {
            value: {
                index,
                data,
            },
            path,
        });
        commit(DELETE_PACKAGING_ATTACHMENT, { productId, attachmentId });

        return productionService
            .deleteAttachment(attachmentId)
            .then(() => {
                commit(REMOVE_FROM_ARCHIVE, path);
            })
            .catch((err) => {
                dispatch(LOG_ERROR, `productionService.deleteAttachment (pakcaging) error: ${err}`);

                dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                    commit(RETURN_PACKAGE_ATTACHMENT_ITEM, { productId, index, data });
                });
            });
    },

    [CREATE_CUSTOM_INGREDIENT]({ commit, getters }, customIngredient) {
        return productionService
            .createCustomIngredient(getters.selectedCompanyId, addDefaultNamesToCustomIngredient(customIngredient))
            .then(
                (customIng) => {
                    commit(ADD_CUSTOM_INGREDIENT_TO_LIST, customIng);
                    return customIng;
                },
                (err) => {
                    return Promise.reject(err);
                }
            );
    },

    [GET_CUSTOM_INGREDIENT](_, ingredientId) {
        return productionService.getCustomIngredientByID(ingredientId).then(
            (customIng) => {
                return customIng;
            },
            (err) => {
                return Promise.reject(err);
            }
        );
    },

    [UPDATE_CUSTOM_INGREDIENT]({ commit }, { customIngredient, attachmentsToDelete }) {
        customIngredient.attachments =
            customIngredient.attachments &&
            customIngredient.attachments
                .filter((file) => !file.id)
                .map((item) => ({
                    certificate_file: item.data,
                    certificate_file_name: item.filename,
                }));

        return deleteCustomIngredientAttachments(attachmentsToDelete).then(() =>
            updateCustomIngredient(customIngredient).then((ing) => {
                commit(UPDATE_CUSTOM_INGREDIENT, ing);
                return ing;
            })
        );
    },

    [DELETE_CUSTOM_INGREDIENT_ATTACHMENT]({ commit }, { customIngredientId, attachmentId }) {
        return productionService.deleteCustomIngredientAttachment(customIngredientId, attachmentId).then((data) => {
            commit(DELETE_CUSTOM_INGREDIENT_ATTACHMENT, {
                customIngredientId,
                attachmentId,
            });
            return data;
        });
    },

    [FETCH_PRODUCT_PUBLIC]({ commit, getters }, token) {
        if (getters.getProductByToken(token)) {
            return getters.getProductByToken(token);
        }

        if (token) {
            return productService.getByToken(token).then((product) => {
                commit(SET_PRODUCT, { data: product });
                return product;
            });
        } else {
            vm.$toastr.e(vm.$t('No token'));
        }
    },
    [INIT_CUSTOM_INGREDIENTS_LIST]({ commit, getters }) {
        if (!getters.getCustomIngredientsList.length) {
            commit(START_VIEW_LOADER);
            return productionService
                .getCustomIngredientsList(getters.selectedCompanyId)
                .then((data) => {
                    commit(SET_CUSTOM_INGREDIENTS_LIST, data);
                })
                .catch(() => {
                    vm.$toastr.e(vm.$t('Error getting Custom Ingredients list'));
                })
                .finally(() => {
                    commit(STOP_VIEW_LOADER);
                });
        }
    },
    [DELETE_CUSTOM_INGREDIENT]({ commit }, id) {
        productionService.deleteCustomIngredient(id).then(() => {
            commit(DELETE_CUSTOM_INGREDIENT, id);
        });
    },
    [GET_INGREDIENT_PRICE_LIST]({ commit, getters }) {
        if (!getters.getIngredientsList.length) {
            commit(START_VIEW_LOADER);
            productionService
                .getIngredientsWithPriceForCompany(getters.selectedCompanyId)
                .then((data) => {
                    commit(SET_INGREDIENT_PRICE_LIST, data);
                })
                .finally(() => {
                    commit(STOP_VIEW_LOADER);
                });
        }
    },

    [SEARCH_INGREDIENT_TO_ADD_PRICE]({ getters }, { query }) {
        return productionService.searchIngredientsForAddingPrice(getters.selectedCompanyId, query);
    },

    [ADD_PRICE_TO_INGREDIENT]({ getters, commit }, data) {
        return productionService.addPriceToIngredient(getters.selectedCompanyId, data).then((res) => {
            commit(SET_INGREDIENT, res);
            return res;
        });
    },

    [UPDATE_PRICE_TO_INGREDIENT]({ getters, commit }, data) {
        return productionService.updatePriceToIngredient(getters.selectedCompanyId, data).then((res) => {
            commit(UPDATE_INGREDIENT, res);
            return res;
        });
    },

    [DELETE_PRICE_FROM_INGREDIENT]({ getters, commit }, id) {
        return productionService.deletePriceToIngredient(getters.selectedCompanyId, id).then(() => {
            commit(DELETE_PRICE_FROM_INGREDIENT, id);
        });
    },

    [DOWNLOAD_PRODUCTS_CSV]({ getters, commit }, idsList) {
        commit(START_VIEW_LOADER);

        return productionService
            .downloadCSV(getters.selectedCompanyId, getters.selectedPlaceId, idsList)
            .finally(() => commit(STOP_VIEW_LOADER));
    },
    [DOWNLOAD_PRODUCT_INGREDIENTS_CSV]({ getters, commit }, idsList) {
        commit(START_VIEW_LOADER);

        return productionService
            .downloadIngredientsCSV(getters.selectedCompanyId, getters.selectedPlaceId, idsList)
            .finally(() => commit(STOP_VIEW_LOADER));
    },
};
