<template>
    <div
        v-if="isEditable"
        v-click-outside="hideDatePicker"
        class="optional-date-picker position-relative"
        @click="toggleDatePicker"
    >
        <BaseInput
            :id="id"
            v-model="inputValue"
            :validation="validation"
            autocomplete="off"
            @input="inputUpdate"
            @keypress="hideDatePicker"
            @blur="onBlur"
        >
            <template #input-append>
                <div
                    class="input-group-text cursor-pointer bg-white"
                    @click.stop="toggleDatePicker"
                >
                    <div>
                        <i class="fd-calendar" />
                    </div>
                </div>
            </template>
        </BaseInput>

        <DatePicker
            v-if="isShown"
            :value="dateValue"
            :config="options"
            :class="[
                {
                    'is-invalid': validation && validation.$error,
                },
                'optional-date-picker__datepicker',
                'shadow-lg',
                'bg-white',
                'position-absolute',
            ]"
            @input="dateUpdate"
        />
    </div>
    <span v-else>
        <slot :value="value">
            {{ value === 0 ? '-' : value }}
        </slot>
    </span>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue-bootstrap-datetimepicker';
import BaseInput from '@components/BaseInput';
import { mapGetters } from 'vuex';

// TODO: FD-5566 all components with works with dates should use DateValue instances as a value
export default {
    name: 'OptionalDatePicker',

    components: {
        BaseInput,
        DatePicker,
    },

    props: {
        id: {
            type: [String, Number],
            default: '',
        },
        value: {
            // TODO: FD-5566 should wait for an Object and Check is it DateValue
            type: [Date, String, Number],
            required: true,
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        format: {
            type: String,
            default: '',
        },
        minDate: {
            // TODO: FD-5566 should wait for an Object and Check is it DateValue
            type: [Date, String, Boolean],
            default: false,
        },
        maxDate: {
            // TODO: FD-5566 should wait for an Object and Check is it DateValue
            type: [Date, String, Boolean],
            default: false,
        },
        isEditable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isShown: false,

            inputValue: this.value,
            dateValue: null,
        };
    },

    computed: {
        ...mapGetters({
            selectedCompanyDateFormat: 'selectedCompanyDateFormat',
        }),
        options() {
            return {
                inline: true,
                format: this.format || '',
                maxDate: this.maxDate && moment(this.maxDate),
                minDate: this.minDate && moment(this.minDate),
            };
        },
    },

    watch: {
        value(val) {
            this.inputValue = val;
            this.dateValue = val || '';
        },
    },

    methods: {
        toggleDatePicker() {
            this.isShown = !this.isShown;
        },
        hideDatePicker() {
            this.isShown = false;
        },
        inputUpdate(value) {
            if (value.length === this.selectedCompanyDateFormat.length) {
                const date = moment(value, this.selectedCompanyDateFormat);
                if (date.isValid()) {
                    this.dateValue = date.format(this.selectedCompanyDateFormat);
                } else {
                    this.dateValue = null;
                }
            } else {
                this.dateValue = null;
            }
        },
        dateUpdate(value) {
            this.dateValue = value;

            if (this.dateValue && this.inputValue !== this.dateValue) {
                this.inputValue = this.dateValue;
            }

            this.update(this.dateValue);
            this.isShown = false;
        },
        update(value) {
            this.$emit('input', value);
        },
        onBlur() {
            this.update(this.dateValue || this.inputValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.optional-date-picker {
    &__datepicker {
        top: 50%;
        right: 20%;
        z-index: 9;
    }

    input {
        box-sizing: border-box;
        padding-right: 47px;
    }
}
</style>
