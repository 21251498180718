import moment from 'moment';
import { vm } from '@/main';

export class EntryField {
    public id?: number;
    public taskStepId: number;
    public value?: string | number;
    public name: string;
    public taskStepTypeName: string;
    public type: string;
    public files: string[];

    constructor(data?: any) {
        this.id = data?.id;
        this.taskStepId = data?.taskStepId;
        this.taskStepTypeName = data?.taskStepTypeName || '';
        this.name = data?.name || '';
        this.type = data?.type || '';
        this.files = data?.files || [];
        if (data?.value) {
            const apiDate = moment(data.value, 'YYYY-MM-DD HH:mm:ss');

            this.value = apiDate.isValid()
                ? apiDate.format(vm?.$store?.getters?.selectedCompanyDateFormat)
                : data.value;
        }
    }

    get valueToString(): string | null {
        // To ignore TS errors, because each field has different value types
        const value: any = this.value;
        if (!value) {
            return '';
        }
        switch (this.type) {
            case 'choose_answer': {
                return `${value.map((v) => ' ' + v.text)}`;
            }
            case 'choose_one_answer': {
                return `${value?.text || ''}`;
            }
            case 'choose_product': {
                return `${value?.name?.[vm.$i18n?.locale] || value?.name?.en || ''}`;
            }
            case 'enter_amount': {
                const amount = value?.amount;
                const unit = value?.unit?.short_name;
                return `${amount || ''}${amount ? ' ' + unit : ''}`;
            }
            case 'choose_partner': {
                return `${value}`;
            }
            case 'enter_value': {
                const amount = value?.amount;
                const unit = value?.unit;
                return `${amount || ''}${amount ? ' ' + unit : ''}`;
            }
            case 'enter_temperature': {
                const temp = value?.temp;
                const unit = value?.unit?.unit;
                return `${temp || ''}${temp ? ' ' + unit : ''}`;
            }
            case 'enter_date':
            case 'enter_text': {
                return `${value}`;
            }
            case 'ticket': {
                return '';
            }
            case 'attach_file':
            default: {
                return null;
            }
        }
    }

    toJSON() {
        const apiDate = moment(this.value, vm.$store.getters.selectedCompanyDateFormat);

        const json = {
            task_step_id: this.taskStepId,
            value: apiDate.isValid() ? apiDate.format('YYYY-MM-DD HH:mm:ss') : this.value || null,
        };

        this.id ? (json['id'] = this.id) : null;

        return json;
    }

    static fromJSON(json: any) {
        return new EntryField({
            id: json?.id,
            taskStepId: json?.task_step_id,
            value: json?.value,
            name: json?.name,
            type: json?.type,
            files: json?.file_list,
        });
    }

    static stringify(field: EntryField): String {
        const name = field.name ? `${field.name.substr(0, 1).toUpperCase()}${field.name.substr(1)}: ` : '';
        return field.valueToString ? `${name}${field.valueToString}` : '';
    }
}
