import {
    ADD_NEW_MEASUREMENT,
    ADD_SERVICE_PROVIDER,
    APPLY_MEASUREMENT_CHANGES,
    CLEAR_MEASUREMENTS,
    CREATE_NEW_ACCOMPLISHED_TASK,
    DELETE_ATTACHMENT,
    DELETE_MEASUREMENT,
    DOWNLOAD_ATTACHMENT,
    FETCH_MEASUREMENTS,
    GET_IS_VALID_TEMPERATURE,
    INIT_CORRECTING_ACTIONS,
    INIT_DEVICE,
    INIT_MONITORING,
    RESET_MONITORING,
    RESET_MONITORING_AND_DEPENDENCIES,
    RETURN_MEASUREMENT_ITEM,
    SEARCH_QUERY,
    SET_CORRECTING_ACTIONS,
    SET_MEASUREMENTS,
    SET_PAGINATION,
    SET_SEARCH_QUERY,
    SET_SORT_BY,
    SORT_BY,
    UPDATE_MEASUREMENT_ATTACHMENTS,
    UPDATE_MEASUREMENT_ITEM,
    UPLOAD_ATTACHMENT,
} from './constants';
import { INIT_STORAGES } from '@store/storage/constants';
import {
    INIT_DEVICES,
    INIT_DEVICES_TYPES,
    RESET_DEVICES,
    UPDATE_DEVICE,
    UPDATE_DEVICES,
} from '@store/devices/constants';
import { INIT_USER_PROFILE } from '@store/profile/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { GET_AND_DELETE_FROM_ARCHIVE, REMOVE_FROM_ARCHIVE, SAVE_TO_ARCHIVE } from '@store/local-archive/constants';
import { FETCH_PRODUCTS } from '@views/Production/store/constants';
// Services
import { measurementsService } from '@common/services/measurements.service';
import { monitoringService } from '@common/services/monitoring.service';
import { attachmentsService } from '@common/services/attachments.service';
import { correctingActionsService } from '@common/services/correctingActions.services';
import { getCurrentDateTime, getDateISOString, isMobile, prepareDate } from '@services/helper.service';
import { FETCH_ALL_MEASUREMENTS_FOR_PERIOD, FILTER_REQUEST } from '@views/Monitoring/store/constants';
import { LOG_ERROR } from '@store/common/constants';
import filters from '@common/filters';

export const actions = {
    async [INIT_MONITORING]({ commit, dispatch }) {
        commit(START_VIEW_LOADER);

        return Promise.all([
            dispatch(INIT_USER_PROFILE),
            dispatch(INIT_CORRECTING_ACTIONS),
            dispatch(INIT_DEVICES),
            dispatch(INIT_DEVICES_TYPES),
            dispatch(INIT_STORAGES),
            dispatch(FETCH_PRODUCTS),
        ])
            .catch((e) => {
                if (e && e?.reason !== 'cancelled') {
                    dispatch(LOG_ERROR, `INIT_MONITORING error: ${e}`);
                }
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    async [INIT_CORRECTING_ACTIONS]({ commit, getters }) {
        if (getters.correctingActionsList.length) {
            return getters.correctingActionsList;
        } else {
            return correctingActionsService.get().then((res) => {
                commit(SET_CORRECTING_ACTIONS, res);

                return res;
            });
        }
    },

    async [INIT_DEVICE]({ getters, commit, dispatch }) {
        if (!Number.isFinite(getters.currentDeviceId)) {
            return;
        }

        if (
            measurementsService.get.lastTarget === getters.currentDeviceId &&
            measurementsService.get.lastSearchQuery === getters.measurementsSearchQuery
        ) {
            if (isMobile()) {
                dispatch(ADD_NEW_MEASUREMENT);
            }

            return getters.measurementsList;
        }

        commit(START_VIEW_LOADER);

        commit(CLEAR_MEASUREMENTS);

        measurementsService.get.lastTarget = getters.currentDeviceId;

        dispatch(FETCH_MEASUREMENTS)
            .then(() => {
                if (isMobile()) {
                    // TODO: Posible improvements handle screen resize
                    // It will create empty row for mobile users
                    // To have an ability create new item
                    dispatch(ADD_NEW_MEASUREMENT);
                }
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    [FETCH_MEASUREMENTS]({ commit, getters }) {
        commit(START_VIEW_LOADER);
        measurementsService.get.lastSearchQuery = getters.measurementsSearchQuery;

        return measurementsService
            .get(
                getters.currentDeviceId,
                prepareDate(getters.measurementsFrom, true),
                prepareDate(getters.measurementsUntil, true),
                getters.currentPage,
                getters.monitoringPerPage,
                getters.measurementsSortBy.key === SORT_BY.INCORRECT,
                getters.measurementsSearchQuery
            )
            .then((res) => {
                commit(SET_MEASUREMENTS, res.data);
                commit(SET_PAGINATION, res);

                return res.data;
            })
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [FETCH_ALL_MEASUREMENTS_FOR_PERIOD]({ commit, getters }) {
        commit(START_VIEW_LOADER);
        return measurementsService
            .getAllForPeriod(
                getters.currentDeviceId,
                prepareDate(getters.measurementsFrom, true),
                prepareDate(getters.measurementsUntil, true)
            )
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [RESET_MONITORING_AND_DEPENDENCIES]({ commit }) {
        commit(RESET_MONITORING);
        commit(RESET_DEVICES);
    },

    [ADD_NEW_MEASUREMENT]({ commit, getters }) {
        if (getters.userProfile && getters.userProfile.id) {
            commit(ADD_NEW_MEASUREMENT, {
                profile: getters.userProfile,
            });

            commit(SET_SORT_BY, SORT_BY.ALL);
        } else {
            throw new Error('User profile unavailable!');
        }
    },

    async [GET_IS_VALID_TEMPERATURE]({ commit, getters }, { index, value }) {
        if (
            getters.measurementsList.length &&
            getters.measurementsList[index].value !== null &&
            getters.measurementsList[index].value !== '' &&
            getters.measurementsList[index].time
        ) {
            const res = await measurementsService.getIsValidTemperature(
                getters.currentDeviceId,
                value,
                filters.formatDateTimeToSend(getters.measurementsList[index].time)
            );
            if (typeof res.is_invalid === 'boolean') {
                commit(UPDATE_MEASUREMENT_ITEM, {
                    value: res.is_invalid,
                    path: [index, 'is_invalid'],
                    profile: getters.userProfile,
                });
            }
        }
    },

    async [UPLOAD_ATTACHMENT]({ commit, getters }, { index, body, onProgress }) {
        if (getters.measurementsList && getters.measurementsList[index]) {
            let { id } = getters.measurementsList[index];

            if (!id) {
                const measurement = prepareMeasurement(getters.measurementsList[index]);

                const res = await measurementsService.create(getters.currentDeviceId, [measurement]);

                if (res && res.measurements && res.measurements.length) {
                    commit(UPDATE_MEASUREMENT_ITEM, {
                        path: [index],
                        value: res.measurements[0],
                        skipUpdateMarker: true,
                    });

                    id = res.measurements[0].id;
                } else {
                    throw new Error("Can't find measurement in API response!");
                }
            }

            return measurementsService
                .uploadImage(id, body, {
                    onUploadProgress: onProgress,
                })
                .then((res) => {
                    commit(UPDATE_MEASUREMENT_ATTACHMENTS, {
                        id,
                        files: res.files,
                    });

                    return res;
                });
        } else {
            throw new Error(`Can't find measurement with index: ${index}`);
        }
    },

    [DOWNLOAD_ATTACHMENT]({ commit }, { fileId, name }) {
        commit(START_VIEW_LOADER);
        return attachmentsService.downloadAttachment(fileId, name).finally(() => commit(STOP_VIEW_LOADER));
    },

    [DELETE_ATTACHMENT]({ commit }, { measurementId, fileId }) {
        return attachmentsService.deleteAttachment(fileId).then(() => {
            commit(DELETE_ATTACHMENT, { measurementId, fileId });
        });
    },

    [DELETE_MEASUREMENT]({ commit, dispatch, getters }, index) {
        const item = getters.measurementsList[index];
        const path = ['monitoring', 'measurement', getters.currentDeviceId, index];

        if (item.id) {
            commit(SAVE_TO_ARCHIVE, {
                value: {
                    index,
                    data: item,
                },
                path,
            });
            commit(DELETE_MEASUREMENT, index);

            measurementsService
                .delete(item.id)
                .then((response) => {
                    commit(REMOVE_FROM_ARCHIVE, path);
                    commit(UPDATE_DEVICE, { id: response.food_device.id, data: response.food_device });
                })
                .catch((err) => {
                    dispatch(LOG_ERROR, `measurementsService.delete error: ${err}`);

                    dispatch(GET_AND_DELETE_FROM_ARCHIVE, path).then(({ index, data }) => {
                        commit(RETURN_MEASUREMENT_ITEM, { index, data });
                    });
                });
        } else {
            commit(DELETE_MEASUREMENT, index);
        }
    },

    async [APPLY_MEASUREMENT_CHANGES]({ commit, getters, dispatch }, measurementsList = null) {
        const requests = [];
        const toUpdate = [];
        const toSave = [];

        if (!measurementsList) {
            measurementsList = getters.measurementsList;
        }

        for (const item of measurementsList) {
            const measurement = prepareMeasurement({
                ...item,
                measurer_user: item.measurer_user || getters.userProfile,
                measurer: item.measurer || (getters.userProfile ? getters.userProfile.id : null),
            });

            if (item.hasUpdated) {
                if (item.id) {
                    measurement.id = item.id;

                    toUpdate.push(measurement);
                } else {
                    toSave.push(measurement);
                }
            }
        }

        if (toUpdate.length) {
            requests.push(measurementsService.update(toUpdate));
        }

        if (toSave.length) {
            requests.push(measurementsService.create(getters.currentDeviceId, toSave));
        }

        if (requests.length) {
            commit(START_VIEW_LOADER);
            return Promise.all(requests)
                .then(async (res) => {
                    if (res && res[0].food_device) {
                        commit(UPDATE_DEVICE, { id: res[0].food_device.id, data: res[0].food_device });
                    }
                    // Needs to be here to not save a second time when saving with "Save and close", coming back
                    // to the page and pressing "Save and close" again
                    // TODO: Implement updating values in store after save
                    await dispatch(FETCH_MEASUREMENTS);

                    return {
                        updated: true,
                        measurements: res[0].measurements,
                    };
                })
                .finally(() => commit(STOP_VIEW_LOADER));
        } else {
            return Promise.resolve(false);
        }
    },

    [CREATE_NEW_ACCOMPLISHED_TASK]({ commit, getters }, deviceId) {
        if (!deviceId) {
            throw new TypeError("Can't get required deviceId!");
        }

        commit(START_VIEW_LOADER);

        return measurementsService
            .create(deviceId, [
                prepareMeasurement({
                    measurer_user: getters.userProfile,
                    time: getCurrentDateTime(),
                    measurer: getters.userProfile ? getters.userProfile.id : null,
                    data: {
                        status: 'done',
                    },
                }),
            ])
            .then((data) => data)
            .finally(() => commit(STOP_VIEW_LOADER));
    },

    [SEARCH_QUERY]({ commit, dispatch }, query) {
        commit(SET_SEARCH_QUERY, query);

        commit(START_VIEW_LOADER);

        // It will call FETCH_MEASUREMENTS under the hood to update measurement list
        // Before query request we need to save all changes because of inability to filter it locally
        dispatch(APPLY_MEASUREMENT_CHANGES)
            .then(async (res) => {
                // If nothing to update make a request directly
                if (!res) {
                    await dispatch(FETCH_MEASUREMENTS);
                }
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    [FILTER_REQUEST]({ commit, dispatch }, sortBy) {
        commit(SET_SORT_BY, sortBy);

        commit(START_VIEW_LOADER);

        // It will call FETCH_MEASUREMENTS under the hood to update measurement list
        // Before sort request we need to save all changes because of inability to filter it locally
        dispatch(APPLY_MEASUREMENT_CHANGES)
            .then(async (res) => {
                // If nothing to update make a request directly
                if (!res) {
                    await dispatch(FETCH_MEASUREMENTS);
                }
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER);
            });
    },

    [ADD_SERVICE_PROVIDER]({ dispatch, getters }, { placeId, deviceId, email }) {
        return monitoringService.addServiceProvider(placeId, deviceId, email, getters.language).then((res) => {
            const device = getters.getDeviceById(deviceId);

            dispatch(UPDATE_DEVICES, [
                {
                    ...device,
                    hasUpdated: true,
                    provider_email: email,
                },
            ]);

            return res;
        });
    },
};

function prepareMeasurement(item) {
    const measurement = {
        time: getDateISOString(item.time),
        value: item.value,
        measurer: item.measurer,
        description: item.description,
        preventive_action: item.preventive_action,
        correcting_text: item.correcting_text,
        not_monitored: item.not_monitored,
    };

    if (item.correcting_action && item.correcting_action.length) {
        measurement.correctingActions = item.correcting_action.map((i) => i.id);
    }

    if (item.data) {
        measurement.data = {
            batch: item.data.batch,
            product: { ...item.data.product, measurements: [] },
            ingredient: item.data.ingredient,
            quality: item.data.quality,
            supplier: item.data.supplier,

            product_id: item.data.product && item.data.product.id,
            ingredient_id: item.data.ingredient && item.data.ingredient.id,
            ingredient_amount: (item.data.ingredient_amount !== null && +item.data.ingredient_amount) || null,
            production_unit_id: item.data.production_unit_id,
            product_amount: item.data.product_amount,
            product_unit_id: item.data.product_unit_id,
            product_batch: item.data.product_batch,
            ingredient_batch: item.data.ingredient_batch,
            ingredient_best_before: item.data.ingredient_best_before,
            exp_date_new: item.data.exp_date_new,
            username: item.data.username,
            food_amount: (item.data.food_amount !== null && +item.data.food_amount) || null,
            food_unit_id: item.data.food_unit_id,
            rate: item.data.rate,
            notes: item.data.notes,
            status: item.data.status,
        };
    }

    return measurement;
}
