<template>
    <Portal
        :order="order"
        to="modal"
    >
        <BModal
            v-if="shown"
            :id="id"
            :ref="`modal_${id}`"
            :visible="true"
            :size="size"
            :centered="centered"
            :modal-class="modalClass"
            :header-class="headerClass || 'modalHeader'"
            :footer-class="footerClass"
            :hide-header="hideHeader"
            :hide-footer="hideFooter"
            :ok-variant="okVariant || (reverseButtonColors ? 'outline-primary' : 'primary')"
            :cancel-variant="reverseButtonColors ? 'primary' : 'outline-primary'"
            :ok-title="okTitle || $t('continue')"
            :cancel-title="cancelTitle || $t('Cancel')"
            :ok-disabled="okDisabled"
            :no-close-on-backdrop="noClose"
            @hidden="close"
            @show="$emit('show')"
            @ok="$emit('submit')"
            @cancel="close"
        >
            <template #modal-header>
                <div class="row w-100">
                    <div class="col-10 col-md-6 my-auto order-md-0 text-break">
                        <span
                            v-if="title"
                            class="font-weight-bold"
                            >{{ title }}</span
                        >
                        <slot
                            v-else
                            name="title"
                        />
                    </div>
                    <div class="col-2 col-md-1 my-auto text-right primary order-md-2">
                        <Action
                            v-b-tooltip.hover="$t('close')"
                            class="cursor-pointer close-button"
                            aria-label="Close"
                            theme="icon"
                            @click.prevent="close"
                        >
                            <i class="fd-close icon-dark"></i>
                        </Action>
                    </div>
                    <div class="col-12 col-md-5 my-auto text-right order-md-1">
                        <slot name="actions" />
                    </div>
                </div>
            </template>
            <template #default>
                <slot :close="close" />
            </template>
            <template #modal-footer>
                <slot
                    :cancel="close"
                    :submit="() => $emit('submit')"
                    name="modal-footer"
                />
            </template>
        </BModal>
    </Portal>
</template>

<script>
import Action from '@components/Action/Action';

export default {
    name: 'BaseModal',

    components: { Action },

    props: {
        order: {
            type: Number,
            default: 0,
        },
        modalClass: {
            type: String,
            default: '',
        },
        reverseButtonColors: Boolean,
        headerClass: {
            type: String,
            default: '',
        },
        footerClass: {
            type: String,
            default: '',
        },
        hideHeader: Boolean,
        hideFooter: Boolean,
        centered: Boolean,
        open: Boolean,
        okDisabled: Boolean,
        okBtnText: {
            type: String,
            default: '',
        },
        cancelBtnText: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'md',
        },
        title: {
            type: String,
            default: '',
        },
        okVariant: {
            type: String,
            default: '',
        },
        okTitle: {
            type: String,
            default: '',
        },
        cancelTitle: {
            type: String,
            default: '',
        },
        noClose: Boolean,
    },

    data() {
        return {
            shown: false,
            // TODO: FD-5566 should not be like this
            id: `${new Date().getTime()} + ${Math.random(10)}`,
        };
    },

    mounted() {
        this.shown = this.open;
    },

    methods: {
        isShown() {
            return this.shown;
        },
        show() {
            this.shown = true;
        },
        hide() {
            this.shown = false;
        },
        close() {
            if (!this.noClose) {
                this.hide();
                this.$emit('cancel');
                this.$emit('close');
            }
        },
    },
};
</script>

<style>
.modalHeader {
    width: 100% !important;
    padding-top: 0.4rem !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}
</style>
