import store from '../../store/index';
import { STOP_VIEW_LOADER } from '@store/loader/constants';
import { vm } from '@/main';

export default async (to, from, next) => {
    const allowedRoles = ['member', 'owner'];
    const role = store.getters?.selectedCompany?.company?.role;
    if (role && allowedRoles.includes(role)) {
        next();
    } else {
        if (vm) {
            vm.$toastr.w(
                vm.$t(
                    'You do not have sufficient permissions to do that. ' +
                        'Please contact to your manager to extend the subscription'
                )
            );
        }
        if (from.name) {
            next(false);
            store.commit(STOP_VIEW_LOADER);
        } else {
            next({ name: 'overview' });
        }
    }
};
