import http from '@http';

function state() {
    return {
        frequencies: [],
        timezones: [],
        companyAccessLevel: [],
        placeAccessLevel: [],
        dateFormats: [],
        verificationNotificationFrequencies: [],
        customFrequencyTypes: [],
        productionExpirationTypes: [],
    };
}

const getters = {
    frequencies(state) {
        return state.frequencies || [];
    },
    timezones(state) {
        return state.timezones || [];
    },
    companyAccessLevel(state) {
        return state.companyAccessLevel || [];
    },
    placeAccessLevel(state) {
        return state.placeAccessLevel || [];
    },
    dateFormats(state) {
        return state.dateFormats || [];
    },
    verificationNotificationFrequencies(state) {
        return state.verificationNotificationFrequencies || [];
    },
    customFrequencyTypes(state) {
        return state.customFrequencyTypes || [];
    },
    productionExpirationTypes(state) {
        return state.productionExpirationTypes || [];
    },
    productsOutsourcedOptions() {
        return ['all', 'outsourced', 'produced', 'ingredient'];
    },
};

const mutations = {
    setDateFormats(state, payload) {
        state.dateFormats = payload;
    },
    setFrequencies(state, payload) {
        state.frequencies = payload;
    },
    setTimezones(state, payload) {
        state.timezones = payload;
    },
    setCompanyAccessLevel(state, payload) {
        state.companyAccessLevel = payload;
    },
    setPlaceAccessLevel(state, payload) {
        state.placeAccessLevel = payload;
    },
    setVerificationNotificationFrequencies(state, payload) {
        state.verificationNotificationFrequencies = payload;
    },
    setCustomFrequencyTypes(state, payload) {
        state.customFrequencyTypes = payload;
    },
    setProductionExpirationTypes(state, payload) {
        state.productionExpirationTypes = payload;
    },
};

const actions = {
    async getFrequencies({ commit, getters }) {
        if (!getters.frequencies.length) {
            const response = await http.get('/api/classifiers/task-frequencies');
            commit('setFrequencies', response.data);
        }
        return getters.frequencies;
    },
    async getDateFormats({ commit, getters }) {
        if (!getters.dateFormats.length) {
            const response = await http.get('/api/classifiers/date-formats');
            commit('setDateFormats', response.data);
        }
    },
    async getTImezones({ commit, getters }) {
        if (!getters.timezones.length) {
            const response = await http.get('/api/classifiers/timezones');
            commit('setTimezones', response.data);
        }

        return getters.timezones;
    },
    async getCompanyAccessLevel({ commit, getters }) {
        if (!getters.companyAccessLevel.length) {
            const response = await http.get('/api/classifiers/access-levels/company-levels');
            commit('setCompanyAccessLevel', response.data);
        }

        return getters.companyAccessLevel;
    },
    async getPlaceAccessLevel({ commit, getters }) {
        if (!getters.placeAccessLevel.length) {
            const response = await http.get('/api/classifiers/access-levels/place-levels');
            commit('setPlaceAccessLevel', response.data);
        }

        return getters.placeAccessLevel;
    },
    async getVerificationNotificationFrequencies({ commit, getters }) {
        if (!getters.verificationNotificationFrequencies.length) {
            const response = await http.get('/api/classifiers/verification-notification-frequencies');
            commit('setVerificationNotificationFrequencies', response.data);
        }

        return getters.verificationNotificationFrequencies;
    },
    async getCustomFrequencyTypes({ commit, getters }) {
        if (!getters.customFrequencyTypes.length) {
            const response = await http.get(`api/classifiers/custom-frequency-types`);
            commit('setCustomFrequencyTypes', response.data);
        }

        return getters.customFrequencyTypes;
    },
    async getProductionExpirationTypes({ commit, getters }) {
        if (!getters.productionExpirationTypes.length) {
            const response = await http.get(`api/classifiers/product-expiration-types`);
            commit('setProductionExpirationTypes', response.data);
        }

        return getters.productionExpirationTypes;
    },
    async getCompanyQuestionnaires({ rootGetters }) {
        const { data } = await http.get(`api/classifiers/questionnaires/${rootGetters.selectedCompanyId}`);

        return data;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
