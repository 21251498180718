<template>
    <ValidationProvider
        v-if="isEditable"
        v-slot="v"
        :name="name"
        :rules="rules"
        mode="eager"
        slim
    >
        <div
            class="base-datepicker max-width position-relative"
            :class="{ 'has-feedback has-error': v.errors.length }"
        >
            <DatePicker
                :id="id"
                ref="date-picker"
                :config="config"
                :value="value"
                :placeholder="!hidePlaceHolder ? placeholder || config.format : ''"
                :disabled="disabled"
                autocomplete="off"
                :class="[
                    {
                        'is-invalid': v.errors.length,
                    },
                ]"
                @input="onInput"
            />
            <label
                class="d-flex align-items-center justify-content-end cursor-text"
                :for="id"
                @click="onLabelClick"
            >
                <i class="pl-2 calendar-icon fd-calendar d-none-for-print"></i>
            </label>
            <span
                v-if="v.errors.length"
                class="invalid-feedback d-block"
                >{{ v.errors[0] }}</span
            >
        </div>
    </ValidationProvider>
    <span v-else>
        {{ value || '-' }}
    </span>
</template>

<script>
import DatePicker from 'vue-bootstrap-datetimepicker';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import { DateValue } from '@common/types';

export default {
    name: 'BaseDatePicker',

    components: {
        DatePicker,
        ValidationProvider,
    },

    props: {
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        disabled: Boolean,
        hidePlaceHolder: Boolean,
        isEditable: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapGetters({
            entriesDiapasonState: 'activityLog/entriesDiapasonState',
        }),
        config() {
            return {
                locale: this.$i18n.locale,
                format: DateValue.getCompanyDateFormat(),
                date: this.value || null,
                ...this.options,
            };
        },
    },

    methods: {
        onLabelClick() {
            try {
                this.$refs['date-picker'].$el.focus();
            } catch (error) {
                console.error(error);
            }
        },
        onInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss">
.max-width {
    display: block;
    width: 8.8rem;
}

.calendar-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    color: $gray-500;
    transform: translate(-50%, -50%);
}
</style>

<style lang="scss" scoped>
.base-datepicker {
    input {
        background: $gray-200;
        border: 2px solid transparent;
        border-radius: 0.3rem;
        transition: border 0.3s ease-in-out;

        &:hover {
            background-color: $gray-300;
            border-color: $gray-400;
        }
    }

    label {
        font-size: 0.875rem;
        font-weight: normal;
    }
}
</style>
