<template>
    <div
        v-if="!hide"
        class="body d-none d-lg-block d-none-for-print"
        :class="{ open: open }"
    >
        <span class="position-fixed">
            <a
                class="text-center"
                href="/"
            >
                <Logo
                    class="space-related-out-y"
                    :small="!open"
                    :responsive-width="28"
                />
            </a>
            <ul
                class="navbar-nav w-100"
                :class="open && 'open'"
            >
                <RouterLink
                    v-for="(link, linkIndex) in linksToShow"
                    :key="`sidenav_${linkIndex}`"
                    class="nav-item"
                    :class="link.separator && 'separator'"
                    tag="li"
                    :to="link.to"
                >
                    <span
                        class="d-flex nav-link text-white space-related-out-l"
                        :class="!open && 'closed'"
                    >
                        <i
                            class="item-icon text-center"
                            :class="`fd-${link.icon} ${open && 'open'}`"
                        ></i>
                        <transition name="item-fade">
                            <span
                                v-if="open"
                                class="item-name"
                                >{{ $t(link.name) }}</span
                            >
                        </transition>
                        <span v-if="open && link.new">
                            <BadgeNew class="ml-2" />
                        </span>
                    </span>
                </RouterLink>
            </ul>
            <button
                class="d-flex align-items-center justify-content-center btn btn-link toggle-button"
                @click="open = !open"
            >
                <i :class="`fd-${open ? 'chevron-left' : 'chevron-right'}`" />
            </button>
        </span>
    </div>
</template>

<script>
import BadgeNew from '@components/BadgeNew';
import { COUNTRIES } from '@common/constants';
import Logo from '@components/Logo';
import { mapGetters } from 'vuex';

export default {
    name: 'SideNav',

    components: {
        Logo,
        BadgeNew,
    },

    computed: {
        ...mapGetters(['selectedCompany', 'selectedPlace']),
        ...mapGetters({ sideNavOpen: 'global/sideNavOpen', isOwnerGlobal: 'isOwner' }),
        hide() {
            const hiddenPlaces = ['create.place', 'create.company'];
            return hiddenPlaces.includes(this.$route.name);
        },
        open: {
            get() {
                return this.sideNavOpen;
            },
            set(val) {
                this.$store.commit('global/setSideNavOpen', val);
            },
        },
        isEstonia() {
            return this.selectedPlace?.country_id === COUNTRIES.ESTONIA;
        },
        isOwner() {
            return this.isOwnerGlobal || this.selectedPlace?.role === 'owner';
        },
        showNewProductionModule() {
            return this.selectedCompany.company.use_new_production_module;
        },
        linksToShow() {
            const productionLink = this.showNewProductionModule
                ? [
                      {
                          name: 'food-production',
                          to: { name: 'traceability' },
                          icon: 'production',
                          new: true,
                      },
                  ]
                : [
                      { name: 'production', to: { name: 'production' }, icon: 'production' },
                      { name: 'Food traceability', to: { name: 'monitoring' }, icon: 'monitoring' },
                  ];
            const links = [
                { name: 'overview', to: { name: 'overview' }, icon: 'overview' },
                // { name: 'reports', to: { name: 'reports' }, icon: 'reports' },
                { name: 'plan', to: { name: 'plan' }, icon: 'haccp' },
                { name: 'Activity Log', to: { name: 'activity-log' }, icon: 'activity-log' },
                ...productionLink,
                { name: 'Audit', to: { name: 'audits.list' }, icon: 'audit' },
                { name: 'Team', to: { name: 'team' }, icon: 'team', hide: !this.isOwner },
                { name: 'documents', to: { name: 'documents' }, icon: 'documents', separator: this.isOwner },
                { name: 'Set up', to: { name: 'set-up' }, icon: 'cog', hide: !this.isOwner },
            ];
            return links.filter((link) => !link?.hide);
        },
    },
};
</script>

<style lang="scss" scoped>
.item-fade-enter-active {
    transition: all 2s ease;
}

.item-fade-leave-active {
    transition: all 0s;
}

.item-fade-enter,
.item-fade-leave-to {
    opacity: 0;
    transform: translateX(10px);
}

.body,
.nav-item,
.toggle-button,
.navbar-nav {
    transition: all 0.4s ease;
}

ul {
    width: 4rem !important;

    &.open {
        width: 12rem !important;
    }
}

li {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.25rem;
    letter-spacing: 0.25px;

    .nav-link {
        display: flex;
        align-items: center;
        margin: 0.5rem 0.5rem 0.5rem 0;

        &.closed {
            justify-content: center;
            margin: 0.5rem 0 !important;
        }
    }

    &.active {
        background-color: $primary !important;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba($gray-400, 0.2);
    }

    &.separator:after {
        display: block;
        width: 80%;
        margin: 0 auto;
        content: '';
        border-bottom: 1px solid $primary;
    }
}

.body {
    z-index: 996;
    min-width: 4rem;
    max-width: 4rem;

    &.open {
        min-width: 12rem;
        max-width: 12rem;
    }

    background-color: $dark-green;
}

.toggle-button {
    position: absolute;
    top: 20rem;
    left: 3.1rem;
    z-index: 999999 !important;
    width: 2rem;
    height: 2rem;
    padding: 0 !important;
    color: $dark-green;
    text-decoration: none !important;
    background-color: $gray-300;
    border-radius: 200px;
}

.item-icon.open {
    margin-right: 0.5rem;
}

.open .toggle-button {
    left: 11rem !important;
}
</style>
