<template>
    <div class="custom-view-loader">
        <BaseModal
            ref="modal"
            no-close
            no-header
            no-footer
            hide-header
            hide-footer
            centered
        >
            <div class="loader-wrapper">
                <span class="loader"></span>
            </div>
            <div
                class="space-unrelated-out-x text-wrapper text-center d-flex flex-column align-items-center justify-content-center"
            >
                <p class="h2">{{ heading }}</p>
                <p>{{ message }}</p>
            </div>
        </BaseModal>
    </div>
</template>

<script>
import BaseModal from '@components/BaseModal/BaseModal';

export default {
    name: 'CustomizableViewLoader',

    components: {
        BaseModal,
    },

    props: {
        heading: {
            type: String,
            required: false,
            default: '',
        },
        message: {
            type: String,
            required: false,
            default: '',
        },
    },

    methods: {
        show() {
            this.$refs.modal.show();
        },
        close() {
            this.$refs.modal.hide();
        },
    },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
    height: 5rem;
}

.text-wrapper {
    margin-top: 4rem;
}

.loader {
    position: absolute;
    top: 28%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: url('/public/img/loader.gif') no-repeat center;
    background-size: contain;
    transform: translate(-50%, -50%);
}
</style>
