import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants.js';

import {
    SET_DEFAULT_TIME_DIAPASON_BY_DEVICE,
    SET_TIME_DIAPASON,
    SET_TIME_DIAPASON_FROM,
    SET_TIME_DIAPASON_UNTIL,
} from './constants';

import { APPLY_MEASUREMENT_CHANGES, FETCH_MEASUREMENTS } from '../constants';
import { getTimeDiapason } from '../../../../common/services/helper.service';

export const actions = {
    async [SET_TIME_DIAPASON]({ commit, dispatch }, { from, until, skipUpdate }) {
        commit(SET_TIME_DIAPASON, { from, until });

        commit(START_VIEW_LOADER);

        if (!skipUpdate) {
            // It will call FETCH_MEASUREMENTS under the hood to update measurement list
            // Before query request we need to save all changes because of inability to filter it locally
            try {
                await dispatch(APPLY_MEASUREMENT_CHANGES).then(async (res) => {
                    // If nothing to update make a request directly
                    if (!res) {
                        return await dispatch(FETCH_MEASUREMENTS);
                    }
                });
            } catch (e) {
                print(e);
            }
        }

        commit(STOP_VIEW_LOADER);
    },

    [SET_TIME_DIAPASON_FROM]({ commit, dispatch }, value) {
        commit(SET_TIME_DIAPASON_FROM, value);

        dispatch(FETCH_MEASUREMENTS);
    },

    [SET_TIME_DIAPASON_UNTIL]({ commit, dispatch }, value) {
        commit(SET_TIME_DIAPASON_UNTIL, value);

        dispatch(FETCH_MEASUREMENTS);
    },

    [SET_DEFAULT_TIME_DIAPASON_BY_DEVICE]({ dispatch, getters }, deviceId) {
        if (!Number.isFinite(deviceId)) {
            console.error(`deviceId is invalid: ${deviceId}`);

            return;
        }

        const device = getters.getDeviceById(deviceId);
        if (!device) {
            console.error(`Can't get device by id: ${deviceId}`);

            return;
        }

        const deviceTypeId = device.device_type_id;
        if (getters.isSingleDeviceType(deviceTypeId)) {
            // by default showing results last 30 days
            const { from, until } = getTimeDiapason({ days: 30 });

            dispatch(SET_TIME_DIAPASON, { from, until, skipUpdate: true });

            return;
        }

        const schedule = getters.getDeviceShadule(deviceId);

        switch (schedule) {
            case 'once_in_month':
            case 'once_in_2_months':
            case 'not_specified': {
                //  by default showing results last 12 months
                const { from, until } = getTimeDiapason({ years: 1 });

                dispatch(SET_TIME_DIAPASON, { from, until, skipUpdate: true });

                break;
            }
            case 'once_a_week': {
                // by default showing results last 90 days'
                const { from, until } = getTimeDiapason({ days: 90 });

                dispatch(SET_TIME_DIAPASON, { from, until, skipUpdate: true });

                break;
            }
            case 'once_a_day':
            case 'twice_a_day':
            case 'other': {
                // by default showing results last 30 days
                const { from, until } = getTimeDiapason({ days: 30 });

                dispatch(SET_TIME_DIAPASON, { from, until, skipUpdate: true });

                break;
            }
            case 'once_in_quartal':
            case '2 times in a year':
            case 'once_in_year': {
                // by default showing last 3 years
                const { from, until } = getTimeDiapason({ years: 3 });

                dispatch(SET_TIME_DIAPASON, { from, until, skipUpdate: true });

                break;
            }
            default: {
                break;
            }
        }
    },
};
