import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { SORT_BY } from '@views/Monitoring/store/constants';

import diapason from '@views/Monitoring/store/diapason/index.js';

const state = {
    currentDeviceId: null,
    data: [],
    measurements: [],
    correctingActions: [],
    pagination: {
        total: 1,
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
    },
    searchQuery: '',
    sortBy: SORT_BY.ALL,
};

export default {
    state,
    mutations,
    actions,
    getters,
    modules: {
        diapason,
    },
};
