<template>
    <div class="text-center">
        <template v-if="url.length">
            <img
                v-if="isImage"
                class="img-fluid"
                :src="url"
                :alt="file.name || file.title || file.fileName || $t('name not found')"
            />
            <iframe
                v-else
                :src="url"
                width="100%"
                height="100%"
                style="min-height: 500px"
            ></iframe>
        </template>
        <Loader v-else />
    </div>
</template>

<script>
import { FilesService } from '../../services/files-v2.service';
import Loader from '@components/Loader';
import { isImageExtensionByName } from '@services/helper.service';

export default {
    name: 'BaseFilePreview',

    components: { Loader },

    props: {
        file: {
            validator: (prop) => typeof prop === 'object' || prop === null,
            required: true,
        },
    },

    data() {
        return {
            url: '',
        };
    },

    computed: {
        isImage() {
            return isImageExtensionByName(this.file.name);
        },
    },

    watch: {
        file: {
            immediate: true,
            handler() {
                this.getUrl();
            },
        },
    },

    methods: {
        async getUrl(tries = 0) {
            FilesService.get(this.file.id)
                .then((response) => {
                    if (response.data.type === 'image/svg') {
                        const blob = new Blob([response.data], { type: 'image/svg+xml' });
                        this.url = URL.createObjectURL(blob);
                    } else {
                        this.url = URL.createObjectURL(response.data);
                    }
                })
                .catch(() => {
                    if (tries < 3) {
                        setTimeout(() => {
                            this.getUrl(++tries);
                        }, 500);
                    }
                });
        },
    },
};
</script>

<style scoped></style>
