import { INIT_USER_PROFILE } from '@store/profile/constants';
import { FETCH_IP_COUNTRY } from '@store/common/constants';

export const authMixin = {
    methods: {
        getInitializationData() {
            if (typeof this.country !== 'number') {
                this.$store.dispatch(FETCH_IP_COUNTRY).then((countryData) => {
                    if (countryData && typeof countryData.id === 'number') {
                        this.country = countryData.id;
                    }
                });
            }
            // Init Country and user email
            this.$store.dispatch(INIT_USER_PROFILE).then((userDetails) => {
                if (userDetails) {
                    if (!this.email && userDetails.username) {
                        this.email = userDetails.username;
                    }
                    if (!this.phone && userDetails.phone) {
                        this.phone = userDetails.phone;
                    }
                }
            });
        },
        redirectToLandingPage() {
            window.location.href = process.env.FRONT_URL;
        },
        setLangCookieFromLandingPage() {
            if (this.$route.query && this.$route.query.lang) {
                const lang = this.$route.query?.lang || 'en';
                this.$i18n.locale = lang.split('?')[0];
            }
        },
    },
};
