<template>
    <!-- DEPRECATED. Use BaseDropdown instead  TODO FD-7609-->
    <div
        ref="dropdown"
        data-cy="dropdown"
        class="dropdown base-dropdown"
    >
        <slot name="button">
            <button
                data-cy="dropdown-btn"
                type="button"
                :class="['btn', btnClass ? btnClass : ['btn-icon', isHide ? 'opacity-0' : 'opacity-0 active']]"
                data-toggle="dropdown"
                :data-boundary="boundary || 'scrollParent'"
                @click="$emit('click')"
            >
                <slot name="button-icon">
                    <i
                        v-if="icon === 'fd-menu-kebab'"
                        class="fd-menu-kebab icon-dark"
                        :class="iconSize ? iconSize : 'font-size-25'"
                    ></i>
                    <i
                        v-if="icon === 'cog'"
                        class="fd-cog text-primary"
                    ></i>
                    <i
                        v-if="icon === 'user'"
                        class="fd-user text-primary"
                    ></i>
                    <i
                        v-if="icon === 'pencil'"
                        class="fd-pencil-alt text-primary"
                    ></i>
                    <i
                        v-if="icon === 'camera'"
                        class="fd-image-upload text-primary"
                        :class="iconSize"
                    ></i>
                    <i
                        v-if="icon === 'video'"
                        class="fd-video text-primary font-size-lg"
                    ></i>
                    <i
                        v-if="icon === 'paperclip'"
                        class="fd-attach font-size-lg"
                    ></i>
                    <i
                        v-if="icon === 'fd-document-download'"
                        class="fd-document-download font-size-lg"
                    ></i>
                </slot>

                <slot name="btn-content" />
            </button>
        </slot>

        <div
            v-if="data"
            :class="['dropdown-menu dropdown-menu-right', isHide ? 'opacity-0' : 'opacity-0 active']"
            @click="$emit('click')"
        >
            <button
                v-for="item of data.filter((i) => i.type !== 'delete')"
                :id="item.id"
                :key="item.key || item.name"
                class="dropdown-item py-3 d-flex icon-dark align-items-center icon-dark"
                type="button"
                @click="item.action()"
            >
                <i
                    v-if="item.type === 'download'"
                    class="fd-arrow-download"
                ></i>
                <i
                    v-else-if="item.type === 'email'"
                    class="fd-envelope"
                ></i>
                <i
                    v-else-if="item.type === 'edit-user'"
                    class="fd-user-edit"
                ></i>
                <i
                    v-else-if="item.type === 'upload'"
                    class="fd-arrow-up text-primary"
                ></i>
                <i
                    v-else-if="item.type === 'edit'"
                    class="fd-edit mr-1"
                ></i>
                <i
                    v-else-if="item.type === 'folder'"
                    class="fd-folder"
                ></i>
                <i
                    v-else-if="item.type === 'link'"
                    class="fd-share-link mr-1"
                ></i>
                <i
                    v-else-if="item.type === 'qr-code'"
                    class="fd-create_QR mr-1"
                ></i>
                <i
                    v-else-if="item.type === 'create'"
                    class="fd-plus"
                ></i>
                <i
                    v-else-if="item.type === 'cog'"
                    class="fd-cog text-primary"
                ></i>
                <i
                    v-else-if="item.type === 'custom-delete'"
                    class="fd-trash text-primary"
                ></i>
                <i
                    v-else-if="item.type === 'not_monitored' && item.value"
                    class="fd-check-square text-primary"
                    data-cy="monitoring-not-done-unselect"
                ></i>
                <i
                    v-else-if="item.type === 'not_monitored' && !item.value"
                    class="fd-square"
                    data-cy="monitoring-not-done-select"
                ></i>
                <span class="ml-2">{{ item.title || $t(item.name) }}</span>
            </button>
            <FdDelete
                v-for="item of data.filter((i) => i.type === 'delete')"
                :key="item.id"
                class="dropdown-item d-flex icon-dark"
                :class="{ 'py-3': !item.noSpacing }"
                label="delete"
                @delete="item.action()"
            />
        </div>
        <div
            v-else
            v-show="!isHide"
            class="dropdown-menu dropdown-menu-right"
            :aria-labelledby="idName"
            @click="$emit('click')"
        >
            <slot />
        </div>
    </div>
</template>

<script>
// DEPRECATED. Use BaseDropdown instead
export default {
    name: 'Dropdown',

    props: {
        data: {
            type: Array,
            nullable: true,
            default: null,
        },
        icon: {
            type: String,
            default: '',
        },
        idName: {
            type: String,
            default: '',
        },
        isHide: Boolean,
        btnClass: {
            type: [Object, String],
            default: '',
        },
        boundary: {
            type: String,
            default: '',
        },
        iconSize: {
            type: String,
            default: '',
        },
    },

    mounted() {
        if (this?.$refs?.dropdown) {
            // TODO: Dropdown shoul be refactored and this should be replaced
            /* eslint-disable */
            $(this.$refs.dropdown).on('show.bs.dropdown', this.onShow);
            $(this.$refs.dropdown).on('hide.bs.dropdown', this.onHide);
            /* eslint-enable */
        }
    },

    beforeDestroy() {
        if (this?.$refs?.dropdown) {
            /* eslint-disable */
            $(this.$refs.dropdown).off('show.bs.dropdown', this.onShow);
            $(this.$refs.dropdown).off('hide.bs.dropdown', this.onHide);
            /* eslint-enable */
        }
    },

    methods: {
        onShow() {
            this.$emit('show');
        },
        onHide() {
            this.$emit('hide');
        },
    },
};
</script>

<style lang="scss" scoped>
button:not(dropdown-item) {
    align-items: center;
}

.font-size-25 {
    font-size: 25px;
}

i {
    color: $gray-500;
}

.base-dropdown {
    opacity: 1;
    transition: all 0.3s ease; // In some reason backward animation don't work without `all`
}

.opacity-0 {
    transition: opacity 0.4s ease;

    &.active {
        opacity: 1;
    }
}

.dropdown-menu {
    z-index: 999999 !important;
}
</style>
