import Vue from 'vue';
import Vuex from 'vuex';

import audits from '@views/Audits/store';
import register from '@views/Auth/views/Register/store';
import monitoring from '@views/Monitoring/store';
import overview from '@views/Overview/store';
import companies from '@store/company/index.js';
import profile from '@store/profile/index.js';
import loader from '@store/loader/index.js';
import devices from '@store/devices/index.js';
import storage from '@store/storage/index.js';
import common from '@store/common/index.js';
import file from '@store/file/index.js';
import production from '@views/Production/store/index.js';
import permission from '@store/permission/index.js';
import localization from '@store/localization/index.js';
import localArchive from '@store/local-archive/index.js';
import userGroups from '@store/user-groups/index.js';
import notifications from '@store/notifications/index.js';
import auth from './auth';
import team from './team';
import roles from './roles';
import documents from './documents';
import pauseMonitoring from './pause-monitoring';
import rooms from './rooms';
import tasks from './tasks';
import classifiers from './classifiers';
import plan from './plan';
import activityLog from './activity-log';
import billing from './billing';
import global from './global';
import files from './files';
import equipment from './equipment';
import settings from './settings';
import sensor from './sensor';
import reports from './reports';
import tickets from './tickets';
import sharedAccess from './shared-access';
import onboardingProfile from './onboarding-profile';
import users from './users';
import { INIT_COMPANIES } from './modules/company/constants';

Vue.use(Vuex);

export default new Vuex.Store({
    actions: {
        async initApp({ dispatch }) {
            return {
                companies: await dispatch(INIT_COMPANIES),
            };
        },
    },
    modules: {
        auth,
        audits,
        billing,
        documents,
        companies,
        common,
        profile,
        plan,
        global,
        devices,
        file,
        loader,
        localArchive,
        localization,
        monitoring,
        'pause-monitoring': pauseMonitoring,
        notifications,
        overview,
        permission,
        production,
        register,
        roles,
        rooms,
        storage,
        tasks,
        team,
        files,
        userGroups,
        classifiers,
        equipment,
        activityLog,
        settings,
        sensor,
        reports,
        tickets,
        'shared-access': sharedAccess,
        users,
        onboardingProfile,
    },
    strict: process.env.NODE_ENV !== 'production',
});
