import routes from '@/routes';
import http from '@http';
import store from '@/store/index';
import { STOP_VIEW_LOADER } from '@store/loader/constants';
import { INIT_USER_PROFILE } from '@store/profile/constants';
import Echo from 'laravel-echo';
import { vm } from '@/main';
import { SET_SELECTED_PLACE, START_PLACE_CREATION } from '@store/company/place/constants';
import { SET_SELECTED_COMPANY, START_COMPANY_CREATION } from '@store/company/constants';
import TrackingService from '@services/tracking.service';

window.Pusher = require('pusher-js');

class AuthService {
    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }

        this._isAuthorized = !!localStorage.getItem('access_token');

        if (this._isAuthorized) {
            this._setupLaravelEcho();
        }

        AuthService.instance = this;
    }

    get isAuthorized() {
        return this._isAuthorized;
    }

    get isSharedLink() {
        return this._isSharedLink;
    }

    activateSharedLink() {
        this._isAuthorized = true;
        this._isSharedLink = true;
    }

    _setupLaravelEcho() {
        const pusherData = {
            cluster: 'eu',
            broadcaster: 'pusher',
            key: process.env.MIX_PUSHER_APP_KEY,
            disableStats: true,
            authEndpoint: `${process.env.API_URL}/api/broadcasting/auth`,
            encrypted: true,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },
        };
        try {
            window.Echo = new Echo(pusherData);
        } catch (error) {
            console.error(error, 'Echo error', process.env.MIX_PUSHER_APP_KEY);
            throw new Error("Can't setup Echo", error);
        }
    }

    hasEmail() {
        return (
            store.getters.userProfile.username &&
            store.getters.userProfile.username.length > 3 &&
            !store.getters.userProfile.username.includes('mail.fooddocs.ee')
        );
    }

    async _postAuthCallback() {
        localStorage.setItem('hideBillingDaysLeftNotification', JSON.stringify({ value: 'false' }));
        if (process.env.APP_ENV === 'production') {
            TrackingService.login(store.getters.profileId);
        }
        await store.dispatch('initApp', null, { root: true });
    }

    finishSmartidLogin(code) {
        return http
            .post(`/api/finish_smartid_login?response_type=token&client_id=${process.env.MIX_CLIENT_ID}`, {
                oauth_code: code,
                redirect_uri: window.location.origin + window.location.pathname,
            })
            .then(async (res) => {
                if (res) {
                    if (res?.data?.firstname && res?.data?.lastname && res?.data?.idcode) {
                        await routes.push({ name: 'messageRegisterIdCard' });
                    } else {
                        http.setToken(res.data.access_token);

                        localStorage.setItem('access_token', res.data.access_token);
                        localStorage.setItem('expires_in', res.data.expires_in);

                        this._isAuthorized = true;
                        await this._postAuthCallback();
                        routes.push('/').catch(() => {});
                    }
                }
            })
            .catch(() => {
                routes.push({ name: 'login' }).catch(() => {});
            })
            .finally(() => {
                store.commit(STOP_VIEW_LOADER);
            });
    }

    async login(username, password, rememberMe) {
        try {
            const res = await http.post('/oauth/token', {
                username,
                password,
                remember_me: rememberMe || false,
                lang: localStorage.getItem('lang'),
            });

            if (!(res?.data && res.data.access_token && res.data.expires_in)) {
                throw new Error("Something went wrong can't find res for /oauth/token request", res);
            }

            await http.setToken(res.data.access_token);

            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('expires_in', res.data.expires_in);

            await this._setupLaravelEcho();

            await store.dispatch(INIT_USER_PROFILE);

            this._isAuthorized = true;
            await this._postAuthCallback();

            if (!this.hasEmail()) {
                await routes.push({ name: 'setEmail' });
            }
        } catch (error) {
            vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
        }
    }

    forceSetToken(token) {
        http.setToken(token);
        localStorage.setItem('access_token', token);
        this._isAuthorized = true;
    }

    async logout(values = {}) {
        const { skipRequest } = values;

        if (!this._isAuthorized || !localStorage.getItem('access_token')) {
            return;
        }

        this._isAuthorized = false;

        if (skipRequest) {
            clearStorage();

            routes.push({ name: 'login' });
        } else {
            return http
                .get('/api/logout')
                .catch(() => console.error('Already logged out'))
                .finally(() => {
                    clearStorage();

                    routes.push({ name: 'login' });
                });
        }
    }

    checkUsername(username) {
        return http.get('/api/account/check_username', { params: { username: username } }).then((res) => res.data);
    }

    checkCoupon(coupon) {
        return http.get('/api/account/check_promo_code', { params: { coupon } });
    }

    register(data) {
        return http.post('/api/account', data);
    }

    requestForgotPassword(username) {
        return http.post('/api/auth/recover', { username, lang: localStorage.getItem('lang') }).then((res) => {
            if (res.status === 200) {
                routes.push({ name: 'password.sent' }).catch(() => {});
            }
        });
    }

    setNewPassword(data) {
        return http.put('/api/account/set_new_password', data);
    }

    setEmail(data) {
        return http.put('/api/account/set_email', data);
    }

    changePassword(data) {
        return http.put('/api/account/set_new_password', data);
    }

    setCompanyOrCreate() {
        let companyId = store.getters.prevCompanyAndPlace.companyId;
        if (!companyId) {
            const filteredCompanies = store.getters.companies.filter((company) => company?.company?.name);
            if (filteredCompanies && filteredCompanies.length) {
                companyId = filteredCompanies[0].company.id;
            }
        }
        if (companyId && store.getters.companies.some((company) => company?.company?.id === companyId)) {
            vm && vm.$toastr && vm.$toastr.i(vm.$t('Error with current company, changed selected company'));
            store.dispatch(SET_SELECTED_COMPANY, companyId);
        } else {
            vm && vm.$toastr && vm.$toastr.i(vm.$t('Error with current company, redirected to create new company'));
            store.dispatch(START_COMPANY_CREATION);
            vm.$router
                .push({
                    name: 'create.company',
                    params: {
                        isCreateNew: false,
                    },
                })
                .catch(() => {});
        }
    }

    setPlaceOrCreate() {
        let placeId = store.getters.prevCompanyAndPlace.placeId;
        if (!placeId) {
            const filteredPlaces = store.getters.selectedCompany?.places?.filter((place) => place.name);
            if (filteredPlaces && filteredPlaces.length) {
                placeId = filteredPlaces[0].id;
            }
        }
        if (placeId && store.getters?.places?.some((place) => place && place.id === placeId)) {
            vm.$toastr.i(vm.$t('Error with current place, changed selected place'));
            store.dispatch(SET_SELECTED_PLACE, placeId);
            return;
        }

        store.dispatch(START_PLACE_CREATION);
    }

    // TODO: Legacy code
    setCompanyPlaceOrCreateNew() {
        if (!store.getters.selectedCompanyId) {
            this.setCompanyOrCreate();
        } else if (!store.getters.selectedPlaceId) {
            this.setPlaceOrCreate();
        }
    }
}

function clearStorage() {
    const updateStatus = localStorage.getItem('update_status');
    const lang = localStorage.getItem('lang');

    localStorage.clear();
    sessionStorage.clear();

    updateStatus && localStorage.setItem('update_status', updateStatus);
    lang && localStorage.setItem('lang', lang);
}

export default new AuthService();
