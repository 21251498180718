import Vue from 'vue';

import {
    DELETE_DEVICE,
    INIT_DEVICES,
    INSERT_DEVICE,
    PUSH_DEVICE,
    RESET_DEVICES,
    SET_DEVICES_DATA,
    SET_TYPES,
    UPDATE_DEVICE,
    UPDATE_DEVICE_IS_DONE,
} from '@store/devices/constants';

export const mutations = {
    [INIT_DEVICES](state) {
        state.isInited = true;
    },
    [SET_TYPES]: (state, data) => {
        state.types = data;
    },
    [SET_DEVICES_DATA]: (state, data) => {
        data.map((device) => {
            if (!device.device_settings) {
                device.device_settings = {};
            }
            if (!device.files) {
                Vue.set(device, 'files', device.file_list);
            }

            return device;
        });
        Vue.set(state, 'devices', data.length ? data : []);
    },
    [UPDATE_DEVICE](state, { id, data }) {
        const hasDevice = !!state.devices.find((item, index) => {
            if (item.id === id) {
                Vue.set(data, 'files', data.file_list);

                Vue.set(state.devices, index, data);

                return true;
            }
        });

        if (hasDevice) {
            state.devices = [...state.devices];
        } else {
            state.devices = [...state.devices, data];
        }
    },
    [UPDATE_DEVICE_IS_DONE](state, { id, is_done }) {
        state.devices = state.devices.map((device) => {
            if (device.id === id) {
                return { ...device, is_done };
            }
            return device;
        });
    },
    [DELETE_DEVICE]: (state, id) => {
        state.devices = state.devices.filter((i) => i.id !== id);
    },
    [RESET_DEVICES](state) {
        state.isInited = false;
        state.devices = [];
    },
    [PUSH_DEVICE](state, device) {
        state.devices.push(device);
    },
    [INSERT_DEVICE](state, { index, data }) {
        state.devices.splice(index, 0, data);
    },
};
