// Views
const Monitoring = () => import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/index');
const MonitoringBase = () => import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Base/index');
const MonitoringDevices = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Devices/index');
const MonitoringDevice = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/index');
const ProductionTraceability = () =>
    import(
        /* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Traceability/ProductionTraceability'
    );
const ShelfLifeTraceability = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Traceability/ShelfLife');
const Complaints = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Complaints');
const CompanySupplier = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/CompanySupplier/index');
const TemperatureStorage = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/TemperatureStorage/index');
const PestControl = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/PestControl');
const Tasks = () => import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Tasks');
const Analyses = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Analyses');
const Calibration = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Calibration');
const Maintenance = () =>
    import(/* webpackChunkName: "monitoring-module" */ '@views/Monitoring/views/Device/views/Maintenance');

export default [
    {
        path: '/monitoring',
        components: { base: Monitoring },
        children: [
            {
                path: '',
                name: 'monitoring',
                components: { monitoring: MonitoringBase },
            },
            {
                path: ':deviceTypeId/devices',
                name: 'monitoring.devices',
                components: { monitoring: MonitoringDevices },
                beforeEnter(to, from, next) {
                    if (+to.params.deviceTypeId === 3) {
                        next('/monitoring/device/complaints');
                    }
                    if (+to.params.deviceTypeId === 4) {
                        next('/monitoring/device/company-supplier');
                    }
                    if (+to.params.deviceTypeId === 5) {
                        next('/monitoring/device/traceability');
                    }
                    if (+to.params.deviceTypeId === 6) {
                        next('/monitoring/device/shelf-life-traceability');
                    }

                    next();
                },
            },
            {
                path: 'device',
                components: { monitoring: MonitoringDevice },
                children: [
                    {
                        path: 'company-supplier',
                        meta: {
                            noNav: true,
                            isSave: true,
                            deviceTypeId: 4,
                            root: '/monitoring',
                        },
                        components: { deviceType: CompanySupplier },
                    },
                    {
                        path: 'complaints',
                        meta: {
                            noNav: true,
                            isSave: true,
                            deviceTypeId: 3,
                            root: '/monitoring',
                        },
                        components: { deviceType: Complaints },
                    },
                    {
                        path: 'traceability',
                        meta: {
                            noNav: true,
                            isSave: true,
                            deviceTypeId: 5,
                            root: '/monitoring',
                        },
                        components: { deviceType: ProductionTraceability },
                    },
                    {
                        path: 'shelf-life-traceability',
                        meta: {
                            noNav: true,
                            isSave: true,
                            deviceTypeId: 6,
                            root: '/monitoring',
                        },
                        components: { deviceType: ShelfLifeTraceability },
                    },
                    {
                        name: 'monitoring.temperatureStorage',
                        path: ':deviceTypeId/temperature-storage/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: TemperatureStorage },
                    },
                    {
                        path: 'pest-control/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: PestControl },
                    },
                    {
                        path: 'pest-control/:deviceId/service-provider',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: PestControl },
                    },
                    {
                        path: 'analyses/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Analyses },
                    },
                    {
                        path: 'analyses/:deviceId/bought-in-service',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Analyses },
                    },
                    {
                        path: 'calibration/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Calibration },
                    },
                    {
                        path: 'calibration/:deviceId/bought-in-service',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Calibration },
                    },
                    {
                        path: 'maintenance/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Maintenance },
                    },
                    {
                        path: 'maintenance/:deviceId/bought-in-service',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Maintenance },
                    },
                    {
                        path: 'tasks/:deviceId',
                        meta: { noNav: true, isSave: true, root: '/monitoring' },
                        components: { deviceType: Tasks },
                    },
                ],
            },
        ],
    },
];
