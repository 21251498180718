import SettingsAccount from '@views/Settings/Account';
import CompanyCreationView from '../SetUp/Company/CompanyCreationView.vue';
import PlaceCreationView from '../SetUp/Location/PlaceCreationView';

import SettingUp from '@views/Auth/views/Register/SettingUp';
// TODO: hot fix cause separate module might cause an error

export default [
    {
        path: '/account-settings',
        components: { base: SettingsAccount },
        name: 'settings.account',
    },
    {
        path: '/create/company',
        name: 'create.company',
        components: { base: CompanyCreationView },
        meta: {
            hideNav: false,
            isCreateNew: true,
        },
    },
    {
        path: '/create/place',
        name: 'create.place',
        components: { base: PlaceCreationView },
    },
    {
        path: '/setting-up',
        name: 'create.setting-up',
        components: { base: SettingUp },
        meta: {
            hideNav: true,
        },
    },
];
