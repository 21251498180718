<template>
    <div v-show="messageStatus">
        <NewReleaseBody class="update-message" />
    </div>
</template>

<script>
import NewReleaseBody from '@components/NewReleaseBody';

export default {
    components: {
        NewReleaseBody,
    },

    data() {
        return {
            messageStatus: false,
            automaticallyRefreshHours: 24,
        };
    },

    mounted() {
        if (window && window.Echo && window.Echo.channel) {
            window.Echo.channel('new-release').listen('NewRelease', () => {
                this.messageStatus = true;
                setTimeout(() => this.reloadPage(), this.automaticallyRefreshHours * 60 * 60 * 1000);
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.update-message {
    position: fixed !important;
    bottom: 10%;
    left: 3%;
    z-index: 99999;
    min-width: 290px;
    max-width: 350px;

    i {
        cursor: pointer;
    }
}
</style>
