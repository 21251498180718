<template>
    <div
        v-if="isEditable"
        class="base-input"
        :class="{ 'd-none-for-print': !value && value !== 0 }"
    >
        <ValidationProvider
            v-slot="v"
            ref="provider"
            :vid="name"
            :name="name"
            :rules="rules"
            mode="aggressive"
            slim
        >
            <div
                class="h-100"
                :class="{
                    'has-feedback has-error': !noValidate && ((validation && validation.$error) || v.errors.length),
                    'form-group w-100 mb-0 grid-template-with-label': isLabelSlot,
                    'd-flex': inline && isLabelSlot,
                    'w-100': isPrependSlot,
                }"
            >
                <label
                    v-if="isLabelSlot"
                    :for="id"
                    :class="labelClass"
                >
                    <slot name="label"></slot>
                </label>

                <div :class="[inputBlockClass, { 'input-group align-items-center': isAppendSlot || isPrependSlot }]">
                    <!--Temporary fix for FD-5458, cursor jumping, most probably it helps because
                    validation provider targets  first input field.-->
                    <input
                        v-model="val"
                        type="hidden"
                    />
                    <div
                        v-if="isPrependSlot"
                        class="input-group-prepend"
                        :class="{
                            'align-prepend': alignPrepend,
                        }"
                    >
                        <slot name="input-prepend"></slot>
                    </div>
                    <input
                        :id="id"
                        ref="inputField"
                        v-model="val"
                        :name="name"
                        :aria-invalid="v.ariaInput['area-invalid']"
                        :aria-required="v.ariaInput['aria-required']"
                        class="form-control"
                        :autocomplete="autocomplete"
                        :class="[
                            {
                                'is-invalid': !noValidate && ((validation && validation.$error) || v.errors.length),
                                'border-danger': hasInvalid,
                            },
                        ]"
                        :type="inputType"
                        :disabled="disabled"
                        :placeholder="placeholder"
                        step="any"
                        lang="en"
                        :min="minValue"
                        :max="maxValue"
                        @input="onInput"
                        @focus="onFocus"
                        @blur="
                            !noValidationAfterBlur && v.validate();
                            onBlur($event);
                        "
                        @keypress.enter="onKeypressEnter"
                        @keypress="onKeypress"
                    />

                    <div
                        v-if="isAppendSlot"
                        class="input-group-append"
                    >
                        <slot name="input-append"></slot>
                    </div>

                    <template v-if="showError">
                        <span
                            v-if="isErrorSlot && v.errors.length"
                            class="invalid-feedback d-block"
                        >
                            <slot
                                :validation="validation"
                                name="error"
                            />
                        </span>
                        <span
                            v-else-if="!noValidate && v.errors.length"
                            class="invalid-feedback d-block"
                        >
                            {{ v.errors[0] }}
                        </span>
                    </template>
                </div>
            </div>
        </ValidationProvider>
    </div>
    <span v-else>
        {{ value || '-' }}
    </span>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'BaseInput',

    components: {
        ValidationProvider,
    },

    directives: {
        wrapIf: {
            bind(el, binding) {
                if (!binding.value) {
                    unwrap(el);
                }
            },
        },
    },

    props: {
        id: {
            type: [String, Number],
            default: '',
        },
        filterOut: {
            type: String,
            default: null,
        },
        disableNegativeValues: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        noValidate: Boolean,
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        type: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        autocomplete: {
            type: String,
            default: 'on',
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        maxValue: {
            type: [String, Number],
            default: 0,
        },
        disabled: Boolean,
        inline: Boolean,
        uppercase: Boolean,
        hideUntouchedError: Boolean,
        aggressiveMode: Boolean,
        noValidationAfterBlur: Boolean,
        hasInvalid: Boolean,
        alignPrepend: Boolean,
        isEditable: {
            type: Boolean,
            default: true,
        },
        showError: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        filter() {
            if (!this.filterOut) {
                return '';
            }
            const filters = this.filterOut?.split('|');
            let filter = '';
            if (filters.includes('curly')) {
                filter += '{}';
            }
            if (filters.includes('angle')) {
                filter += '<>';
            }
            if (filters.includes('curves')) {
                filter += '()';
            }
            if (filters.includes('brackets')) {
                filter += '\\[\\]';
            }
            if (filters.includes('and')) {
                filter += '&';
            }
            return filter;
        },
        minValue() {
            return this.disableNegativeValues ? 0 : '';
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-5 col-form-label text-md-right text-sm-left';
            }

            return '';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-7';
            }

            return '';
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        isErrorSlot() {
            return !!(this.$scopedSlots.error || this.$slots.error);
        },
        isAppendSlot() {
            return !!this.$slots['input-append'];
        },
        isPrependSlot() {
            return !!this.$slots['input-prepend'];
        },
        isNumberType() {
            return this.type === 'number' || this.type === 'numberOnly';
        },
        inputType() {
            // this.type !== 'number' is to fix issues with some phone keyboards not showing '-' sign
            if (this.type && this.type !== 'number') {
                return this.type;
            }

            return 'text';
        },
    },

    methods: {
        onInput(e) {
            const { value } = e.target;
            if (this.disabled) {
                return;
            }
            if (this.filter?.length) {
                const regex = new RegExp(`[${this.filter}]`, 'g');
                if (value) {
                    this.val = value.replaceAll(regex, '');
                }
            } else if (this.isNumberType) {
                this.val = value.replace(/,/g, '.');

                if (+this.maxValue && +value > +this.maxValue) {
                    this.val = this.maxValue;
                }
            } else if (this.uppercase) {
                this.val = value.toUpperCase();
            } else {
                this.val = value;
            }
        },
        onKeypressEnter(e) {
            this.$emit('keypress-enter', e);
        },
        onBlur(e) {
            this.$emit('blur', e);

            this.validation && this.validation.$touch();
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        clear() {
            if (this.$refs.inputField) {
                this.$refs.inputField.value = '';
            }
        },
        blur() {
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
            }
            this.$emit('blur');
        },
        focus() {
            if (this.$refs.inputField) {
                this.$refs.inputField.focus();
            }
        },
        onKeypress(e) {
            this.$emit('keypress', e);
            this.isNumber(e);
            this.filterOutValue(e);
        },
        isNumber(evt) {
            if (this.isNumberType) {
                evt = evt ? evt : window.event;
                const charCode = evt.which ? evt.which : evt.keyCode;
                const key = String.fromCharCode(charCode);
                const regex = this.type === 'numberOnly' ? /^[0-9]+$/ : /^[0-9.,-]+$/;
                if (!regex.test(key)) {
                    evt.preventDefault();
                }
            }
        },
        filterOutValue(evt) {
            if (this.filter?.length) {
                evt = evt ? evt : window.event;
                const charCode = evt.which ? evt.which : evt.keyCode;
                const key = String.fromCharCode(charCode);
                const regex = new RegExp(`^[${this.filter}]+$`);
                if (regex.test(key)) {
                    evt.preventDefault();
                }
            }
        },
    },
};

function unwrap(wrapper) {
    wrapper.outerHTML = wrapper.innerHTML;
}
</script>

<style lang="scss" scoped>
.base-input,
.input-group {
    input {
        border-width: 1px;
        background: $gray-200;
        border-radius: 0.3rem;
        transition: border 0.3s ease-in-out;

        &:not(.is-invalid) {
            border-color: transparent;
            border-style: solid;
        }

        &:hover {
            background-color: $gray-300;
            border-color: $gray-400;
        }
    }

    label {
        font-size: 0.875rem;
        font-weight: normal;
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.align-prepend {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    z-index: 999;
}
</style>
