import { RESET_TIME_DIAPASON, SET_TIME_DIAPASON, SET_TIME_DIAPASON_FROM, SET_TIME_DIAPASON_UNTIL } from './constants';

import { getLast30DaysDiapason } from '@services/helper.service';

export const mutations = {
    [SET_TIME_DIAPASON](state, { from, until }) {
        state.timeDiapason.from = from;
        state.timeDiapason.until = until;
    },

    [SET_TIME_DIAPASON_FROM](state, value) {
        state.timeDiapason.from = value;
    },

    [SET_TIME_DIAPASON_UNTIL](state, value) {
        state.timeDiapason.until = value;
    },

    [RESET_TIME_DIAPASON](state) {
        state.timeDiapason = getLast30DaysDiapason();
    },
};
