// Mutations
export const SAVE_BASE64 = 'SAVE_BASE64';
export const CLEAR_BASE64 = 'CLEAR_BASE64';
export const REMOVE_BASE64 = 'REMOVE_BASE64';
export const ADD_FILE_LOADING = 'ADD_FILE_LOADING';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const REMOVE_FILE_LOADING = 'REMOVE_FILE_LOADING';

// Actions
export const FETCH_BASE64 = 'FETCH_BASE64';
export const DELETE_FILE = 'DELETE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SHOW_OR_DOWNLOAD_FILE = 'SHOW_OR_DOWNLOAD_FILE';
export const START_FILE_LOADING = 'START_FILE_LOADING';
export const RENAME_VTA_DOC = 'RENAME_VTA_DOC';
export const DOWNLOAD_FILE = 'file/DOWNLOAD_FILE';
