<template>
    <div>
        <RouterView v-if="isLoaded" />
        <Loader
            v-show="isLoading"
            :block-page="true"
        />

        <FileLoaderBox
            v-if="backgroundUploadingFiles.length"
            class="file-loader-box"
            :value="backgroundUploadingFiles"
        />

        <template v-if="isLoaded">
            <NotificationsView />
            <WrapperMessage />
            <AffectPricingNotificationModal />
            <NeedToPayNotification
                ref="subscriptionModal"
                @cancel="redirectToOverview"
                @submit="redirectToBilling"
            >
                <h1>{{ $t('Your subscription has ended.') }}</h1>
                <h2>{{ $t('Please subscribe to our service to continue using FoodDocs. Hope to see you soon!') }}</h2>
            </NeedToPayNotification>
            <NeedToPayNotification
                ref="trialModal"
                @cancel="redirectToOverview"
                @submit="redirectToBilling"
            >
                <h1>{{ $t('Your free trial has ended.') }}</h1>
                <h2>{{ $t('Please subscribe to our service to continue using FoodDocs. Hope to see you soon!') }}</h2>
            </NeedToPayNotification>
        </template>

        <BaseModal
            ref="shared-link-expired-modal"
            size="md"
            modal-class="baseModal"
            hide-header
            hide-footer
            no-close
            centered
        >
            <template #default>
                <p>{{ $t('This link is not valid anymore') }}</p>
                <button
                    class="btn btn-primary"
                    @click="closeExpireLinkModal"
                >
                    {{ $t('Close') }}
                </button>
            </template>
        </BaseModal>

        <BaseModal
            ref="easy-app-modal"
            size="md"
            modal-class="baseModal"
            hide-header
            hide-footer
            centered
        >
            <img
                src="/assets/img/easy_app.svg"
                alt="Mobile app"
            />
            <div class="space-related-out-t text-center">
                <h3 class="h1">{{ $t('Daily tasks in easy app') }}</h3>
                <p>
                    {{ $t('All your daily tasks can be filled in on a mobile or tablet, download FoodDocs app on') }}
                    <a
                        target="_blank"
                        :href="APP_LINKS.GOOGLE_PLAY"
                        >Google Play</a
                    >
                    {{ $t('or') }}
                    <a
                        target="_blank"
                        :href="APP_LINKS.APPLE_STORE"
                        >Apple App Store</a
                    >
                </p>
            </div>
            <div class="d-flex justify-content-center space-related-out-t">
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="closeEasyAppModal"
                >
                    {{ $t('Close') }}
                </button>
            </div>
        </BaseModal>

        <NeedRefreshModal ref="newRelease" />

        <PortalTarget
            name="modal"
            class="app-modal"
            multiple
        />

        <QuestionnaireModal ref="questionnaireModal" />
    </div>
</template>

<script>
import { APP_LINKS } from '@common/constants';
import AffectPricingNotificationModal from '@components/modals/Notifications/AffectPricingNotificationModal';
import BaseModal from '@components/BaseModal';
import ChatService from './common/services/chat.service';
import FileLoaderBox from '@components/FileLoaderBox';
import Loader from '@components/Loader';
import NeedRefreshModal from '@components/NeedRefreshModal';
import NeedToPayNotification from '@components/NeedToPayNotification';
import NotificationsView from '@components/modals/Notifications/NotificationsView';
import { SET_IS_MOBILE } from '@store/common/constants';
import WrapperMessage from '@components/WrapperMessage';
import { isMobile } from '@common/services/helper.service';
import { mapGetters } from 'vuex';
import QuestionnaireModal from '@components/modals/QuestionnaireModal';

function loadScript(src) {
    return new Promise(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
            resolve();
            return;
        }

        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.src = src;

        el.addEventListener('load', resolve);
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);

        document.head.appendChild(el);
    });
}

export default {
    components: {
        FileLoaderBox,
        NeedToPayNotification,
        NotificationsView,
        BaseModal,
        Loader,
        WrapperMessage,
        AffectPricingNotificationModal,
        NeedRefreshModal,
        QuestionnaireModal,
    },

    data() {
        return {
            isLoaded: false,
            APP_LINKS,
        };
    },

    computed: {
        ...mapGetters(['selectedPlace', 'userProfile']),
        ...mapGetters({
            backgroundUploadingFiles: 'files/filesUploading',
            selectedCompany: 'selectedCompany',
            selectedCompanyId: 'selectedCompanyId',
            sharedLInkExpired: 'shared-access/isExpire',
            isSharedAccess: 'shared-access/isSharedAccess',
            profile: 'userProfile',
        }),
        isLoading() {
            return this.$store.getters.isViewLoading || !this.$store.getters.isCompaniesInited;
        },
        isTrialEnd() {
            return this.selectedCompany?.company?.trial_end_date && this.isSubscriptionEnd;
        },
        isSubscriptionEnd() {
            return (
                !this.selectedCompany?.company?.subscription &&
                this.selectedPlace &&
                Object.keys(this.selectedPlace).length
            );
        },
    },

    watch: {
        sharedLInkExpired(value) {
            const m = this.$refs['shared-link-expired-modal'];
            value ? m?.show() : m?.hide();
        },
        isTrialEnd(value) {
            if (value) {
                this.$refs.trialModal.show();
            }
        },
        isSubscriptionEnd(value) {
            if (!this.$refs.trialModal?.shown && value) {
                this.$refs.subscriptionModal.show();
            }
        },
        selectedCompany(value) {
            this.$store.dispatch('classifiers/getDateFormats');
            if (value?.company?.id && this.$store.getters['auth/isInvitedUser']) {
                this.$refs?.['easy-app-modal']?.show();
                this.$store.dispatch('unsetUserAsInvited');
            }
        },
        selectedCompanyId: {
            immediate: true,
            async handler(val) {
                if (!val) {
                    return;
                }

                const questionnaires = await this.$store.dispatch('classifiers/getCompanyQuestionnaires');

                const routeOrigin = this.$route?.name ? this.$route.name.split('.')[0] : '';
                const isNoShowPage = ['register', 'login'].includes(routeOrigin);

                if (questionnaires?.length && !isNoShowPage) {
                    const onboardingQuestionnaireIndex = questionnaires.findIndex(
                        (questionnaire) => questionnaire.name === 'onboarding_questionnaire'
                    );
                    if (onboardingQuestionnaireIndex !== -1) {
                        this.$refs.questionnaireModal.show(questionnaires[onboardingQuestionnaireIndex]);
                    }
                }
            },
        },
        userProfile(profile) {
            if (typeof dataLayer === 'undefined') {
                return;
            }

            // eslint-disable-next-line no-undef
            dataLayer.push({
                user_id: profile?.id || '',
            });
        },
    },

    created() {
        this.$store.dispatch('classifiers/getDateFormats');

        if (this.$route.query?.lang || window.location.search.match(/lang=/)) {
            const urlParams = new URLSearchParams(window.location.search);
            const lang = this.$route.query?.lang || urlParams.get('lang') || 'en';
            this.$i18n.locale = lang.split('?')[0];
        }

        this.isLoaded = true;

        window.addEventListener('resize', () => {
            this.$store.commit(SET_IS_MOBILE, isMobile());
        });
        this.$store.commit(SET_IS_MOBILE, isMobile());

        this.$root.$on('new-release', () => {
            if (!this.$refs?.newRelease?.shown) {
                this.$refs.newRelease?.show();
                setTimeout(() => {
                    this.$root.$emit('new-release');
                }, 1000);
            }
        });
    },

    async mounted() {
        if (this.isSharedAccess) {
            const sharedItem = await this.$store.dispatch('shared-access/get');
            const lang = sharedItem?.lang || 'en';
            this.$i18n.locale = lang?.split('?')[0];
        }

        if (['local', 'test'].includes(process.env.MIX_APP_ENV)) {
            await loadScript('https://js.hs-scripts.com/5951199.js');
        }
        ChatService.init();

        const m = this.$refs['shared-link-expired-modal'];
        this.sharedLInkExpired ? m?.show() : m?.hide();

        const company = this.selectedCompany?.company;

        if (process.env.MIX_APP_ENV !== 'local') {
            // eslint-disable-next-line no-undef
            profitwell('start', { user_id: company?.stripe_customer_id });

            window.usetifulTags = {
                language: this.$i18n.locale,
                role: company?.role || 'unknown',
                firstName: this.userProfile?.firstname || 'customer',
                pricePackage: company?.subscription?.price_package_id?.toString() || 'unknown',
                lookingFor: localStorage.getItem('interest') || company?.interest_in_fooddocs || 'not set',
                userId: localStorage.getItem('userId') || this.userProfile?.id,
            };

            let headElement = document.getElementsByTagName('head')[0];
            let scriptElement = document.createElement('script');
            scriptElement.setAttribute('id', 'usetifulScript');

            scriptElement.async = true;
            scriptElement.src = 'https://www.usetiful.com/dist/usetiful.js';
            scriptElement.dataset.token = process.env.USETIFUL_KEY;
            headElement.appendChild(scriptElement);
        }
    },

    methods: {
        closeEasyAppModal() {
            this.$refs?.['easy-app-modal']?.hide();
            this.$store.dispatch('auth/unsetUserAsInvited');
        },
        redirectToOverview() {
            this.$router.push({ name: 'overview' });
            this.$refs.trialModal.hide();
            this.$refs.subscriptionModal.hide();
        },
        redirectToBilling() {
            this.$router.push({ name: 'billing.choose-payment' });
            this.$refs.trialModal.hide();
            this.$refs.subscriptionModal.hide();
        },
        closeExpireLinkModal() {
            this.$store.commit('shared-access/reset');
            this.$router.push({ name: 'login' });
        },
    },
};
</script>

<style lang="scss" scoped>
.file-loader-box {
    position: fixed;
    bottom: $modal-dialog-margin;
    left: 50%;
}
</style>

<style lang="scss">
@import './styles/styles';
</style>
