<template>
    <div class="row">
        <div class="offset-md-5 col-md-7 text-center">
            <h2>{{ $t('Change language') }}</h2>
        </div>
        <form
            class="col-12"
            @submit.prevent="save"
        >
            <div class="form-group row">
                <label
                    :for="'language'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('Language') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="preferred-language"
                        v-model="selectedLanguage"
                        :options="languages"
                        :custom-label="(item) => item.text"
                        :placeholder="$t('Choose preferred language')"
                        not-empty
                    />
                </div>
            </div>

            <div class="form-group row">
                <div class="offset-md-5 col-md-7">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_LANGUAGE } from '@store/localization/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

export default {
    data() {
        return {
            selectedLanguage: null,
        };
    },

    computed: {
        ...mapGetters({ languages: 'languagesWithEnglishVariations' }),
        currentLang() {
            return this.$store.getters.userProfile.lang;
        },
    },

    created() {
        this.selectedLanguage = this.languages.find((lang) => lang.key === this.currentLang) || null;
    },

    methods: {
        async save() {
            if (!this?.selectedLanguage?.key) {
                this.$toastr.e(this.$t('No language is selected'));
                return;
            }
            if (this.currentLang === this.selectedLanguage.key) {
                this.$toastr.e(this.$t('Please select a different language'));
                return;
            }
            this.$store.commit(START_VIEW_LOADER);
            await this.$store.dispatch(SET_LANGUAGE, this.selectedLanguage.key);
            this.$store.commit(STOP_VIEW_LOADER);
        },
    },
};
</script>
