export interface IPlace {
    id: number;
    name: string;
}
export class Place implements IPlace {
    id: number = 0;
    name: string = '';

    constructor(data: IPlace) {
        this.id = data.id;
        this.name = data.name;
    }

    static fromJSON(json): Place {
        return new Place({
            id: json.id,
            name: json.place_name,
        });
    }
}

export enum VIEW_TYPES {
    matrix = 'matrix',
    graph = 'graph',
    table = 'table',
}

export enum FILTER {
    rooms = 'rooms',
    equipment = 'equipment',
    people = 'people',
    tasks = 'tasks',
    partners = 'partners',
    product = 'product',
    ingredient = 'ingredient',
    employee = 'employee',
    roles = 'roles',
}

export interface ITableData {
    originData: any;
    headers: ITableHeader[];
    data: ITableItem[];
}

export interface ITableHeader {
    name: string;
    key: string;
}
export interface ITableItem {
    [key: string]: string;
}

export interface IChartData {}

export interface ITask {
    id: number;
    name: string;
}

export interface IConfig {
    name: string;
    getTasks: (rootState: any, rootGetters: any, params: { [key: string]: string }) => Promise<ITask[] | null>;
    getChartData: (rootState: any, rootGetters: any, params: { [key: string]: string }) => Promise<IChartData[] | null>;
    getTableData: (rootState: any, rootGetters: any, params: { [key: string]: string }) => Promise<ITableData | null>;
    isSinglePlaceSelectable?: boolean;
    isSingleTaskSelectable?: boolean;
    applicable: Array<{
        name: string;
        value: string;
        key: string;
    }>;
    groupBy: Array<{
        key: string;
        name: string;
    }>;
    viewTypes: Array<{
        key: VIEW_TYPES;
        name: string;
    }>;
    state: {
        currentView: VIEW_TYPES;
    };
}
export interface IFilter {
    createConfig(): IConfig;
    taskFromJSON(i: any, index: number): { id: number; name: string };
    createTableHeaders(json: any): ITableHeader[];
    createTableItems(json: any): ITableItem[];
    createChartsData(json: any): IChartData[];
    CSVStrategy?(payload: any): string;
    createRequestProps(): any;
}
