import http from '@http';
import { blobToBase64 } from './helper.service';

class AttachmentsService {
    fetchAttachmentContent(id) {
        return http.get(`/api/module/production/attachment/${id}/download`, {
            responseType: 'blob',
        });
    }

    fetchAttachmentPublicContent(id, token) {
        return http.get(`/api/public/attachment/${id}/download/${token}`, {
            responseType: 'blob',
        });
    }

    downloadAttachment(id, name = 'file.*') {
        return this.fetchAttachmentContent(id).then(async (res) => {
            const contentType = res && res.headers && res.headers['content-type'] ? res.headers['content-type'] : null;

            return {
                name: name,
                data: await blobToBase64(res.data),
                type: contentType,
            };
        });
    }

    deleteAttachment(id) {
        return http.delete(`/api/module/production/attachment/${id}/delete`);
    }
}

export const attachmentsService = new AttachmentsService();
