<template>
    <div
        v-if="isLoading && !$route.query.fromSetup"
        class="bg-gray-200 border-bottom border-top pl-3 pt-1 space-unrelated-in d-flex align-items-center justify-content-center"
    >
        <span class="space-related-in-x">{{ $t('Your HACCP is being initialized.') }}</span
        ><span class="loader"></span>
    </div>
    <div
        v-else-if="generalDataOnceFilled || !$route.query.fromSetup"
        class="bg-gray-200 border-bottom border-top pl-3 pt-1"
    >
        <div v-if="showConfirmButton || !planConfirmed">
            <div
                v-if="planFilled"
                class="row plan-header-row bg-green-light space-related-in d-flex align-items-center justify-content-center"
            >
                <span class="mr-2 font-size-xs mt-1 d-flex align-items-center">
                    {{ $t('You have made changes to your HACCP plan.') }}
                </span>
                <button
                    id="confirm-plan"
                    type="button"
                    :disabled="isLoading"
                    class="btn btn-primary mt-1"
                    @click="confirmPlan"
                >
                    {{ $t('Confirm changes') }}
                </button>
            </div>

            <div
                v-else
                class="row plan-header-row bg-gray-200 space-unrelated-in d-flex align-items-center justify-content-center"
            >
                {{ $t('Not all sections of your HACCP are filled. Incomplete sections in the menu are marked with') }}
                <i class="fd-circle text-red ml-2"></i>.
                {{ $t('Fill the required parts completely in order to confirm, download and share your plan.') }}
            </div>
        </div>
        <div
            v-else-if="planConfirmed || !confirmedBy"
            class="w-100 d-flex align-items-center justify-content-between space-related-in"
        >
            <div class="d-flex flex-column justify-content-center">
                <div class="overflow-ellipsis">{{ $t('HACCP') }} - {{ selectedPlace.name }}</div>
                <div
                    v-if="planConfirmed"
                    class="d-flex align-items-center"
                >
                    <span class="d-flex flex-column align-items-end font-size-xs">
                        <span class="text-gray-500">
                            {{ $t('Confirmed') }} {{ confirmedData.confirmed_at | formatDate }} {{ confirmedBy }}</span
                        >
                    </span>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-end flex-wrap font-size-xs">
                <RouterLink
                    :to="{ name: 'plan.hazards-summary-list' }"
                    class="btn text-gray-700 hover-green font-size-xs"
                >
                    <i class="fd-haccp mr-2"></i>{{ $t('Summary') }}
                </RouterLink>
                <RouterLink
                    v-if="$route.name !== 'plan.setup' && !isSharedAccess"
                    :to="{ name: 'plan.setup' }"
                    class="btn text-gray-700 hover-green font-size-xs"
                >
                    <i class="fd-cog mr-2"></i>
                    {{ $t('Manage') }}
                </RouterLink>
                <button
                    v-if="!showConfirmButton"
                    id="download-plan"
                    type="button"
                    class="btn text-gray-700 hover-green font-size-xs"
                    :disabled="isLoading"
                    @click="downloadFspPdf"
                >
                    <i class="fd-document-download mr-2 text-gray-700"></i>
                    {{ $t('download') }}
                </button>
                <RouterLink
                    v-if="!showConfirmButton"
                    id="share"
                    type="button"
                    class="btn text-gray-700 hover-green font-size-xs d-flex align-items-center"
                    :disabled="isLoading"
                    :to="{ name: 'share-accesses-new' }"
                >
                    <i class="fd-envelope mr-2 hover-green"></i>
                    {{ $t('Invite to read') }}
                </RouterLink>
            </div>
        </div>
        <!-- Start of Discount Modal -->
        <BaseModal
            ref="renewableHaccpOfferModal"
            v-slot="{ close }"
            centered
            hide-header
            hide-footer
            size="md"
        >
            <div class="text-center space-related-out-t">
                <img
                    class="space-related-out-b"
                    src="/assets/img/illu_celebrate.svg"
                    alt="Woman with tablet"
                />
                <div class="space-unrelated-out-x">
                    <div class="heading space-related-out-t">
                        {{ $t('Thank you for purchasing the HACCP plan!') }}
                    </div>
                    <div class="space-related-out-x space-related-out-t">
                        {{ $t('Would you like to access your HACCP plan after 30 days') }}
                        <span class="font-weight-bold">{{ $t('Subscribe for digital HACCP') }}</span>
                    </div>

                    <div>
                        <button
                            type="button"
                            class="btn btn-primary space-related-out"
                            @click="$router.push({ name: 'billing.choose-payment', params: { payFor: fastHACCP } })"
                        >
                            {{ $t('continue') }}
                        </button>
                    </div>
                    <a
                        class="cursor-pointer"
                        @click="close"
                        >{{ $t("No thank you, I don't want to store my HACCP") }}
                    </a>
                </div>
            </div>
        </BaseModal>
        <!-- Start of Join us Modal -->
        <BaseModal
            id="fsp-time-to-join-us"
            ref="fsp-time-to-join-us"
            v-slot="{ close }"
            :ok-title="$t('download')"
            :cancel-title="$t('Cancel')"
            :title="$t('module_food_safety_plan')"
            centered
            hide-header
            hide-footer
            size="md"
            modal-class="timeToJoinUs"
            @close="closeTimeToJoinUsModal"
        >
            <img
                :alt="$t('Time to join us')"
                class="modal-image"
                src="/assets/img/illu_celebrate.svg"
            />
            <div class="text-center mt-1">
                <h1>{{ $t('Time to join us') }}</h1>
                <h2>{{ timeToJoinUsMessage }}</h2>
            </div>
            <div class="text-center mt-5 d-flex align-items-center justify-content-center">
                <button
                    class="btn btn-outline-primary mx-1"
                    type="button"
                    @click="closeTimeToJoinUsModal(close)"
                >
                    {{ $t('Cancel') }}
                </button>
                <RouterLink
                    type="button"
                    class="btn btn-primary mx-1"
                    :to="{ name: 'billing', params: { fromPlan: true } }"
                >
                    {{ $t('continue') }}
                </RouterLink>
            </div>
        </BaseModal>
        <!-- End of Join us Modal -->

        <CustomizableViewLoader
            ref="planDownloadLoader"
            :heading="getLoaderHeading()"
            :message="getLoaderMessage()"
        />
    </div>
</template>

<script>
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { mapGetters } from 'vuex';
import CustomizableViewLoader from '@components/CustomizableViewLoader/CustomizableViewLoader';
import { vm } from '@main';
import BaseModal from '@components/BaseModal/BaseModal';

export default {
    name: 'PrintLayoutHeader',

    components: { BaseModal, CustomizableViewLoader },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$store.dispatch('plan/getDetails');
            vm.showConfirmButton = vm.checkShowConfirmButton();
        });
    },

    data() {
        return {
            language: vm.$i18n.locale,
            selectedPlace: this.$store.getters.selectedPlace,
            timeToJoinUsMessage: '',
            showConfirmButton: false,
        };
    },

    computed: {
        ...mapGetters({
            editModeActive: 'plan/editModeActive',
            isEditMode: 'plan/isEditMode',
            isLoading: 'plan/sections/isLoading',
            planStatus: 'plan/selectedPlanStatus',
            checkStatus: 'plan/checkStatus',
            generalDataOnceFilled: 'plan/generalDataOnceFilled',
            isSharedAccess: 'shared-access/isSharedAccess',
            canWritePlace: 'canWritePlace',
            confirmedBy: 'confirmedBy',
        }),
        confirmedData() {
            return this.selectedPlace
                ? {
                      confirmed_at: this.selectedPlace.plan_confirmed_at,
                      confirmed_by: this.selectedPlace.plan_confirmed_by,
                  }
                : { confirmed_at: null, confirmed_by: null };
        },
        planFilled() {
            return this.planStatus === 'filled' || this.checkStatus('allFilled');
        },
        planConfirmed() {
            return this.checkStatus('confirmed') || this.planStatus === 'confirmed';
        },
    },

    methods: {
        async confirmPlan() {
            this.$store.commit(START_VIEW_LOADER);
            if (this.planFilled) {
                await this.$store.dispatch('plan/confirmPlan');
                await this.$store.dispatch('plan/getStates');
                this.showConfirmButton = false;
            }
            this.$store.commit(STOP_VIEW_LOADER);
        },
        checkShowConfirmButton() {
            return this.canWritePlace && !this.planConfirmed && this.planFilled;
        },
        downloadFspPdf() {
            this.$refs.planDownloadLoader.show();
            let filename = `${this.$store.getters.selectedPlace.name}_FoodDocs_HACCP_plan`;
            this.$store
                .dispatch('plan/downloadFsp', {
                    placeId: this.$store.getters.selectedPlace.id,
                    language: this.language,
                    filename: filename,
                })
                .then(() => {
                    if (this.$store.getters.selectedCompany?.company?.subscription?.haccp_only) {
                        this.$refs.renewableHaccpOfferModal.show();
                    }
                })
                .catch((error) => {
                    if (error?.message) {
                        this.timeToJoinUsMessage = this.$t(error.message);
                        this.$refs['fsp-time-to-join-us'].show();
                    }
                })
                .then(() => {
                    this.$refs.planDownloadLoader.close();
                });
        },
        isPlanFilled() {
            return (
                this.confirmedData &&
                this.confirmedData.confirmed_at &&
                this.confirmedData.confirmed_by &&
                this.checkStatus('allFilled')
            );
        },
        showPlanNotFilledToast() {
            this.$toastr.e(this.$t('All fields in the self control plan must be filled to perform this action'));
        },
        getLoaderHeading() {
            return this.$t('Thank you for downloading the HACCP plan!');
        },
        getLoaderMessage() {
            return this.$t("This download might take up to 3 minutes. Please don't close this page");
        },
        closeTimeToJoinUsModal(close) {
            if (typeof close !== 'undefined') {
                close();
            }
        },
    },
};
</script>

<style>
.overflow-ellipsis {
    text-overflow: ellipsis;
}
</style>
