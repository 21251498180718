// Views
const Production = () => import(/* webpackChunkName: "production-module" */ '@views/Production');
const CustomIngredients = () =>
    import(/* webpackChunkName: "production-ingredients-module" */ '@views/Production/views/CustomIngredients');
const Ingredients = () =>
    import(/* webpackChunkName: "production-ingredients-module" */ '@views/Production/views/Ingredients');

// Nested routes
import createEditViewRoutes from '@views/Production/views/Products/views/CreateEditView/routes';
import productViewRoutes from '@views/Production/views/Products/views/ProductView/routes';

const ProductsBase = () =>
    import(/* webpackChunkName: "production-module" */ '@views/Production/views/Products/views/Base');
const ProductsExport = () =>
    import(/* webpackChunkName: "production-module" */ '@views/Production/views/Products/views/Export');
const Products = () => import(/* webpackChunkName: "production-module" */ '@views/Production/views/Products');

export default [
    {
        path: '',
        name: 'production',
        redirect: { name: 'production.products' },
        component: Production,
        children: [
            ...createEditViewRoutes,
            ...productViewRoutes,
            {
                path: 'production',
                components: { production: Products },
                children: [
                    {
                        path: '',
                        components: { products: ProductsBase },
                        name: 'production.products',
                    },
                    {
                        path: 'export',
                        components: { products: ProductsExport },
                        name: 'production.products-export',
                    },
                ],
            },
            {
                path: 'ingredients',
                name: 'production.ingredientsTab',
                components: { production: Ingredients },
            },
            {
                path: 'custom-ingredients',
                name: 'production.customIngredientsTab',
                components: { production: CustomIngredients },
            },
        ],
    },
];
