import { FILTER } from '../types';

import { PeopleFilter } from './PeopleFilter';
import { EquipmentFilter } from './EquipmentFilter';
import { RoomsFilter } from './RoomsFilter';
import { TasksFilter } from './TasksFilter';

export default {
    [FILTER.equipment]: new EquipmentFilter(),
    [FILTER.people]: new PeopleFilter(),
    [FILTER.rooms]: new RoomsFilter(),
    [FILTER.tasks]: new TasksFilter(),
};
