import { monitoringService } from '@services/monitoring.service';
import { SORT_BY } from '@views/Monitoring/store/constants';

export const getters = {
    currentDeviceId(state) {
        return state.currentDeviceId;
    },
    measurementsList(state) {
        return state.measurements || [];
    },
    correctingActionsList(state) {
        return state.correctingActions;
    },
    correctingActionsListById: (state, getters) => (id) => {
        const deviceType = getters.getDeviceById(id).device_type_id;
        return getters.correctingActionsList.filter((item) => item.device_type_id === deviceType);
    },
    problemsList() {
        return monitoringService.getProblemsList();
    },
    preventionsList() {
        return monitoringService.getPreventionsList();
    },
    supplierPreventionsList() {
        return monitoringService.getSupplierPreventionsList();
    },
    supplierDefectsList() {
        return monitoringService.getSupplierDefectsList();
    },
    getPagination(state) {
        return state.pagination;
    },
    monitoringPerPage(state) {
        if (state.pagination) {
            return +state.pagination.perPage || 10;
        }
        return 10;
    },
    isReadyForDeviceView(state, getters) {
        return getters.isDevicesInited;
    },
    measurementsSearchQuery(state) {
        return state.searchQuery;
    },
    measurementsSortBy(state, getters) {
        return getters.measurementsFilters.find((item) => item.key === state.sortBy) || getters.measurementsFilters[0];
    },

    measurementsFilters() {
        return [
            {
                key: SORT_BY.ALL,
                name: 'All results',
            },
            {
                key: SORT_BY.INCORRECT,
                name: 'Incorrect results',
            },
        ];
    },
};
