import Vue from 'vue';

import {
    ADD_NEW_MEASUREMENT,
    CLEAR_MEASUREMENTS,
    DELETE_ATTACHMENT,
    DELETE_MEASUREMENT,
    RESET_MONITORING,
    RETURN_MEASUREMENT_ITEM,
    SET_CORRECTING_ACTIONS,
    SET_CURRENT_DEVICE,
    SET_MEASUREMENTS,
    SET_PAGINATION,
    SET_SEARCH_QUERY,
    SET_SORT_BY,
    UPDATE_MEASUREMENT,
    UPDATE_MEASUREMENT_ATTACHMENTS,
    UPDATE_MEASUREMENT_ITEM,
} from './constants';

import { getCurrentDateTime, getLast30DaysDiapason } from '@services/helper.service';
import { SET_MONITORING_PER_PAGE } from '@views/Monitoring/store/constants';

export const mutations = {
    [SET_CURRENT_DEVICE](state, id) {
        if (state.currentDeviceId !== id) {
            state.currentDeviceId = id;
        }
    },

    [SET_MEASUREMENTS](state, data) {
        if (!Array.isArray(data)) {
            throw new TypeError("Can't get data");
        }

        state.measurements = data.map((measurement) => {
            if (!measurement.data) {
                measurement.data = {};
            }
            measurement.correcting_action = measurement.correctingActions;

            if (measurement.files) {
                measurement.files = measurement.files.map((file) => ({
                    id: file.id,
                    name: file.filename,
                }));
            } else {
                measurement.files = [];
            }

            return measurement;
        });
    },

    [CLEAR_MEASUREMENTS](state) {
        state.measurements = [];
        state.timeDiapason = getLast30DaysDiapason();
    },

    [ADD_NEW_MEASUREMENT](state, defaultValues = {}) {
        state.measurements.unshift({
            hasUpdated: true,
            measurer_user: defaultValues.profile,
            time: getCurrentDateTime(),
            is_invalid: false,
            value: null,
            measurer: defaultValues.profile ? defaultValues.profile.id : null,
            description: null,
            correcting_action: null,
            preventive_action: null,
            correcting_text: null,
            data: {
                product: null,
                ingredient: null,
                quality: null,
                supplier: null,

                product_id: null,
                ingredient_id: null,
                ingredient_amount: null,
                production_unit_id: null,
                batch: null,
                exp_date_new: null,
                username: null,
                food_amount: null,
                food_unit_id: null,

                rate: null,
                notes: null,
                status: null,
            },
        });
    },

    [UPDATE_MEASUREMENT_ITEM](state, { value, path, skipUpdateMarker }) {
        if (!path.length) {
            throw new TypeError('Path should be array of keys!');
        }

        let obj = state.measurements;

        path.slice(0, -1).forEach((key) => {
            obj = (obj && obj[key]) || null;
        });
        if (obj) {
            Vue.set(obj, path[path.length - 1], value);
        }

        if (!skipUpdateMarker && state.measurements[path[0]]) {
            Vue.set(state.measurements[path[0]], 'hasUpdated', true);
        }
    },

    [RETURN_MEASUREMENT_ITEM](state, { index, data }) {
        state.measurements.splice(index, 0, data);
    },

    [DELETE_MEASUREMENT](state, index) {
        state.measurements.splice(index, 1);
    },

    [SET_CORRECTING_ACTIONS](state, data) {
        state.correctingActions = data;
    },

    [UPDATE_MEASUREMENT](state, { data, target, id }) {
        const list = [...state.measurements];

        const hasValue = !!list.find((item, index) => {
            if (item === target || item.id === id) {
                list[index] = { ...data };

                return true;
            }
        });

        if (hasValue) {
            state.measurements = list;
        }
    },

    [UPDATE_MEASUREMENT_ATTACHMENTS](state, { id, files }) {
        const list = [...state.measurements];

        const hasValue = !!list.find((item, index) => {
            if (item.id === id) {
                list[index].files = files.map((file) => ({
                    id: file.id,
                    name: file.filename,
                }));

                return true;
            }
        });

        if (hasValue) {
            state.measurements = list;
        }
    },

    [DELETE_ATTACHMENT](state, { measurementId, fileId }) {
        state.measurements.find((item, itemIndex) => {
            if (item.id === measurementId) {
                state.measurements[itemIndex].files.find((file, fileIndex) => {
                    if (file.id === fileId) {
                        state.measurements[itemIndex].files.splice(fileIndex, 1);

                        return true;
                    }
                });

                return true;
            }
        });
    },

    [SET_PAGINATION](state, data) {
        state.pagination = {
            total: data.total,
            currentPage: data.current_page,
            lastPage: data.last_page,
            perPage: data.per_page,
        };
    },

    [SET_MONITORING_PER_PAGE](state, value) {
        if (Number.isFinite(value)) {
            state.pagination.perPage = value;
        } else {
            throw new TypeError('Value should be a Number');
        }
    },

    [RESET_MONITORING](state) {
        state.currentDeviceId = null;
        state.data = [];
    },

    [SET_SEARCH_QUERY](state, query) {
        state.searchQuery = query;
    },

    [SET_SORT_BY](state, sortBy) {
        state.sortBy = sortBy;
    },
};
