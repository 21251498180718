var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEditable)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDatePicker),expression:"hideDatePicker"}],staticClass:"optional-date-picker position-relative",on:{"click":_vm.toggleDatePicker}},[_c('BaseInput',{attrs:{"id":_vm.id,"validation":_vm.validation,"autocomplete":"off"},on:{"input":_vm.inputUpdate,"keypress":_vm.hideDatePicker,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"input-append",fn:function(){return [_c('div',{staticClass:"input-group-text cursor-pointer bg-white",on:{"click":function($event){$event.stopPropagation();return _vm.toggleDatePicker.apply(null, arguments)}}},[_c('div',[_c('i',{staticClass:"fd-calendar"})])])]},proxy:true}],null,false,4011094594),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),(_vm.isShown)?_c('DatePicker',{class:[
            {
                'is-invalid': _vm.validation && _vm.validation.$error,
            },
            'optional-date-picker__datepicker',
            'shadow-lg',
            'bg-white',
            'position-absolute',
        ],attrs:{"value":_vm.dateValue,"config":_vm.options},on:{"input":_vm.dateUpdate}}):_vm._e()],1):_c('span',[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.value === 0 ? '-' : _vm.value)+" ")]},{"value":_vm.value})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }