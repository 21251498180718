<template>
    <BaseSelect
        :id="`ingredient-production-unit_${id}`"
        v-model="selectedValue"
        :class="{ 'unit-field': !disabled }"
        :validation="validation"
        :custom-label="(unit) => $t(shortHand ? unit.short_name : unit.name)"
        :options="productionUnits"
        :placeholder="$t('unit')"
        label="name"
        :is-editable="!disabled"
        :value="selectedValue || ''"
        @input="update"
    >
        {{ selectedValue.short_name ? $t(selectedValue.short_name) : '-' }}
    </BaseSelect>
</template>

<script>
import { KILOGRAM_ID } from '@common/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductionUnits',

    props: {
        value: {
            type: Number,
            default: 0,
        },
        id: {
            type: String,
            default: '',
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        shortHand: Boolean,
        disabled: Boolean,
        noOverride: Boolean,
    },

    computed: {
        ...mapGetters({
            productionUnits: 'getCompanyProductionUnitsWithoutPieces',
            metricUnits: 'global/allMeasurements',
        }),
        selectedValue: {
            get() {
                const allUnits = [].concat.apply([], this.metricUnits);
                return allUnits.find((i) => i.id === +this.value);
            },
            set(value) {
                this.update(value);
            },
        },
    },

    mounted() {
        if (!this.value && this.value !== 0 && !this.noOverride) {
            // Override or show default value
            this.$emit('input', KILOGRAM_ID);
        }
    },

    methods: {
        update(value) {
            if (value) {
                this.$emit('input', value.id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.unit-field {
    min-width: 80px;
}
</style>
