<template>
    <div class="card border shadow">
        <div class="card-body">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-3">
                        <img
                            src="/assets/img/release_icon.png"
                            alt="Release icon"
                        />
                    </div>
                    <div class="col-9 font-weight-bold">
                        {{ $t('new_release_message') }}
                    </div>
                </div>
                <div class="text-center">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="reloadPage"
                    >
                        {{ $t('Refresh') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewReleaseBody',

    methods: {
        reloadPage() {
            location.reload(true);
        },
    },
};
</script>
