import http from '@http';
import { download } from '@/common/services/helper.service';

class DevicesService {
    getDevices(placeId) {
        return http.get(`/api/place/${placeId}/food-devices`).then((res) => {
            return Array.isArray(res.data) ? res.data : [];
        });
    }

    addDevice(placeId, data) {
        return http.post(`/api/place/${placeId}/food-devices`, data).then((res) => {
            return res.data;
        });
    }

    updateDevice(id, data) {
        return http.put(`/api/food-devices/${id}`, data).then((res) => {
            return res.data;
        });
    }

    deleteDevice(id) {
        return http.delete(`/api/food-devices/${id}`).then((res) => {
            return res.data;
        });
    }

    getAllTypes() {
        return http.get('/api/classifiers/device-types').then((res) => res.data);
    }

    importDevices(id, data) {
        return http.post(`/api/devices/${id}/import`, { file_xlsx: data }).then((res) => {
            return res.data;
        });
    }

    removeAttachment(placeId, id) {
        return http.delete(`/api/place/${placeId}/food-devices/attachment/${id}/delete`).then((res) => {
            return res.data;
        });
    }

    downloadAttachment(placeId, file) {
        if (file.base64) {
            download(file.filename, file.base64, file.type);
        } else {
            const url = `/api/place/${placeId}/food-devices/attachment/${file.id}/download`;
            http.get(url, { responseType: 'blob' }).then((res) => {
                if (res) {
                    download(file.filename, res.data, file.type);
                }
            });
        }
    }
}

export const devicesService = new DevicesService();
