<template>
    <div class="row">
        <div class="offset-md-5 col-md-7 text-center">
            <h2>{{ $t('Change Id code') }}</h2>
        </div>
        <form
            class="col-12"
            @submit.prevent="change"
        >
            <div class="form-group row">
                <label
                    :for="'idCode'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('Id code') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="idCode"
                        v-model="idCode"
                    />
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-md-5 col-md-7">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { CHANGE_NAME } from '@store/profile/constants';

export default {
    name: 'ChangeIdCode',

    data() {
        return {
            idCode: '',
        };
    },

    validations: {
        firstName: {
            required,
        },
        lastName: {
            required,
        },
    },

    computed: {
        ...mapGetters(['userProfile']),
    },

    watch: {
        userProfile: function (profile) {
            this.setIdCode(profile);
        },
    },

    created() {
        this.setIdCode(this.userProfile);
    },

    methods: {
        change() {
            if (this.idCode !== '' && this.idCode != null) {
                const data = {
                    id_code: this.idCode,
                };
                this.$store.dispatch(CHANGE_NAME, data).then(() => {
                    this.$toastr.s(this.$t('Id code changed successfully!'), this.$t('success') + '!');
                });
            }
        },
        setIdCode(profile) {
            this.idCode = profile.id_code;
        },
    },
};
</script>
