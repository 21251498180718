import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import http from '@http';
import axios from 'axios';
import moment, { now } from 'moment';
import { arrayToMap, download, getFormattedFileName, mapToArray, objToQuery } from '@services/helper.service';
import statistic from './statistic';
import { DateType } from '@type/misc';
import { DisplayableEntry, Entry, RelatedUnit, Subtask, Task } from './types';
import { Factory } from './factory';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { vm } from '@/main';
import cloneDeep from 'lodash.clonedeep';
import xlsx from 'json-as-xlsx';
import { DateValue } from '@common/types';
import { DEFAULT_PAGE_STATE } from '@common/constants';

type EntryForRequest = { entry: DisplayableEntry; taskSubtypeId: number; taskId: number };
// TODO: FD-5566 should use DateValue type
type EntriesQuery = { toDate: moment.Moment; unitId: number | string };
type PageState = { perPage: number; totalItems: number; page: number };

const DUE = 'due';
const OVERDUE = 'overdue';

interface ActivityLogState {
    isVerificationNeeded: boolean;
    isVerificationSet: boolean;
    tasks: { [key: string]: Task };
    subtasks: { [key: string]: Subtask[] };
    dates: any[];
    currentEntries: { [key: string]: DisplayableEntry };
    tickets: any[];
    entriesState: {
        page: {
            perPage: number;
            totalItems: number;
            page: number;
        };
        diapason: {
            from: string;
            to: string;
        };
        searchQuery: string;
        filter: string;
    };
    currentState: {
        tableData: {
            headers: [];
            data: [];
        };
    };
    loader: number;
    CSVEntries: [];
    XLSXEntries: [];
}

function state(): ActivityLogState {
    return {
        isVerificationNeeded: false,
        isVerificationSet: false,
        tasks: {},
        subtasks: {}, // contain tasks map with group id as a key
        dates: [],
        tickets: [],
        currentEntries: {},
        entriesState: {
            page: DEFAULT_PAGE_STATE,
            diapason: {
                from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
            },
            searchQuery: '',
            filter: 'all',
        },
        currentState: {
            tableData: {
                headers: [],
                data: [],
            },
        },
        loader: 0,
        CSVEntries: [],
        XLSXEntries: [],
    };
}

const getters: GetterTree<ActivityLogState, any> = {
    isVerificationNeeded: (state) => state.isVerificationNeeded,
    isVerificationSet: (state) => state.isVerificationSet,
    sideMenuItems: (state, getters, rootState, rootGetters) => {
        const role = rootGetters?.selectedCompany?.company?.role || rootGetters.selectedPlace?.role;
        const isOwner = role === 'owner';
        const isMonitoring = role === 'readonly,temp-measurements';

        const routes = [
            {
                title: 'MONITORING',
                type: 'contentSeparator',
                noOrderNumber: true,
            },
            {
                title: 'Fill entries',
                to: {
                    name: 'activity-log.timeline',
                },
            },
            ...(isMonitoring || isOwner
                ? [
                      {
                          title: 'Verify entries',
                          to: {
                              name: 'activity-log.verification.list',
                          },
                          hasAttentionMarker: getters.isVerificationNeeded,
                      },
                  ]
                : []),
            {
                title: 'Entry history',
                to: {
                    name: 'activity-log.entries',
                },
            },
        ];

        if (isOwner) {
            routes.push(
                ...[
                    {
                        title: 'SETTINGS',
                        type: 'contentSeparator',
                        noOrderNumber: true,
                    },
                    {
                        title: 'Equipment',
                        to: {
                            name: 'activity-log.equipments',
                        },
                    },
                    {
                        title: 'Rooms',
                        to: {
                            name: 'activity-log.rooms',
                        },
                    },
                    {
                        title: 'Monitoring tasks',
                        to: {
                            name: 'activity-log.tasks',
                        },
                    },
                    {
                        title: 'Pause monitoring',
                        to: {
                            name: 'activity-log.pause-monitoring',
                        },
                    },
                ]
            );
        }

        return routes;
    },
    isTasksFilled: (state, getters) => {
        return !getters.tasks.some((task: Task) => [OVERDUE, DUE].includes(task.status));
    },
    tasks: (state) => {
        return Object.keys(state.tasks)
            .map((id) => state.tasks[id])
            .sort((a, b) => a.orderNumber - b.orderNumber);
    },
    getTask: (state) => (id) => state.tasks[id],
    getTickets: (state) => state.tickets,
    dates: (state) => state.dates || [],
    getSubtasks: (state) => (id) => state.subtasks[id],
    entries: (state) => {
        return Object.keys(state.currentEntries).map((key) => state.currentEntries[key]);
    },
    getEntry: (state) => (id) => state.currentEntries[id],
    entriesMap: (state) => {
        // TODO: delete this hot fix should be `state.currentEntries` only
        return { ...state.currentEntries };
    },
    entriesPageState: (state) => state.entriesState.page,
    entriesDiapasonState: (state) => state.entriesState.diapason,
    entriesSearchState: (state): string => state.entriesState.searchQuery || '',
    entriesFilterState: (state) => state.entriesState.filter,
    // TODO: Optimisation is needed
    subtasksToCreate: (state, getters) => {
        const res: any = {
            subtasks: {},
            unitsPerSubtask: {},
        };
        getters.entries
            ?.filter((i) => i?.id)
            ?.forEach((i) => {
                if (i?.subtask) {
                    res.subtasks[i.subtask.id] = i.subtask;

                    if (!res.unitsPerSubtask[i.subtask.id]) {
                        res.unitsPerSubtask[i.subtask.id] = {};
                    }
                    if (i.subtask.task.relatedTo !== 'none') {
                        res.unitsPerSubtask[i.subtask.id][i.subtask.relatedUnit.id] = i.subtask.relatedUnit;
                    }
                }
            });

        Object.keys(res.unitsPerSubtask).forEach((key) => {
            res.unitsPerSubtask[key] = mapToArray(res.unitsPerSubtask[key]);
        });

        return {
            subtasks: mapToArray(res.subtasks),
            unitsPerSubtask: res.unitsPerSubtask,
        };
    },
    cannotAddEntry: (state, getters) => {
        return !getters.subtasksToCreate.subtasks.length;
    },
    loader: (state, getters, rootState, rootGetters) => state.loader > 0 && !rootGetters.isViewLoading,
    tableData: (state) => state.currentState.tableData,
    CSVEntries: (state) => state.CSVEntries,
};

const mutations: MutationTree<ActivityLogState> = {
    setIsVerificationSet(state, bool) {
        state.isVerificationSet = bool;
    },
    setIsVerificationNeeded(state, bool) {
        state.isVerificationNeeded = bool;
    },
    setGroup(state, payload) {
        Vue.set(state.tasks, payload.id, payload);
    },
    setSubtasks(state, payload) {
        Vue.set(state.subtasks, payload.id, payload.subtasks);
    },
    setDates(state, payload) {
        Vue.set(state, 'dates', payload);
    },
    setCurrentEntries(state, payload: DisplayableEntry[]) {
        state.currentEntries = <{ [key: string]: DisplayableEntry }>arrayToMap(payload, 'uuid');
    },
    setEntriesPageState(state, payload: PageState) {
        Vue.set(state.entriesState, 'page', payload);
    },
    setEntriesFilterState(state, filter: string) {
        Vue.set(state.entriesState, 'filter', filter);
    },
    setEntriesSearchState(state, value: string) {
        Vue.set(state.entriesState, 'searchQuery', value);
    },
    setEntriesDiapasonState(state, value) {
        Vue.set(state.entriesState, 'diapason', value);
    },
    setTickets(state, value: Array<Object>) {
        Vue.set(state.tickets, 'tickets', value);
    },
    setEntry(state, payload: DisplayableEntry) {
        Vue.set(state.currentEntries, payload.uuid, cloneDeep(payload));
    },
    deleteEntry(state, payload) {
        Vue.delete(state.currentEntries, payload.uuid);
    },
    setEntryWithCreationState(state, payload: DisplayableEntry) {
        const val = cloneDeep(payload);
        // @ts-ignore
        val.creation = true;

        Vue.set(state.currentEntries, val.uuid, val);
    },
    setEntryForUpdate(state, payload: DisplayableEntry) {
        const val = cloneDeep(payload);
        // @ts-ignore
        val.creation = true;
        // @ts-ignore
        val.needUpdate = true;

        Vue.set(state.currentEntries, val.uuid, val);
    },
    resetCurrentEntries(state) {
        Vue.set(state, 'currentEntries', {});
    },
    reset(storeState) {
        const s = state();
        Vue.set(storeState, 'tasks', s['tasks']);
        Vue.set(storeState, 'subtasks', s['subtasks']);
        Vue.set(storeState, 'dates', s['dates']);
    },
    setTableData(state, payload: { headers: any; data: any }) {
        state.currentState.tableData = {
            headers: payload.headers,
            data: payload.data,
        };
    },
    setLoader(state, payload) {
        state.loader += payload ? 1 : -1;
    },
    setCSVEntries(state, payload) {
        state.CSVEntries = payload.data.data;
    },
    setXLSXEntries(state, payload) {
        state.XLSXEntries = payload.data.data;
    },
};

function mapTask(taskName: string, state, taskId, currentEntry: any, fieldNames: Set<string>, verification: boolean) {
    taskName = state.tasks[taskId].name;

    const dateTranslation = vm.$t('kuupaev');
    const measured = vm.$t('measured');
    const notes = vm.$t('notes');
    const updateDateTranslation = vm.$t('updated_date');
    const updateTimeTranslation = vm.$t('updated_time');
    const registered_by = vm.$t('updatedByTranslation');
    const relatedUnitTranslation = vm.$t('Related unit');
    const statusTranslation = vm.$t('status');
    const description = vm.$t('description');
    const verification_date = vm.$t('verification date');
    const verification_time = vm.$t('verification time');
    const verified_by = vm.$t('verified by');
    const verification_comment = vm.$t('verification comment');

    const date = currentEntry.entry_date;
    const updatedDate = moment(currentEntry.updated_at).format('YYYY-MM-DD');
    const time =
        currentEntry.filled_by_sensor && moment(currentEntry.updated_at)?.isValid()
            ? moment(currentEntry.entry_time).format('HH:mm')
            : moment(currentEntry.updated_at).format('HH:mm') || '';

    let correctiveActionsString = '';
    if (currentEntry.action_options) {
        currentEntry.action_options.forEach((action) => {
            correctiveActionsString = correctiveActionsString + action.option.option_text + '; ';
        });
    }

    let thisEntryRow = {
        [dateTranslation]: date,
        [measured]: state.tasks[taskId].name,
    };

    const relatedUnit = state.tasks[taskId].relatedUnits.find((unit) => unit.id === currentEntry.related_entity_id);
    if (relatedUnit && relatedUnit.name !== state.tasks[taskId].name) {
        fieldNames.add(relatedUnitTranslation);
        thisEntryRow = {
            ...thisEntryRow,
            [relatedUnitTranslation]: relatedUnit?.name,
        };
    }

    // maps into => 'Temp': 4.7, 'Product': 'Cheese' etc
    let measuredFields: any = [];
    currentEntry.entry_fields.map((entry) => {
        let entryValue = '';
        if (entry.type === 'ticket') {
            // todo after FD-6414 is merged
            return;
        } else if (entry.type === 'choose_answer' && entry.value) {
            entry?.value?.forEach((answer) => {
                entryValue = entryValue + answer.text + '; ';
            });
        } else if ((entry.type === 'enter_text' || entry.type === 'enter_date') && entry.value) {
            entryValue = entry.value;
        } else if (entry.type === 'choose_product' && entry.value) {
            if (typeof entry?.value?.name === 'string') {
                entryValue = entry?.value.name;
            } else {
                const existsInLanguages = Object.keys(entry?.value?.name);
                // user lang is default
                entryValue = entry?.value?.name[vm.$i18n.locale] || entry?.value?.name[existsInLanguages[0]] || '';
            }
        } else {
            entryValue = entry?.value?.name || entry.value?.text || entry.value?.temp || entry.value?.amount || '-';
        }

        if (entry.name) {
            fieldNames.add(entry.name);
            measuredFields = {
                ...measuredFields,
                [entry.name]: entryValue,
            };
        }

        if (entry?.value?.unit) {
            const unitTranslation = entry.name + ' (' + vm.$t('unit') + ')';
            fieldNames.add(unitTranslation);
            measuredFields = {
                ...measuredFields,
                [unitTranslation]: vm.$t(entry.value.unit.name) || vm.$t(entry.value.unit),
            };
        }
    });

    if (currentEntry.task_desc) {
        fieldNames.add(description);
        measuredFields = {
            ...measuredFields,
            [description]: currentEntry.task_desc,
        };
    }

    if (currentEntry.entry_fields.length === 0) {
        fieldNames.add(statusTranslation);
        measuredFields[statusTranslation] = vm.$t(currentEntry.status);
        measuredFields = {
            ...measuredFields,
            [statusTranslation]: vm.$t(currentEntry.status),
        };
    }

    thisEntryRow = {
        ...thisEntryRow,
        ...measuredFields,
        [notes]: correctiveActionsString || currentEntry.comment,
        [updateDateTranslation]: updatedDate,
        [updateTimeTranslation]: time,
        [registered_by]: currentEntry.updated_by_user?.username,
    };

    if (verification) {
        const verificationTime = currentEntry.verification?.verified_at
            ? new DateValue(currentEntry.verification?.verified_at).timeFormat
            : '';
        const verificationDate = currentEntry.verification?.verified_at
            ? new DateValue(currentEntry.verification?.verified_at).requestDateFormat
            : '';
        thisEntryRow = {
            ...thisEntryRow,
            [verification_date]: verificationDate,
            [verification_time]: verificationTime,
            [verified_by]: currentEntry.verification?.verified_by?.fullname,
            [verification_comment]: currentEntry.verification?.comment,
        };
    }
    return { taskName, thisEntryRow };
}

function translateStrings() {
    const name = vm.$t('name');
    const notes = vm.$t('notes');
    const dateTranslation = vm.$t('kuupaev');
    const timeTranslation = vm.$t('time');
    const updateDateTranslation = vm.$t('updated_date');
    const updateTimeTranslation = vm.$t('updated_time');
    const registered_by = vm.$t('registered_by');
    const updatedByTranslation = vm.$t('updatedByTranslation');
    const statusTranslation = vm.$t('status');
    return {
        name,
        notes,
        dateTranslation,
        timeTranslation,
        updateDateTranslation,
        updateTimeTranslation,
        registered_by,
        updatedByTranslation,
        statusTranslation,
    };
}

function setEntryData(
    statusTranslation: string,
    ticket: any,
    name: string,
    notes: string,
    dateTranslation: string,
    timeTranslation: string,
    registered_by: string,
    updateDateTranslation: string,
    updateTimeTranslation: string,
    updatedByTranslation: string
) {
    return {
        [statusTranslation]: vm.$t(ticket.entry_status),
        [name]: ticket.description,
        [notes]: ticket.entry_comment,
        [dateTranslation]: moment(ticket.created_at).format('YYYY-MM-DD'),
        [timeTranslation]: moment(ticket.created_at).format('HH:mm'),
        [registered_by]: ticket.created_by?.username,
        [updateDateTranslation]: moment(ticket.entry_time).format('YYYY-MM-DD'),
        [updateTimeTranslation]: moment(ticket.entry_time).format('HH:mm'),
        [updatedByTranslation]: ticket.entry_created_by?.username,
    };
}

const actions: ActionTree<ActivityLogState, any> = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
            commit('resetCurrentEntries');
            commit('setIsVerificationNeeded', false);
            commit('setIsVerificationSet', false);
        },
    },
    /**
     * Returns tasks that are scheduled and applicable for a date. Defaults on today.
     * Accepts queryparam date in format yyyy-mm-dd
     */
    async getAllScheduledTasks({ getters, rootGetters, commit }, payload: { date?: DateType }): Promise<Task[]> {
        try {
            commit('reset');
            const query = payload.date ? `?date=${payload.date}` : '';
            const response = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/scheduled-task-subtypes${query}`
            );
            response.data?.forEach((item) => commit('setGroup', Task.fromJSON(item)));
            const ticketResponse = await http.get(`/api/places/${rootGetters.selectedPlaceId}/tickets/status${query}`);
            if (Object.keys(ticketResponse.data).length !== 0) {
                commit('setGroup', Task.fromTicketStatus(ticketResponse.data, []));
            }

            return getters.tasks || [];
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);

            return [];
        }
    },

    async getTask(
        { rootGetters, commit, getters },
        { taskSubtypeId, includeEntries = false, query, findFromStore = false }
    ): Promise<Task> {
        if (findFromStore) {
            const task = getters.getTask(taskSubtypeId);

            if (task) {
                return task;
            }
        }
        const dateString = query?.toDate
            ? `?date=${query?.toDate}&fromDate=${query?.toDate}&toDate=${query?.toDate}`
            : '';

        const { data } = await http.get(
            `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${taskSubtypeId}${dateString}`,
            {
                params: {
                    includeEntries,
                },
            }
        );

        const task = Task.fromJSON(data);
        commit('setGroup', task);

        return task;
    },

    /**
     * Returns all active tasks of the place regardless of scheduling
     */
    async getAllTasks({ getters, rootGetters, commit }) {
        try {
            if (!getters.tasks.length) {
                const response = await http.get(
                    `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes`
                );

                response.data?.forEach((item) => commit('setGroup', Task.fromJSON(item)));
                const end = moment().locale('en').format('YYYY-MM-DD');

                const ticketResponse = await axios.get(`/api/places/${rootGetters.selectedPlaceId}/tickets/status`, {
                    params: { date: end },
                });

                commit('setGroup', Task.fromTicketStatus(ticketResponse.data, []));
            }

            return getters.tasks || [];
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async getIfVerificationIsSet({ rootGetters, commit }) {
        try {
            const response = await http.get(`api/place/${rootGetters.selectedPlaceId}/tasks/has-verification-tasks`);

            commit('setIsVerificationSet', response.data.has_verification_tasks);

            return response.data.has_verification_tasks;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async getAllUnverifiedTasks({ getters, rootGetters, commit }) {
        if (!getters.isVerificationSet) {
            return [];
        }

        try {
            const response = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/verifications/task-subtypes`
            );

            const result = response.data?.data || [];

            commit('setIsVerificationNeeded', result.length > 0);

            return result;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async getTickets({ rootGetters }, payload: { date?: Date }) {
        try {
            const date = moment(payload?.date).locale('en').format('YYYY-MM-DD');
            const { data } = await axios.get(`/api/places/${rootGetters.selectedPlaceId}/tickets`, {
                params: { date },
            });
            return data;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },
    async getTicketsBetweenDates(
        { getters, rootGetters, commit },
        payload: { from: any; to: any; perPage: number; page: number }
    ) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/tickets/entries`, {
                params: {
                    fromDate: moment(payload?.from).locale('en').format('YYYY-MM-DD'),
                    toDate: moment(payload?.to).locale('en').format('YYYY-MM-DD'),
                    page: payload.page || getters.entriesPageState.page,
                    perPage: payload.perPage || getters.entriesPageState.perPage,
                },
            });

            if (!payload.page && !payload.perPage) {
                commit('setEntriesPageState', {
                    perPage: data?.meta?.per_page,
                    totalItems: data?.meta?.total,
                    page: data?.meta?.current_page,
                });
            }

            return data?.data;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async createTicketEntry({ rootGetters }, payload: { ticketId: Number; comment: String; entry_status: String }) {
        try {
            const { data } = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/tickets/${payload.ticketId}/entry`,
                {
                    entry_comment: payload.comment,
                    entry_status: payload.entry_status,
                }
            );
            return data;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async updateTickets(
        { rootGetters },
        payload: { ticketId: Number; comment: String; entry_status: String; file_list?: Array<Object> }
    ) {
        try {
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/tickets/${payload.ticketId}/entry`,
                {
                    entry_comment: payload.comment,
                    entry_status: payload.entry_status,
                    file_list: payload.file_list,
                }
            );
            return data;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    async getTicketSubtasks({ commit, getters, rootGetters }, payload: { ticket: Task; date?: Date }) {
        try {
            const date = moment(payload?.date).locale('en').format('YYYY-MM-DD');

            const { data } = await axios.get(`/api/places/${rootGetters.selectedPlaceId}/tickets`, { params: date });

            const task = payload.ticket;
            let subtasks: Subtask[] = [];

            if (task.relatedUnits?.length) {
                subtasks = data.map((i: any) => {
                    if (task.isForm) {
                        i.task_desc = task.name;
                    }
                    const subtask = Subtask.fromJSON(i, task);

                    return subtask;
                });
            }

            commit('setSubtasks', {
                id: null,
                subtasks,
            });
            return getters.getSubtasks(null) || [];
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    /**
     * Returns all entries for a group for a specific date. Defaults on today.
     * Accepts queryparam date in format yyyy-mm-dd
     */
    async getSubtasks({ commit, getters, rootGetters }, payload: { id: number; date?: Date; withEntries?: boolean }) {
        try {
            let query = payload.withEntries ? '' : '?includeEntries=false';
            if (payload?.date) {
                // TODO: FD-5566 use DataValue type
                query = `?date=${moment(payload?.date).locale('en').format('YYYY-MM-DD')}`;

                if (!payload.withEntries) {
                    query += '&includeEntries=false';
                }
            }
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload.id}${query}`
            );

            const task = Task.fromJSON(data);
            let subtasks: Subtask[] = [];
            if (task.relatedUnits?.length) {
                task.relatedUnits.forEach((unit: RelatedUnit) => {
                    subtasks.push(
                        ...data.tasks
                            .filter((i: any) => i.related_entity_id === unit.id)
                            .map((i: any) => {
                                const subtask = Subtask.fromJSON(i, task, unit);

                                return subtask;
                            })
                    );
                });
            } else {
                subtasks = data.tasks.map((i: any) => {
                    // In case of form type taks has only one subtask with no name
                    if (task.isForm) {
                        i.task_desc = task.name;
                    }
                    const subtask = Subtask.fromJSON(i, task);

                    return subtask;
                });
            }

            commit('setSubtasks', {
                id: payload.id,
                subtasks,
            });
            return getters.getSubtasks(payload.id) || [];
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },
    async getTicketEntries(
        { dispatch, commit },
        { ticket, isTimeline, query }: { ticket: Task; isTimeline: boolean; query: EntriesQuery }
    ) {
        let res: DisplayableEntry[] = [];

        if (isTimeline) {
            const items = await dispatch('getTicketSubtasks', { ticket, date: query?.toDate });

            res = items.map((item: any) => {
                const entry = Entry.fromJSON(item);
                const entrySubtask = items.find((subtask: Subtask) => {
                    // Assign unset properties to entry fields
                    // For ex. API not return `options` for select field type
                    entry.fields = subtask.prototype.fields.map((item) => ({
                        ...item,
                        ...entry.fields?.find((field) => field.stepId === item.id),
                    }));
                    entry.ticket = subtask?.entries?.find((e) => e.id === entry.id)?.ticket;

                    return (
                        subtask.id === item.task.id &&
                        (subtask.task.relatedTo === 'none' || subtask.relatedUnitId === entry.relatedEntityId)
                    );
                });
                return new DisplayableEntry(entry, entrySubtask);
            });
        }

        commit('setCurrentEntries', res);

        return res;
    },

    async getEntries(
        { dispatch, commit, rootGetters, getters },
        { taskId, isTimeline, query }: { taskId: number; isTimeline: boolean; query: EntriesQuery }
    ) {
        let res: DisplayableEntry[] = [];

        if (isTimeline) {
            const items = await dispatch('getSubtasks', { id: taskId, date: query?.toDate, withEntries: true });
            items.forEach((subtask: Subtask) => {
                subtask.entries.sort((a, b) => {
                    return a.updatedAt?.isBefore(b.updatedAt) ? -1 : 1;
                });
                res.push(
                    ...subtask.entries.reduce((acc: DisplayableEntry[], item: Entry) => {
                        acc.push(new DisplayableEntry(item, subtask));

                        return acc;
                    }, [])
                );
            });
        } else {
            const queryParams: any = {};

            if (query?.toDate) {
                // TODO: FD-5566 use DataValue type
                queryParams['toDate'] = moment(query.toDate).locale('en').format('YYYY-MM-DD');
            } else {
                if (getters.entriesDiapasonState.from) {
                    // TODO: FD-5566 use DataValue type
                    queryParams['fromDate'] = moment(getters.entriesDiapasonState.from)
                        .locale('en')
                        .format('YYYY-MM-DD');
                }
                if (getters.entriesDiapasonState.to) {
                    // TODO: FD-5566 use DataValue type
                    queryParams['toDate'] = moment(getters.entriesDiapasonState.to).locale('en').format('YYYY-MM-DD');
                }
            }

            // unitId is 'none' when relatedTo is 'none'
            if (query?.unitId && query?.unitId !== 'none') {
                queryParams.entityId = query.unitId;
            }

            if (getters.entriesSearchState.trim()) {
                queryParams.searchText = getters.entriesSearchState.trim();
            }

            if (getters.entriesFilterState === 'incorrect') {
                queryParams.status = 'not_done';
            } else if (getters.entriesFilterState === 'correct') {
                queryParams.status = 'done';
            }

            queryParams.page = getters.entriesPageState.page;
            queryParams.perPage = getters.entriesPageState.perPage;

            const queryStr = objToQuery(queryParams);
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${taskId}/entries?${queryStr}`
            );

            commit('setEntriesPageState', {
                ...getters.entriesPageState,
                totalItems: data.total,
            });

            const subtasks = await dispatch('getSubtasks', { id: taskId, date: query?.toDate, withEntries: false });

            // TODO FD-7572
            res = data.data.map((item: any) => {
                const entry = Entry.fromJSON(item);
                const entrySubtask = subtasks.find((subtask: Subtask) => {
                    // Assign unset properties to entry fields
                    // For ex. API not return `options` for select field type
                    entry.fields = subtask.prototype.fields.map((i) => ({
                        ...i,
                        ...entry.fields?.find((field) => field.stepId === i.id),
                    }));
                    entry.ticket = subtask?.entries?.find((e) => e.id === entry.id)?.ticket;

                    if (subtask.task.relatedTo === 'none') {
                        return subtask.id === item.task_id;
                    } else {
                        return subtask.id === item.task_id && subtask.relatedUnitId === entry.relatedEntityId;
                    }
                });
                return new DisplayableEntry(entry, entrySubtask);
            });
        }

        commit('setCurrentEntries', res);

        return res;
    },

    async getUnverifiedEntries({ dispatch, commit, rootGetters, getters }, payload: { taskId: number }) {
        let res: DisplayableEntry[] = [];
        const { data } = await http.get(
            `/api/places/${rootGetters.selectedPlaceId}/activity-log/verifications/task-subtypes/${payload.taskId}/entries`
        );
        commit('setEntriesPageState', {
            ...getters.entriesPageState,
            totalItems: data.total,
        });
        const subtasks = await dispatch('getSubtasks', { id: payload.taskId, withEntries: true });
        res = data.data.map((item: any) => {
            const entry = Entry.fromJSON(item);
            return new DisplayableEntry(entry, subtasks);
        });
        commit('setCurrentEntries', res);
        return res;
    },

    async getEntry({ dispatch, rootGetters }, payload: EntryForRequest): Promise<Entry | undefined> {
        const { data } = await http.get(
            `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-entries/${payload.entry.id}`
        );
        return DisplayableEntry.fromJSON(data);
    },

    async getTaskVersions({ commit, rootGetters, state }, payload) {
        commit(START_VIEW_LOADER, null, { root: true });
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload.id}/versions?fromDate=${payload.dates.from}&toDate=${payload.dates.to}`,
                payload
            );
            return data;
        } catch (error: any) {
            console.error(error);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },

    async getUnverifiedTaskVersions({ commit, rootGetters, state }, payload) {
        commit(START_VIEW_LOADER, null, { root: true });
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/verifications/task-subtypes/${payload.id}/versions`,
                payload
            );
            return data.data;
        } catch (error: any) {
            console.error(error);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },

    async verifyTasks({ commit, rootGetters, state, dispatch }, payload) {
        try {
            const response = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/verifications/task-subtypes/${payload.taskId}`,
                payload
            );

            dispatch('getAllUnverifiedTasks');

            return response.data;
        } catch (e: any) {
            vm.$toastr.e(vm.$t(e?.response?.data?.message || 'error'));
            console.error(e);
        }
    },

    /**
     * Creates new entry under a task in backend and returns the entry with id
     */
    async createEntryRequest({ rootGetters }, payload: EntryForRequest): Promise<Entry | undefined> {
        try {
            const response = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload.taskSubtypeId}/tasks/${payload.taskId}/entries`,
                payload.entry
            );

            this.dispatch('activityLog/statistic/init', {});
            return Entry.fromJSON(response.data);
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    /**
     * Same endpoint for update and create
     */
    async createOrUpdateEntries({ rootGetters }, payload: any) {
        try {
            const response = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload[0].taskSubtypeId}/entries`,
                { entries: payload }
            );
            this.dispatch('activityLog/statistic/init', {});
            return response.data;
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },

    /**
     * Update an existing entry by id
     * taskSubtypeId
     * taskId - either tasks[n] for checklist or tasks[0] for form
     */
    async updateEntryRequest({ rootGetters }, payload: EntryForRequest) {
        try {
            const response = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload.taskSubtypeId}/tasks/${payload.taskId}/entries/${payload.entry.id}`,
                payload.entry
            );

            this.dispatch('activityLog/statistic/init', {});
            return Entry.fromJSON(response.data);
        } catch (error: any) {
            vm?.$toastr && vm.$toastr.e(vm.$t(error?.message));
            console.error(error);
        }
    },
    async setEntry({ dispatch, commit, state }, payload: EntryForRequest) {
        if (payload.entry && payload.entry.id) {
            try {
                const updateRes: Entry = await dispatch('updateEntryRequest', payload);

                payload.entry.id = updateRes.id;
                payload.entry.status = updateRes?.status;
                payload.entry.updatedAt = moment(now());
                payload.entry.sensorTime = updateRes?.sensorTime;
                payload.entry.updatedBy = {};
                payload.entry.updatedBy.firstname = updateRes?.updatedBy?.firstname;
                payload.entry.updatedBy.lastname = updateRes?.updatedBy?.lastname;
                payload.entry.updatedBy.username = updateRes?.updatedBy?.username;

                commit('setEntry', payload.entry);
            } catch (e: any) {
                console.error(e);
                // @ts-ignore
                Vue.rollbar.error(e);
            }
        } else {
            // @ts-ignore
            if (state.currentEntries[payload.entry?.uuid]?.creation) {
                commit('setEntryForUpdate', payload.entry);
            } else {
                commit('setEntryWithCreationState', payload.entry);
                try {
                    const createRes: Entry = await dispatch('createEntryRequest', payload);

                    payload.entry.id = createRes.id;

                    // @ts-ignore
                    if (state.currentEntries[payload.entry.uuid].needUpdate) {
                        if (payload.entry.id !== undefined && payload.entry.id !== null) {
                            payload.entry.id = createRes.id;
                        }

                        const updateRes: Entry = await dispatch('updateEntryRequest', payload);
                        payload.entry.id = updateRes.id;
                        payload.entry.status = updateRes?.status;
                        payload.entry.updatedAt = moment(now());
                        payload.entry.sensorTime = updateRes?.sensorTime;
                        payload.entry.updatedBy = {};
                        payload.entry.updatedBy.firstname = updateRes?.updatedBy?.firstname;
                        payload.entry.updatedBy.lastname = updateRes?.updatedBy?.lastname;
                        payload.entry.updatedBy.username = updateRes?.updatedBy?.username;

                        commit('setEntry', payload.entry);
                    } else {
                        payload.entry.id = createRes.id;
                        payload.entry.status = createRes.status;
                        payload.entry.updatedAt = moment(now());
                        payload.entry.sensorTime = createRes?.sensorTime;
                        payload.entry.updatedBy = {};
                        payload.entry.updatedBy.firstname = createRes?.updatedBy?.firstname;
                        payload.entry.updatedBy.lastname = createRes?.updatedBy?.lastname;
                        payload.entry.updatedBy.username = createRes?.updatedBy?.username;

                        commit('setEntry', payload.entry);
                    }
                } catch (e: any) {
                    commit('resetCurrentEntries');
                    console.error(e);
                    // @ts-ignore
                    Vue.rollbar.error(e);
                }
            }
        }
        return payload.entry;
    },
    async createEntry({ dispatch }, payload) {
        const newEntry = new Entry(payload.entry);
        // TODO: FD-5566 use DataValue type
        newEntry.date = payload.date;
        newEntry.relatedEntityId = payload.entry.related_entity_id;

        const item = new DisplayableEntry(newEntry, payload.subtask);
        // @ts-ignore
        item.fresh = true;

        return dispatch('setEntry', {
            entry: item,
            taskSubtypeId: payload.taskSubtypeId,
            taskId: payload.taskId,
        });
    },
    async deleteEntry({ commit, rootGetters, state }, payload) {
        try {
            await http.delete(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${payload.taskSubtypeId}/tasks/${payload.taskId}/entries/${payload.entry.id}`
            );

            this.dispatch('activityLog/statistic/init', {});
            commit('deleteEntry', payload.entry.id);
        } catch (e: any) {
            vm.$toastr.e(vm.$t(e?.response?.data?.message || 'error'));
            console.error(e);
        }
    },
    async deleteTicket({ rootGetters }, uuid) {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/tickets/${uuid}`);
        } catch (e: any) {
            vm.$toastr.e(vm.$t(e?.response?.data?.message || 'error'));
            console.error(e);
        }
    },

    async exportTicketsSheet({ getters, dispatch }, payload: { type?: string }) {
        const taskName = vm.$t('tickets');

        const {
            name,
            notes,
            dateTranslation,
            timeTranslation,
            updateDateTranslation,
            updateTimeTranslation,
            registered_by,
            updatedByTranslation,
            statusTranslation,
        } = translateStrings();

        const requestedDate = getters.entriesDiapasonState;
        const to = moment(requestedDate.to).format('YYYY-MM-DD');
        const from = moment(requestedDate.from).format('YYYY-MM-DD');
        const dateString = from + '_' + to;

        const tickets = await dispatch('getTicketsBetweenDates', {
            from,
            to,
            page: 1,
            perPage: 1000000,
        });

        const fields: any = Object.values(tickets).map((ticket: any) => {
            return setEntryData(
                statusTranslation,
                ticket,
                name,
                notes,
                dateTranslation,
                timeTranslation,
                registered_by,
                updateDateTranslation,
                updateTimeTranslation,
                updatedByTranslation
            );
        });

        const factory = new Factory();

        if (payload?.type === 'xlsx') {
            xlsx(factory.XLSXTicketsStrategy(fields, taskName), {
                fileName: getFormattedFileName(factory.getConfig(taskName, dateString).name()),
            });
        } else {
            const fileName = getFormattedFileName(factory.getConfig(taskName, dateString).name());
            download(`${fileName}.csv`, factory.CSVTicketsStrategy(fields));
        }
    },

    async exportXLSX({ state, dispatch, rootGetters, getters, commit }, { taskId, requestedDate }) {
        const factory = new Factory();

        let from = '';
        let to = '';
        // for file name
        let taskName = '';
        let dateString = '';
        let verificationIsSet = false;

        try {
            const fieldNames = new Set<string>();

            to = moment(requestedDate.to).format('YYYY-MM-DD');
            from = moment(requestedDate.from).format('YYYY-MM-DD');
            dateString = from + '_' + to;

            const perPage = 100000;

            const entriesData: any = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${taskId}/entries?fromDate=${from}&toDate=${to}&perPage=${perPage}`
            );
            commit('setXLSXEntries', entriesData);

            const rows: any = [];
            if (state.XLSXEntries.length !== 0) {
                verificationIsSet = entriesData.data?.data[0].verification !== null;
                Object.values(state.XLSXEntries).map((currentEntry: any) => {
                    const taskSet = mapTask(taskName, state, taskId, currentEntry, fieldNames, verificationIsSet);
                    taskName = taskSet.taskName;
                    const thisEntryRow = taskSet.thisEntryRow;

                    rows.push(thisEntryRow);
                });
            }

            const labels = factory.XLSXFields(fieldNames, verificationIsSet);

            const data = [
                {
                    columns: labels,
                    content: rows,
                },
            ];
            const settings = {
                fileName: taskName + dateString,
            };
            xlsx(data, settings);
        } catch (e: any) {
            console.error(e);
        }
    },

    async exportCSV({ state, dispatch, rootGetters, getters, commit }, { taskId, requestedDate }) {
        const factory = new Factory();
        commit(START_VIEW_LOADER, null, { root: true });

        let from = '';
        let to = '';
        // for CSV file name
        let taskName = '';
        let dateString = '';
        let verificationIsSet = false;

        try {
            const fieldNames = new Set<string>(); // for CSV header
            const fields: any = [];

            to = moment(requestedDate.to).format('YYYY-MM-DD');
            from = moment(requestedDate.from).format('YYYY-MM-DD');

            dateString = from + '_' + to;
            const perPage = 100000;

            const entriesData: any = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/activity-log/task-subtypes/${taskId}/entries?fromDate=${from}&toDate=${to}&perPage=${perPage}`
            );
            commit('setCSVEntries', entriesData);

            if (state.CSVEntries.length !== 0) {
                verificationIsSet = entriesData.data?.data[0].verification !== null;
                Object.values(state.CSVEntries).map((currentEntry: any) => {
                    const taskSet = mapTask(taskName, state, taskId, currentEntry, fieldNames, verificationIsSet);
                    taskName = taskSet.taskName;
                    const thisEntryRow = taskSet.thisEntryRow;

                    fields.push(thisEntryRow);
                });
            }

            const csv = factory.CSVStrategy(fields, fieldNames, verificationIsSet);

            const fileName = getFormattedFileName(factory.getConfig(taskName, dateString).name());

            download(`${fileName}.csv`, csv);
        } catch (e: any) {
            console.error(e);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
};

export default {
    modules: {
        statistic,
    },
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function dateFromJSON(json: any) {
    return {
        // TODO: FD-5566 use DataValue type
        date: moment(json.date, 'YYYY-MM-DD').toDate(),
        lastEntry: {
            time: json.last_entry?.time,
            name: json.last_entry?.name,
        },
        status: json.status,
        taskCount: json.task_count,
        responsible:
            json.task_responsible_roles?.map((role) => ({
                id: role?.id,
                name: role?.name,
            })) || [],
    };
}
