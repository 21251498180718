import authService from '@services/auth.service';
import axios from 'axios';
import { SET_USER_PROFILE } from '@store/profile/constants';
import routes from '@/routes';
import { vm } from '@/main';
import http, { sharedAccessTokenStrategy } from '@http';
import VueCookies from 'vue-cookies';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { LOG_ERROR } from '@store/common/constants';
import { SET_SELECTED_COMPANY } from '@store/company/constants';
import moment from 'moment';

const getters = {
    isAuthorized() {
        return authService.isAuthorized;
    },
    isInvitedUser() {
        return !!window.localStorage.getItem('user-was-invited');
    },
    hasAccessToFullPlanFeatures() {
        let subscription = vm.$store.getters.selectedCompany.company.subscription;

        let haccpOnly = subscription?.haccp_only;
        let haccpRenewable = subscription?.haccp_renewable;
        let haccpFast = subscription?.haccp_fast;

        let validTo = moment(subscription?.renewal_valid_to, 'YYYY-MM-DD HH:mm:ss').toDate();
        let today = new Date();
        let hasSubscriptionDaysLeft = today < validTo;

        return hasSubscriptionDaysLeft && !haccpOnly && !haccpRenewable && !haccpFast;
    },
    hasAccessToHaccpPlanFeatures() {
        let subscription = vm.$store.getters.selectedCompany?.company?.subscription;
        let validTo = moment(subscription?.renewal_valid_to, 'YYYY-MM-DD HH:mm:ss').toDate();
        let today = new Date();
        return today < validTo;
    },
    isMonitoringEditingAllowed() {
        const isCompanyOwner = vm.$store.getters.selectedCompany.company.role === 'owner';
        const isAllowedMonitoringInPlace = ['readonly,temp-measurements', 'owner'].includes(
            vm.$store.getters.selectedPlace.role
        );
        return isCompanyOwner || isAllowedMonitoringInPlace;
    },
};

const actions = {
    async login({ commit }, cred) {
        commit(START_VIEW_LOADER, null, { root: true });

        // TODO: TMP to override
        const res = await authService.login(cred.username, cred.password, cred.rememberMe);

        commit(STOP_VIEW_LOADER, null, { root: true });

        return res;
    },
    logout({ commit }) {
        commit(START_VIEW_LOADER, null, { root: true });
        authService
            .logout()
            .then(() => {
                const newStore = {};
                Object.keys(vm.$store).forEach((key) => {
                    newStore[key] = null;
                });
                vm.$store.replaceState(newStore);

                // TODO: Hot fix for data clearing
                location.reload();
            })
            .finally(() => commit(STOP_VIEW_LOADER, null, { root: true }));
    },
    signup({ commit, dispatch }, { data, showLoading = true }) {
        if (showLoading) {
            commit(START_VIEW_LOADER, null, { root: true });
        }
        return authService
            .register(data)
            .then((res) => {
                if (res.status === 200) {
                    if (typeof window.gtag_report_conversion === 'function') {
                        window.gtag_report_conversion();
                    }

                    if (VueCookies.get('invite_code')) {
                        VueCookies.remove('invite_code');
                    }

                    vm?.$ga?.event({
                        eventCategory: 'Register',
                        eventAction: 'User registered',
                        eventLabel: data.username,
                    });
                }
            })
            .catch((error) => {
                dispatch(LOG_ERROR, `authService.register error: ${error}`, { root: true });
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER, null, { root: true });
            });
    },
    checkUsername(_, username) {
        return authService.checkUsername(username).then((res) => res.exists);
    },
    checkCoupon(_, coupon) {
        return authService.checkCoupon(coupon);
    },
    recoverPassword({ commit }, username) {
        commit(START_VIEW_LOADER, null, { root: true });
        authService
            .requestForgotPassword(username)
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER, null, { root: true });
            });
    },
    async setNewPassword({ commit }, data) {
        commit(START_VIEW_LOADER, null, { root: true });
        await authService.setNewPassword(data).catch((error) => {
            console.error(error);
        });
        const res = await authService.login(data.username, data.new_password);
        commit(STOP_VIEW_LOADER, null, { root: true });
        return res;
    },
    setEmail({ commit, getters }, data) {
        commit(START_VIEW_LOADER, null, { root: true });
        return authService
            .setEmail(data)
            .then((res) => {
                const profile = Object.assign(getters.userProfile, { username: data.email });
                commit(SET_USER_PROFILE, profile, { root: true });
                routes.push({ path: '/overview' });
                return res;
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER, null, { root: true });
            });
    },
    changePassword({ commit }, data) {
        commit(START_VIEW_LOADER, null, { root: true });
        return authService
            .changePassword(data)
            .then((res) => {
                if (res) {
                    routes.push({ name: 'login' });
                }
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                commit(STOP_VIEW_LOADER, null, { root: true });
            });
    },
    setSharedToken({ commit }, token) {
        // it will reset selected previously company and place
        commit(SET_SELECTED_COMPANY, null, { root: true });

        delete axios.defaults.headers.common['Authorization'];
        http.tokenStrategy = sharedAccessTokenStrategy;

        authService.activateSharedLink();

        http.setToken(token);

        commit('shared-access/setSharedAccessState', token, { root: true });
    },
    setUserAsInvited() {
        window.localStorage.setItem('user-was-invited', true);
    },
    unsetUserAsInvited() {
        window.localStorage.removeItem('user-was-invited');
    },
};

export default {
    store: {},
    mutations: {},
    getters,
    actions,
    namespaced: true,
};
