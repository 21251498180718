import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    data: [],
    customIngredients: [],
    ingredients: [],
    productInstructions: [],
};

export default {
    state,
    mutations,
    actions,
    getters,
};
