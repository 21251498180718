// Mutations
export const SET_TYPES = 'SET_DEVICES_TYPES';
export const SET_DEVICES_DATA = 'SET_DEVICES_DATA';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const RESET_DEVICES = 'RESET_DEVICES';
export const PUSH_DEVICE = 'devices/PUSH_DEVICE';
export const INSERT_DEVICE = 'devices/INSERT_DEVICE';
export const UPDATE_DEVICE_IS_DONE = 'UPDATE_DEVICE_IS_DONE';

// Actions
export const INIT_DEVICES_TYPES = 'INIT_DEVICES_TYPES';
export const INIT_DEVICES = 'INIT_DEVICES';
export const ADD_DEVICES = 'ADD_DEVICES';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const IMPORT_DEVICES = 'IMPORT_DEVICES';
export const UPLOAD_ATTACHMENT = 'devices/UPLOAD_ATTACHMENT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const SETUP_FOLDER = 'devices/SETUP_FOLDER';
export const RENAME_FOLDER = 'devices/RENAME_FOLDER';
export const GET_CUSTOM_FOLDER_FILES = 'devices/GET_CUSTOM_FOLDER_FILES';

// Both
export const DELETE_DEVICE = 'DELETE_DEVICE';
