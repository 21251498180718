<template>
    <BaseModal
        ref="modal"
        centered
        hide-header
        hide-footer
        size="lg"
        modal-class="welcome-modal"
        no-close
    >
        <div class="d-flex flex-column align-items-center space-unrelated-out">
            <div
                v-if="questionnaire.heading"
                class="text-center mt-1"
            >
                <h1 v-html="$t(questionnaire.heading)"></h1>
            </div>
            <div class="row mt-md-3">
                <div class="col-12 col-md-6 order-md-1">
                    <div v-if="questionImage">
                        <img
                            class="question-image"
                            :alt="questionImage"
                            :src="questionImage"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 order-md-0">
                    <h3 v-html="$t(questionText)"></h3>
                    <div class="row d-inline-flex justify-content-center mb-1">
                        <div
                            v-for="option in options"
                            :key="option.id"
                            class="col-12"
                        >
                            <BaseRadio
                                :id="`option_${option.id}`"
                                :key="option.id"
                                v-model="selectedOption"
                                :val="option.id"
                                name="options"
                                :label="$t(option.option)"
                                has-space-between
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-md-1">
                <div class="col">
                    <button
                        :disabled="!selectedOption"
                        class="btn btn-primary"
                        @click="submitOption"
                    >
                        {{ isLastQuestion ? $t('Finish') : $t('next') }}
                    </button>
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import BaseModal from '@components/BaseModal/BaseModal';
import BaseRadio from '@components/Radio';
import { UPDATE_COMPANY } from '@store/company/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'QuestionnaireModal',

    components: { BaseRadio, BaseModal },

    data() {
        return {
            questionnaire: {},
            questionIndex: 0,
            selectedOption: null,
            answers: [],
        };
    },

    computed: {
        ...mapGetters(['selectedCompanyId', 'profileId']),
        isLastQuestion() {
            return this.questionnaire?.questions?.length - 1 === this.questionIndex;
        },
        currentQuestion() {
            return this.questionnaire?.questions?.[this.questionIndex];
        },
        options() {
            return this.currentQuestion?.options || [];
        },
        questionText() {
            return this.currentQuestion?.question;
        },
        questionImage() {
            return this.currentQuestion?.image;
        },
    },

    methods: {
        show(questionnaire) {
            this.questionnaire = questionnaire;

            // To make sure that the ref is loaded as this is called out in app init faze
            this.$nextTick(() => {
                this.$refs.modal.show();
            });
        },
        hide() {
            this.$refs.modal.hide();
        },
        async submitOption() {
            this.answers.push(this.selectedOption);

            if (this.isLastQuestion) {
                await this.sendInterest();
            } else {
                this.selectedOption = null;
                this.questionIndex++;
            }
        },
        async sendInterest() {
            this.$store.commit(START_VIEW_LOADER);

            await this.$store.dispatch(UPDATE_COMPANY, {
                companyId: this.selectedCompanyId,
                data: {
                    questionnaires: [
                        {
                            id: this.questionnaire.id,
                            questions: this.answers.map((answer, index) => ({
                                id: this.questionnaire.questions[index].id,
                                option_id: answer,
                            })),
                        },
                    ],
                },
            });

            this.hide();
            this.$store.commit(STOP_VIEW_LOADER);
        },
    },
};
</script>

<style lang="scss">
.welcome-modal {
    .modal-content {
        border: none !important;
        border-radius: 16px !important;
        box-shadow: unset;
    }
}

.question-image {
    width: 100% !important;
}
</style>
