<template>
    <div class="wrapper w-100">
        <SideNav v-if="isNavExists" />
        <div class="d-flex flex-column w-100 overflow-clip">
            <AppHeader
                ref="app-header"
                class="app-header"
                :class="{
                    'd-none': !$store.getters['global/showHeader'],
                }"
            />
            <component
                :is="getSecondaryHeader"
                v-if="shouldShowSecondaryHeader"
                :class="{ 'haccp-printlayout-navbar': !isSharedAccess, 'menu-closed': !sideNavOpen }"
            />

            <div class="d-inline-flex">
                <PortalTarget
                    v-if="!$route.name.includes('plan.setup')"
                    name="sidemenu"
                    :class="{ 'shared-access-view': isSharedAccess }"
                    class="d-none d-lg-block"
                />
                <div
                    id="app"
                    :class="{
                        'shared-access-view': isSharedAccess,
                        'extended-header': showBillingDaysLeftNotification,
                    }"
                >
                    <div
                        class="app-content"
                        :class="{
                            'app-content-printlayout-haccp': $route.name.includes('plan') && !isSharedAccess,
                            'd-none': hideContent,
                        }"
                    >
                        <PortalTarget
                            name="sidemenu"
                            class="d-lg-none"
                        />
                        <RouterView v-if="$store.getters.isCompaniesInited" />
                    </div>
                    <PortalTarget
                        name="panel"
                        class="app-panel"
                        @change="handlePortalUpdate"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@components/layout/Header';
import PrintLayoutHeader from '@views/Plan/components/PrintLayoutHeader';
import SideNav from '@components/SideNav';
import { mapGetters } from 'vuex';

export default {
    components: {
        SideNav,
        AppHeader,
    },

    data() {
        return {
            companiesLoaded: false,
            isPanelActive: false,
            showBillingDaysLeftNotification: false,
        };
    },

    computed: {
        ...mapGetters({
            isSharedAccess: 'shared-access/isSharedAccess',
            generalDataOnceFilled: 'plan/generalDataOnceFilled',
            sideNavOpen: 'global/sideNavOpen',
            hasAccessToHaccpPlanFeatures: 'auth/hasAccessToHaccpPlanFeatures',
        }),
        hideContent() {
            return this.$store.getters['global/mobileHeaderOpen'] && this.$store.getters['isMobileView'];
        },
        isNavExists() {
            return !this.$store.getters['shared-access/isSharedAccess'];
        },
        getSecondaryHeader() {
            switch (this.$route.name.split('.')[0]) {
                case 'plan':
                    return PrintLayoutHeader;
                default:
                    return null;
            }
        },
        shouldShowSecondaryHeader() {
            return (
                this.$route.name.includes('plan') &&
                this.$route.name !== 'plan.setup' &&
                !this.isSharedAccess &&
                this.hasAccessToHaccpPlanFeatures
            );
        },
    },

    mounted() {
        this.showBillingDaysLeftNotification = this.$refs['app-header']?.showBillingDaysLeftNotification;
    },

    methods: {
        handlePortalUpdate(value) {
            this.isPanelActive = value;
        },
    },
};
</script>

<style lang="scss">
.app-header {
    z-index: 1036;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar.active {
    margin-left: -250px;
}

#app {
    display: grid;
    grid-template-areas:
        'header'
        'content'
        'footer';
    grid-template-rows: min-content auto 0;
    width: 100% !important;
    min-height: 100vh;

    .app-header {
        grid-area: header;
    }

    &.shared-access-view {
        margin-top: 4.5rem;
    }

    .app-content {
        position: relative;
        display: grid;
        grid-area: content;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            grid-template-columns: unset;
            width: 100vw;
            max-width: 100vw;
        }
    }

    .app-main {
        grid-area: 1 * 0.5;
        justify-self: center;
        width: 100%;
    }

    .app-side {
        grid-area: 1 / 1;
        background: rgba(0, 0, 0, 0.03);

        > * {
            width: 12rem;
        }
    }

    .app-footer {
        grid-area: footer;
        transition: right 0.3s ease;
    }

    .with-panel {
        right: 51rem;
    }

    .app-panel {
        position: fixed;
        z-index: 1039;
    }
}

.panel-content {
    position: relative;
    z-index: 1000;
}
</style>
