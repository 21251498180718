import { DEVICES } from '@common/constants';
import maxBy from 'lodash/maxBy';
import concat from 'lodash/concat';

export const getters = {
    devicesTypes(state) {
        return state.types.length ? state.types.filter((item) => item.id !== DEVICES.OTHER) : [];
    },
    deviceTypesPlusDummies(state, getters) {
        const typesDummies = [
            { slug: 'Dangerous food', $isDisabled: true },
            { slug: 'Donation delivery note', $isDisabled: true },
            { slug: '3rd cat waste delivery note', $isDisabled: true },
            { slug: 'Resale delivery note', $isDisabled: true },
            { slug: 'Dangerous food notification to VTA', $isDisabled: true },
        ];

        return [...getters.devicesTypes, ...typesDummies];
    },
    devicesList(state) {
        return state.devices.length ? state.devices : [];
    },
    devicesToDisplay(state, getters) {
        if (getters.devicesList.length && state.types.length) {
            const deviceTypes = state.types
                .filter(({ id }) => {
                    return !!getters.getDevicesByTypeId(id).length && id !== DEVICES.OTHER;
                })
                .map((type) => {
                    const data = getters.getDevicesByTypeId(type.id);

                    let res = null;

                    if (getters.isMultiDevicesTypes(type.id)) {
                        res = {
                            ...type,
                            is_schedule_done: !data.some((device) => !device.is_schedule_done),
                            is_done: !data.some((device) => !device.is_done),
                            on_paper: data.length && data.filter((device) => device.on_paper).length === data.length,
                            updated_at: maxBy(data, 'updated_at') ? maxBy(data, 'updated_at').updated_at : null,
                            has_multiple: true,
                        };

                        res.hasMarker = !res.on_paper && (!res.is_done || !res.is_schedule_done);
                    } else {
                        res = {
                            ...type,
                            device_id: data[0].id,
                            is_schedule_done: data[0].is_schedule_done,
                            on_paper: data[0].on_paper,
                            updated_at: data[0].updated_at,
                        };
                    }

                    return res;
                });

            return concat(
                deviceTypes.filter(
                    (deviceType) => !deviceType.on_paper && (!deviceType.is_schedule_done || !deviceType.is_done)
                ),
                deviceTypes.filter(
                    (deviceType) => !deviceType.on_paper && deviceType.is_schedule_done && deviceType.is_done
                ),
                deviceTypes.filter((deviceType) => deviceType.on_paper),
                getters.devicesList
                    .filter((item) => item.device_type_id === DEVICES.OTHER)
                    .map((item) => ({
                        ...item,
                        slug: item.name,
                    }))
            );
        }

        return [];
    },
    devicesUnfilledToday(state, getters) {
        return getters.devicesToDisplay.filter(
            (deviceType) =>
                getters.isMultiDevicesTypes(deviceType.id) && !deviceType.is_schedule_done && !deviceType.on_paper
        );
    },
    hasDevices(state) {
        return !!state.devices.length;
    },
    isDevicesInited(state) {
        return state.isInited;
    },
    getDevicesByTypeId: (state, getters) => (id) => {
        if (id) {
            return getters.devicesList.filter((i) => i.device_type_id === +id);
        }
    },
    getDeviceById: (state, getters) => (id) => {
        if (id) {
            return getters.devicesList.find((i) => +i.id === +id);
        } else {
            throw TypeError('Id is required!');
        }
    },
    getDeviceIndexById: (state, getters) => (id) => {
        if (id) {
            let itemIndex = null;

            getters.devicesList.find((item, index) => {
                if (+item.id === +id) {
                    itemIndex = index;
                    return true;
                }
            });

            return itemIndex;
        } else {
            throw TypeError('Id is required!');
        }
    },
    getDeviceTypeById: (state, getters) => (id) => {
        if (id) {
            return getters.devicesTypes.find((deviceType) => deviceType.id === +id);
        }
    },
    getDeviceShadule: (state, getters) => (id) => {
        const device = getters.getDeviceById(id);

        if (device && device.measurement_schedule) {
            return device.measurement_schedule.schedule;
        } else {
            console.error(`Can't get device by it id: ${id}, or can't find measurements_schedule prop`);
        }
    },
    getDeviceLimits: (state, getters) => (id) => {
        const device = getters.getDeviceById(id);

        if (device) {
            const settings = device.device_settings;
            let lower_margin = null;
            let upper_margin = null;
            if (
                settings &&
                device.sensor_id &&
                settings.notif_confirmed === true &&
                (!isNaN(settings.lower_error_margin) || !isNaN(settings.upper_error_margin))
            ) {
                lower_margin = settings.lower_error_margin;
                upper_margin = settings.upper_error_margin;
            }
            if (device.storage_id) {
                const storage = getters.getStorageById(device.storage_id);
                if (storage) {
                    return {
                        lower:
                            lower_margin === null
                                ? storage.lower_limit === null
                                    ? null
                                    : +storage.lower_limit
                                : +lower_margin,
                        upper:
                            upper_margin === null
                                ? storage.upper_limit === null
                                    ? null
                                    : +storage.upper_limit
                                : +upper_margin,
                        display_lower: storage.lower_limit === null ? null : +storage.lower_limit,
                        display_upper: storage.upper_limit === null ? null : +storage.upper_limit,
                    };
                }
            } else if (settings && (!isNaN(settings.lower_limit) || !isNaN(settings.upper_limit))) {
                return {
                    lower:
                        lower_margin === null
                            ? isNaN(settings.lower_limit)
                                ? null
                                : +settings.lower_limit
                            : +lower_margin,
                    upper:
                        upper_margin === null
                            ? isNaN(settings.upper_limit)
                                ? null
                                : +settings.upper_limit
                            : +upper_margin,
                    display_lower: isNaN(settings.lower_limit) ? null : +settings.lower_limit,
                    display_upper: isNaN(settings.upper_limit) ? null : +settings.upper_limit,
                };
            }
        }

        return {
            lower: null,
            upper: null,
        };
    },
    isMultiDevicesTypes: () => (id) => {
        return [
            DEVICES.COLD_STORAGE_ID,
            DEVICES.HOT_STORAGE_ID,
            DEVICES.PEST_CONTROL,
            DEVICES.TASKS,
            DEVICES.CLEANING_AND_DISINFECTION_TASKS,
            DEVICES.SUPERVISORY_TASKS,
            DEVICES.ANALYSES,
            DEVICES.CALIBRATION,
            DEVICES.MAINTENANCE,
        ].includes(id);
    },
    isSingleDeviceType: (state, getters) => (id) => {
        return !getters.isMultiDevicesTypes(id);
    },
    isTemperatureStorage: () => (id) => {
        return [DEVICES.COLD_STORAGE_ID, DEVICES.HOT_STORAGE_ID].includes(id);
    },
    isFoodCoreStorage: () => (id) => {
        return id === DEVICES.HOT_STORAGE_ID;
    },
    isPestControl: () => (id) => {
        return id === DEVICES.PEST_CONTROL;
    },
    isAnalyses: () => (id) => {
        return id === DEVICES.ANALYSES;
    },
    isCalibration: () => (id) => {
        return id === DEVICES.CALIBRATION;
    },
    isTasks: () => (id) => {
        return (
            id === DEVICES.TASKS || id === DEVICES.CLEANING_AND_DISINFECTION_TASKS || id === DEVICES.SUPERVISORY_TASKS
        );
    },
    isMaintenance: () => (id) => {
        return id === DEVICES.MAINTENANCE;
    },
};
