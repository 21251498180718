<template>
    <button
        v-b-tooltip.hover.topleft="$t('Notifications')"
        type="button"
        data-cy="notification-btn"
        class="btn btn-link text-decoration-none pb-0"
        @click.stop="onClick"
    >
        <span
            class="label-pos"
            :class="{ 'attention-marker-red': isAttentionNeeded }"
        >
            <i class="fd-alert text-dark"></i>
        </span>
    </button>
</template>

<script>
import { mapGetters } from 'vuex';
import { INIT_NOTIFICATIONS, MARK_AS_VISITED, OPEN_NOTIFICATIONS_VIEW } from '@store/notifications/constants';

export default {
    name: 'NotificationsButton',

    computed: {
        ...mapGetters(['notifications', 'isAttentionNeeded']),
    },

    mounted() {
        this.$store.dispatch(INIT_NOTIFICATIONS);
    },

    methods: {
        onClick() {
            this.$store.commit(OPEN_NOTIFICATIONS_VIEW);
            this.$store.dispatch(MARK_AS_VISITED);
        },
    },
};
</script>

<style lang="scss" scoped>
.label-pos {
    &:before,
    &:after {
        top: 0%;
        left: 100%;
    }
}
</style>
