<template>
    <ul class="navbar-nav">
        <li class="nav-item dropdown">
            <a
                aria-expanded="false"
                aria-haspopup="true"
                class="nav-link"
                data-toggle="dropdown"
                href="#"
                role="button"
            >
                <img
                    :src="`https://translations.fooddocs.ee/app/flags/${currentFlag}.svg`"
                    class="mr-1 lang-shadow"
                    height="20"
                    :alt="`${currentFlag}.svg`"
                />
            </a>
            <div
                class="dropdown-menu"
                :class="position"
                aria-labelledby="navbarDropdown"
            >
                <a
                    v-for="lang in unselectedLanguages"
                    :key="lang.key"
                    class="dropdown-item"
                    @click="setLanguage(lang.key)"
                >
                    <img
                        :alt="`${lang && lang.flag}.svg`"
                        :src="`https://translations.fooddocs.ee/app/flags/${lang && lang.flag}.svg`"
                        height="15"
                    />
                    <span class="text-body px-2">{{ lang.text }}</span>
                </a>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_LANGUAGE } from '@store/localization/constants';

export default {
    props: {
        position: {
            type: String,
            default: null,
        },
        avoidGlobalLangChange: {
            type: Boolean,
            default: null,
        },
        langs: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            selectedLanguage: this.$i18n.locale,
            loadedLanguages: [],
        };
    },

    computed: {
        ...mapGetters(['languages']),
        unselectedLanguages() {
            if (this.langs) {
                return this.langs.filter((el) => el.key !== this.selectedLanguage);
            } else {
                return this.languages.filter((el) => el.key !== this.selectedLanguage);
            }
        },
        currentFlag() {
            return this.languages.find((x) => x.key === this.selectedLanguage)?.flag;
        },
    },

    created() {
        if (this.langs) {
            this.setLanguage(this.langs[0].key);
        } else {
            const language = this.languages.find((x) => x.key === this.$i18n.locale) ? this.$i18n.locale : 'en';
            this.setLanguage(language);
        }
    },

    methods: {
        setLanguage(lang) {
            // eslint-disable-next-line no-negated-condition
            if (!this.avoidGlobalLangChange) {
                this.$store
                    .dispatch(SET_LANGUAGE, lang)
                    .then(() => {
                        this.selectedLanguage = lang;
                    })
                    .catch(() => {
                        if (lang !== 'en') {
                            this.setLanguage('en');
                        }
                    });
            } else {
                this.selectedLanguage = lang;
                this.$emit('lang-change', lang);
            }
        },
    },
};
</script>

<style lang="scss">
.nav-link {
    .far {
        transition: all 0.225s linear;
    }
}

.nav-link[aria-expanded='true'] > .far {
    transform: rotate(180deg);
}

.dropdown-menu {
    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: #e3f1e2;
    }
}

.lang-shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}
</style>
