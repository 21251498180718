<template>
    <div class="container d-flex align-items-center justify-content-center">
        <PlaceInformation
            class="w-50"
            @submit="submit"
        />
    </div>
</template>

<script>
import { ADD_PLACE_INFO } from '@store/company/place/constants';
import { LOG_ERROR } from '@store/common/constants';
import PlaceInformation from './PlaceInformation';

export default {
    name: 'PlaceCreationView',

    components: {
        PlaceInformation,
    },

    methods: {
        async submit({ values }) {
            const data = {
                location_type: values.locationType?.id,
                placeData: {
                    name: values.name,
                    address: values.address,
                    contact_person: values.representativePerson,
                    person_email: values.email,
                    notification_email: values.notificationEmail,
                    person_phone: values.phone,
                    country_id: values.country?.id,
                    state_id: values.state?.id,
                    preferred_language: values.preferredLanguage?.key,
                    business_activity_id: values.businessActivity?.id,
                    location_type: values.locationType?.id,
                    number_of_employees: values.numberOfEmployees,
                    timezone: values.timezone,
                },
            };
            this.$store.dispatch('plan/getProductGroups', {
                businessActivityId: values.businessActivity?.id,
                placeId: this.selectedPlaceId,
                refresh: true,
            });

            try {
                await this.$store.dispatch(ADD_PLACE_INFO, {
                    placeId: this.$store.getters.selectedCompany.places[0].id,
                    data,
                });
            } catch (e) {
                this.$store.dispatch(LOG_ERROR, `Place edit ADD_PLACE_INFO error: ${e}`);
            }
        },
    },
};
</script>
