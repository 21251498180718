import { ActionTree, GetterTree, MutationTree } from 'vuex';
import moment from 'moment';
import http from '@http';
import { FILTER, IFilter, IPlace, ITask, Place, VIEW_TYPES } from './types';
import factories from './factories';
import { download } from '@services/helper.service';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

interface IState {
    places: IPlace[];
    tasks: string[];
    applicableOptions: any[];
    groupByOptions: any[];
    viewTypes: any[];
    tableDataOrigin: any[];

    currentState: {
        filter: string;
        tasks: ITask[];
        applicable: any[];

        currentView: VIEW_TYPES;

        year: string;
        dateDiapason: {
            from: any;
            to: any;
        };
        groupBy: string;
        places: IPlace[];

        chartsData: any[];

        tableData: {
            headers: any[];
            data: any[];
        };
    };
}

const defaultFactoryConfig = factories[FILTER.people].createConfig();

function state(): IState {
    return {
        places: [],
        tasks: [],
        applicableOptions: [],
        groupByOptions: [],
        viewTypes: [],
        tableDataOrigin: [],

        currentState: {
            filter: FILTER.people,
            tasks: [],
            applicable: [],

            currentView: defaultFactoryConfig.viewTypes[0].key,

            year: `${new Date().getFullYear()}`,
            dateDiapason: {
                from: null,
                to: null,
            },
            groupBy: '',
            places: [],

            chartsData: [],

            tableData: {
                headers: [],
                data: [],
            },
        },
    };
}

const getters: GetterTree<IState, any> = {
    places: (state) => state.places,
    currentState: (state) => state.currentState,
    filterOptions: () => Object.keys(factories).map((key) => ({ key, name: factories[key].createConfig().name })),
    isSingleTaskSelectable: (state, getters) => getters.factory.createConfig().isSingleTaskSelectable,
    isSinglePlaceSelectable: (state, getters) => getters.factory.createConfig().isSinglePlaceSelectable,

    tasksOptions: (state) => state.tasks,
    groupByOptions: (state) => state.groupByOptions,
    applicableOptions: (state) => state.applicableOptions,
    viewTypes: (state) => state.viewTypes,
    chartsData: (state) => state.currentState.chartsData || [],

    tableData: (state) => state.currentState.tableData,

    factory: (state: any): IFilter => factories[state.currentState.filter],
    isCSVExport: (state: any, getters: any): boolean => !!getters.factory?.CSVStrategy,
};

const mutations: MutationTree<IState> = {
    setPlaces(state, payload: IPlace[]) {
        state.places = payload;
    },
    setGroupByOptions(state, payload) {
        state.groupByOptions = payload;
    },
    setApplicableOptions(state, payload) {
        state.applicableOptions = payload;
    },
    setTasksOptions(state, payload: string[]) {
        state.tasks = payload;
    },
    setCurrentState(state, payload) {
        state.currentState = {
            ...state.currentState,
            ...payload,
        };
    },
    setFilter(state, payload) {
        state.currentState = {
            ...state.currentState,
            filter: payload,
        };
    },
    setCurrentView(state, payload) {
        state.currentState.currentView = payload;
    },
    setTableDataOrigin(state, payload) {
        state.tableDataOrigin = payload;
    },
    setTableData(state, payload: { headers: any; data: any }) {
        state.currentState.tableData = {
            headers: payload.headers,
            data: payload.data,
        };
    },
    setGroupBy(state, payload) {
        state.currentState.groupBy = payload;
    },
    setChartsData(state, payload: any[]) {
        state.currentState.chartsData = payload;
    },
    setViewTypes(state, payload) {
        state.viewTypes = payload;
    },
};

const actions: ActionTree<IState, any> = {
    async init({ rootGetters, getters, commit, dispatch }, filterKey) {
        try {
            const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/places`);

            commit('setPlaces', data.map(Place.fromJSON));
        } catch (e) {
            console.error(e);
        }

        const filter = filterKey || getters.currentState.filter;
        const conf = factories[filter].createConfig();

        commit('setFilter', filter);
        commit('setCurrentState', conf.state || {});
        commit('setGroupByOptions', conf.groupBy || []);
        commit('setApplicableOptions', conf.applicable || []);
        commit('setViewTypes', conf.viewTypes || []);
        commit('setTableData', {
            data: [],
            headers: [],
        });

        const req: any = [];
        req.push(dispatch('getTasks'));
        req.push(dispatch('getChartsData'));

        if (conf.state?.currentView) {
            req.push(dispatch('setCurrentView', conf.state.currentView));
        }

        return Promise.all(req);
    },
    async getTasks({ rootState, rootGetters, commit, state, getters }) {
        const factory = getters.factory;

        const params: any = {
            places: getters.currentState.places.length
                ? getters.currentState.places.map((p: Place) => p.id).join(',')
                : null,
            startDate: getters.currentState.dateDiapason.from
                ? moment(getters.currentState.dateDiapason.from).format('YYYY-MM-DD')
                : null,
            endDate: getters.currentState.dateDiapason.to
                ? moment(getters.currentState.dateDiapason.to).format('YYYY.MM.DD')
                : null,
        };

        switch (getters.currentState.filter) {
            case FILTER.people: {
                params.certs = getters.currentState.tasks.length
                    ? getters.currentState.tasks.map((t: ITask) => t.name).join(',')
                    : null;
                params.year = getters.currentState.year;
                delete params.startDate;
                delete params.endDate;
                break;
            }
        }

        try {
            const tasks = await factory.createConfig().getTasks(rootState, rootGetters, params);

            if (tasks) {
                commit('setTasksOptions', tasks);
            } else {
                commit('setTasksOptions', []);
            }

            return state.tasks;
        } catch (e) {
            console.error(e);
        }
    },
    async getChartsData({ rootState, rootGetters, commit, getters }) {
        const factory = getters.factory;

        const params: any = {
            places: getters.currentState.places.length
                ? getters.currentState.places.map((p: Place) => p.id).join(',')
                : null,
            startDate: getters.currentState.dateDiapason.from
                ? moment(getters.currentState.dateDiapason.from).format('YYYY-MM-DD')
                : null,
            endDate: getters.currentState.dateDiapason.to
                ? moment(getters.currentState.dateDiapason.to).format('YYYY-MM-DD')
                : null,
        };

        switch (getters.currentState.filter) {
            case FILTER.people: {
                params.certs = getters.currentState.tasks.length
                    ? getters.currentState.tasks.map((t: ITask) => t.name).join(',')
                    : null;
                params.year = getters.currentState.year;
                delete params.startDate;
                delete params.endDate;
                break;
            }
        }

        try {
            commit('setChartsData', []);

            const chartData = await factory.createConfig().getChartData(rootState, rootGetters, params);

            commit('setChartsData', chartData);

            return getters.chartsData;
        } catch (e) {
            console.error(e);
        }
    },
    async getTableData({ rootState, rootGetters, commit, getters }) {
        const factory = getters.factory;
        const params: any = {
            places: getters.currentState.places.length
                ? getters.currentState.places.map((p: Place) => p.id).join(',')
                : null,
            certs: getters.currentState.tasks.length
                ? getters.currentState.tasks.map((t: ITask) => t.name).join(',')
                : null,
        };

        try {
            const tableData = await factory.createConfig().getTableData(rootState, rootGetters, params);

            if (tableData) {
                commit('setTableDataOrigin', tableData.originData);
                commit('setTableData', tableData);

                return getters.chartsData;
            }

            return;
        } catch (e) {
            console.error(e);
        }
    },
    setFilter({ dispatch }, key) {
        return dispatch('init', key);
    },
    setCurrentState({ state, commit, dispatch }, payload) {
        if (payload.filter && state.currentState.filter !== payload.filter) {
            payload.tasks = [];
        }
        commit('setCurrentState', payload);

        const req: any = [];

        req.push(dispatch('getTasks'));

        switch (state.currentState.currentView) {
            case VIEW_TYPES.graph:
            case VIEW_TYPES.matrix: {
                req.push(dispatch('getChartsData'));
                break;
            }
            case VIEW_TYPES.table: {
                req.push(dispatch('getTableData'));
                break;
            }
        }

        return Promise.all(req);
    },
    setCurrentView({ commit, dispatch }, payload) {
        commit('setCurrentView', payload);

        switch (payload) {
            case VIEW_TYPES.table:
                return dispatch('getTableData');
            case VIEW_TYPES.graph:
                return dispatch('getChartsData');
        }
    },
    async exportCSV({ state, dispatch, getters, commit }) {
        commit(START_VIEW_LOADER, null, { root: true });
        try {
            if (!state.tableDataOrigin.length) {
                await dispatch('getTableData');
            }
            const csv = getters.factory.CSVStrategy(state.tableDataOrigin);

            download(`${getters.factory.createConfig().name}.csv`, csv);
        } catch (e) {
            console.error(e);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
