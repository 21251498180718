import {
    ADD_CUSTOM_INGREDIENT_TO_LIST,
    ADD_PRODUCT_HAZARD,
    ADD_PROPERTIES_IMAGE,
    CHANGE_PRODUCT_HAZARD,
    CHANGE_PRODUCT_HAZARDS,
    CREATE_PRODUCT,
    DELETE_ADDITIVES,
    DELETE_ADDITIVES_ATTACHMENT,
    DELETE_CUSTOM_INGREDIENT,
    DELETE_CUSTOM_INGREDIENT_ATTACHMENT,
    DELETE_INGREDIENT,
    DELETE_INGREDIENT_ATTACHMENT,
    DELETE_PACKAGING_ATTACHMENT,
    DELETE_PRICE_FROM_INGREDIENT,
    DELETE_PRODUCT,
    DELETE_PRODUCT_HAZARDS,
    DELETE_PROPERTIES_ATTACHMENT,
    RESET_PRODUCTION,
    RESET_PRODUCTS,
    RETURN_ADDITIVES_ATTACHMENT_ITEM,
    RETURN_INGREDIENT_ATTACHMENT_ITEM,
    RETURN_PACKAGE_ATTACHMENT_ITEM,
    RETURN_PROPERTY_ATTACHMENT_ITEM,
    SET_CUSTOM_INGREDIENTS_LIST,
    SET_INGREDIENT,
    SET_INGREDIENT_PRICE_LIST,
    SET_PRODUCT,
    SET_PRODUCTS,
    SET_PRODUCT_INSTRUCTIONS,
    UPDATE_ADDITIVES,
    UPDATE_ADDITIVES_ATTACHMENTS,
    UPDATE_CUSTOM_INGREDIENT,
    UPDATE_INGREDIENT,
    UPDATE_INGREDIENTS,
    UPDATE_INGREDIENT_ATTACHMENTS,
    UPDATE_PACKAGING,
    UPDATE_PACKAGING_ATTACHMENTS,
    UPDATE_PREPARATION,
    UPDATE_PRODUCT,
} from '@views/Production/store/constants';
import Vue from 'vue';
import {
    deleteIngredientByType,
    prepareAttachments,
    prepareIngredientsAndEsubstances,
    preparePackaging,
    updateIngredientsByType,
} from '@views/Production/store/helper';
import cloneDeep from 'lodash/cloneDeep';

export default {
    [SET_PRODUCT_INSTRUCTIONS](state, data) {
        state.productInstructions = data;
    },
    [ADD_PRODUCT_HAZARD](state, { productId, hazard }) {
        let data = state.data.find((product) => product.id === parseInt(productId));
        if (data.hazards) {
            data.hazards.push(hazard);
        } else {
            data.hazards = [hazard];
        }
    },
    [CHANGE_PRODUCT_HAZARD](state, { productId, hazard }) {
        let stateHazards = state.data.find((product) => product.id === parseInt(productId)).hazards;
        const index = stateHazards.findIndex((sh) => sh.id === hazard.id);
        if (index === -1) {
            stateHazards.push(hazard);
        } else {
            stateHazards[index] = hazard;
        }
    },
    [CHANGE_PRODUCT_HAZARDS](state, { productId, hazards }) {
        state.data.find((product) => product.id === parseInt(productId)).hazards = hazards;
    },
    [DELETE_PRODUCT_HAZARDS](state, { productId, hazardId }) {
        const product = state.data.find((product) => product.id === parseInt(productId));
        product.hazards = product.hazards.filter((instruction) => instruction.id !== hazardId);
    },
    [RESET_PRODUCTION](state) {
        state.data = [];
        state.customIngredients = [];
        state.ingredients = [];
    },
    [RESET_PRODUCTS](state) {
        state.data = [];
    },
    [SET_PRODUCTS](state, data) {
        if (!Array.isArray(data)) {
            throw new TypeError('Data property should be an array!');
        }

        state.data = data.map((product) => {
            product = prepareIngredientsAndEsubstances(product);
            product = prepareAttachments(product);
            product = preparePackaging(product);

            return product;
        });
    },

    [CREATE_PRODUCT](state, data) {
        state.data.unshift(data);
    },

    [UPDATE_PRODUCT](state, data) {
        state.data.find((item, index) => {
            if (item.id === data.id) {
                data.products_custom_ingredients = state.data[index].products_custom_ingredients;
                state.data[index] = data;

                return true;
            }
        });
    },

    [DELETE_PRODUCT](state, id) {
        state.data.find((item, index) => {
            if (item.id === id) {
                state.data.splice(index, 1);

                return true;
            }
        });
    },

    [SET_PRODUCT](state, { data, index }) {
        const list = [...state.data];

        if (Number.isFinite(index)) {
            list.splice(index, 0, data);
        } else {
            const hasValue = !!list.find((item, index) => {
                if (item.id === data.id) {
                    list[index] = prepareIngredientsAndEsubstances(data);
                    list[index] = prepareAttachments(list[index]);
                    list[index] = preparePackaging(list[index]);

                    return true;
                }
            });

            // Allow to set single product data before receive all data
            if (!hasValue) {
                list.push(data);
            }
        }

        state.data = list;
    },

    [DELETE_INGREDIENT](state, { productId, ingredientId }) {
        state.data = deleteIngredientByType(cloneDeep(state.data), productId, ingredientId);
    },

    [DELETE_ADDITIVES](state, { productId, esubstanceId }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                product.esubstances.find((esubstance, index) => {
                    if (esubstance.id === esubstanceId) {
                        product.esubstances.splice(index, 1);

                        return true;
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [DELETE_INGREDIENT_ATTACHMENT](state, { productId, ingredientId, attachmentId }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                product.ingredients.find((ingredient) => {
                    if (ingredient.id === ingredientId) {
                        ingredient.files.find((attachment, index) => {
                            if (attachment.id === attachmentId) {
                                ingredient.files.splice(index, 1);

                                return true;
                            }
                        });

                        return true;
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [RETURN_INGREDIENT_ATTACHMENT_ITEM](state, { productId, ingredientId, index, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.ingredients.find((ingredient) => {
                    if (ingredient.id === ingredientId) {
                        ingredient.files.splice(index, 0, data);

                        return true;
                    }
                });

                return true;
            }
        });
    },

    [UPDATE_INGREDIENT_ATTACHMENTS](state, { productId, ingredientId, files }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                product.ingredients.find((ingredient) => {
                    if (ingredient.id === ingredientId) {
                        Vue.set(ingredient, 'files', files);

                        return true;
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [DELETE_ADDITIVES_ATTACHMENT](state, { productId, additivesId, attachmentId }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                product.esubstances.find((esubstance) => {
                    if (esubstance.id === additivesId) {
                        esubstance.files.find((attachment, index) => {
                            if (attachment.id === attachmentId) {
                                esubstance.files.splice(index, 1);

                                return true;
                            }
                        });

                        return true;
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [RETURN_ADDITIVES_ATTACHMENT_ITEM](state, { productId, additivesId, index, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.esubstances.find((esubstance) => {
                    if (esubstance.id === additivesId) {
                        esubstance.files.splice(index, 0, data);

                        return true;
                    }
                });

                return true;
            }
        });
    },

    [UPDATE_INGREDIENTS](state, { productId, ingredientsList }) {
        state.data = updateIngredientsByType([...state.data], productId, ingredientsList);
        state.ingredients = [];
        state.customIngredients = [];
    },

    [UPDATE_ADDITIVES](state, { productId, additivesList }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                additivesList.forEach((additiveToUpdate) => {
                    const hasValue = !!product.esubstances.find((ingredient, index) => {
                        if (ingredient.id === additiveToUpdate.id) {
                            product.esubstances[index] = additiveToUpdate;

                            return true;
                        }
                    });

                    if (!hasValue) {
                        product.esubstances.push(additiveToUpdate);
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [UPDATE_ADDITIVES_ATTACHMENTS](state, { productId, additiveId, files }) {
        const data = [...state.data];

        data.find((product) => {
            if (product.id === productId) {
                product.esubstances.find((additive) => {
                    if (additive.id === additiveId) {
                        Vue.set(additive, 'files', files);

                        return true;
                    }
                });

                return true;
            }
        });

        state.data = data;
    },

    [ADD_PROPERTIES_IMAGE](state, { productId, files, thumbnail }) {
        const data = [...state.data];

        data.find((product, index) => {
            if (product.id === productId) {
                product.files = files;
                product.thumbnail = thumbnail;
                data[index] = prepareAttachments(product);

                return true;
            }
        });

        state.data = data;
    },

    [RETURN_PROPERTY_ATTACHMENT_ITEM](state, { productId, index, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.files.splice(index, 0, data);

                return true;
            }
        });
    },

    [DELETE_PROPERTIES_ATTACHMENT](state, { productId, attachmentId }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.files.find((file, index) => {
                    if (file.id === attachmentId) {
                        product.files.splice(index, 1);

                        return true;
                    }
                });

                return true;
            }
        });
    },

    [UPDATE_PACKAGING_ATTACHMENTS](state, { productId, data }) {
        const list = [...state.data];

        list.find((product) => {
            if (product.id === productId) {
                product.packaging.files = data;

                preparePackaging(product);

                return true;
            }
        });

        state.data = list;
    },

    [DELETE_PACKAGING_ATTACHMENT](state, { productId, attachmentId }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.packaging.files.find((file, index) => {
                    if (file.id === attachmentId) {
                        product.packaging.files.splice(index, 1);

                        return true;
                    }
                });

                return true;
            }
        });
    },

    [RETURN_PACKAGE_ATTACHMENT_ITEM](state, { productId, index, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.packaging.files.splice(index, 0, data);

                return true;
            }
        });
    },

    [UPDATE_PREPARATION](state, { productId, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.description = data.description;
                product.taste_and_smell = data.taste_and_smell;
                product.preparation_instructions = data.preparation_instructions;

                return true;
            }
        });
    },

    [UPDATE_PACKAGING](state, { productId, data }) {
        state.data.find((product) => {
            if (product.id === productId) {
                product.packaging = data.packaging;
                product.is_completed = data.is_completed;
                product.is_completed_errors = data.is_completed_errors;
                return true;
            }
        });
    },

    [DELETE_CUSTOM_INGREDIENT_ATTACHMENT](state, { customIngredientId, attachmentId }) {
        const data = cloneDeep(state.data);
        data.forEach((product) => {
            product.ingredients.forEach((ingredient) => {
                if (
                    ingredient.custom_ingredient_id &&
                    ingredient.ingredient_data &&
                    ingredient.ingredient_data.id === customIngredientId
                ) {
                    ingredient.ingredient_data.attachments.find((attachment, index) => {
                        if (attachment.id === attachmentId) {
                            ingredient.ingredient_data.attachments.splice(index, 1);
                            return true;
                        }
                    });
                }
            });
        });
        state.data = data;
    },

    [SET_CUSTOM_INGREDIENTS_LIST](state, data) {
        if (Array.isArray(data)) {
            state.customIngredients = data;
        }
    },

    [ADD_CUSTOM_INGREDIENT_TO_LIST](state, data) {
        state.customIngredients.push(data);
    },

    [UPDATE_CUSTOM_INGREDIENT](state, data) {
        state.customIngredients = state.customIngredients.map((ing) => {
            if (ing.id === data.id) {
                return data;
            }
            return ing;
        });
    },

    [DELETE_CUSTOM_INGREDIENT](state, id) {
        state.customIngredients = state.customIngredients.filter((ing) => ing.id !== id);
    },

    [SET_INGREDIENT_PRICE_LIST](state, data) {
        state.ingredients = data;
    },

    [SET_INGREDIENT](state, data) {
        state.ingredients.push(data);
    },

    [UPDATE_INGREDIENT](state, data) {
        state.ingredients = state.ingredients.map((ing) => {
            if (ing.id === data.id) {
                return data;
            }
            return ing;
        });
    },

    [DELETE_PRICE_FROM_INGREDIENT](state, id) {
        state.ingredients = state.ingredients.filter((ing) => ing.id !== id);
    },
};
