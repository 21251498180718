import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';
import { arrayToMap } from '@services/helper.service';
import cloneDeep from 'lodash.clonedeep';
import { vm } from '@/main';
import { correctiveActionsFromJSON } from '@/store/tasks';

export type RelatedToType = 'none' | 'Equipment' | 'Rooms';

/**
 * ENTRIES
 */
export class Entry {
    public id: number;
    public uuid: string;
    // TODO: FD-5566 use DataValue type
    public date: moment.Moment;
    public comment: string;
    public status: string;
    public correctiveActions: any[]; // TODO:
    public relatedEntityId: number;
    public fileList: any[]; // TODO:
    public sensorTime: moment.Moment | null;
    // TODO: FD-5566 use DataValue type
    public updatedAt: moment.Moment | null;
    public updatedBy: any; // TODO:
    public fields: any[]; // TODO:
    public isBySensor?: boolean = false;
    public taskId: number;
    public taskDescription: string;
    public taskSubtypeId: number;
    public ticket?: Ticket;
    public verification?: object;

    constructor(entry: any) {
        this.id = entry.id;
        this.uuid = uuidv1();
        this.date = entry.date;
        this.sensorTime = entry.sensorTime;
        this.comment = entry.comment;
        this.status = entry.status;
        this.correctiveActions = entry.correctiveActions;
        this.relatedEntityId = entry.relatedEntityId;
        this.fileList = entry.fileList || [];
        this.updatedAt = entry.updatedAt;
        this.isBySensor = entry.isBySensor;
        this.updatedBy = entry.updatedBy || {};
        this.fields = entry.fields;
        this.taskId = entry.taskId;
        this.taskDescription = entry.taskDescription;
        this.taskSubtypeId = entry.taskSubtypeId;
        this.ticket = entry.ticket;
        this.verification = entry.verification;
    }

    get entryTime() {
        let res;
        // If entry was not created it should show updateAt otherwise sensorTime has priority
        if (this.sensorTime && (this.isBySensor || !this.id)) {
            // TODO: FD-5566 use DataValue type
            res = this.sensorTime;
        } else if (this.updatedAt) {
            res = this.updatedAt;
        } else {
            res = null;
        }

        return res;
    }

    clone() {
        return cloneDeep(this);
    }

    // TODO: not as intended need more work
    get fieldsMap() {
        return arrayToMap(this.fields, 'stepId');
    }

    static fromJSON(json: any): Entry {
        json.entry_date = json.entry_date || json.date;
        return new Entry({
            id: json?.id,
            status: json?.status,
            correctiveActions:
                json?.action_options?.map((i) => {
                    // eslint-disable-next-line
                    i = i.option || i;

                    return {
                        id: i.id,
                        custom: i.is_custom,
                        text: i.option_text,
                    };
                }) || [],
            relatedEntityId: json?.related_entity_id,
            comment: json?.comment,
            // TODO: FD-5566 use DataValue type
            date: json?.entry_date ? moment(json.entry_date, 'YYYY-MM-DD') : moment(),
            fileList: json?.file_list || [],
            sensorTime: json?.entry_time ? moment(json?.entry_time, 'YYYY-MM-DD HH:mm:ss') : null,
            // TODO: FD-5566 use DataValue type
            updatedAt: json?.updated_at ? moment(json.updated_at, 'YYYY-MM-DD HH:mm:ss') : null,
            isBySensor: json?.filled_by_sensor,
            updatedBy: {
                firstname: json?.updated_by_user?.firstname,
                lastname: json?.updated_by_user?.lastname,
                username: json?.updated_by_user?.username,
            },
            entryTime: json?.entry_time,
            fields:
                json?.entry_fields?.map((field: any) => ({
                    ...field,
                    stepId: field.step_id || field.id,
                    allowCustom: true, // TODO: replace it when API will be ready
                    fileList: field.file_list || [],
                    options: field.task_step?.task_step_options || field.options || [],
                })) || [],
            ticket: json?.ticket ? Ticket.fromJSON(json?.ticket) : null,
            taskId: json?.task_id,
            taskDescription: json?.task_desc,
            taskSubtypeId: json?.task_subtype_id,
            verification: json?.verification,
        });
    }

    toJSON() {
        return {
            related_entity_id: this.relatedEntityId,
            // TODO: FD-5566 use DataValue type
            date: moment(this.date).locale('en').format('YYYY-MM-DD'),
            action_options: this.correctiveActions
                ?.filter((i) => i.id || i.text)
                ?.map((i) => {
                    if (i.custom) {
                        return { option_text: i.text };
                    } else {
                        return { id: i.id };
                    }
                }),
            entry_time:
                this.isBySensor || !this.sensorTime ? null : moment(this.sensorTime).format('YYYY-MM-DD HH:mm:ss'),
            entry_fields:
                this.fields?.map?.((field) => {
                    if (field.value?.custom) {
                        delete field.value.id;
                    }

                    return {
                        id: field.stepId || field.id,
                        value: field.value,
                        file_list: field.fileList, // for Form type
                    };
                }) || [],
            status: this.status,
            comment: this.comment,
            task_id: this.taskId,
            file_list: this.fileList, // for Checklist type
        };
    }
}

export class DisplayableEntry extends Entry {
    public subtask: Subtask;

    constructor(entry: Entry, entrySubtask: Subtask) {
        super(entry);

        this.subtask = entrySubtask;
    }

    get task() {
        return this.subtask.task;
    }

    clone() {
        return cloneDeep(this);
    }
}

export class CreatedBy {
    firstname: string;
    id: number;
    idCode: number;
    lastname: string;
    phone: string;

    constructor(createdBy: any) {
        this.firstname = createdBy?.firstname;
        this.id = createdBy?.id;
        this.idCode = createdBy?.idCode;
        this.lastname = createdBy?.lastname;
        this.phone = createdBy?.phone;
    }

    static fromJSON(json: any): CreatedBy {
        return new CreatedBy({
            firstname: json.firstname,
            id: json.id,
            idCode: json.id_code,
            lastname: json.lastname,
            phone: json.phone,
        });
    }
}

export class Ticket {
    public id: number;
    public createdAt: moment.Moment;
    public createdBy: CreatedBy;
    public files: string[];
    public description: string;
    public relatedTaskEntryId: number;
    public relatedTaskEntry: Entry;
    public ticketTaskEntryDate: moment.Moment;
    public ticketTaskEntryId: number;

    constructor(ticket: any) {
        this.id = ticket?.id;
        this.createdAt = ticket?.createdAt;
        this.createdBy = CreatedBy.fromJSON(ticket?.createdBy || {});
        this.files = ticket?.files;
        this.description = ticket?.description;
        this.relatedTaskEntryId = ticket?.relatedTaskEntryId;
        this.relatedTaskEntry = ticket?.relatedTaskEntry;
        this.ticketTaskEntryDate = ticket?.ticketTaskEntryDate;
        this.ticketTaskEntryId = ticket?.ticketTaskEntryId;
    }

    get creator(): string {
        return `${this.createdBy.firstname} ${this.createdBy.lastname}`;
    }

    static fromJSON(json: any): Ticket {
        return new Ticket({
            id: json?.id,
            createdAt: json?.created_at,
            createdBy: json?.created_by,
            files: json?.file_list,
            description: json?.description,
            relatedTaskEntryId: json?.related_task_entry_id,
            relatedTaskEntry: json?.related_task_entry,
            ticketTaskEntryDate: json?.ticket_task_entry_date,
            ticketTaskEntryId: json?.ticket_task_entry_id,
        });
    }
}

/**
 * TASKS
 */
export class Task {
    public id: number;
    public name: string;
    public isDisabled: boolean;
    public isDefault: boolean;
    public isChecklist: boolean;
    public isTicket: boolean;
    public sameTaskForAll: boolean;
    public orderNumber: number;
    public isScheduled: boolean;
    public status: string;
    public taskCount: number;
    public lastEntry: ILastEntry;
    public responsible: any[];
    public relatedTo: string;
    public subtasks?: Subtask[]; // TODO: set it
    public relatedUnits: RelatedUnit[] = []; // TODO: set it
    public tasks: any[];
    public userGroup: any;
    public userGroupId: number;
    public newVersionCreatedAt?: moment.Moment;
    public prevVersionId?: number;
    private isVerificationEnabled: boolean;

    constructor(task: any, subtasks?: Subtask[] | undefined) {
        this.id = task.id;
        this.name = task.name;
        this.isDefault = task.isDefault;
        this.isDisabled = task.isDisabled;
        this.isChecklist = task.isChecklist;
        this.isTicket = task.isTicket;
        this.isVerificationEnabled = task.isVerificationEnabled;
        this.sameTaskForAll = task.sameTaskForAll;
        this.orderNumber = task.orderNumber;
        this.isScheduled = task.isScheduled;
        this.status = task.status;
        this.taskCount = task.taskCount;
        this.lastEntry = task.lastEntry;
        this.responsible = task.responsible;
        this.relatedTo = task.relatedTo;
        this.relatedUnits = task.relatedUnits;
        this.subtasks = subtasks;
        this.tasks = task.tasks;
        this.userGroup = task.userGroup;
        this.userGroupId = task.userGroupId;
        this.newVersionCreatedAt = task.newVersionCreatedAt;
        this.prevVersionId = task.prevVersionId;
    }

    get isForm(): boolean {
        return !this.isChecklist;
    }

    static fromJSON(json: any, subtasks?: Subtask[] | undefined): Task {
        return new Task(
            {
                id: json.id,
                name: json.is_default ? vm.$t(json.name) : json.name || json.task_desc,
                isDisabled: json.is_disabled,
                isDefault: json.is_default,
                isChecklist: json.is_checklist,
                isTicket: json.is_ticket,
                isVerificationEnabled: json.is_verification_enabled,
                sameTaskForAll: json.same_task_for_all,
                orderNumber: json.order_number + 1,
                isScheduled: json.scheduled,
                status: json.status,
                taskCount: json.task_count,
                taskSubtypeId: json.task_subtype_id,
                lastEntry: {
                    time: json.last_entry?.time,
                    name: json.last_entry?.name,
                },
                relatedTo: json.related_to,
                relatedUnits: json?.related_entities?.map(RelatedUnit.fromJSON) || [],
                tasks: json.tasks,
                userGroup: json.user_group,
                userGroupId: json.user_group_id,
                newVersionCreatedAt: json.new_version_created_at,
                prevVersionId: json.prev_version_id,
                responsible:
                    json.task_responsible_roles?.map((role: any) => ({
                        id: role?.id,
                        name: role?.name,
                    })) || [],
            },
            subtasks
        );
    }

    static fromTicketStatus(jsonStatus: any, jsonTickets: any): Task {
        return new Task({
            id: null,
            name: vm.$t('Tickets'),
            isDisabled: false,
            isDefault: undefined,
            isChecklist: false,
            isTicket: true,
            sameTaskForAll: false,
            orderNumber: 0,
            isScheduled: true,
            status: jsonStatus.is_filled === false ? 'due' : 'ok',
            taskCount: undefined,
            lastEntry: {
                time: undefined,
                name: undefined,
            },
            relatedTo: undefined,
            relatedUnits: jsonTickets?.map(RelatedUnit.fromJSON) || [],
            tasks: jsonTickets,
            userGroup: undefined,
            userGroupId: undefined,
            responsible: [jsonStatus.user_groups],
        });
    }
}

/**
 * SUBTASKS
 */
export class Subtask {
    public task: Task;
    public relatedUnit?: RelatedUnit;
    public id: number;
    public allowNotDone: boolean;
    public correctiveAction: any;
    public name: string;
    public prototype: Entry;
    public lastEntry: ILastEntry;
    public responsible: any[];
    public relatedUnitId: number;
    public entries: Entry[];

    constructor(subtask: any, task: Task, relatedUnit?: RelatedUnit) {
        this.id = subtask.id;
        this.allowNotDone = subtask.allowNotDone;
        this.correctiveAction = subtask.correctiveAction;
        this.name = subtask.name;
        this.prototype = subtask.prototype;
        this.lastEntry = subtask.lastEntry;
        this.responsible = subtask.responsible;
        this.relatedUnitId = subtask.relatedUnitId;
        this.entries = subtask.entries;
        this.task = task;
        this.relatedUnit = relatedUnit;
    }

    static fromJSON(json: any, task: Task, relatedUnit?: RelatedUnit): Subtask {
        return new Subtask(
            {
                id: json.id,
                allowNotDone: json.allow_not_done,
                correctiveAction: json.corrective_action ? correctiveActionsFromJSON(json.corrective_action) : null,
                name: json.task_desc,
                prototype: Entry.fromJSON({
                    ...json.entry_prototype,
                    related_entity_id: json.related_entity_id,
                }),
                lastEntry: json.last_entry
                    ? {
                          // TODO: FD-5566 use DataValue type
                          time: json.last_entry?.time
                              ? moment(json.last_entry?.time, 'YYYY-MM-DD HH:mm').toDate()
                              : null,
                          name: json.last_entry?.name.trim(),
                      }
                    : null,
                relatedUnitId: json.related_entity_id,
                responsible:
                    (json.task_responsible_role &&
                        [json.task_responsible_role]?.map((role) => ({
                            id: role?.id,
                            name: role?.name,
                        }))) ||
                    [],
                entries: Array.isArray(json.entries) ? json.entries?.map(Entry.fromJSON) : [],
            },
            task,
            relatedUnit
        );
    }
}

export class RelatedUnit {
    public id: number;
    public isActive: boolean;
    public isDefault: boolean;
    public lastEntry: ILastEntry;
    public name: string;
    public status: string;
    public responsible: any[];
    public tasks: Task[];

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.isActive = data.isActive;
        this.isDefault = data.isDefault;
        this.lastEntry = data.lastEntry;
        this.status = data.status;
        this.responsible = data.responsible;
        this.tasks = data.tasks;
    }

    static fromJSON(json: any): RelatedUnit {
        return new RelatedUnit({
            id: json.id,
            name: json.name,
            lastEntry: json.last_entry
                ? {
                      // TODO: FD-5566 use DataValue type
                      time: json.last_entry?.time ? moment(json.last_entry?.time, 'YYYY-MM-DD HH:mm').toDate() : null,
                      name: json.last_entry?.name.trim(),
                  }
                : null,
            responsible:
                json.task_responsible_roles?.map((role) => ({
                    id: role?.id,
                    name: role?.name,
                })) || [],
            status: json.status,
            isActive: json.is_active,
            isDefault: json.is_default,
            tasks: json.tasks?.map((task) => ({
                id: task.id,
                entries: task.entries.map(Entry.fromJSON),
                relatedEntityId: task.related_entity_id,
            })),
        });
    }
}

interface ILastEntry {
    // TODO: FD-5566
    time: Date | null;
    name: string;
}
