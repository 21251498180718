import http from '@http';

class MeasurementsService {
    async get(id, from, until, page, perPage, filterByIncorrect = false, searchQuery = '') {
        return http
            .get(
                `/api/measurements/${id}?start_time=${from}&end_time=${until}&page=${page}&per_page=${perPage}&search=${searchQuery}&invalid_first=${+filterByIncorrect}`
            )
            .then((res) => res.data);
    }

    getAllForPeriod(id, from, until) {
        return http.get(`/api/measurements/${id}?start_time=${from}&end_time=${until}`).then((res) => res.data);
    }

    create(deviceId, data) {
        return http.post(`/api/measurements/${deviceId}`, data).then((res) => res.data);
    }

    update(data) {
        return http.put('/api/measurements', data).then((res) => res.data);
    }

    delete(id) {
        return http.delete(`/api/measurements/${id}`).then((res) => res.data);
    }

    getIsValidTemperature(deviceId, temperature, time) {
        return http.post(`/api/measurements/${deviceId}/status`, { temperature, time }).then((res) => res.data);
    }

    uploadImage(id, body, config) {
        return http.post(`/api/measurements/${id}/image`, body, config).then((res) => res.data);
    }
}

export const measurementsService = new MeasurementsService();
