<template>
    <header
        v-if="places.length"
        class="w-100 d-none-for-print"
    >
        <div
            class="navbar-light d-flex justify-content-between flex-wrap flex-md-nowrap"
            :class="{
                'fixed-top-navbar': !isSharedAccess,
                'fixed-top border-bottom': isSharedAccess,
            }"
        >
            <!-- choose company start-->
            <span class="order-1 order-md-0 d-flex align-items-center space-related-out w-100 w-md-auto">
                <SelectEstablishment
                    class="d-none d-lg-block"
                    :selected-company="selectedCompany"
                    :selected-place="selectedPlace"
                />
                <!-- mobile-->
                <a
                    class="d-flex align-items-center w-50"
                    href="/"
                >
                    <Logo
                        class="d-lg-none"
                        responsive
                        :responsive-height="35"
                        :responsive-width="35"
                    />
                </a>
                <SelectEstablishment
                    class="d-lg-none"
                    :selected-company="selectedCompany"
                    :selected-place="selectedPlace"
                />

                <!-- mobile menu hamburger  -->
                <button
                    v-if="!isSharedAccess"
                    class="btn btn-link d-lg-none"
                    type="button"
                    @click.prevent="mobileHeader = true"
                >
                    <span class="navbar-toggler-icon" />
                </button>
            </span>

            <!-- choose company end-->

            <!-- start of trial notice -->
            <div
                v-if="showBillingDaysLeftNotification && daysLeftUntilAccess"
                class="order-0 order-lg-1 d-flex justify-content-center bg-warning font-size-xs px-2 border-radius align-items-center space-related-out py-2 px-3"
            >
                <span class="d-flex align-items-center">
                    <i class="fd-info mr-2" />
                    <span class="text-gray-700">
                        {{
                            trialDaysLeftNumber === 0
                                ? $t('Your Fooddocs trial will end today!')
                                : $t(`billing.${showBillingDaysLeftNotification}_days_left_notification_short`, {
                                      days: daysLeftUntilAccess,
                                  })
                        }}
                    </span>
                </span>
                <RouterLink
                    :to="{ name: 'billing' }"
                    class="space-unrelated-out-l ml-2 btn btn-primary btn-black font-size-xs"
                >
                    {{ $t('Subscribe') }}
                </RouterLink>
            </div>
            <!-- end of trial notice -->

            <!-- user menu-->
            <div
                v-if="!isSharedAccess"
                id="topNav"
                class="d-none order-2 d-lg-flex align-items-center"
            >
                <div class="my-2 my-lg-0 dropdown-menu-left">
                    <div class="d-flex align-items-center">
                        <HelpButton class="mr-1" />
                        <NotificationsButton class="mr-3" />
                        <div class="mr-3">
                            <div class="btn-group">
                                <a
                                    id="profile"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    class="d-flex align-items-center cursor-pointer user-logo text-decoration-none"
                                    data-toggle="dropdown"
                                >
                                    <i class="fd-user-circle font-size-lg space-related-out-r text-dark" />
                                    <span
                                        id="profile-name"
                                        class="text-dark mr-1"
                                    >
                                        {{ getUserDisplayName }}
                                    </span>
                                    <i class="fd-chevron-down font-size-sm text-dark" />
                                </a>
                                <div
                                    id="nav-menu"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <div
                                        v-for="(setting, setupIndex) in userSettings"
                                        :key="`${setupIndex}_${setting.title}`"
                                        v-b-tooltip.hover="$t(setting.tooltip)"
                                    >
                                        <RouterLink
                                            :to="setting.to || ''"
                                            class="dropdown-item py-3 d-none d-lg-flex align-items-center"
                                            tag="button"
                                            :disabled="!setting.to"
                                            :event="setting.event"
                                            :class="setting.class"
                                        >
                                            <i
                                                v-if="setting.icon"
                                                :class="`${setting.icon} mr-2`"
                                            />
                                            {{ $t(setting.title) }}
                                        </RouterLink>
                                        <BDropdownDivider v-if="setting.separator" />
                                    </div>
                                    <button
                                        class="dropdown-item py-3 d-flex align-items-center"
                                        type="button"
                                        @click="logout"
                                    >
                                        <i class="fd-sign-out mr-2" />
                                        <div class="d-inline-block">
                                            {{ $t('log_out') }}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- user menu end-->
        </div>
        <div
            id="mobileTopNav"
            class="collapse navbar-collapse"
            :class="{ visible: mobileHeader }"
        >
            <div class="my-2 my-lg-0 ml-auto dropdown-menu-left">
                <div class="row">
                    <div class="col-12">
                        <ul
                            id="mobile-nav-menu"
                            class="navbar-nav w-100 mobile-font"
                            @click="mobileHeader = false"
                        >
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="{ name: 'overview' }"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('overview') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="{ name: 'plan' }"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('plan') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="{ name: 'activity-log' }"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('Activity Log') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="productionModuleLink"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('food-production') }}</a
                                >
                            </RouterLink>
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="{ name: 'audits.list' }"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('Audit') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                :to="{ name: 'team' }"
                                class="nav-item separator"
                                tag="li"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('Team') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                :to="{ name: 'documents' }"
                                class="nav-item separator"
                                tag="li"
                            >
                                <a
                                    class="nav-link text-white ml-4"
                                    href="#"
                                    >{{ $t('documents') }}
                                </a>
                            </RouterLink>
                            <RouterLink
                                class="nav-item"
                                tag="li"
                                :to="{ name: 'set-up' }"
                            >
                                <a
                                    class="nav-link text-white ml-4 d-inline-flex align-items-center"
                                    href="#"
                                    >{{ $t('Set up') }}
                                    <BadgeNew class="ml-2" />
                                </a>
                            </RouterLink>
                        </ul>
                    </div>
                </div>
                <div class="bot">
                    <button
                        class="dropdown-item mobile-font pt-2 pb-3 text-white"
                        type="button"
                        @click="
                            mobileHeader = false;
                            showMobileSetup = true;
                        "
                    >
                        {{ getUserDisplayName }}
                    </button>
                    <button
                        id="mobile-log-out-btn"
                        class="btn btn-outline-primary mobile-font log-out"
                        type="button"
                        @click="logout"
                    >
                        {{ $t('log_out') }}
                    </button>
                </div>
            </div>
            <button
                class="close-button btn btn-link"
                @click.prevent="mobileHeader = false"
            >
                <i class="fd-close text-primary close-icon" />
            </button>
        </div>

        <Sidemenu
            v-if="showMobileSetup"
            v-model="showMobileSetup"
            :items="userSettings.map((o) => ({ ...o, title: $t(o.title) }))"
            hide-on-back
            hide-toggle
            :title="getUserDisplayName"
            :order="3"
        />
        <ReviewChangeProposalsModal ref="reviewChangeProposalsModal" />
    </header>
    <NewAccountHeader v-else-if="newAccount || !places.length" />
</template>

<script>
import BadgeNew from '@components/BadgeNew';
import { COUNTRIES } from '@common/constants';
import { FETCH_NEW_TIME_UNITS } from '@store/common/constants';
import HelpButton from '@components/layout/Header/HelpButton';
import { INIT_PERMISSIONS } from '@store/permission/constants';
import { INIT_USER_PROFILE } from '@store/profile/constants';
import Logo from '@components/Logo';
import NewAccountHeader from './NewAccountHeader';
import NotificationsButton from '@components/modals/Notifications/NotificationsButton';
import ReviewChangeProposalsModal from '@components/ReviewChangeProposalsModal';
import { SET_LANGUAGE } from '@store/localization/constants';
import { SET_SELECTED_COMPANY } from '@store/company/constants';
import { SET_SELECTED_PLACE } from '@store/company/place/constants';
import SelectEstablishment from '@components/layout/Header/SelectEstablishment';
import Sidemenu from '@components/Sidemenu/Sidemenu';
import authService from '@services/auth.service';
import filters from '@common/filters';
import { mapGetters } from 'vuex';
import { retry } from '@services/helper.service';

const DEFAULT_TRIAL_DURATION_DAYS = 14;
const MONTHLY_SUBSCRIPTION_OVERDUE_PERIOD = 2;

export default {
    components: {
        HelpButton,
        Sidemenu,
        SelectEstablishment,
        Logo,
        NewAccountHeader,
        NotificationsButton,
        BadgeNew,
        ReviewChangeProposalsModal,
    },

    data() {
        return {
            showMobileSetup: false,
            isMobileNavVisible: false,
            permissionsLoaded: false,
            hideBillingDaysLeftNotification: false,
            hideMobileAppDownloadNotification: false,
        };
    },

    computed: {
        daysLeftUntilAccess() {
            return this.showBillingDaysLeftNotification === 'trial'
                ? this.trialDaysLeftNumber
                : this.subscriptionDaysLeft;
        },
        showMobileDownloadNotification() {
            return (
                !this.hideMobileAppDownloadNotification && !sessionStorage.getItem('hideMobileAppDownloadNotification')
            );
        },
        productionModuleLink() {
            return { name: this.selectedCompany.use_new_production_module ? 'traceability' : 'production' };
        },
        ...mapGetters(['getUserDisplayName', 'permissions']),
        ...mapGetters({
            isTrialOngoing: 'global/isTrialOngoing',
            subscriptionRenewalDifferenceInDays: 'global/subscriptionRenewalDifferenceInDays',
            isSharedAccess: 'shared-access/isSharedAccess',
        }),
        mobileHeader: {
            get() {
                return this.$store.getters['global/mobileHeaderOpen'];
            },
            set(value) {
                this.$store.commit('global/setMobileHeaderState', value);
            },
        },
        userSettings() {
            return [
                {
                    to: { name: 'settings.account' },
                    title: 'account',
                    icon: 'fd-user',
                },
                {
                    to: { name: 'billing' },
                    disabled: !this.isMemberOrOwner,
                    icon: 'fd-document-text',
                    title: 'billing',
                },
            ];
        },
        expiryNotificationDays() {
            return filters.positiveOrZero(
                this.showBillingDaysLeftNotification === 'trial' ? this.isTrialOngoing : this.subscriptionDaysLeft
            );
        },
        showBillingDaysLeftNotification() {
            const subscription = this.selectedCompany.subscription;
            if (!subscription) {
                return false;
            }

            return (this.isTrialOngoing && 'trial') || (this.subscriptionDaysLeft && 'subscription');
        },
        noNav() {
            return this.$route.meta.noNav;
        },
        newAccount() {
            return this.$route.meta.newAccount;
        },
        companies() {
            return this.$store.getters.companies.map((company) => company.company);
        },
        places() {
            return this.$store.getters.places;
        },
        selectedCompanyId: {
            get() {
                return this.$store.getters.selectedCompanyId;
            },
            set(value) {
                this.$store.dispatch(SET_SELECTED_COMPANY, value);
            },
        },
        selectedPlace: {
            get() {
                return this.$store.getters.selectedPlace;
            },
            async set(place) {
                await this.$store.dispatch(SET_SELECTED_PLACE, place.id);
                this.checkForProposals();
            },
        },
        selectedCompany: {
            get() {
                if (this.$store.getters.selectedCompany) {
                    return this.$store.getters.selectedCompany.company;
                } else {
                    return {};
                }
            },
            set(company) {
                this.$store.dispatch(SET_SELECTED_COMPANY, company.id);
            },
        },
        isMemberOrOwner() {
            const allowedRoles = ['member', 'owner'];
            return allowedRoles.includes(this?.selectedCompany?.role);
        },
        isEstonia() {
            return this.selectedPlace && this.selectedPlace.country_id === COUNTRIES.ESTONIA;
        },
        trialDaysLeftNumber() {
            return this.selectedCompany?.subscription?.trial_days_left;
        },
        subscriptionDaysLeft() {
            try {
                const subscription = this.selectedCompany?.subscription;
                const renewalPeriod = subscription.subscription_renewal_period;

                if (
                    renewalPeriod.toLowerCase().trim() === '1 year' &&
                    this.subscriptionRenewalDifferenceInDays <= DEFAULT_TRIAL_DURATION_DAYS
                ) {
                    return this.subscriptionRenewalDifferenceInDays;
                } else if (
                    renewalPeriod.toLowerCase().trim() === '1 month' &&
                    this.subscriptionRenewalDifferenceInDays <= MONTHLY_SUBSCRIPTION_OVERDUE_PERIOD
                ) {
                    return this.subscriptionRenewalDifferenceInDays;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        },
    },

    created() {
        if (!this.$store.getters['shared-access/isSharedAccess']) {
            this.$store
                .dispatch(INIT_USER_PROFILE, this.$store.getters.selectedCompany)
                .then(() => {
                    if (authService.isAuthorized && !authService.hasEmail()) {
                        this.navigateTo({ name: 'setEmail' });
                    }
                    this.$store.dispatch(INIT_PERMISSIONS).finally(() => {
                        this.permissionsLoaded = true;
                        this.$root.$emit(INIT_PERMISSIONS);
                    });
                    this.checkForProposals();
                })
                .catch((e) => {
                    if (e && e?.reason !== 'cancelled') {
                        return e;
                    }
                });
        }
        if (authService.isAuthorized && this.$store.getters.selectedCompanyId) {
            this.$store.dispatch(FETCH_NEW_TIME_UNITS);
        }
        const RETRY_TIMES = 5;
        const RETRY_TIMEOUT = 300;
        retry(
            () => {
                if (!this.$store.getters.userProfile.lang) {
                    throw new Error('Language not found!');
                }
                this.$store.dispatch(SET_LANGUAGE, this.$store.getters.userProfile.lang);
            },
            RETRY_TIMES,
            RETRY_TIMEOUT,
            () => {
                this.$store.dispatch(SET_LANGUAGE, this.$i18n.locale);
            }
        );
    },

    methods: {
        checkForProposals() {
            if (this.selectedPlace?.id && this.$store.getters.isOwner) {
                const hasCheckedForProposals = window.sessionStorage.getItem(
                    `${this.selectedPlace.id}-has-checked-for-proposals`
                );
                if (!hasCheckedForProposals) {
                    this.$store.dispatch('plan/custom-content/getProposals').then((res) => {
                        if (res?.length) {
                            this.$refs?.reviewChangeProposalsModal?.show(res);
                        }
                    });
                }
            }
        },
        getDifferenceInDays(startDate, endDate) {
            const MILLIS_IN_DAY = 86400000;
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            const differenceInTime = endDate.getTime() - startDate.getTime();
            return differenceInTime / MILLIS_IN_DAY;
        },
        closeNotification(type, useSessionStorage) {
            // TODO: Get rid of it
            this[type] = true; // TODO: Never use things like this. You Should not access variables using string literals
            // TODO: FD-5566
            let expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 1);
            const item = {
                value: true,
                expiry: expireDate.getTime(),
            };
            if (useSessionStorage) {
                sessionStorage.setItem(type, JSON.stringify(item));
            } else {
                localStorage.setItem(type, JSON.stringify(item));
            }
        },
        logout() {
            this.$store.dispatch('auth/logout');
        },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    margin: 0.5rem 1rem;
}

.close-button {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
}

#mobile-nav-menu {
    height: 70vh;
    overflow: auto;

    & > li.active {
        background-color: $primary;
    }
}

li.separator:after {
    display: block;
    width: 88%;
    margin: 0 auto;
    content: '';
    border-bottom: 1px solid $primary;
}

#billingDaysLeftNotification {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    font-size: 0.85rem;
    background-color: $yellow;

    a {
        color: black;
    }

    img {
        max-width: none;
    }

    @include media-breakpoint-up(md) {
        font-size: 0.92rem;
    }
}

header {
    border-bottom: 1px solid $gray-300;
}

.loco-divider {
    line-height: 80%;
    border-right: 2px solid $gray-500;
}

#mobileTopNav {
    position: absolute;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    display: block !important;
    height: 100% !important;
    padding-top: 0.5rem;
    margin-right: 100%;
    margin-left: -100%;
    overflow: hidden;
    background: $dark-green;
    transition: all 0.4s ease;
    overflow-y: clip;
    z-index: 3001;

    &.visible {
        margin-right: 0;
        margin-left: 0;
    }

    @include media-breakpoint-up(lg) {
        display: none !important;
    }

    select option {
        background: $gray-600;
    }

    .dropdown-item {
        &:hover,
        &:focus {
            background-color: $gray-500;
        }
    }
}

.close-icon {
    font-size: 1.8rem;
    font-weight: 100 !important;
}

.bot {
    position: absolute;
    bottom: 2rem;
    z-index: 999999 !important;
    width: 100%;
    height: 15vh;
}

#mobile-log-out-btn {
    font-size: 0.875rem !important;
    letter-spacing: 0.5px !important;
    background-color: transparent !important;
}

.mobile-font {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
}

@include media-breakpoint-down(lg) {
    .nav-item {
        padding: 0.6rem 0;
    }
}

.log-out {
    margin-left: 1.5rem;
}
</style>
