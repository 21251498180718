import { mapGetters } from 'vuex';
import { UPDATE_PLACE } from '@store/company/place/constants';
import store from '@/store';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

const COMPANY_DETAILS = 'Company Details';

export const planMixin = {
    data() {
        return {
            instructionUpdateQueue: [],
        };
    },
    computed: {
        ...mapGetters({
            sideMenu: 'plan/sideMenu',
        }),
        showGrayPrintBackground() {
            return (
                !['plan.setup', 'plan.hazards-summary-list'].includes(this.$route.name) &&
                !['other_documents', 'documentation', 'auditing', 'control_frequencies'].includes(
                    this.$route.path.split('/')[2]
                )
            );
        },
        fspProductGroupId() {
            return this.$route.query.fspProductGroupId;
        },
        currentPageRouteName() {
            const routeParams = this.$route.name.split('.');
            return this.$route?.params?.custom || routeParams[routeParams.length - 1];
        },
        currentGroup() {
            const formatRouterLink = (link) => {
                return link?.includes('.') ? link.split('.')[1] : link;
            };

            try {
                return this.sideMenu.find((sideMenuItem) =>
                    sideMenuItem?.children?.find(
                        (page) => formatRouterLink(page.to.name || page.to.link) === this.currentPageRouteName
                    )
                );
            } catch (e) {
                return null;
            }
        },
        currentPage() {
            if (this.fspProductGroupProcess?.sections?.[0]?.translations) {
                return { title: this.decipherAPIString(this.fspProductGroupProcess?.sections?.[0]?.translations) };
            }

            const formatRouterLink = (link) => {
                return link.includes('.') ? link.split('.')[1] : link;
            };

            try {
                const getDefaultName = () =>
                    this.currentPageRouteName === 'raw-materials'
                        ? 'food_groups'
                        : this.currentPageRouteName.replace('-', '_');

                return (
                    this.currentGroup?.children?.find(
                        (page) => formatRouterLink(page.to.name || page.to.link) === this.currentPageRouteName
                    ) || { title: getDefaultName() }
                );
            } catch (e) {
                console.error(e);
                return null;
            }
        },
        currentPageTitle() {
            return this.currentPage?.title;
        },
        isGeneralDataGroup() {
            try {
                if (this.fspProductGroupId && !['plan.fsp-products'].includes(this.$route.name)) {
                    return true;
                }

                const index = this.sideMenu.indexOf(this.currentGroup);

                return index !== -1 && this.sideMenu[index]?.title === COMPANY_DETAILS;
            } catch (e) {
                return true;
            }
        },
    },
    methods: {
        toggleInstructions(instructions, ignore = false) {
            instructions
                .filter((i) => ignore || i.disabled_for_place !== i.originallyDisabledForPlace)
                .forEach((instruction) => {
                    this.$store.dispatch('plan/editProductionProcessInstruction', {
                        instruction: {
                            id: instruction.id,
                            disabled_for_place: instruction.disabled_for_place,
                        },
                    });
                });
        },
        toggleSwitchQueue(instruction) {
            if (this.instructionUpdateQueue.find((i) => i.id === instruction.id)) {
                this.instructionUpdateQueue = this.instructionUpdateQueue.filter((i) => {
                    return i.id !== instruction.id;
                });
            } else {
                this.instructionUpdateQueue.push(instruction);
            }
        },
        async save() {
            this.$store.commit(START_VIEW_LOADER);

            let toggleUpdates = this.getSwitchQueue();

            toggleUpdates.forEach((update) => {
                this.toggleHazard(update);
            });

            await this.$store.dispatch('plan/custom-content/getContent', {
                id: this.planTable?.id,
                reload: true,
            });

            this.$store.commit('plan/setEditModeActive', false);
            this.emptyQueue();

            this.$store.commit(STOP_VIEW_LOADER);
        },
        getSwitchQueue() {
            return this.instructionUpdateQueue;
        },
        emptyQueue() {
            this.instructionUpdateQueue = [];
        },
        async toggleHazard(instruction) {
            this.toggleSwitchQueue(instruction);
            await this.$store.dispatch('plan/editProductionProcessInstruction', { instruction });
            await this.$store.dispatch('plan/getDetails');
        },
        async switchOffSystemDefinedHazard() {
            store.commit(START_VIEW_LOADER);
            let sectionId = this.getProductionProcess().container.id;
            const instruction = { id: sectionId, disabled_for_place: !this.disabledForPlace };
            this.disabledForPlace = !this.disabledForPlace;

            await this.$store.dispatch('plan/editProductionProcessInstruction', { instruction });
            await this.$store.dispatch('plan/getDetails');

            store.commit(STOP_VIEW_LOADER);
        },
        useAIPlan() {
            const place = Object.assign({}, this.$store.getters.selectedPlace);
            place.uses_custom_plan = false;
            this.$store.commit(UPDATE_PLACE, place);

            const updatePlaceData = { uses_custom_plan: false };
            this.$store
                .dispatch(UPDATE_PLACE, {
                    placeId: this.$store.getters.selectedPlace.id,
                    data: updatePlaceData,
                    redirect: false,
                })
                .then(() => {
                    this.navigateTo({ name: 'plan' });
                });
        },
        getLevel4StaticName(condition, conditionIndex) {
            if (conditionIndex === 0) {
                return this.removeParagraphTags(this.decipherAPIString(condition?.translations))
                    ? this.removeParagraphTags(this.decipherAPIString(condition?.translations))
                    : condition.level4StaticName;
            } else {
                return condition && condition.level4ColumnName && this.decipherAPIString(condition.level4ColumnName)
                    ? this.decipherAPIString(condition.level4ColumnName)
                    : this.$t(condition.level4StaticName);
            }
        },
        getLevel4StaticTitle(condition) {
            return this.$t('level4_' + condition.level4StaticName + '_title');
        },
        getLevel4StaticSubTitle(condition) {
            return this.$t('level4_' + condition.level4StaticName + '_subtitle');
        },
        decipherAPIString(string) {
            const langs = this.$store.getters['plan/getLanguageIds'];
            const currentLang = langs.filter((lang) => lang.iso2 === this.$i18n.locale);
            const currentLangId = currentLang?.length && currentLang[0].id ? currentLang[0].id : 43;
            try {
                return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
            } catch (e) {
                try {
                    const currentLangId = langs.filter((lang) => lang.iso2 === 'en')[0].id;
                    return string.filter((s) => s.language_id === currentLangId)[0].translation_text;
                } catch (e) {
                    return '';
                }
            }
        },
        removeParagraphTags(string) {
            let newString = string.split('').join('');
            if (!this.$store.getters['global/showHeader']) {
                newString = newString.replace(/<(a|\/a)[^>]*>/g, '');
            }
            if (
                newString[0] === '<' &&
                newString[1] === 'p' &&
                newString[2] === '>' &&
                newString[newString.length - 4] === '<' &&
                newString[newString.length - 3] === '/' &&
                newString[newString.length - 2] === 'p' &&
                newString[newString.length - 1] === '>'
            ) {
                newString = newString.slice(3, -4);
            }
            return newString
                .split('<br>')
                .join('')
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .split('<iframe')
                .join('<p><div class="embed-responsive embed-responsive-16by9"><iframe width="100%"')
                .split('</iframe>')
                .join('</iframe></div></p>');
        },
    },
};
