import moment, { Moment } from 'moment';
import { vm } from '@/main';

const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
const REQUEST_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const FALLBACK_DATE_FORMAT = REQUEST_DATE_FORMAT;

// TODO: FD-5566 important thing here is that make sure that user will not destroy time zone value for example in case when he will get it from URL query or something
export class DateValue {
    private date: Moment;

    constructor(date: Date = new Date()) {
        this.date = moment(date);
    }

    get momentValue(): Moment {
        return this.date;
    }

    set momentValue(value: Moment) {
        this.date = value;
    }

    get dateValue(): Date {
        return this.date.toDate();
    }

    set dateValue(date: Date) {
        this.date = moment(date);
    }

    get isValid(): boolean {
        return this.date.isValid();
    }

    /**
     * FORMATS
     */
    get dateFormat() {
        return this.format(DateValue.getCompanyDateFormat());
    }

    get dateTimeFormat() {
        const format = DateValue.getCompanyDateFormat();
        return this.format(`${format} HH:mm`);
    }

    get timeFormat() {
        return this.date.format(`HH:mm`);
    }

    get dateDayTimeFormat() {
        return this.format(`dddd, ${DateValue.getCompanyDateFormat()}`);
    }

    get requestDateFormat() {
        return this.format(REQUEST_DATE_FORMAT);
    }

    get requestDateTimeFormat() {
        return this.format(REQUEST_DATE_TIME_FORMAT);
    }

    get urlFormat() {
        return this.dateValue.getTime();
    }

    format(formatStr: string): string {
        return this.date.format(formatStr);
    }

    static fromRequestDate(str: string): DateValue {
        const momentValue = moment(str, REQUEST_DATE_FORMAT);
        if (!momentValue.isValid()) {
            return DateValue.fromRequestDateTime(str);
        }
        return new DateValue(momentValue.toDate());
    }

    static fromRequestDateTime(str: string): DateValue {
        return new DateValue(moment(str, REQUEST_DATE_TIME_FORMAT).toDate());
    }

    static fromFormattedDate(str: string): DateValue {
        const format = DateValue.getCompanyDateFormat();
        return new DateValue(moment(str, format).toDate());
    }

    static getCompanyDateFormat(): string {
        return vm?.$store?.getters?.selectedCompanyDateFormat || FALLBACK_DATE_FORMAT;
    }
    // TODO: Add comparison
}
