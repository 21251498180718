import http from '@services/http/http.service';
import { vm } from '@/main';

class CommonService {
    constructor() {
        if (CommonService.instance) {
            return CommonService.instance;
        }

        CommonService.instance = this;
    }

    getCountries() {
        return http.get('/api/classifiers/countries').then((res) => res.data);
    }

    getLanguages() {
        return http.get('/api/classifiers/languages').then((res) => res.data);
    }

    getCurrencies() {
        return http.get('/api/classifiers/currencies').then((res) => res.data);
    }

    getBusinessActivities() {
        return http.get('/api/classifiers/business-activities').then((res) => res.data);
    }

    getLocationTypes() {
        return http.get('/api/classifiers/location_types').then((res) => res.data);
    }

    getCustomerGroups() {
        return http.get('/api/classifiers/customer-groups').then((res) => res.data);
    }

    getTimeUnits() {
        return http.get('/api/classifiers/time-units').then((res) => res.data);
    }

    getProductionUnits() {
        return http.get('/api/classifiers/production-units').then((res) => res.data);
    }

    getFoodGroupCategories() {
        return http.get('/api/classifiers/food-group-categories').then((res) => res.data);
    }

    getProductGroups() {
        return http.get('/api/classifiers/product-groups').then((res) => res.data);
    }

    getEsubstancesList() {
        return http.get('/api/module/production/esubstances').then((res) => res.data);
    }

    getAdditiveUsages() {
        return http.get('/api/classifiers/additive-usages').then((res) => res.data);
    }

    getPackagingList() {
        return http.get('/api/module/production/packaging-materials').then((res) => res.data);
    }

    getClaimsList() {
        return http.get('/api/classifiers/claims').then((res) => res.data);
    }

    getIpCountry() {
        return http.get('/api/users/country').then((res) => res.data);
    }

    createAndStoreSessionId() {
        if (!localStorage.getItem('sess_id')) {
            const token = Math.random().toString(36).substr(2);
            localStorage.setItem('sess_id', token);

            return token;
        }
        return localStorage.getItem('sess_id');
    }

    storeProgress(data) {
        return http.post('/api/save-progress', data).then((res) => res.data);
    }

    fetchTechnologistPrice(countryId) {
        return http.get(`/api/price/country/${countryId}`).then((res) => res.data);
    }

    getChangelogs() {
        return http
            .get('/api/page-management/changelog', {
                params: { country: vm.$store.getters?.selectedCompany?.company?.country_id },
            })
            .then((res) => res.data);
    }

    getChangelog(id) {
        return http.get(`/api/page-management/changelog/${id}`).then((res) => res.data);
    }

    sendLastSeenNotificationDate(date) {
        return http.put('/api/profile/last-seen-notification', {
            date,
        });
    }
}

export default new CommonService();
