import commonService from '@services/common.service';
import {
    CLOSE_NOTIFICATIONS_VIEW,
    INIT_NOTIFICATIONS,
    LOAD_NOTIFICATION_DETAILS,
    MARK_AS_VISITED,
    OPEN_NOTIFICATIONS_VIEW,
    RESET_VISITED_STATE,
    SET_HIDE_STATE_NOTIFICATION_DETAILS_VIEW,
    SET_INTERVAL,
    SET_LAST_SEEN_NOTIFICATION_DATE,
    SET_NOTIFICATIONS,
    SET_SHOW_STATE_NOTIFICATION_DETAILS_VIEW,
    UPDATE_NOTIFICATION_ITEM,
} from '@store/notifications/constants';

const timeBeforeNextUpdate = 1000 * 60 * 60 * 24;

const state = {
    timer: null,
    notifications: [],

    visited: true,
    lastSeenNotificationDate: localStorage.getItem('notifications/lastNotificationDate'),

    isViewOpen: false,
    isNotificationDetailsShown: false,
};

const mutations = {
    [SET_INTERVAL](state, timer) {
        state.timer = timer;
    },
    [SET_NOTIFICATIONS](state, notifications) {
        state.notifications = notifications;
    },
    [SET_LAST_SEEN_NOTIFICATION_DATE](state, date) {
        state.lastSeenNotificationDate = date;
        localStorage.setItem('notifications/lastNotificationDate', date);
    },
    [RESET_VISITED_STATE](state) {
        state.visited = false;
    },
    [MARK_AS_VISITED](state) {
        state.visited = true;
    },

    [OPEN_NOTIFICATIONS_VIEW](state) {
        state.isViewOpen = true;
    },
    [CLOSE_NOTIFICATIONS_VIEW](state) {
        state.isViewOpen = false;
    },

    [UPDATE_NOTIFICATION_ITEM](state, { id, data }) {
        state.notifications = state.notifications.map((item, index) => {
            if (item.id === id) {
                return data;
            }
            return state.notifications[index];
        });
    },

    [SET_SHOW_STATE_NOTIFICATION_DETAILS_VIEW](state) {
        state.isNotificationDetailsShown = true;
    },
    [SET_HIDE_STATE_NOTIFICATION_DETAILS_VIEW](state) {
        state.isNotificationDetailsShown = false;
    },
};

const actions = {
    [INIT_NOTIFICATIONS]({ commit, getters }) {
        getNotifications();
        commit(SET_INTERVAL, setInterval(getNotifications, timeBeforeNextUpdate));

        function getNotifications() {
            commonService.getChangelogs().then((res) => {
                if (Array.isArray(res)) {
                    commit(SET_NOTIFICATIONS, res);

                    // TODO: FD-5566
                    const freshest = [...res].sort((a, b) => {
                        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
                    })[0]; // Get first item from sorted list

                    const freshestDate = freshest ? new Date(freshest.updated_at) : null;

                    if (freshestDate && getters.isFreshNotification(freshestDate)) {
                        commit(SET_LAST_SEEN_NOTIFICATION_DATE, freshest.updated_at);
                        commit(RESET_VISITED_STATE);
                    }
                }
            });
        }
    },
    [LOAD_NOTIFICATION_DETAILS]({ commit }, id) {
        commonService.getChangelog(id).then((res) => {
            if (res) {
                commit(UPDATE_NOTIFICATION_ITEM, {
                    id,
                    data: res,
                });
            }
        });
    },
    [MARK_AS_VISITED]({ commit, getters }) {
        commit(MARK_AS_VISITED);
        commonService.sendLastSeenNotificationDate(getters.lastSeenNotificationDate);
    },
};

const getters = {
    notifications() {
        return state.notifications ? state.notifications : [];
    },
    getNotificationById: (state, getters) => (id) => {
        return getters.notifications.find((item) => item.id === id);
    },
    hasNotifications(state, getters) {
        return getters.notifications.length > 0;
    },
    isAttentionNeeded(state, getters) {
        return getters.hasNotifications && !state.visited;
    },

    isFreshNotification: (state, getters) => (currentNotifDate) => {
        if (getters.lastSeenNotificationDate) {
            // TODO: FD-5566
            return new Date(getters.lastSeenNotificationDate).getTime() < currentNotifDate.getTime();
        } else {
            return true;
        }
    },

    lastSeenNotificationDate(state) {
        return state.lastSeenNotificationDate;
    },

    isNotificationsViewOpen(state) {
        return state.isViewOpen;
    },
    isNotificationDetailsShown(state) {
        return state.isNotificationDetailsShown;
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
