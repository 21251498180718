import http from '@http';
import { GENERATE_SESSION_ID, SET_SESSION_ID } from '@views/Auth/views/Register/store/constants';
import { vm } from '@/main';
import { CREATE_COMPANY, SET_SELECTED_COMPANY, UPDATE_COMPANY } from '@store/company/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import commonService from '@services/common.service';
import { CREATE_PLACE } from '@/store/modules/company/place/constants';
import TrackingService from '@services/tracking.service';

const state = {
    sessionId: null,
};

const mutations = {
    [SET_SESSION_ID](state, sessionId) {
        state.sessionId = sessionId;
    },
};

const actions = {
    [GENERATE_SESSION_ID]({ commit }) {
        commit(SET_SESSION_ID, commonService.createAndStoreSessionId());
    },
    async [`auth/register/createAccount`]({ commit, dispatch }, { name, email, password }) {
        commit(START_VIEW_LOADER);

        try {
            await dispatch('auth/signup', {
                data: getAccountData({
                    name,
                    email,
                    password,
                }),
                showLoading: false,
            });

            await dispatch('auth/login', {
                username: email,
                password: password,
            });

            // TODO: Have no idea what the hell is it
            const _hsq = (window._hsq = window._hsq || []);
            _hsq.push([
                'identify',
                {
                    email,
                },
            ]);
            _hsq.push(['trackPageView']);
        } finally {
            commit(STOP_VIEW_LOADER);
        }
    },
    async ['auth/register/createCompany'](
        { dispatch, commit },
        { name, countryId, countryStateId, locationsAmount, businessActivityId, numberOfEmployees }
    ) {
        commit(START_VIEW_LOADER);

        const companyForm = getCompanyData({ name, countryId, countryStateId, locationsAmount, numberOfEmployees });

        const company = await dispatch(CREATE_COMPANY, { data: companyForm });

        try {
            await dispatch(SET_SELECTED_COMPANY, company.id);
        } catch (e) {
            console.error(e);
            commit(STOP_VIEW_LOADER);
        }

        try {
            await dispatch(CREATE_PLACE, {
                companyId: company.id,
                data: {
                    name,
                    country_id: countryId,
                    state_id: countryStateId,
                    business_activity_id: businessActivityId,
                    number_of_employees: numberOfEmployees,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // To set default timezone
                },
            });
        } catch (e) {
            console.error(e);
            commit(STOP_VIEW_LOADER);
        }

        commit(STOP_VIEW_LOADER);
    },
    async ['auth/register/verifyUser']({ commit, getters, dispatch }, { phone, phoneCode, newsletters }) {
        commit(START_VIEW_LOADER);

        TrackingService.signup(
            getters.profileId,
            getters.getCountryCodeById(getters.selectedCompany?.company?.country_id)
        );

        try {
            await http.put('api/account/newsletter', {
                newsletter: newsletters,
            });

            await dispatch(UPDATE_COMPANY, {
                companyId: getters.selectedCompanyId,
                data: {
                    phone: getPhone(phoneCode, phone),
                },
            });
        } finally {
            commit(STOP_VIEW_LOADER);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    actions,
    getters,
};

function getAccountData(data) {
    const res = { ...data };

    const name = res.name.split(' ');
    if (name.length < 2) {
        res.first_name = name.pop();
    } else {
        res.last_name = name.pop();
        res.first_name = name.join(' ');
    }
    delete res.name;

    res.username = res.email;
    delete res.email;

    res.phone = getPhone(res.phoneCode, res.phone);
    delete res.phoneCode;

    const lang = vm.$i18n.locale || localStorage.getItem('lang') || 'en';
    res.lang = lang.split('?')[0];

    return res;
}

function getCompanyData(data) {
    const res = { ...data };

    res.phone = getPhone(res.phoneCode, res.phone);
    delete res.phoneCode;

    res.locations_amount = res.locationsAmount;
    delete res.locationsAmount;

    res.country_id = res.countryId;
    delete res.countryId;

    res.state_id = res.countryStateId;
    delete res.countryStateId;

    res.preferred_language = vm.$i18n.locale || localStorage.getItem('lang');
    if (!res.preferred_language) {
        res.preferred_language = 'en';
    }

    res.number_of_employees = data.numberOfEmployees;
    delete res.numberOfEmployees;

    return res;
}

function getPhone(phoneCode, phone) {
    if (phone && phoneCode?.phone_code && !phone.includes('+')) {
        return `+${phoneCode.phone_code} ${phone}`;
    }
    return phone;
}
