import Vue from 'vue';
import { productionService } from '@services/production.service';
import { DELETE_INGREDIENT, UPDATE_INGREDIENTS } from '@views/Production/store/constants';
import { vm } from '@/main';
import { getAllNames } from '@services/helper.service';

export function prepareIngredientsAndEsubstances(product) {
    return product;
}

export function preparePackaging(product) {
    const data = { ...product };

    return data;
}

export function prepareAttachments(product) {
    const data = { ...product };

    return data;
}

export function deleteIngredientByType(data, productId, ingredientId, type = 'ingredients') {
    data.find((product) => {
        if (product.id === productId) {
            Array.isArray(product[type]) &&
                product[type].find((ingredient, index) => {
                    if (ingredient.id === ingredientId) {
                        product[type].splice(index, 1);

                        return true;
                    }
                });

            return true;
        }
    });

    return data;
}

export function updateIngredientsByType(data, productId, ingredientsList, type = 'ingredients') {
    if (!Array.isArray(data)) {
        return data;
    }

    data.find((product) => {
        if (product.id === productId) {
            ingredientsList.forEach((ingredientToUpdate) => {
                const hasValue = !!(
                    Array.isArray(product[type]) &&
                    product[type].find((ingredient, index) => {
                        if (ingredient.id === ingredientToUpdate.id) {
                            Vue.set(product[type], index, ingredientToUpdate);

                            return true;
                        }
                    })
                );

                if (!hasValue) {
                    Array.isArray(product[type]) && product[type].push(ingredientToUpdate);
                }
            });

            return true;
        }
    });

    return data;
}

export function setIngredientsByType(commit, productId, data, isCustom = false, isProduct = false) {
    const toCreate = [];
    const toUpdate = [];

    data.forEach((item) => {
        if (item.production_unit && item.ingredient_data) {
            if (item.newItem) {
                toCreate.push({
                    [productionService.getValueByIngredientType(
                        isCustom,
                        isProduct,
                        'custom_ingredient_id',
                        'product_ingredient_id',
                        'ingredient_data_id'
                    )]: item.ingredient_data && item.ingredient_data.id,
                    net_amount: item.net_amount,
                    bruto_amount: item.bruto_amount,
                    cost_price: item.cost_price,
                    sales_price: item.sales_price,
                    show_percentage: item.show_percentage,
                    [productionService.getValueByIngredientType(
                        isCustom,
                        isProduct,
                        'production_unit_id',
                        'production_unit_id',
                        'production_unit'
                    )]: item.production_unit.id,
                    file_list: item.file_list,
                });
            } else if (item.hasUpdated) {
                toUpdate.push({
                    id: item.id,
                    [productionService.getValueByIngredientType(
                        isCustom,
                        isProduct,
                        'custom_ingredient_id',
                        'product_ingredient_id',
                        'ingredient_data_id'
                    )]: item.ingredient_data && item.ingredient_data.id,
                    net_amount: item.net_amount,
                    bruto_amount: item.bruto_amount,
                    cost_price: item.cost_price,
                    sales_price: item.sales_price,
                    show_percentage: item.show_percentage,
                    [productionService.getValueByIngredientType(
                        isCustom,
                        isProduct,
                        'production_unit_id',
                        'production_unit_id',
                        'production_unit'
                    )]: item.production_unit.id,
                    file_list: item.file_list,
                });
            }
        }
    });

    const requests = [];
    if (toCreate.length) {
        const createFunction = productionService.getValueByIngredientType(
            isCustom,
            isProduct,
            'setCustomIngredients',
            'setProductIngredients',
            'setIngredients'
        );
        requests.push(
            productionService[createFunction](productId, !isCustom && !isProduct ? { ingredient: toCreate } : toCreate)
        );
    }

    if (toUpdate.length) {
        const updateFunction = productionService.getValueByIngredientType(
            isCustom,
            isProduct,
            'updateCustomIngredient',
            'updateProductIngredient',
            'updateIngredient'
        );
        requests.push(
            productionService[updateFunction](productId, !isCustom && !isProduct ? { ingredients: toUpdate } : toUpdate)
        );
    }

    if (requests.length) {
        return Promise.all(requests).then((res) => {
            let data = [];

            res.forEach((item) => {
                if (item.length) {
                    data.push(...item);
                } else {
                    data.push(item);
                }
            });
            if (isCustom) {
                data = getCustomIngredientModel(data);
            } else if (isProduct) {
                data = getProductIngredientModel(data);
            }
            commit(UPDATE_INGREDIENTS, {
                productId,
                ingredientsList: data,
            });

            return res;
        });
    }
}

export function getCustomIngredientModel(data) {
    return data.map((i) => ({
        created_at: i.created_at,
        id: i.id,
        net_amount: i.net_amount,
        bruto_amount: i.bruto_amount,
        production_unit: i.production_unit,
        production_unit_id: i.production_unit && i.production_unit.id,
        updated_at: i.updated_at,
        ingredient_data: i.custom_ingredient,
        percentage: i.percentage,
        custom: true,
        components: i.custom_ingredient && i.custom_ingredient.components,
        cost_price: i.cost_price,
        share: i.share,
        file_list: i.custom_ingredient?.file_list,
    }));
}

export function getProductIngredientModel(data) {
    return data.map((i) => ({
        created_at: i.created_at,
        id: i.id,
        net_amount: i.net_amount,
        bruto_amount: i.bruto_amount,
        production_unit: i.production_unit,
        production_unit_id: i.production_unit && i.production_unit.id,
        cost_price: i.cost_price,
        sales_price: i.sales_price,
        updated_at: i.updated_at,
        ingredient_data: i.sub_product,
        custom: false,
        product: true,
        file_list: i.sub_product && i.sub_product.file_list,
    }));
}

export function deleteIngredientByTypeFn(commit, productId, ingredientId) {
    if (productId && ingredientId) {
        return productionService.deleteIngredient(productId, ingredientId).then(
            () => {
                commit(DELETE_INGREDIENT, {
                    productId,
                    ingredientId,
                });
                return true;
            },
            () => {
                vm.$toastr.e(vm.$t('error'));
            }
        );
    }
}

export function addDefaultNamesToCustomIngredient(customIngredient) {
    return {
        ...customIngredient,
        name: getAllNames(customIngredient.name),
        alt_names: getAllNames(customIngredient.alt_names),
    };
}

export function deleteCustomIngredientAttachments(attachments) {
    return Promise.all(
        attachments.map(({ customIngredientId, attachmentId }) =>
            productionService.deleteCustomIngredientAttachment(customIngredientId, attachmentId)
        )
    );
}

export function updateCustomIngredient(customIngredient) {
    return productionService.updateCustomIngredientData(addDefaultNamesToCustomIngredient(customIngredient));
}
