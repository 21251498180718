import { TimeUnit } from '@common/types/TimeUnit';

export class Frequency {
    public id?: number;
    public name: string;
    public taskSubtypeId: number;
    public taskFrequencyCnt: number;
    public timeUnitCnt: number;
    public timeUnit?: TimeUnit;

    constructor(data?: any) {
        this.id = data?.id;
        this.name = data?.name;
        this.taskSubtypeId = data?.taskSubtypeId;
        this.taskFrequencyCnt = data?.taskFrequencyCnt;
        this.timeUnitCnt = data?.timeUnitCnt;
        this.timeUnit = data?.timeUnit;
    }

    static fromJSON(json: any) {
        return new Frequency({
            id: json.id,
            name: json.name,
            taskSubtypeId: json.task_subtype_id,
            taskFrequencyCnt: json.task_frequency_cnt,
            timeUnitCnt: json.time_unit_cnt,
            timeUnit: json.time_unit ? TimeUnit.fromJSON(json.time_unit) : undefined,
        });
    }

    toJSON() {
        const json = {
            name: this.name,
            task_subtype_id: this.taskSubtypeId,
            task_frequency_cnt: this.taskFrequencyCnt,
            time_unit_cnt: this.timeUnitCnt,
        };

        this.id ? (json['id'] = this.id) : null;

        return json;
    }
}
