<template>
    <Transition name="jump-down">
        <BProgress
            v-show="show"
            :value="percent"
            max="100"
            height="0.8rem"
            class="w-100"
        />
    </Transition>
</template>

<script>
import { BProgress } from 'bootstrap-vue';

export default {
    name: 'ProgressBar',

    components: {
        BProgress,
    },

    props: {
        percent: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        afterLeave() {
            this.$emit('after-leave');
        },
    },
};
</script>
