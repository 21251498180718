import Vue from 'vue';
import http from '@http';
import moment from 'moment';
import { isUndefined } from '@services/helper.service';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { DateValue } from '@common/types';

function initState() {
    return {
        items: {},
    };
}

const getters = {
    getTask: (state) => (id) => state.items[id],
    tasks: (state) => Object.keys(state.items).map((id) => state.items[id]),
};

const mutations = {
    add(state, payload) {
        Vue.set(state.items, payload.id, payload);
    },
    set(state, payload) {
        Vue.set(state.items, payload.id, payload);
    },
    remove(state, id) {
        Vue.delete(state.items, id);
    },
    reset(storeState) {
        const s = initState();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    confirm({ rootGetters }) {
        return http.post(`/api/place/${rootGetters.selectedPlaceId}/tasks/confirm`);
    },
    async getAll({ commit, rootGetters, getters }) {
        try {
            if (!rootGetters.selectedPlaceId) {
                throw new Error('Cannot get placeId');
            }

            const response = await http.get(`/api/place/${rootGetters.selectedPlaceId}/tasks`);
            response.data.forEach((task) => {
                commit('add', fromJSON(task));
            });

            return getters.tasks;
        } catch (e) {
            console.error(e);
        }
    },
    async get({ commit, rootGetters, getters }, id) {
        try {
            if (!rootGetters.selectedPlaceId) {
                throw new Error('Cannot get placeId');
            }

            const response = await http.get(`/api/place/${rootGetters.selectedPlaceId}/tasks/${id}`);
            commit('set', fromJSON(response.data));

            return getters.getTask(id);
        } catch (error) {
            // TODO: Add message for the user
            console.error(error);
        }
    },
    async create({ commit, rootGetters }, payload) {
        try {
            const response = await http.post(`/api/place/${rootGetters.selectedPlaceId}/tasks`, toJSON(payload));
            const data = fromJSON(response.data);
            commit('add', data);

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async update({ commit, rootGetters }, payload) {
        try {
            commit(START_VIEW_LOADER, null, { root: true });
            const response = await http.put(
                `/api/place/${rootGetters.selectedPlaceId}/tasks/${payload.id}`,
                toJSON(payload)
            );
            const data = fromJSON(response.data);

            if (payload.isDefault) {
                commit('remove', payload.id);
            }

            commit('set', data);

            return data;
        } catch (error) {
            console.error(error);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
    async duplicate({ commit, rootGetters }, payload) {
        try {
            commit(START_VIEW_LOADER, null, { root: true });
            const response = await http.put(
                `/api/place/${rootGetters.selectedPlaceId}/tasks/${payload.id}?create_new_version=true`,
                toJSON(payload)
            );
            const data = fromJSON(response.data);

            commit('set', data);
            commit('remove', data.prevVersionId);

            return data;
        } catch (error) {
            console.error(error);
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
    async isNewVersionRequired({ commit, rootGetters }, payload) {
        try {
            commit(START_VIEW_LOADER, null, { root: true });
            const response = await http.post(
                `/api/place/${rootGetters.selectedPlaceId}/tasks/${payload.id}/is-new-version-required`,
                toJSON(payload)
            );
            return response.data;
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
    reorder({ commit, dispatch }, arr) {
        return Promise.all(
            arr.map((item) => {
                commit('set', item);
                return dispatch('update', {
                    id: item.id,
                    orderNumber: item.orderNumber,
                });
            })
        );
    },
    async toggleUsage({ dispatch, getters }, id) {
        const task = getters.getTask(id);

        dispatch('update', {
            id: task.id,
            isActive: !task.isActive,
        });
    },
};

export default {
    state: initState,
    getters,
    mutations,
    actions,
    namespaced: true,
};

function fromJSON(json) {
    function formatStartTime(time) {
        return time ? new DateValue(time).requestDateTimeFormat : null;
    }

    const res = {
        id: json.id,
        name: json.name,
        isDefault: json.is_default,
        isActive: !json.is_disabled,
        instructionText: json.instruction_text,
        instructionSummaryText: json.instruction_summary_text,
        orderNumber: json.order_number,
        relatedType: json.related_to,
        sameTaskForAll: !!json.same_task_for_all,
        sameFrequencyForTasks: json.same_freq_for_all,
        sameRoleForTasks: json.same_role_for_all,
        verificationReq: json.is_verification_enabled,
        verificationResponsible: json.verifier_user_group_id,
        verificationNotificationFrequency: json.verification_notification_frequency_type_id,
        prevVersionId: json.prev_version_id,
        newVersionCreatedAt: json.new_version_created_at,
        createdBy: json.created_by,
        createdAt: json.created_at,
        hasEntries: !!json.has_entries,
        responsible:
            json.responsible_roles?.map((item) => ({
                name: item.name,
            })) || [],
        notify:
            json.notify_roles?.map((item) => ({
                name: item.name,
            })) || [],
        tasksCount: json.count_tasks || 0,
        isForm: !json.is_checklist,
        updatedAt: new DateValue(json.updated_at).requestDateFormat,
        tasks: json.tasks?.map((taskJson) => ({
            id: taskJson.id,
            name: taskJson.task_desc,
            orderNumber: taskJson.order_number,
            frequency: taskJson.task_frequency_type,
            responsible: taskJson.user_group?.id,
            allowCustom: taskJson.allow_not_done,
            customFrequency: {
                repeatEveryUnitId: taskJson.custom_frequency?.custom_frequency_type_id || null,
                repeatEvery: taskJson.custom_frequency?.task_frequency_cnt || 1,
                times: taskJson.custom_frequency?.times || [],
                weekDays: taskJson.custom_frequency?.days_of_week || [],
                startDateTime: formatStartTime(taskJson.custom_frequency?.start_time),
                repeatOn: taskJson.custom_frequency?.repeat_on || null,
            },
            taskFrequencyTypeId: taskJson.task_frequency_type_id,
            correctiveActions: taskJson.corrective_action
                ? correctiveActionsFromJSON(taskJson.corrective_action)
                : null,
            taskSteps:
                taskJson.task_steps?.map((step) => ({
                    id: step.id,
                    name: step.name,
                    orderNumber: step.order_number,
                    timerNotifyBeforeMinutes: step.timer_notify_before_minutes,
                    timerHours: step.timer_hours,
                    typeId: step.task_id,
                    sensor: step.sensor_id,
                    type: step.task_step_type_name,
                    entryMinValue: step.entry_min_value,
                    entryMaxValue: step.entry_max_value,
                    customUnit: step.custom_unit,
                    allowCustom: step.allow_custom_value_ind,
                    userGroupId: step.user_group_id,
                    options:
                        step.task_step_options?.map((option) => ({
                            id: option.id,
                            text: option.text,
                            orderNumber: option.order_number,
                        })) || [],
                })) || [],
        })),
    };

    res.relatedEntities = {};
    json.related_entities?.forEach((entity) => {
        res.relatedEntities[entity.id] = {
            id: entity.id,
            name: entity.name,
            isActive: !entity.is_disabled,
            instructionText: entity.instruction_text,
            instructionSummaryText: entity.instruction_summary_text,
            tasks: entity.tasks?.map((taskJson) => ({
                id: taskJson.id,
                name: taskJson.task_desc,
                orderNumber: taskJson.order_number,
                frequency: taskJson.task_frequency_type,
                responsible: taskJson.user_group?.id,
                customFrequency: {
                    repeatEveryUnitId: taskJson.custom_frequency?.custom_frequency_type_id || null,
                    repeatEvery: taskJson.custom_frequency?.task_frequency_cnt || 1,
                    times: taskJson.custom_frequency?.times || [],
                    weekDays: taskJson.custom_frequency?.days_of_week || [],
                    startDateTime: formatStartTime(taskJson.custom_frequency?.start_time),
                    repeatOn: taskJson.custom_frequency?.repeat_on || null,
                },
                allowCustom: taskJson.allow_not_done,
            })),
        };
    });

    if (res.sameFrequencyForTasks) {
        const getFirstRelatedEntity = res.relatedEntities[Object.keys(res.relatedEntities)[0]];
        const firstTask = res.tasks.length ? res.tasks[0] : getFirstRelatedEntity.tasks[0];
        res.frequency = firstTask.frequency;
        res.customFrequency = firstTask.customFrequency;
    }

    if (res.sameRoleForTasks) {
        res.responsibleRole = json.responsible_roles;
    }

    return res;
}

function toJSON(data) {
    const res = {
        id: data.id,
        name: data.name,
        instruction_text: data.instructionText,
        instruction_summary_text: data.instructionSummaryText,
        same_freq_for_all: data.sameFrequencyForTasks,
        same_role_for_all: data.sameRoleForTasks,
        is_verification_enabled: data.verificationReq,
        verifier_user_group_id: data.verificationResponsible,
        verification_notification_frequency_type_id: data.verificationNotificationFrequency,
    };

    if (!isUndefined(data.orderNumber)) {
        res.order_number = data.orderNumber;
    }
    if (!isUndefined(data.relatedType)) {
        res.related_to = data.relatedType;
    }
    if (!isUndefined(data.notify)) {
        res.notify_roles = data.notify?.map((item) => ({ name: item.name })) || [];
    }
    if (!isUndefined(data.isDefault)) {
        res.is_default = data.isDefault;
    }
    if (!isUndefined(data.sameTaskForAll) || !isUndefined(data.isForm)) {
        res.same_task_for_all = data.sameTaskForAll || data.isForm;
    }
    if (!isUndefined(data.isForm)) {
        res.is_checklist = !data.isForm;
    }
    if (!isUndefined(data.isActive)) {
        res.is_disabled = !data.isActive;
    }

    if (data.relatedType !== 'none' && data.relatedEntities) {
        res.related_entities =
            Object.keys(data.relatedEntities)
                ?.filter((key) => data.relatedEntities[key].isActive)
                .map((key) => {
                    const entity = data.relatedEntities[key];
                    const res = {
                        id: entity.id,
                        name: entity.name,
                        is_disabled: !entity.isActive,
                    };

                    if (!data.sameTaskForAll) {
                        res.tasks = entity.tasks?.map(tasksMap);
                    }

                    return res;
                }) || [];
    }

    if (res.same_task_for_all || data.relatedType === 'none') {
        res.tasks = data.tasks?.map(tasksMap) || [];
    }

    res.prev_version_id = data.prevVersionId;
    res.new_version_created_at = data.newVersionCreatedAt;
    return res;

    function tasksMap(task) {
        const customFrequency = data.sameFrequencyForTasks ? data.customFrequency : task.customFrequency;
        const responsibleRole = data.sameRoleForTasks ? data.responsibleRole : task.responsible;

        const res = {
            id: task.id,
            task_desc: task.name,
            order_number: task.orderNumber,
            task_frequency_type: data.sameFrequencyForTasks ? data.frequency : task.frequency,
            instruction_text: task.instruction_text,
            instruction_summary_text: task.instruction_summary_text,
            custom_frequency: {
                custom_frequency_type_id: customFrequency?.repeatEveryUnitId,
                task_frequency_cnt: customFrequency?.repeatEvery,
                times: customFrequency?.times || [],
                days_of_week: customFrequency?.weekDays || [],
                start_time: moment(customFrequency?.startDateTime).format('YYYY-MM-DD 00:00:00'),
                repeat_on: customFrequency?.repeatOn || null,
            },
            allow_not_done: task.allowCustom,
            user_group: responsibleRole ? { id: responsibleRole } : null,
            corrective_action: task.correctiveActions ? correctiveActionsToJSON(task.correctiveActions) : null,
        };

        if (data.isForm) {
            res.task_steps =
                task.taskSteps?.map((step) => ({
                    id: step.id,
                    name: step.name,
                    order_number: step.orderNumber,
                    timer_notify_before_minutes: step.timerNotifyBeforeMinutes,
                    timer_hours: step.timerHours,
                    sensor_id: step.sensor,
                    task_step_type: step.type,
                    entry_min_value: step.entryMinValue,
                    entry_max_value: step.entryMaxValue,
                    custom_unit: step.customUnit,
                    allow_custom_value_ind: step.allowCustom,
                    user_group_id: step.userGroupId,
                    task_step_options:
                        step.options?.map((option) => ({
                            id: option.id,
                            text: option.text,
                            order_number: option.orderNumber,
                        })) || [],
                })) || [];
        }

        return res;
    }
}

export function correctiveActionsFromJSON(json) {
    return {
        text: json?.action_text,
        allowCustom: json?.custom_options_enabled,
        options: json?.options?.map((option) => ({
            id: option.id,
            text: option.option_text,
            custom: option.is_custom,
        })),
    };
}

function correctiveActionsToJSON(value) {
    return {
        action_text: value?.text,
        custom_options_enabled: value?.allowCustom,
        options: value?.options
            ?.filter((option) => !option.custom)
            ?.map((option) => ({
                ...option,
                option_text: option.text,
            })),
    };
}
