<template>
    <nav class="navbar row d-flex justify-content-between align-items-center w-100">
        <div class="d-flex justify-content-between justify-content-md-start align-items-center">
            <span
                class="cursor-pointer"
                @click="redirectToLandingPage"
            >
                <Logo responsive />
            </span>
            <LanguageSwitcher
                v-if="!logoOnly"
                position="dropdown-menu position-absolute"
                class="d-inline-block ml-3"
            />
        </div>

        <RouterLink
            v-if="isCreateNew && redirectTo && !isCreatePlace"
            :to="{ name: redirectTo }"
            data-cy="close"
            @click.native="restoreCompanyAndPlace"
        >
            <i class="fd-close"></i>
        </RouterLink>

        <div
            v-else-if="hasProfile"
            class="btn-group"
        >
            <a
                id="profile"
                aria-expanded="false"
                aria-haspopup="true"
                class="d-flex align-items-center cursor-pointer user-logo"
                data-toggle="dropdown"
            >
                <i class="fd-user-circle mr-1"></i>
                <span
                    id="profile-name"
                    class="text-dark mr-1"
                    >{{ getUserDisplayName }}</span
                >
                <i class="fd-chevron-down font-size-sm"></i>
            </a>
            <div
                id="nav-menu"
                class="dropdown-menu dropdown-menu-right"
            >
                <button
                    class="dropdown-item d-flex justify-content-between align-items-center"
                    type="button"
                    @click="logout"
                >
                    <div class="d-inline-block">{{ $t('log_out') }}</div>
                    <i class="fd-sign-out pl-2"></i>
                </button>
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from '@components/Logo';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { RESTORE_COMPANY_AND_PLACE } from '@store/company/constants';
import { mapGetters } from 'vuex';
import { authMixin } from '@views/Auth/mixins/auth.mixin';

import MobileAppService from '@services/mobile-app.service';

export default {
    components: {
        Logo,
        LanguageSwitcher,
    },

    mixins: [authMixin],

    props: { logoOnly: Boolean },

    computed: {
        ...mapGetters(['getUserDisplayName', 'hasProfile']),
        isCreateNew() {
            return (
                (this.$route.params && this.$route.params.isCreateNew) ||
                (this.$route.meta && this.$route.meta.isCreateNew)
            );
        },
        redirectTo() {
            return (
                (this.$route.params && this.$route.params.redirectTo) ||
                (this.$route.meta && this.$route.meta.redirectTo)
            );
        },
        isCreatePlace() {
            return this.$route && this.$route.name === 'create.place';
        },
    },

    methods: {
        restoreCompanyAndPlace() {
            this.$store.dispatch(RESTORE_COMPANY_AND_PLACE);
        },
        logout() {
            this.$store.dispatch('auth/logout');
        },
        navigateToLogin() {
            MobileAppService.emitTokenCallback();

            this.$router.push({ name: 'login' });
        },
    },
};
</script>
