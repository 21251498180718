import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const state = {
    types: [],
    devices: [],

    isInited: false,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
