import http from '@http';
import { vm } from '@/main';
import { download } from '@services/helper.service';

class FileService {
    downloadFile(file) {
        const url = `/api/document-file/${file.id}`;
        return http.get(url, { responseType: 'blob' }).then((res) => {
            if (res) {
                download(file.filename, res.data, res.data.type);
            }
        });
    }

    downloadLocalFile(name, url) {
        this.setupForDownload(name, url);
    }

    setupForDownload(name, url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getFileBase64(fileId) {
        return http
            .get(`/api/document-file/encoded/${fileId}`)
            .then((res) => res.data)
            .catch(() => {
                vm && vm.$toastr && vm.$toastr.e('File not found! ' + vm.$t('error_occurred'));

                return Promise.reject();
            });
    }

    deleteFile(fileId) {
        return http.delete(`/api/vta_docs/document_file/${fileId}`, {}).then((res) => res.data);
    }

    uploadFile(placeId, type, body, config) {
        return http.post(`/api/vta_docs/document_file/${placeId}/${type}`, body, config).then((res) => res.data);
    }

    getFilesFoodDeviceFiles(placeId, deviceId) {
        return http
            .get(`/api/vta_docs/document_file/${placeId}/subject/foodDevice/${deviceId}`)
            .then((res) => res.data);
    }
}

export default new FileService();
