<template>
    <BaseModal
        ref="modal"
        centered
        hide-footer
        size="md"
        modal-class="refreshModal"
        hide-header
        no-close
    >
        <div class="text-center d-flex flex-column align-items-center space-unrelated-out">
            <img
                class="space-related-out"
                src="/img/fd_clean.svg"
                alt="Cleaning hand"
            />
            <div class="h2 space-related-out">{{ $t('FoodDocs has probably been updated in the background.') }}</div>
            <div class="space-related-out-x">
                {{ $t('Please refresh the page.') }}
            </div>
            <div class="space-related-out-b font-weight-bold d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-primary space-related-out text-uppercase"
                    @click="reloadPage"
                >
                    {{ $t('Refresh now') }}
                </button>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from '@components/BaseModal';

export default {
    name: 'NeedRefreshModal',

    components: { BaseModal },

    methods: {
        reloadPage() {
            location.reload(true);
        },
        show() {
            this.$refs.modal?.show();
        },
    },
};
</script>

<style lang="scss">
.refreshModal {
    .modal-content {
        border: 2px solid $red;
    }
}
</style>
