import { ADD_PLACE_INFO } from '@store/company/place/constants';
import { UPDATE_COMPANY } from '@store/company/constants';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';

function state() {}

const getters = {};

const mutations = {};

const actions = {
    update({ commit, dispatch }, { place, company }) {
        const req = [];
        commit(START_VIEW_LOADER, null, { root: true });

        // TODO: do not manage API data structure inside component
        if (company) {
            const data = company;

            // @ts-ignore
            req.push(dispatch(UPDATE_COMPANY, { companyId: company.id, data }, { root: true }));
        }

        if (place) {
            const data = {
                placeData: place,
            };

            req.push(
                // @ts-ignore
                dispatch(ADD_PLACE_INFO, { placeId: place.id, data }, { root: true }).then(() => {
                    dispatch('plan/getStates', null, { root: true });
                })
            );
        }

        return Promise.all(req).finally(() => {
            commit(STOP_VIEW_LOADER, null, { root: true });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
