<template>
    <Component
        :is="component"
        :ref="component === 'a' ? 'noopener noreferer' : null"
        class="btn"
        :class="classList"
        :type="type"
        :disabled="disabled"
        :to="to"
        :href="href"
        :target="component === 'a' ? '_blank' : null"
        @click="onClick"
    >
        <slot />
    </Component>
</template>

<script>
export default {
    name: 'Action',

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        download: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        theme: {
            validator: (x) => ['button', 'link', 'icon'].includes(x),
            default: 'button',
        },
        to: {
            type: [String, Object],
            default: null,
        },
        type: {
            type: String,
            default: 'button',
        },
    },

    computed: {
        component() {
            if (this.href) {
                return 'a';
            }
            if (this.to) {
                return 'router-link';
            }
            return 'button';
        },
        classList() {
            return {
                'btn-primary': true,
                'btn-link': this.theme === 'link',
                'btn-icon': this.theme === 'icon',
            };
        },
    },

    methods: {
        onClick(event) {
            if (this.loading === true) {
                return;
            }
            this.$emit('click', event);
        },
    },
};
</script>

<style scoped lang="scss">
.btn-icon {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    cursor: pointer;
    background-color: #0000;
    border: 0;
    border-radius: 50%;
    transition: background-color 200ms ease-out;

    svg {
        width: 100%;
        height: 100%;
    }
}
</style>
