import { TaskStep } from '@common/types/TaskStep';

export class TeamTask {
    public id?: number;
    public isDefault: boolean;
    public isDisabled: boolean;
    public taskDesc?: string;
    public taskSubtypeId: number;
    public userGroupId: number;
    public taskFrequencyTypeId: number;
    public customFrequency: null;
    public taskSteps: TaskStep[];
    public isEdit: boolean;

    constructor(data?: any) {
        this.id = data ? data.id : null;
        this.isDefault = data ? data.isDefault : false;
        this.isDisabled = !!data?.isDisabled;
        this.taskDesc = data ? data.taskDesc : '';
        this.taskSubtypeId = data?.taskSubtypeId;
        this.userGroupId = data?.userGroupId;
        this.taskFrequencyTypeId = data?.taskFrequencyTypeId;
        this.customFrequency = data ? data.customFrequency : true;
        this.taskSteps = data ? data.taskSteps : [];
        this.isEdit = !data;
    }

    static fromJSON(json: any) {
        return new TeamTask({
            id: json.id,
            isDefault: json.is_default,
            isDisabled: json.is_disabled,
            taskDesc: json.task_desc,
            taskSubtypeId: json.task_subtype_id,
            userGroupId: json.user_group_id,
            taskFrequencyTypeId: json.task_frequency_type_id,
            customFrequency: json.custom_frequency,
            taskSteps: json.task_steps.map(TaskStep.fromJSON),
        });
    }

    toJSON() {
        const json = {
            is_default: this.isDefault,
            is_disabled: this.isDisabled,
            task_desc: this.taskDesc,
            task_subtype_id: this.taskSubtypeId,
            user_group_id: this.userGroupId,
            task_frequency_type_id: this.taskFrequencyTypeId,
            custom_frequency: this.customFrequency,
            task_steps: this.taskSteps.map((step) => step.toJSON()),
        };

        this.id ? (json['id'] = this.id) : null;
        if (this.isDefault) {
            delete json.task_desc;
        }

        return json;
    }
}
