export default [
    {
        path: '',
        name: 'traceability',
        redirect: { name: 'traceability.products.list' },
        component: () => import('./Index.vue'),
        children: [
            {
                path: 'products',
                name: 'traceability.products',
                component: () => import('./Products/Index.vue'),
                children: [
                    {
                        path: 'allergen-matrix',
                        name: 'traceability.products.matrix',
                        component: () => import('./Products/AllergenMatrix/Index.vue'),
                    },
                    {
                        path: 'all',
                        name: 'traceability.products.list',
                        component: () => import('./Products/ProductsList.vue'),
                    },
                    {
                        path: 'details',
                        name: 'traceability.products.details',
                        redirect: { name: 'traceability.products.details.general' },
                        component: () => import('./Products/ProductDetails/Index.vue'),
                        children: [
                            {
                                path: 'general',
                                name: 'traceability.products.details.general',
                                component: () => import('./Products/ProductDetails/ProductGeneralInfo.vue'),
                            },
                            {
                                path: 'production-info',
                                name: 'traceability.products.details.production-info',
                                component: () => import('./Products/ProductDetails/ProductProductionInfo.vue'),
                            },
                            {
                                path: 'consumer-info',
                                name: 'traceability.products.details.consumer-info',
                                component: () => import('./Products/ProductDetails/ProductConsumerInfo.vue'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'matrix',
                name: 'traceability.matrix',
                component: () => import('./Products/AllergenMatrix/Index.vue'),
            },
            {
                path: 'settings',
                name: 'traceability.settings',
                redirect: 'traceability.settings.units',
                component: () => import('./Settings/Index.vue'),
                children: [
                    {
                        path: 'units',
                        name: 'traceability.settings.units',
                        component: () => import('./Settings/CustomMeasuringUnits.vue'),
                    },
                ],
            },
        ],
    },
];
