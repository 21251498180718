import http from '@http';
import { download } from '@/common/services/helper.service';

class ProductionService {
    getAll(companyId, placeId) {
        if (!this.getAll.inProgress) {
            this.getAll.inProgress = {};
        }

        if (!this.getAll.inProgress[companyId]) {
            const request = http
                .get(`/api/module/production/company/${companyId}/${placeId}/products`)
                .then((res) => res.data)
                .finally(() => {
                    delete this.getAll.inProgress[companyId];
                });

            this.getAll.inProgress[companyId] = request;
        }

        return this.getAll.inProgress[companyId];
    }

    get(productId, selectedPlace) {
        return http
            .get(`/api/module/production/products/${productId}`, { params: { placeId: selectedPlace } })
            .then((res) => res.data);
    }

    create(companyId, data) {
        return http.post(`/api/module/production/company/${companyId}/product`, data).then((res) => res.data);
    }

    update(productId, data) {
        return http.put(`/api/module/production/products/${productId}`, data).then((res) => res.data);
    }

    duplicate(data) {
        return http.post('/api/module/production/products/duplicate', data).then((res) => res.data);
    }

    delete(id) {
        return http.delete(`/api/module/production/products/${id}`).then((res) => res.data);
    }

    searchIngredients(companyId, placeId, query, lang, productId) {
        return http
            .post(`/api/module/production/company/${companyId}/${placeId}/products/search`, {
                q: query,
                lang,
                productId,
            })
            .then((res) => res.data[0]);
    }

    setIngredients(productId, body) {
        return http.post(`/api/module/production/products/${productId}/ingredient`, body).then((res) => res.data);
    }

    updateIngredient(productId, body) {
        return http.put(`/api/module/production/products/${productId}/ingredients`, body).then((res) => res.data);
    }

    deleteIngredient(productId, ingredientId) {
        return http.delete(`/api/module/production/products/${productId}/ingredient/${ingredientId}`);
    }

    setEsubstance(productId, body) {
        return http.post(`/api/module/production/products/${productId}/esubstance`, body).then((res) => {
            return res.data;
        });
    }

    updateEsubstance(productId, body) {
        return http.put(`/api/module/production/products/${productId}/esubstances`, body).then((res) => {
            return res.data;
        });
    }

    deleteEsubstance(productId, esubstanceId) {
        return http.delete(`/api/module/production/products/${productId}/esubstance/${esubstanceId}`);
    }

    updatePreparation(productId, body) {
        return http.post(`/api/module/production/products/${productId}/preparation`, body).then((res) => {
            return res.data;
        });
    }

    updatePackaging(productId, body) {
        return http.post(`/api/module/production/products/${productId}/packaging`, body).then((res) => {
            return res.data;
        });
    }

    deleteAttachment(id) {
        return http.delete(`/api/module/production/attachment/${id}/delete`);
    }

    addImage(productId, file, config) {
        return http.post(`/api/module/production/product/${productId}/image`, file, config).then((res) => res.data);
    }

    addPackagingAttachment(packagingId, file, config) {
        return http
            .post(
                `/api/module/production/packaging/${packagingId}/certificate`,
                {
                    certificate_file: file.data,
                    certificate_file_name: file.name,
                },
                config
            )
            .then((res) => res.data);
    }

    createCustomIngredient(companyId, data) {
        return http.post(`/api/custom-ingredients/${companyId}`, data).then((res) => res.data);
    }

    getCustomIngredientByID(customIngredientId) {
        return http.get(`/api/custom-ingredients/${customIngredientId}`).then((res) => res.data);
    }

    setCustomIngredients(productId, ingredients) {
        return http
            .post(`/api/module/production/products/${productId}/products-custom-ingredients`, ingredients)
            .then((res) => res.data);
    }

    updateCustomIngredient(productId, ingredients) {
        return http
            .put(`/api/module/production/products/${productId}/products-custom-ingredients`, ingredients)
            .then((res) => res.data);
    }

    setProductIngredients(productId, ingredients) {
        return http
            .post(`/api/module/production/products/${productId}/products-product-ingredients`, ingredients)
            .then((res) => res.data);
    }

    updateProductIngredient(productId, ingredients) {
        return http
            .put(`/api/module/production/products/${productId}/products-product-ingredients`, ingredients)
            .then((res) => res.data);
    }

    updateCustomIngredientData(ingredient) {
        return http.put(`/api/custom-ingredients/${ingredient.id}`, ingredient).then((res) => res.data);
    }

    uploadIngredientAttachment(ingredientId, file, options) {
        return http
            .post(
                `/api/module/production/ingredient/${ingredientId}/certificate`,
                {
                    certificate_file: file.data,
                    certificate_file_name: file.name,
                },
                options
            )
            .then((res) => res.data);
    }

    uploadAdditiveAttachment(additiveId, file, options) {
        return http
            .post(
                `/api/module/production/esubstance/${additiveId}/certificate`,
                {
                    certificate_file: file.data,
                    certificate_file_name: file.name,
                },
                options
            )
            .then((res) => res.data);
    }

    deleteCustomIngredientAttachment(customIngredientId, attachmentId) {
        return http.delete(`/api/custom-ingredients/${customIngredientId}/${attachmentId}`);
    }

    downloadTechCardPDF(productId, lang = 'en') {
        return http.get(`/api/module/production/products/${productId}/tech-card?lang=${lang}`, {
            responseType: 'arraybuffer',
        });
    }

    downloadTechDescPDF(productId, lang = 'en') {
        return http.get(`/api/module/production/products/${productId}/tech-desc?lang=${lang}`, {
            responseType: 'arraybuffer',
        });
    }

    downloadPackageInfoPDF(productId, lang = 'en') {
        return http.get(`/api/module/production/products/${productId}/package-info?lang=${lang}`, {
            responseType: 'arraybuffer',
        });
    }

    downloadProductionSheetPDF(data, lang = 'en') {
        return http.get(
            `/api/module/production/products/${data.productId}/production-sheet?lang=${lang}&amount=${data.amount}&unitId=${data.unitId}`,
            {
                responseType: 'arraybuffer',
            }
        );
    }

    getWithToken(token) {
        return http.get(`/api/public/product/${token}`).then((res) => res.data);
    }

    getValueByIngredientType(isCustom, isProduct, customValue, productValue, defaultValue) {
        if (isCustom) {
            return customValue;
        }
        if (isProduct) {
            return productValue;
        }
        return defaultValue;
    }

    getCustomIngredientsList(companyId) {
        return http.get(`/api/custom-ingredients/company/${companyId}`).then((res) => res.data);
    }

    deleteCustomIngredient(id) {
        return http.delete(`/api/custom-ingredients/${id}`).then((res) => res.data);
    }

    getIngredientsWithPriceForCompany(companyId) {
        return http.get(`/api/module/production/company/${companyId}/ingredients`).then((res) => res.data);
    }

    searchIngredientsForAddingPrice(companyId, query) {
        return http
            .post(`/api/module/production/company/${companyId}/ingredients/search`, {
                q: query,
            })
            .then((res) => res.data);
    }

    addPriceToIngredient(companyId, data) {
        return http.post(`/api/module/production/company/${companyId}/ingredients`, data).then((res) => res.data);
    }

    updatePriceToIngredient(companyId, data) {
        return http.put(`/api/module/production/company/${companyId}/ingredients`, data).then((res) => res.data);
    }

    deletePriceToIngredient(companyId, ingredientId) {
        return http
            .delete(`/api/module/production/company/${companyId}/ingredients/${ingredientId}`)
            .then((res) => res.data);
    }

    downloadCSV(companyId, placeId, list) {
        return http
            .post(
                `/api/module/production/company/${companyId}/${placeId}/products/export-csv`,
                {
                    product_ids: list,
                },
                { responseType: 'blob' }
            )
            .then((res) => download('products.csv', res.data));
    }
    downloadIngredientsCSV(companyId, placeId, list) {
        return http
            .post(
                `/api/module/production/company/${companyId}/${placeId}/products/export-ingredients-csv`,
                {
                    product_ids: list,
                },
                { responseType: 'blob' }
            )
            .then((res) => download('product-ingredients.csv', res.data));
    }
}

export const productionService = new ProductionService();
