import store from '@/store';
import http from '@http';
import { vm } from '@main';
import { DateValue } from '@common/types';
import moment from 'moment';

const state = {
    pausePeriods: [],
    selected_place_id: +localStorage.getItem('selected_place_id') || null,
};

const mutations = {
    updatePeriods(state, data) {
        state.pausePeriods = data;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ dispatch }) {
            if (!store.getters['shared-access/isSharedAccess']) {
                dispatch('getSelectedPlacePausePeriods');
            }
        },
    },

    async getSelectedPlacePausePeriods({ rootGetters, commit }) {
        try {
            const { data } = await http.get(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods`);

            let periods = data?.reduce((p, c) => p.concat([{ ...c, editing: false }]), []);
            commit('updatePeriods', periods);
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async updatePausePeriodDetails({ rootGetters, commit, dispatch }, period) {
        try {
            const startDate =
                new DateValue(period.start_date).format('YYYY-MM-DD') === period.start_date
                    ? period.start_date
                    : new DateValue(moment(period.start_date, rootGetters.selectedCompanyDateFormat).toDate())
                          .requestDateFormat;
            const endDate =
                new DateValue(period.end_date).format('YYYY-MM-DD') === period.end_date
                    ? period.end_date
                    : new DateValue(moment(period.end_date, rootGetters.selectedCompanyDateFormat).toDate())
                          .requestDateFormat;
            const payload = {
                name: period.name,
                start_date: startDate,
                end_date: endDate,
            };
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${period.id}`,
                payload
            );

            commit('updatePeriods', { data });
            dispatch('getSelectedPlacePausePeriods');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async getPeriodDetails({ rootGetters }, periodId) {
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${periodId}`
            );
            return data;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async deletePausePeriod({ rootGetters, dispatch }, periodId) {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods/${periodId}`);
            dispatch('getSelectedPlacePausePeriods');
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },

    async addPeriod({ rootGetters, dispatch }, data) {
        try {
            const res = await http.post(`/api/places/${rootGetters.selectedPlaceId}/monitoring-pause-periods`, data);
            dispatch('getSelectedPlacePausePeriods');
            return res;
        } catch (error) {
            if (vm?.$toastr) {
                vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            }
        }
    },
};

const getters = {
    getSelectedPlacePausePeriods: (state) => {
        return state.pausePeriods;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
