import http from '@http';
import { mapToArray } from '@services/helper.service.js';
import { OverviewPercentage } from '../types';

class OverviewService {
    getCompanies(selectedCompanyId) {
        return http.get(`/api/overview?companyId=${selectedCompanyId}`).then((res) => {
            if (res && res.data) {
                res = res.data;

                if (res.hasGroups) {
                    return {
                        hasGroups: res.hasGroups,
                        grouped: mapToArray(res.data.grouped).map((i) => {
                            return {
                                ...i,
                                data: new OverviewPercentage({ ...i.data }),
                                places: mapToArray(i.places),
                            };
                        }),
                        ungrouped: mapToArray(res.data.ungrouped),
                    };
                } else {
                    return {
                        hasGroups: res.hasGroups,
                        companies: mapToArray(res.data).map((i) => {
                            return {
                                ...i,
                                places: mapToArray(i.places),
                            };
                        }),
                    };
                }
            }

            return Promise.reject();
        });
    }

    statusColor(percent) {
        if (percent) {
            if (percent < 50) {
                return 'text-danger';
            } else if (percent >= 50 && percent < 100) {
                return 'text-warning';
            } else if (percent >= 100) {
                return 'text-primary';
            }
        } else {
            return 'text-secondary';
        }
    }
}

export default new OverviewService();
