<template>
    <div
        v-click-outside="closeDropdownOnClickOutside"
        @mouseleave="showDropdown = false"
    >
        <div
            class="multiselect-wrapper"
            @click.stop="openDropdown"
        >
            <div class="multiselect">
                <div class="multiselect__custom-select">
                    <i
                        :class="`fd-chevron-${showDropdown ? 'up' : 'down'}`"
                        @click.stop="closeDropdown"
                    ></i>
                </div>
                <div class="multiselect__tags">
                    <input
                        v-show="showDropdown"
                        ref="searchBox"
                        v-model="search"
                        autocomplete="off"
                        class="multiselect__input"
                        :placeholder="$t('Search')"
                        type="text"
                    />
                    <span
                        v-if="!showDropdown"
                        class="multiselect__single"
                    >
                        {{ selectedPlace.name }}
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="showDropdown"
            class="dropdown"
        >
            <div
                v-for="(company, index) in companies"
                :key="company.id"
                :class="companies.length - 1 !== index && 'mb-3'"
            >
                <div
                    class="font-weight-bold company-name"
                    :class="index === 0 && 'first'"
                >
                    {{ company.name }}
                </div>
                <div
                    v-for="place in company.places"
                    :key="place.id"
                    class="place"
                    :class="selectedPlace.id === place.id && 'selected'"
                    @click="choosePlace(company.id, place.id)"
                >
                    <span>{{ place.name }}</span>
                </div>
            </div>
            <span
                v-if="!companies.length"
                class="ml-2"
            >
                {{ $t('No such company was found') }}
            </span>
        </div>
    </div>
</template>

<script>
import { SET_SELECTED_PLACE } from '@store/company/place/constants';
import { SET_SELECTED_COMPANY } from '@store/company/constants';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'SelectEstablishment',

    props: {
        selectedCompany: {
            type: Object,
            required: true,
        },
        selectedPlace: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            showDropdown: false,
            search: '',
        };
    },

    computed: {
        companiesWithPlaces() {
            const companies = cloneDeep(this.$store.getters.companiesWithPlaces);
            const selected = companies.findIndex((company) => company.id === this.selectedCompany.id);
            if (selected !== -1) {
                companies.unshift(companies.splice(selected, 1)[0]);
            }
            return companies;
        },
        companies() {
            if (!this?.search?.length) {
                return this.companiesWithPlaces;
            }
            const query = this.search.toLowerCase();
            let companies = [];

            this.companiesWithPlaces.forEach((company) => {
                if (company.name.toLocaleLowerCase().includes(query)) {
                    companies.push(company);
                } else {
                    const places = company.places.filter((place) => place.name.toLowerCase().includes(query));
                    if (places.length) {
                        companies.push({ ...company, places });
                    }
                }
            });

            return companies;
        },
    },

    methods: {
        openDropdown() {
            this.showDropdown = true;
            this.search = '';
            this.$nextTick(() => {
                if (this.$refs.searchBox) {
                    this.$refs.searchBox.focus();
                }
            });
        },
        closeDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        closeDropdownOnClickOutside() {
            // this.showDropdown = this.showDropdown || true;
            if (this.showDropdown) {
                this.showDropdown = false;
            }
        },
        async choosePlace(companyId, placeId) {
            if (companyId !== this.selectedCompany.id) {
                await this.$store.dispatch(SET_SELECTED_COMPANY, companyId);
            }
            if (placeId !== this.selectedPlace.id) {
                await this.$store.dispatch(SET_SELECTED_PLACE, placeId);
            }
            this.showDropdown = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.multiselect-wrapper {
    z-index: 9999999;
    width: 13rem !important;
}

.dropdown {
    position: absolute;
    z-index: 9999999;
    width: 20rem;
    max-height: 30rem;
    padding: 1rem 0.5rem;
    overflow: scroll;
    overflow-x: hidden;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 2px 20px rgba(134, 134, 134, 0.3);

    .company-name {
        margin: 2rem 1.1rem 0 1.1rem;
        font-size: 0.75rem;
        font-weight: 400 !important;
        color: $gray-500;
        text-transform: uppercase;

        &.first {
            margin-top: 0 !important;
        }
    }

    .place {
        padding: 0.5rem 1rem;
        margin: 0.1rem;
        font-size: 1rem !important;
        border: 1px solid transparent;
        border-radius: 6px;

        &:hover {
            cursor: pointer;
            border: 1px solid black;
        }

        &.selected {
            background-color: $gray-200;
        }
    }

    @include media-breakpoint-down(md) {
        right: 10%;
        left: 10%;
        width: auto;
    }
}

.multiselect__tags {
    border: none !important;
}
</style>
