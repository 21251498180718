import moment from 'moment';

export interface ISharedItem {
    name: string;
    email: string;
    modules: Array<string>;
    validUntil: Date;
    createdAt?: Date;
    token?: string;
    lang?: string;
}

export class SharedItem implements ISharedItem {
    name = '';
    email = '';
    modules = new Array<string>();
    validUntil = new Date();
    createdAt;
    token;
    lang;

    constructor(data: ISharedItem) {
        this.name = data.name;
        this.email = data.email;
        this.modules = data.modules;
        this.createdAt = data.createdAt;
        this.validUntil = data.validUntil;
        this.token = data.token;
        this.lang = data.lang;
    }

    toJSON() {
        return {
            name: this.name,
            email: this.email,
            valid_date: moment(this.validUntil).format('YYYY-MM-DD'),
            modules: this.modules,
        };
    }

    static fromJSON(json: any): SharedItem {
        return new SharedItem({
            name: json.name,
            email: json.email,
            modules: json.modules,
            token: json.token,
            createdAt: moment(json.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
            validUntil: moment(json.valid_date, 'YYYY-MM-DD').toDate(),
            lang: json.lang,
        });
    }
}
