const Audits = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits');

const AuditList = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Audit');
const EditAudit = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Audit/Edit');
const ShowAudit = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Audit/View');

const AuditTemplates = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Template');
const EditAuditTemplate = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Template/Edit');
const ShowTemplate = () => import(/* webpackChunkName: "audits-module" */ '@views/Audits/views/Template/View');

export default [
    {
        path: '/audits',
        components: { base: Audits },
        children: [
            {
                path: '',
                components: { audit: AuditList },
                meta: { auditNav: true },
                name: 'audits.list',
            },
            {
                path: 'templates/:audit_template_id/audit/create',
                components: { audit: EditAudit },
                meta: { noNav: true, isSave: true, auditNav: false, root: '/audits' },
                name: 'audits.create',
            },
            {
                path: ':audit_result_id/edit',
                components: { audit: EditAudit },
                meta: { noNav: true, isSave: true, auditNav: false, root: '/audits' },
                name: 'audits.edit',
            },
            {
                path: ':audit_result_id/show',
                components: { audit: ShowAudit },
                meta: { noNav: true, auditNav: false, root: '/audits' },
                name: 'audits.show',
            },
            {
                path: 'templates',
                components: { audit: AuditTemplates },
                meta: { auditNav: true },
                name: 'audits.templates.list',
            },
            {
                path: 'templates/create',
                components: { audit: EditAuditTemplate },
                meta: { noNav: true, isSave: true, auditNav: false, root: '/audits/templates' },
                name: 'audits.templates.create',
            },
            {
                path: 'templates/:audit_template_id/edit',
                components: { audit: EditAuditTemplate },
                meta: { noNav: true, isSave: true, auditNav: false, root: '/audits/templates' },
                name: 'audits.templates.edit',
            },
            {
                path: 'templates/:audit_template_id/show',
                components: { audit: ShowTemplate },
                meta: { noNav: true, isSave: false, auditNav: false, root: '/audits/templates' },
                name: 'audits.templates.show',
            },
        ],
    },
];
