<template>
    <div class="row">
        <div class="offset-md-5 col-md-7 text-center">
            <h2>{{ $t('changing_name') }}</h2>
        </div>
        <form
            class="col-12"
            @submit.prevent="changeName"
        >
            <div
                class="form-group row"
                :class="{ 'has-feedback has-error': $v.firstName.$error }"
            >
                <label
                    :for="'firstName'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('first_name') }}</label
                >
                <div class="col-md-7">
                    <FdInput
                        id="firstName"
                        v-model="$v.firstName.$model"
                        :validation="$v.firstName"
                    />
                </div>
            </div>

            <div
                class="form-group row"
                :class="{ 'has-feedback has-error': $v.lastName.$error }"
            >
                <label
                    :for="'lastName'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('last_name') }}</label
                >
                <div class="col-md-7">
                    <FdInput
                        id="lastName"
                        v-model.trim="$v.lastName.$model"
                        :validation="$v.lastName"
                    />
                </div>
            </div>

            <div class="form-group row">
                <div class="offset-md-5 col-md-7">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { CHANGE_NAME } from '@store/profile/constants';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
        };
    },

    validations: {
        firstName: {
            required,
        },
        lastName: {
            required,
        },
    },

    computed: {
        ...mapGetters(['userProfile']),
    },

    watch: {
        userProfile: function (profile) {
            this.setName(profile);
        },
    },

    created() {
        this.setName(this.userProfile);
    },

    methods: {
        changeName() {
            this.$v && this.$v.$touch();
            if (!this.$v.$invalid) {
                const data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                };
                this.$store.dispatch(CHANGE_NAME, data).then(() => {
                    this.$toastr.s(this.$t('name_change_success'), this.$t('success') + '!');
                });
            }
        },
        setName(profile) {
            this.firstName = profile.firstname;
            this.lastName = profile.lastname;
        },
    },
};
</script>
