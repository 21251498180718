import http from '@http';
import { IChartData, IConfig, IFilter, ITableHeader, ITableItem, VIEW_TYPES } from '../types';

export class TasksFilter implements IFilter {
    createConfig(): IConfig {
        return {
            name: 'Tasks',
            getTasks: async () => null,
            getChartData: async (rootState, rootGetters, params = {}) => {
                const placeId = params.places || rootGetters.selectedPlaceId;
                delete params.places;

                const data = await Promise.all([
                    http.get(`/api/reports/places/${placeId}/plots/binary-tasks`, { params }).then((res) => res.data),
                    http
                        .get(`/api/reports/places/${placeId}/plots/irregular-tasks`, { params })
                        .then((res) => res.data),
                ]);

                return this.createChartsData(data);
            },
            getTableData: async (rootState, rootGetters, params) => {
                const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/personnel`, {
                    params,
                });

                return {
                    originData: data,
                    headers: this.createTableHeaders(rootGetters['reports/tasksOptions']),
                    data: this.createTableItems(data),
                };
            },
            isSinglePlaceSelectable: true,
            applicable: [],
            groupBy: [],
            viewTypes: [
                {
                    key: VIEW_TYPES.graph,
                    name: 'Graph view',
                },
            ],
            state: {
                currentView: VIEW_TYPES.matrix,
            },
        };
    }
    taskFromJSON(i) {
        return { id: i.room_id, name: i.name };
    }
    createTableHeaders(json: any): ITableHeader[] {
        return [];
    }
    createTableItems(json: any): ITableItem[] {
        return [];
    }
    createChartsData(json: any[]): IChartData[] {
        const data: any[] = [];

        json.forEach((jsonItem) => {
            const res = Array.isArray(jsonItem) ? jsonItem : Object.keys(jsonItem).map((key) => jsonItem[key]);
            data.push(...res);
        });

        return data.map((i) => {
            const dataValue = i.data || i.traces;
            const layoutValue = i.layout || { title: i.title };

            return {
                data: dataValue,
                layout: layoutValue,
            };
        });
    }
    createRequestProps() {
        return {};
    }
}
