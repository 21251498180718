import http from '@http';
import { IChartData, IConfig, IFilter, ITableHeader, ITableItem, VIEW_TYPES } from '../types';

export class EquipmentFilter implements IFilter {
    createConfig(): IConfig {
        return {
            name: 'Equipment',
            getTasks: async (rootState, rootGetters, params) => {
                const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/equipment`, {
                    params,
                });
                return data.map(this.taskFromJSON);
            },
            getChartData: async (rootState, rootGetters, params) => {
                if (rootGetters['reports/currentState'].tasks.length) {
                    const [equipment]: { id: number; name: string }[] = rootGetters['reports/currentState'].tasks;
                    const { data } = await http.get(
                        `/api/reports/companies/${rootGetters.selectedCompanyId}/plots/equipment/${equipment.id}/temperature-tasks`,
                        { params }
                    );
                    return this.createChartsData(data);
                }
                const { data } = await http.get(
                    `/api/reports/companies/${rootGetters.selectedCompanyId}/plots/equipment/temperature-tasks`,
                    { params }
                );

                return this.createChartsData(data);
            },
            getTableData: async () => {
                return null;
            },
            isSingleTaskSelectable: true,
            applicable: [],
            groupBy: [],
            viewTypes: [
                {
                    key: VIEW_TYPES.matrix,
                    name: 'Matrix view',
                },
            ],
            state: {
                currentView: VIEW_TYPES.matrix,
            },
        };
    }
    taskFromJSON(i) {
        return { id: i.id, name: i.equipment_name };
    }
    createTableHeaders(json: any): ITableHeader[] {
        return [];
    }
    createTableItems(json: any): ITableItem[] {
        return [];
    }
    createChartsData(json: any): IChartData[] {
        const data: any = Array.isArray(json) ? json : Object.keys(json).map((key) => json[key]);

        return data.map((i) => {
            const dataValue = i.data || i.traces;
            const layoutValue = i.layout || { title: i.title };

            return {
                data: dataValue,
                layout: layoutValue,
            };
        });
    }
    createRequestProps() {
        return {};
    }
}
