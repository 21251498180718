import moment from 'moment';
import routes from '@/routes';
import { vm } from '@/main';
import Mime from 'mime';
import isEmpty from 'lodash/isEmpty';

let debounceTimer = null;

// TODO: FD-5566. get rid of it
export function prepareDate(date, noTime) {
    date = new Date(date);

    const year = date.getFullYear();
    const month = prepareDateNumber(date.getMonth() + 1);
    const day = prepareDateNumber(date.getDate());

    const hours = prepareDateNumber(date.getHours());
    const minutes = prepareDateNumber(date.getMinutes());

    let res = `${year}-${month}-${day}`;

    if (!noTime) {
        res += ` ${hours}:${minutes}:00`;
    }

    return res;
}

function prepareDateNumber(value) {
    return value.toString().length < 2 ? `0${value}` : value;
}

export function download(filename, content, type) {
    if (!filename) {
        throw TypeError('filename is required!');
    }
    if (!content) {
        throw TypeError('content is required!');
    }

    const mimeType = type || Mime.getType(filename);
    let blob = content;
    if (blob && (typeof blob !== 'string' || !blob.includes('base64,'))) {
        blob = window.URL.createObjectURL(mimeType ? new Blob([content], { mimeType }) : new Blob([content]));
    }

    const element = document.createElement('a');
    element.setAttribute('href', blob);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return blob;
}

// TODO: FD-5566 get rid of it
export function getCurrentDateTime(format = 'YYYY-MM-DD HH:mm:ss') {
    return moment().format(format);
}

export function isImageExtensionByName(name) {
    return /\.(gif|jpg|jpeg|tiff|png)$/i.test(name);
}

export function isPdfExtensionByName(name) {
    return /\.(pdf)$/i.test(name);
}

export function isSvgExtensionByName(name) {
    return /\.(svg)$/i.test(name);
}

export function isVideoExtensionByName(name) {
    return /\.(mp4|avi|webm|mkv|flv|ogv|ogg|gif|mov|wmv|mpg|mpeg|3gp)$/i.test(name);
}

export function resolveImageSrc(data) {
    if (hasBase64(data)) {
        return data;
    }

    return `data:image/png;base64,${data}`;
}

export function removeBase64Prefix(data) {
    if (!hasBase64(data)) {
        return data;
    }

    const res = data.split(',');
    res.shift();

    return res.join();
}

export function hasBase64(data) {
    return /^(data:.*;base64,).*/i.test(data);
}

export function onPaymentRequired(msg = null) {
    if (msg) {
        vm.$toastr.w(msg);
    }
    routes.push('/billing/choose-payment').catch(() => {});
}

export function getLocaleName(name) {
    if (name && typeof name === 'object') {
        return name[vm.$i18n.locale] || name.en || name[Object.keys(name)[0]] || getName(name);
    }
    return name;
}

function getName(name) {
    const keys = Object.keys(name);
    if (keys.length) {
        for (const key of keys) {
            if (name[key]) {
                return name[key];
            }
        }
    }
    return null;
}

// TODO: FD-5566. get rid of it. Shold be inside component of something
export function getThisMonthTimeDiapason() {
    const date = new Date();

    const from = new Date(date.getFullYear(), date.getMonth(), 1);
    const until = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
        from: from,
        until: until,
    };
}

// TODO: FD-5566. get rid of it. Shold be inside component of something
export function getLast30DaysDiapason() {
    const date = new Date();

    const from = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
    const until = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return {
        from: from,
        until: until,
    };
}

// TODO: FD-5566. get rid of it. Shold be inside component of something
export function getTimeDiapason({ days, years }) {
    const date = new Date();

    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (days) {
        from = new Date(from.getFullYear(), from.getMonth(), from.getDate() - days);
    }

    if (years) {
        from = new Date(date.getFullYear() - years, from.getMonth(), from.getDate());
    }

    const until = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return {
        from: from,
        until: until,
    };
}

export function getCurrentOrSomething(data, currentLang) {
    if (!data) {
        return data;
    }

    if (data[currentLang]) {
        return data[currentLang];
    }
    if (data.en) {
        return data.en;
    }

    const keys = Object.keys(data);
    if (keys.length) {
        for (const key of keys) {
            if (data[key]) {
                return data[key];
            }
        }
    }

    return null;
}

export function getAllNames(name) {
    return {
        en: name.en || name[vm.$i18n.locale] || name.et || name.ru || name.uk || name.de || null,
        et: name.et || name[vm.$i18n.locale] || name.en || name.ru || name.uk || name.de || null,
        ru: name.ru || name[vm.$i18n.locale] || name.en || name.et || name.uk || name.de || null,
        uk: name.uk || name[vm.$i18n.locale] || name.en || name.et || name.ru || name.de || null,
        de: name.de || name[vm.$i18n.locale] || name.en || name.et || name.ru || name.uk || null,
    };
}

// TODO: FD-5566 Not sure why it's in helpers but let's get rid of it
export function getNewExpirationDate(product, servingTime) {
    if (!product?.packaging?.time_unit?.name || !servingTime) {
        return null;
    }
    const {
        packaging: {
            expiry_date: expiryOffset,
            best_before: bestBeforeOffset,
            time_unit: { name: unitName },
        },
    } = product;

    let newExpiryDate = moment(servingTime, ['YYYY-MM-DD HH:mm:ss', moment.ISO_8601]);
    if (!newExpiryDate.isValid()) {
        return null;
    }

    const offset = Math.min(...[expiryOffset, bestBeforeOffset].filter((x) => !!x));
    switch (unitName) {
        case 'hour_prod': {
            newExpiryDate.add(offset, 'hours');
            break;
        }
        case 'day_prod': {
            newExpiryDate.add(offset, 'days').endOf('day');
            break;
        }
        case 'week_prod': {
            newExpiryDate.add(offset, 'weeks').endOf('day');
            break;
        }
        case 'month_prod': {
            newExpiryDate.add(offset, 'months').endOf('day');
            break;
        }
        case 'year_prod': {
            newExpiryDate.add(offset, 'years').endOf('day');
            break;
        }
    }
    return newExpiryDate.toDate();
}

export function sortBy(array, property) {
    return [...array].sort(function (a, b) {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
            return -1;
        }
        if (a[property].toLowerCase() > b[property].toLowerCase()) {
            return 1;
        }
        return 0;
    });
}

export function getShortProductionUnits(units) {
    if (units && units.length) {
        return units.map((unit) => {
            const productionUnit = { ...unit };
            switch (productionUnit.name) {
                case 'gram':
                    productionUnit.name = 'g';
                    return productionUnit;
                case 'kilogram':
                    productionUnit.name = 'kg';
                    return productionUnit;
                case 'liter':
                    productionUnit.name = 'l';
                    return productionUnit;
                case 'milliliter':
                    productionUnit.name = 'ml';
                    return productionUnit;
                case 'portion':
                    productionUnit.name = 'ptn';
                    return productionUnit;
            }
        });
    }
    return units;
}

export function debounce(callback, time = 400) {
    if (debounceTimer) {
        clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(() => {
        callback();
    }, time);
}

export function search(query, str) {
    return str.toLowerCase().trim().includes(query.toLowerCase().trim());
}

// TODO: FD-5566 depricated get rid of it
export function formatDateToSend(date) {
    if (!date) {
        throw new TypeError("Can't find date!");
    }

    return moment(String(date)).format();
}

export function isMobile() {
    const BOOTSTRAP_MD_SIZE = 801;

    return window.innerWidth < BOOTSTRAP_MD_SIZE;
}

export function getPageScrollTop() {
    return (document.body.scrollTop = document.documentElement.scrollTop = 0);
}

export function prepareFileToSend(file) {
    if (!file) {
        return file;
    }

    // Cut first part of base64 because of API needs
    const base64 = file.split(',');
    return base64.length > 1 ? base64[1] : file;
}

export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        if (!window.FileReader) {
            reject('FileReader unsupported!');
        }

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
}

export function base64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function notificationEmailValidator(value) {
    if (value) {
        const emails = value.split(',');
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let valid = true;
        emails.forEach((email) => {
            if (!re.test(email.trim())) {
                valid = false;
            }
        });
        return valid;
    }
    return true;
}

export function returnMultilingualFieldValue(data, $event) {
    for (const lang in $event) {
        if (!data[lang]) {
            delete data[lang];
        }
    }
    if (isEmpty(data)) {
        data = null;
    }
    return data;
}

export function getFormattedFileName(name) {
    const split = name.split(/(hygiene|health)_cert_[0-9]+_/);
    if (split.length > 1) {
        return split[split.length - 1];
    }
    return name;
}

export function arrayToMap(list, key = 'id') {
    if (!list || !Array.isArray(list) || !key) {
        throw 'Some required argument is missing!';
    }

    const map = {};

    list.forEach((item) => {
        map[item[key]] = item;
    });

    return map;
}

export function mapToArray(map) {
    if (!map) {
        throw "Can't get map!";
    }

    const keys = Object.keys(map);

    if (!keys.length) {
        return [];
    }

    return keys.map((key) => map[key]);
}

export function formatBytes(a, b) {
    if (0 == a) {
        return '0 Bytes';
    }
    const c = 1024,
        d = b || 2,
        e = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

export function capitalizeFLetter(value) {
    return value[0].toUpperCase() + value.slice(1);
}

export function scrollToError(validationForm) {
    const errors = Object.entries(validationForm.refs)
        .map(([key, value]) => ({
            key,
            value,
        }))
        .filter((error) => {
            if (!error || !error.value || !error.value.failedRules) {
                return false;
            }
            return Object.keys(error.value.failedRules).length > 0;
        });
    if (errors && errors.length > 0) {
        validationForm.refs[errors[0].key].$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }
}

export function getTimes() {
    const arr = [];
    for (let i = 0; i < 10; i++) {
        const val = '0' + i + ':00';
        arr.push({ value: `${val}:00`, name: val });
    }
    for (let i = 10; i < 24; i++) {
        const val = i + ':00';
        arr.push({ value: `${val}:00`, name: val });
    }
    arr.push({ value: 23 + ':59:00', name: 23 + ':59' });
    return arr;
}

export function scrollTo(el) {
    el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
}

export async function retry(callback, times = 10, len = 500, action, returnDefault = false) {
    if (times !== 0) {
        try {
            typeof action !== 'undefined' && (await action());
            return await callback();
        } catch {
            setTimeout(() => retry(callback, times - 1, len, action, returnDefault), len);
        }
    } else if (returnDefault) {
        typeof action !== 'undefined' && action();
    }
}

// TODO: FD-5566 implement DateRange type that use DateValue times inside
export function outOfRange(date, currentDate, mode) {
    if (!currentDate || currentDate == new Date(0)) {
        return true;
    }

    let startDate, endDate;

    switch (mode) {
        case 'week': {
            const current = moment(currentDate);
            startDate = current.clone().startOf('isoWeek');
            endDate = current.clone().endOf('isoWeek');
            break;
        }
        case 'month': {
            const current = moment(currentDate);
            startDate = current.clone().startOf('month');
            endDate = current.clone().endOf('month');
            break;
        }
        default: {
            return false;
        }
    }

    return !(moment(date).isSameOrAfter(startDate) && moment(date).isSameOrBefore(endDate));
}

export function isUndefined(val) {
    return typeof val === 'undefined';
}

export function objToQuery(obj) {
    const esc = encodeURIComponent;
    return Object.keys(obj)
        .map((key) => esc(key) + '=' + esc(obj[key]))
        .join('&');
}

export function nthPostfix(d) {
    if (d > 3 && d < 21) {
        return 'th';
    }
    switch (d % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

export function getDateISOString(date) {
    return new Date(new Date(date) - new Date().getTimezoneOffset() * 60000).toISOString();
}

export function getCurrentTimeISOString() {
    return this.getDateISOString(new Date());
}
