import Vue from 'vue';
import http from '@http';
import { IChartData, IConfig, IFilter, ITableHeader, ITableItem, VIEW_TYPES } from '../types';
import moment from 'moment';
import json2csv from 'json2csv';
import Marker from '@components/Marker.vue';
import { vm } from '@main';

export class PeopleFilter implements IFilter {
    createConfig(): IConfig {
        return {
            name: 'People',
            getTasks: async (rootState, rootGetters, params) => {
                const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/certs`, { params });
                return data.map(this.taskFromJSON);
            },
            getChartData: async (rootState, rootGetters, params) => {
                const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/plots/certs`, { params });

                return this.createChartsData(data);
            },
            getTableData: async (rootState, rootGetters, params) => {
                const { data } = await http.get(`/api/reports/companies/${rootGetters.selectedCompanyId}/personnel`, { params });

                return {
                    originData: data,
                    headers: this.createTableHeaders(rootGetters['reports/tasksOptions']),
                    data: this.createTableItems(data)
                };
            },
            isSingleTaskSelectable: false,
            applicable: [],
            groupBy: [],
            viewTypes: [{
                key: VIEW_TYPES.graph,
                name: 'Graph view',
            }, {
                key: VIEW_TYPES.table,
                name: 'Table view',
            }],
            state: {
                currentView: VIEW_TYPES.graph,
            }
        };
    }
    taskFromJSON(i, index){
        return { id: index, name: i };
    }
    createTableHeaders(json: any): ITableHeader[] {
        return [
            {
                name: 'Name',
                key: 'name',
            },
            ...json.map(i => ({
                name: i.name,
                key: i.name,
            }))
        ];
    }
    createTableItems(json: any): ITableItem[] {

        const getCertData = (cert) => {
            switch (cert.status) {
                case 'ok':
                case 'expired':
                case 'getting_expired':
                    return cert.end_time ? moment(cert.end_time, 'YYYY-MM-DD').format(vm.$store.getters.selectedCompanyDateFormat) : null;
                case 'no expiry':
                    return '∞';
                default:
                    return '-';
            }
        };

        const getCertColor = (cert) => {
            switch (cert.status) {
                case 'expired':
                case 'missing':
                    return 'danger';
                case 'getting_expired':
                    return 'warning';
                default:
                    return 'success';
            }
        };

        return json.map(i => {
            const res = {
                name: Vue.extend({
                    render() {
                        return <span>{ i.worker_name }</span>;
                    }
                }),
            };

            i.certs.forEach(cert => {
                res[cert.name] = Vue.extend({
                    render() {
                        // @ts-ignore
                        return (
                            <div>
                                <span>
                                    {/*// @ts-ignore */}
                                    <Marker variant={getCertColor(cert)}/>
                                    { getCertData(cert) }
                                </span>
                            </div>
                        );
                    }
                });
            });

            return res;
        });
    }
    createChartsData(json: any): IChartData[] {
        const data: any = Array.isArray(json) ? json : Object.keys(json).map(key => json[key]);

        return data.map(i => {
            const dataValue = i.data || i.traces;
            const layoutValue = i.layout || { title: i.title };

            return {
                data: dataValue,
                layout: layoutValue,
            };
        });
    }
    createRequestProps() {
        return {};
    }
    CSVStrategy(payload: any): string{
        const fields = [
            'worker_id',
            'worker_name',
            'certs.name',
            'certs.end_time',
            'certs.status',
        ];
        const transforms = [json2csv.transforms.unwind({ paths: ['certs'] })];
        const parser = new json2csv.Parser({ fields, transforms });
        return  parser.parse(payload);
    }
}
