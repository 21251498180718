// Mutations
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_INGREDIENTS = 'UPDATE_INGREDIENTS';
export const UPDATE_INGREDIENT_ATTACHMENTS = 'UPDATE_INGREDIENT_ATTACHMENTS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const UPDATE_PACKAGING_ATTACHMENTS = 'UPDATE_PACKAGING_ATTACHMENTS';
export const UPDATE_ADDITIVES = 'UPDATE_ADDITIVES';
export const UPDATE_ADDITIVES_ATTACHMENTS = 'productions/additives/UPDATE_ADDITIVES_ATTACHMENTS';
export const RETURN_ADDITIVES_ATTACHMENT_ITEM = 'RETURN_ADDITIVES_ATTACHMENT_ITEM';
export const RETURN_INGREDIENT_ATTACHMENT_ITEM = 'RETURN_INGREDIENT_ATTACHMENT_ITEM';
export const RETURN_PROPERTY_ATTACHMENT_ITEM = 'RETURN_PROPERTY_ATTACHMENT_ITEM';
export const RETURN_PACKAGE_ATTACHMENT_ITEM = 'production/package/RETURN_PACKAGE_ATTACHMENT_ITEM';
export const SET_CUSTOM_INGREDIENTS_LIST = 'SET_CUSTOM_INGREDIENTS_LIST';
export const ADD_CUSTOM_INGREDIENT_TO_LIST = 'ADD_CUSTOM_INGREDIENT_TO_LIST';
export const SET_INGREDIENT_PRICE_LIST = 'SET_INGREDIENT_PRICE_LIST';
export const SET_INGREDIENT = 'SET_INGREDIENT';
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
export const RESET_PRODUCTION = 'RESET_PRODUCTION';
export const CHANGE_PRODUCT_HAZARDS = 'CHANGE_PRODUCT_HAZARDS';
export const CHANGE_PRODUCT_HAZARD = 'CHANGE_PRODUCT_HAZARD';
export const ADD_PRODUCT_HAZARD = 'ADD_PRODUCT_HAZARD';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const SET_PRODUCT_INSTRUCTIONS = 'SET_PRODUCT_INSTRUCTIONS';
export const DELETE_PRODUCT_HAZARDS = 'DELETE_PRODUCT_HAZARDS';

// Actions
export const REMOVE_PRODUCT_INSTRUCTION = 'REMOVE_PRODUCT_INSTRUCTION';
export const INIT_PRODUCTION = 'INIT_PRODUCTION';
export const INIT_INSTRUCTIONS = 'INIT_INSTRUCTIONS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const INIT_PRODUCT_GENERAL = 'INIT_PRODUCT_GENERAL';
export const INIT_PRODUCT_INGREDIENTS = 'INIT_PRODUCT_INGREDIENTS';
export const INIT_PRODUCT_PACKAGE = 'INIT_PRODUCT_PACKAGE';
export const SEARCH_FOR_INGREDIENTS = 'SEARCH_FOR_INGREDIENTS';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const SET_ADDITIVES = 'SET_ADDITIVES';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const ADD_PACKAGING_ATTACHMENT = 'ADD_PACKAGING_ATTACHMENT';
export const CREATE_CUSTOM_INGREDIENT = 'CREATE_CUSTOM_INGREDIENT';
export const GET_CUSTOM_INGREDIENT = 'GET_CUSTOM_INGREDIENT';
export const SET_CUSTOM_INGREDIENTS = 'SET_CUSTOM_INGREDIENTS';
export const SET_PRODUCT_INGREDIENTS = 'SET_PRODUCT_INGREDIENTS';
export const INIT_GROUPS = 'INIT_GROUPS';
export const DOWNLOAD_TECH_CARD_PDF = 'productions/DOWNLOAD_TECH_CARD_PDF';
export const DOWNLOAD_TECH_DESC_PDF = 'productions/DOWNLOAD_TECH_DESC_PDF';
export const DOWNLOAD_PACKAGE_INFO_PDF = 'productions/DOWNLOAD_PACKAGE_INFO_PDF';
export const DOWNLOAD_PRODUCTION_SHEET_PDF = 'productions/DOWNLOAD_PRODUCTION_SHEET_PDF';
export const GET_PRODUCT_IMAGE_BASE64 = 'GET_PRODUCT_IMAGE_BASE64';
export const GET_PRODUCT_IMAGE_BASE64_PUBLIC = 'GET_PRODUCT_IMAGE_BASE64_PUBLIC';
export const FETCH_PRODUCT_PUBLIC = 'FETCH_PRODUCT_PUBLIC';
export const ADD_INGREDIENTS_ATTACHMENT = 'productions/ingredients/ADD_INGREDIENTS_ATTACHMENT';
export const ADD_ADDITIVES_ATTACHMENT = 'productions/additives/ADD_ADDITIVES_ATTACHMENT';
export const INIT_CUSTOM_INGREDIENTS_LIST = 'INIT_CUSTOM_INGREDIENTS_LIST';
export const GET_SUB_PRODUCT = 'GET_SUB_PRODUCT';
export const GET_INGREDIENT_PRICE_LIST = 'GET_INGREDIENT_PRICE_LIST';
export const SEARCH_INGREDIENT_TO_ADD_PRICE = 'SEARCH_INGREDIENT_TO_ADD_PRICE';
export const ADD_PRICE_TO_INGREDIENT = 'ADD_PRICE_TO_INGREDIENT';
export const UPDATE_PRICE_TO_INGREDIENT = 'UPDATE_PRICE_TO_INGREDIENT';
export const DOWNLOAD_PRODUCTS_CSV = 'production/DOWNLOAD_PRODUCTS_CSV';
export const DOWNLOAD_PRODUCT_INGREDIENTS_CSV = 'production/DOWNLOAD_PRODUCT_INGREDIENTS_CSV';

// Both
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';
export const DELETE_INGREDIENT_ATTACHMENT = 'DELETE_INGREDIENT_ATTACHMENT';
export const DELETE_ADDITIVES = 'DELETE_ADDITIVES';
export const DELETE_ADDITIVES_ATTACHMENT = 'DELETE_ADDITIVES_ATTACHMENT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_INSTRUCTIONS = 'UPDATE_PRODUCT_INSTRUCTIONS';
export const UPDATE_PREPARATION = 'UPDATE_PREPARATION';
export const UPDATE_PACKAGING = 'UPDATE_PACKAGING';
export const ADD_PROPERTIES_IMAGE = 'ADD_PROPERTIES_IMAGE';
export const DELETE_PROPERTIES_ATTACHMENT = 'DELETE_PROPERTIES_ATTACHMENT';
export const DELETE_PACKAGING_ATTACHMENT = 'DELETE_PACKAGING_ATTACHMENT';
export const DELETE_CUSTOM_INGREDIENT_ATTACHMENT = 'DELETE_CUSTOM_INGREDIENT_ATTACHMENT';
export const UPDATE_CUSTOM_INGREDIENT = 'UPDATE_CUSTOM_INGREDIENT';
export const DELETE_CUSTOM_INGREDIENT = 'DELETE_CUSTOM_INGREDIENT';
export const DELETE_PRICE_FROM_INGREDIENT = 'DELETE_PRICE_FROM_INGREDIENT';
