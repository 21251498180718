<template>
    <BaseSelect
        v-if="isEditMode"
        v-model="val"
        :options="timezones"
        :custom-label="label"
        :placeholder="$t('Time zone')"
        not-empty
    />
    <span v-else>
        <span v-if="val">{{ val.timezone }}, {{ val.offset }}</span>
        <span v-else>-</span>
    </span>
</template>

<script>
import BaseSelect from '@components/BaseSelect';

export default {
    name: 'TimezoneSelect',

    components: {
        BaseSelect,
    },

    props: {
        value: {
            type: String,
            default: null,
            nullable: true,
        },
        isEditMode: Boolean,
    },

    computed: {
        timezones() {
            return this.$store.getters['classifiers/timezones'];
        },
        val: {
            get() {
                const val = this.value || Intl.DateTimeFormat().resolvedOptions().timeZone;
                return this.timezones.find((i) => i.timezone === val);
            },
            set(value) {
                this.$emit('input', value?.timezone);
            },
        },
    },

    created() {
        this.$store.dispatch('classifiers/getTImezones');
    },

    methods: {
        label(item) {
            return `(${item.offset}) ${item.timezone}`;
        },
    },
};
</script>
