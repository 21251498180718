// Services
import { getThisMonthTimeDiapason } from '@services/helper.service';

import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

const state = {
    timeDiapason: getThisMonthTimeDiapason(),
};

export default {
    state,
    mutations,
    actions,
    getters,
};
