// Views
const CreateEditView = () =>
    import(
        /* webpackChunkName: "production-create-edit-module" */ '@views/Production/views/Products/views/CreateEditView'
    );
const General = () =>
    import(
        /* webpackChunkName: "production-create-edit-module" */ '@views/Production/views/Products/views/CreateEditView/views/General'
    );
const Ingredients = () =>
    import(
        /* webpackChunkName: "production-create-edit-module" */ '@views/Production/views/Products/views/CreateEditView/views/Ingredients'
    );
const Properties = () =>
    import(
        /* webpackChunkName: "production-create-edit-module" */ '@views/Production/views/Products/views/CreateEditView/views/Properties'
    );
const Package = () =>
    import(
        /* webpackChunkName: "production-create-edit-module" */ '@views/Production/views/Products/views/CreateEditView/views/Package'
    );
const Instructions = () => import('@views/Production/views/Products/views/CreateEditView/views/Instructions');

// Meta Property
const meta = { noNav: true, root: '/production/products' };

const general = {
    path: 'general',
    meta,
    components: { productCreateEdit: General },
};

export default [
    {
        path: 'products/create',
        name: 'products.create',
        components: { production: CreateEditView },
        redirect: 'products/create/general',
        children: [{ ...general, name: 'production.create.general' }],
    },
    {
        path: 'products/edit/:productId',
        name: 'production.products.edit',
        components: { production: CreateEditView },
        redirect: { name: 'products.edit.ingredients' },
        children: [
            { ...general, name: 'products.edit.general' },
            {
                path: 'ingredients',
                name: 'products.edit.ingredients',
                meta,
                components: { productCreateEdit: Ingredients },
            },
            {
                path: 'properties',
                name: 'products.edit.properties',
                meta,
                components: { productCreateEdit: Properties },
            },
            {
                path: 'package',
                name: 'products.edit.package',
                meta,
                components: { productCreateEdit: Package },
            },
            {
                path: 'instructions',
                name: 'products.edit.instructions',
                meta,
                components: { productCreateEdit: Instructions },
                children: [
                    {
                        path: ':instructionId',
                        name: 'products.edit.hazards',
                        component: () => import('@views/Plan/panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                    {
                        path: 'new',
                        name: 'products.add.hazards',
                        component: () => import('@views/Plan/panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                ],
            },
        ],
    },
];
