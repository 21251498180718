import http from '@http';

class MonitoringService {
    getProblemsList() {
        return [
            { id: 1, name: 'Diarrhea' },
            { id: 2, name: 'Food poisoning' },
            { id: 3, name: 'Allergy from food which was determined as no-allergens' },
            { id: 4, name: 'Quality of food was not acceptable' },
            { id: 5, name: 'Unsuitable objects in the food' },
            { id: 6, name: 'Shelf-life was over' },
            { id: 7, name: 'Package damages' },
        ];
    }

    getPreventionsList() {
        return [
            { id: 1, name: 'Employees were trained' },
            { id: 2, name: 'Guidance was modified' },
        ];
    }

    getSupplierDefectsList() {
        return [
            { id: 1, name: 'Expiration date exceeded' },
            { id: 2, name: 'Product is not quality' },
            { id: 3, name: 'Package was broken' },
            { id: 4, name: 'Temperature was not correct' },
        ];
    }

    getSupplierPreventionsList() {
        return [
            { id: 1, name: 'Employees were trained' },
            { id: 2, name: 'Guidance was modified' },
            { id: 3, name: 'Returned goods' },
            { id: 4, name: 'Food was reprocessed' },
            { id: 5, name: 'Food was rejected' },
            { id: 6, name: 'Supplier was notified' },
        ];
    }

    addServiceProvider(placeId, deviceId, email, language = 'en') {
        return http.put(`/api/place/${placeId}/providerEmail/monitoring/${deviceId}`, {
            email,
            language,
        });
    }
}

export const monitoringService = new MonitoringService();
