import { vm } from '@/main';
import http from '@http';
import cloneDeep from 'lodash.clonedeep';
import userManagementService from '@services/user_management.service';
import tasks from './tasks';
import taskSubtypes from './task-subtypes';
import { Worker } from '@common/types/Worker';
import Vue from 'vue';

function state() {
    return {
        filterQuery: '',
        workers: [],
    };
}

const mutations = {
    init(state, payload: Worker[]) {
        state.workers = payload;
    },
    set(state, payload: Worker) {
        const index = state.workers.findIndex((worker) => worker.id === payload.id);
        if (index === -1) {
            state.workers.push(payload);
        } else {
            Vue.set(state.workers, index, payload);
        }
    },
    delete(state, id) {
        const index = state.workers.findIndex((worker) => worker.id === +id);
        if (index !== -1) {
            state.workers.splice(index, 1);
        }
    },
    setFilterQuery(state, query) {
        state.filterQuery = query;
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ commit, rootGetters }): Promise<void> {
        try {
            const response = await http
                .get(`/api/places/${rootGetters.selectedPlaceId}/workers`)
                .then(({ data }) => data);
            commit('reset');
            commit('init', response.map(Worker.fromJSON));
        } catch (error) {
            console.error(error);
        }
    },
    async sendInvitation({ dispatch, rootGetters }, payload): Promise<void> {
        if (payload.accessLevel.toString() !== 'no_access') {
            await userManagementService.togglePermission(
                payload.accessLevel,
                payload.email,
                payload.name,
                rootGetters.selectedPlaceId,
                null,
                true
            );
        }
    },
    async create({ commit, rootGetters }, payload: { worker: Worker; placeId: number }): Promise<Worker | undefined> {
        try {
            const response = await http.post(
                `/api/places/${payload.placeId || rootGetters.selectedPlaceId}/workers`,
                payload.worker.toJSON()
            );
            const worker = Worker.fromJSON(response.data);
            commit('set', worker);
            return worker;
        } catch (error: any) {
            vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            console.error(error);
        }
    },
    async update({ commit, rootGetters }, payload: { worker: Worker; placeId: number }): Promise<Worker | undefined> {
        // if access is removed, the user connected to the worker is deleted
        if (['no_access'].includes(payload?.worker?.accessLevel?.toString())) {
            await userManagementService.deletePermission(
                payload.worker.userId,
                rootGetters.selectedPlaceId,
                rootGetters.selectedCompanyId
            );
        }
        try {
            const response = await http.put(
                `/api/places/${payload.placeId || rootGetters.selectedPlaceId}/workers/${payload.worker.id}`,
                payload?.worker?.toJSON()
            );
            const worker = Worker.fromJSON(response.data);
            commit('set', worker);
            return worker;
        } catch (error: any) {
            vm.$toastr.e(vm.$t(error?.response?.data?.message || 'error'));
            console.error(error);
        }
    },
    async delete({ commit, rootGetters }, id): Promise<void> {
        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/workers/${id}`);
            commit('delete', id);
        } catch (error) {
            console.error(error);
        }
    },
    async toggleActivation({ dispatch, getters }, id): Promise<void> {
        const worker = cloneDeep(getters.getWorker(id));
        worker.isDisabled = !worker.isDisabled;
        worker.stopNotificationCert = !worker.isDisabled;
        worker.stopNotificationTraining = !worker.isDisabled;
        await dispatch('update', { worker });
    },
    async setRoles({ dispatch, getters }, { id, groups }): Promise<void> {
        const worker = getters.getWorker(id);
        await dispatch('update', {
            ...worker,
            groupsIds: groups,
        });
    },
    async import({ commit, rootGetters }, file): Promise<void> {
        try {
            const response = await http.post(`/api/places/${rootGetters.selectedPlaceId}/workers/import`, { file });
            if (Array.isArray(response.data?.newWorkers)) {
                response.data.newWorkers.forEach((worker) => commit('set', Worker.fromJSON(worker)));
            }
            if (Array.isArray(response.data?.existingWorkers)) {
                response.data.existingWorkers.forEach((worker) => commit('set', Worker.fromJSON(worker)));
            }
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    filterQuery: (state) => state.filterQuery,
    getWorker: (state) => (id) => state.workers.find((worker) => worker?.id === +id),
    workers: (state) => state.workers,
    permissionsOptions() {
        return ['readonly,temp-measurements', 'owner', 'no_access'];
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    modules: {
        tasks,
        taskSubtypes,
    },
    namespaced: true,
};
