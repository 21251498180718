// Mutations
export const RESET_TIME_DIAPASON = 'RESET_TIME_DIAPASON';

// Actions
export const SET_DEFAULT_TIME_DIAPASON_BY_DEVICE = 'SET_DEFAULT_TIME_DIAPASON_BY_DEVICE';

// Both
export const SET_TIME_DIAPASON_FROM = 'SET_TIME_DIAPASON_FROM';
export const SET_TIME_DIAPASON_UNTIL = 'SET_TIME_DIAPASON_UNTIL';
export const SET_TIME_DIAPASON = 'SET_TIME_DIAPASON';
