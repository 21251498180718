import { render, staticRenderFns } from "./WrapperMessage.vue?vue&type=template&id=aaa1627e&scoped=true&"
import script from "./WrapperMessage.vue?vue&type=script&lang=js&"
export * from "./WrapperMessage.vue?vue&type=script&lang=js&"
import style0 from "./WrapperMessage.vue?vue&type=style&index=0&id=aaa1627e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa1627e",
  null
  
)

export default component.exports