import { CHANGE_NAME, INIT_USER_PROFILE, SET_USER_PROFILE } from '@store/profile/constants';
import MobileAppService from '@services/mobile-app.service';
import { SET_LAST_SEEN_NOTIFICATION_DATE } from '@store/notifications/constants';
import profileService from '@services/profile.service.js';
import { retry } from '@services/helper.service';
import settingsService from '@services/settings.service';

const state = {
    profile: {},
};

const mutations = {
    [SET_USER_PROFILE](state, profile) {
        state.profile = profile;
    },
};

const actions = {
    async [INIT_USER_PROFILE]({ commit, getters, dispatch }, companyObject) {
        const hasCompany = companyObject != null;
        const useSmartLook = process.env.APP_ENV === 'production' && !MobileAppService.isMobileView;

        if (!hasCompany || !getters.hasProfile) {
            dispatch('global/fetchAllGlobalCommons');
        }

        if (profileService.getProfile.inProgress) {
            return;
        }

        if (getters.userProfile.username) {
            if (useSmartLook) {
                initSmartLook(getters.userProfile, companyObject?.company, companyObject?.places?.[0]);
            }
            return getters.userProfile;
        } else {
            profileService.getProfile.inProgress = true;

            return profileService
                .getProfile()
                .then((res) => {
                    if (!hasCompany || !getters.hasProfile) {
                        commit(SET_USER_PROFILE, res);
                        commit(SET_LAST_SEEN_NOTIFICATION_DATE, res.last_seen_notification_at);
                    }

                    if (useSmartLook) {
                        initSmartLook(res, companyObject?.company, companyObject?.places?.[0]);
                    }
                    return res;
                })
                .finally(() => {
                    delete profileService.getProfile.inProgress;
                });
        }
    },
    [CHANGE_NAME]({ commit, getters }, fullName) {
        return settingsService.updateProfile(fullName).then(() => {
            const profile = getters.userProfile;
            profile.firstname = fullName.first_name;
            profile.lastname = fullName.last_name;
            profile.id_code = fullName.id_code;
            commit(SET_USER_PROFILE, profile);
        });
    },
};

const getters = {
    userProfile: (state) => {
        return state.profile || {};
    },
    getUserDisplayName() {
        let name = '';
        const firstName = state.profile?.firstname;
        name += firstName ? firstName + ' ' : '';
        name += state.profile?.lastname || '';
        name += (!name && state.profile?.username) || '';
        return name || '-';
    },
    hasProfile(state, getters) {
        return !!getters.profileId;
    },
    profileId(state, getters) {
        return getters.userProfile?.id;
    },
};

function initSmartLook(profile, company, place) {
    const RETRY_TIMES = 15;
    const RETRY_TIMEOUT = 600;
    retry(
        () => {
            if (!profile) {
                throw 'Profile not found!';
            }
            if (typeof smartlook === 'undefined') {
                throw 'Smartlook not found!';
            }
            const callSmartlook = (params) => {
                // eslint-disable-next-line no-undef
                smartlook(...params);
            };

            callSmartlook(['consentForms', 'Accepted by default.']);
            callSmartlook(['consentIP', 'Accepted by default.']);
            callSmartlook(['consentAPI', 'Accepted by default.']);
            callSmartlook([
                'identify',
                profile.username,
                {
                    name: `${profile.firstname} ${profile.lastname}`,
                    email: profile.username,
                    id: `${profile.id || ''}`,
                    company_id: company?.id,
                    sales_pipe: company?.sales_pipe_type?.name,
                    country: company?.country?.code,
                    business_activity: place?.business_activity?.name,
                },
            ]);
        },
        RETRY_TIMES,
        RETRY_TIMEOUT
    );
}

export default {
    state,
    mutations,
    actions,
    getters,
};
