import VueRouter from 'vue-router';

import store from '@/store';
import authService from '@services/auth.service';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { SET_SELECTED_PLACE } from '@store/company/place/constants';
import { FETCH_COMPANIES_HARD, SET_SELECTED_COMPANY } from '@store/company/constants';

// Layouts
import Default from '@/layouts/Default.vue';
import NotFound from '@/layouts/NotFound.vue';
import Multicolumn from '@/layouts/Multicolumn.vue';

// Routes
import overviewRoutes from '@views/Overview/routes';
import authRoutes, { beforeEachGuard as authBeforeEachGuard } from '@views/Auth/routes';
import auditRoutes from '@views/Audits/routes';
import planRoutes from '@views/Plan/routes';
import productionRoutes from '@views/Production/routes';
import productViewPublicRoutes from '@views/Production/views/Products/views/ProductView/public-routes';
import monitoringRoutes from '@views/Monitoring/routes';
import settingsRoutes from '@views/Settings/routes';
import billingRoutes from '@views/Billing/routes';
import traceabilityRoutes from '@views/Traceability/routes';
import documentsRoutes from '@views/Documents/routes';
import activityLog from '@views/ActivityLog/routes';
import setup from '@views/SetUp/routes';
import reportsRoutes from '@views/Reports/routes';
import team from '@views/Team/routes';

// Will catch all useless error messages from VueRouter
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

const routes = [
    ...authRoutes,
    ...productViewPublicRoutes,
    {
        path: '/contact',
        component: Default,
        children: [
            {
                path: '',
                name: 'contact',
                components: {
                    base: () => import('@views/contact.vue'),
                },
            },
        ],
    },
    {
        path: '/bootstrap',
        component: Multicolumn,
        children: [
            {
                path: '',
                component: () => import('@views/bootstrap.vue'),
            },
        ],
    },
    {
        path: '/set-up',
        component: Multicolumn,
        children: setup,
    },
    {
        path: '/activity-log',
        component: Multicolumn,
        children: activityLog,
    },
    {
        path: '/reports',
        component: Multicolumn,
        children: reportsRoutes,
    },
    {
        path: '/plan',
        component: Multicolumn,
        children: planRoutes,
    },
    {
        path: '/production',
        component: Multicolumn,
        children: productionRoutes,
    },
    {
        path: '/traceability',
        component: Multicolumn,
        children: traceabilityRoutes,
    },
    {
        path: '/billing',
        component: Multicolumn,
        children: billingRoutes,
    },
    {
        path: '/team',
        component: Multicolumn,
        children: team,
    },
    {
        path: '/documents',
        component: Multicolumn,
        children: documentsRoutes,
    },
    {
        path: '/overview',
        name: 'overview',
        component: Multicolumn,
        children: overviewRoutes,
    },
    {
        path: '',
        redirect: { name: 'overview' },
        component: Default,
        children: [...monitoringRoutes, ...auditRoutes, ...settingsRoutes],
    },
    {
        path: '*',
        name: 'not_found',
        component: NotFound,
        meta: { noNav: true, newAccount: true },
    },
];

// It will just take access_token from the url once when script will be loaded
if (window.location.search.match(/access_token=/)) {
    const urlParams = new URLSearchParams(window.location?.search);
    const token = urlParams?.get('access_token') || null;
    authService?.forceSetToken(token);
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes,
    linkActiveClass: 'active',
});

let timeout;
router.beforeEach((to, from, next) => {
    store.commit(STOP_VIEW_LOADER); // HOT FIX
    clearTimeout(timeout);
    store.commit(START_VIEW_LOADER);

    // Fix to prevent infinity loader
    timeout = setTimeout(() => {
        store.commit(STOP_VIEW_LOADER);
    }, 2000);

    const _hsq = (window._hsq = window._hsq || []); // Hubspot
    _hsq.push(['setPath', to?.path]); // refresh hubspot page tracking
    _hsq.push(['trackPageView']); // restart tracking
    if (!store.getters['global/headerConfigured'] && to?.query?.hide_header === 'true') {
        store.commit('global/setShowHeader', false);
    }

    /**
     * If company is not exists and it's not signup page
     * Redirect user to the first company creation page
     */
    if (authService.isAuthorized && !store.getters.companies.length && !to.name.includes('register')) {
        next({ name: 'register.company' });
        return;
    }

    if (to?.query?.place_id && to?.query?.company_id) {
        loadPlaceBeforeEachGuard(to.query.company_id, to.query.place_id);
    }
    authBeforeEachGuard(to, from, next);
});

router.afterEach(() => {
    clearTimeout(timeout);
    store.commit(STOP_VIEW_LOADER);
});

export default router;

async function loadPlaceBeforeEachGuard(companyId, placeId) {
    await store.dispatch(FETCH_COMPANIES_HARD);
    await store.dispatch(SET_SELECTED_COMPANY, parseInt(companyId));
    await store.dispatch(SET_SELECTED_PLACE, parseInt(placeId));
}
