import Vue from 'vue';
import axios from 'axios';
import Rollbar from 'vue-rollbar';
import VueGtm from 'vue-gtm';
import App from '@/App.vue';
import router from './routes.js';
import store from './store';
import VueRouter from 'vue-router';
import Toastr from 'vue-toastr';
import VueCookies from 'vue-cookies';
import Vuelidate from 'vuelidate';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PortalVue from 'portal-vue';
import 'wicg-inert';
import VueQuillEditor from 'vue-quill-editor';
import * as VueGoogleMaps from 'vue2-google-maps';
import authService from '@services/auth.service';
import { PiniaVuePlugin, createPinia, setMapStoreSuffix } from 'pinia';

// Bootstrap
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';

// TODO: Replace with single component imports
import BootstrapVue from 'bootstrap-vue';
import DatePicker from 'vue-bootstrap-datetimepicker';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import linkify from 'vue-linkify';
import localFilters from '@common/filters';

// Internal components
import Dropdown from '@components/Dropdown';
import MultilingualTextarea from '@components/MultilingualTextarea';
import MultilingualInput from '@components/MultilingualInput';
import MultilingualQuill from '@components/MultilingualQuill';
import BaseInput from '@components/BaseInput';
import { BaseForm } from '@components/BaseForm';
import BaseSelect from '@components/BaseSelect';
import BaseBadges from '@components/BaseBadges';
import Marker from '@components/Marker';
import { BaseDatePicker } from '@components/BaseDatePicker';
import Radio from '@components/Radio';
import InvalidIndicator from '@components/InvalidIndicator';
import OptionalDatePicker from '@components/OptionalDatePicker';
import BreadcrumbMenu from '@components/layout/BreadcrumbMenu';
import IntroductionBody from '@components/layout/IntroductionBody';
import ProductionUnits from '@components/ProductionUnits';
import clickOutsideDirective from '@common/directives/click-outside.js';
import VueMeta from 'vue-meta';
import Delete from '@components/Delete';
import Collapse from '@components/Collapse';
import VueApexCharts from 'vue-apexcharts';
import routerMixin from '@common/mixins/router.mixin.js';

import i18n from './i18n';

import validators from '@common/validators.js';
import BaseFilePreview from './common/components/BaseFilePreview/BaseFilePreview';

validators.init();

console.info(`%cApp version is: ${process.env.APP_VERSION}`, 'background: #222; color: #fff; padding: 10px;');

setMapStoreSuffix('Store');

const pinia = createPinia();

Vue.use(VueMeta);
Vue.use(DatePicker);
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(PiniaVuePlugin);
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image', 'video'],
];

Vue.use(VueQuillEditor, {
    modules: {
        toolbar: toolbarOptions,
    },
});

let locale = Vue.prototype.$cookies.get('lang');
if (!locale) {
    locale = localStorage.getItem('lang') || 'en';
}

if (process.env.MIX_APP_ENV === 'production' || process.env.MIX_APP_ENV === 'staging') {
    Vue.use(Rollbar, {
        accessToken: process.env.MIX_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        source_map_enabled: true,
        environment: process.env.MIX_APP_ENV,
        payload: {
            client: {
                javascript: {
                    code_version: process.env.APP_VERSION,
                },
            },
        },
    });

    if (Vue.rollbar) {
        Vue.rollbar.configure({
            /**
             * An optional function that will be used to ignore uncaught exceptions based on its return value.
             * The function signature should be: function checkIgnore(isUncaught, args, payload) { ... } and should return
             * true if the error should be ignored.
             *
             * @param isUncaught Boolean - true if error caught in client, false if not
             * @param args Array - contains error message as array items
             * @param payload Object - The javascript object that is about to be sent to Rollbar. This will contain all
             * of the context and payload information for this notifier and error. This parameter is useful
             * for advanced ignore functionality
             *
             * @return Boolean - true if error should be ignored, otherwise false
             **/
            // eslint-disable-next-line no-unused-vars
            checkIgnore: function (isUncaught, args, payload) {
                const toIgnores = ['status code 401', 'status code 402', 'Network error'];
                return args && typeof args[0] === 'string' && toIgnores.some((toIgnore) => args[0].includes(toIgnore));
            },
        });
    }

    Vue.config.errorHandler = (err, vm, info) => {
        console.error(err, vm, info);
        Vue.rollbar.error(err);
    };
}

const errLogFn = console.error;
console.error = function (err, ...rest) {
    Vue?.rollbar?.error(err);
    errLogFn(err, ...rest);

    if (err?.name === 'ChunkLoadError' || err?.message?.includes('chunk')) {
        /**
         * Show new release message with refresh button
         * cause ChunkLoadError appears after a release
         */
        vm.$root.$emit('new-release');
    }
};

Vue.use(Toastr, {
    defaultTimeout: 10000,
    defaultPosition: 'toast-top-center',
    defaultCloseOnHover: false,
    defaultPreventDuplicates: true,
    defaultProgressBar: false,
});

Vue.use(VueRouter);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB1rmZvodn5toVSWM-AgUsUVcoL0A8bPCY',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
});

Vue.use(VueGtm, {
    id: [process.env.GTM_ID],
    queryParams: (() => {
        return {
            production: {
                gtm_auth: process.env.GTM_PROD_AUTH,
                gtm_preview: process.env.GTM_PROD_PREVIEW,
                gtm_cookies_win: process.env.GTM_PROD_COOKIES_WIN,
            },
            development: {
                gtm_auth: process.env.GTM_DEV_AUTH,
                gtm_preview: process.env.GTM_DEV_PREVIEW,
                gtm_cookies_win: process.env.GTM_DEV_COOKIES_WIN,
            },
        }[process.env.NODE_ENV];
    })(),
    enabled: true,
    debug: process.env.NODE_ENV === 'development',
    loadScript: false,
    vueRouter: router,
    ignoredViews: [],
});

window.Popper = require('popper.js').default;

/**
 * changing interceptor
 */

const filters = {
    ...localFilters,
};

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Dropdown', Dropdown);
Vue.component('MultilingualTextarea', MultilingualTextarea);
Vue.component('MultilingualInput', MultilingualInput);
Vue.component('MultilingualQuill', MultilingualQuill);
Vue.component('IntroductionBody', IntroductionBody);
Vue.component('FdInput', BaseInput);
Vue.component('FdDatePicker', BaseDatePicker);
Vue.component('FdSelect', BaseSelect);
Vue.component('FdRadio', Radio);
Vue.component('InvalidIndicator', InvalidIndicator);
Vue.component('OptionalDatePicker', OptionalDatePicker);
Vue.component('BreadcrumbMenu', BreadcrumbMenu);
Vue.component('ProductionUnits', ProductionUnits);
Vue.component('QrCode', VueQrcode);
Vue.component('FdDelete', Delete);
Vue.component('Collapse', Collapse);
Vue.component('FdMarker', Marker);
Vue.component('BaseBadges', BaseBadges);

// Controllers declaration
Vue.component('BaseForm', BaseForm);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseFilePreview', BaseFilePreview);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseDatePicker', BaseDatePicker);

Vue.component('ApexChart', VueApexCharts);
// Common directives
Vue.directive('click-outside', clickOutsideDirective);
Vue.directive('focus', {
    inserted: function (el, binding, vNode) {
        if (vNode && vNode.componentInstance && !vNode.componentInstance.val) {
            el.getElementsByTagName('input')[binding.value || 0].focus();
        }
    },
});
Vue.directive('linkified', linkify);

Vue.mixin(routerMixin);

// TMP
// eslint-disable-next-line no-undef
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
    icons: {
        time: 'fd-clock',
        date: 'fd-calendar',
        up: 'fd-arrow-up',
        down: 'fd-arrow-down',
        previous: 'fd-chevron-left',
        next: 'fd-chevron-right',
        today: 'fd-calendar',
        clear: 'fd-trash',
        close: 'fd-close-circle',
    },
    format: 'DD.MM.YYYY HH:mm',
});

axios.defaults.baseURL = process.env.API_URL;
export let vm;

async function presetAppData() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('shared_access_token');

    if (token) {
        await store.dispatch('auth/setSharedToken', token);
    }

    if (authService.isAuthorized) {
        return store.dispatch('initApp');
    }

    return;
}

presetAppData().then(() => {
    vm = new Vue({
        el: '#app',

        i18n: i18n(),

        // Cause it need to init cookie first
        filters,

        router,

        store,

        render: (h) => h(App),

        pinia,
    });
});

if (window.Cypress) {
    // only available during E2E tests
    window.app = vm;
}

export const eventBus = new Vue();

window.onerror = function (message, url, lineNo, columnNo, error) {
    if (typeof message === 'string' && message.includes('Uncaught SyntaxError: Unexpected token "<"')) {
        alert(vm.$t('New version has been released and application needs to be refreshed'));
        window.location.reload(true);
    } else {
        throw error;
    }
};
