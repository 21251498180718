import { INIT_PERMISSIONS, SET_PERMISSIONS } from '@store/permission/constants';
import userManagementService from '@services/user_management.service.js';

const state = {
    permissions: [],
};

const mutations = {
    [SET_PERMISSIONS](state, data) {
        state.permissions = data;
    },
};

const actions = {
    [INIT_PERMISSIONS]({ commit }) {
        return userManagementService.getPermissions().then((companies) => {
            commit(SET_PERMISSIONS, companies);
            return companies;
        });
    },
};

const getters = {
    canWrite(state, getters, rootState, rootGetters) {
        return !!(
            rootGetters.selectedCompany &&
            rootGetters.selectedCompany.company &&
            (getters.isOwner || rootGetters.selectedCompany.company.role === 'member')
        );
    },
    isOwner(state, getters, rootState, rootGetters) {
        return !!(
            rootGetters.selectedCompany &&
            rootGetters.selectedCompany.company &&
            rootGetters.selectedCompany.company.role === 'owner'
        );
    },
    canWritePlace(state, getters, rootState, rootGetters) {
        return (
            getters.canWrite ||
            getters.isOwnerPlace ||
            (rootGetters.selectedPlace && rootGetters.selectedPlace.role === 'member')
        );
    },
    isOwnerPlace(state, getters, rootState, rootGetters) {
        return (rootGetters.selectedPlace && rootGetters.selectedPlace.role === 'owner') || getters.isOwner;
    },
    canEditMeasurementsForPlace(state, getters, rootState, rootGetters) {
        if (!rootGetters.selectedPlace) {
            return false;
        }
        const roles =
            rootGetters.selectedPlace && rootGetters.selectedPlace.role && rootGetters.selectedPlace.role.split(',');
        return roles && roles.includes('temp-measurements');
    },
    canEditMeasurements(state, getters, rootState, rootGetters) {
        if (!rootGetters.selectedCompany) {
            return false;
        }
        const roles =
            rootGetters.selectedCompany &&
            rootGetters.selectedCompany.company &&
            rootGetters.selectedCompany.company.role &&
            rootGetters.selectedCompany.company.role.split(',');

        return (
            getters.canWritePlace ||
            getters.canEditMeasurementsForPlace ||
            (roles && roles.includes('temp-measurements'))
        );
    },
    canEditPersonnel(state, getters, rootState, rootGetters) {
        if (!rootGetters.selectedCompany) {
            return false;
        }
        const roles =
            rootGetters.selectedCompany &&
            rootGetters.selectedCompany.company &&
            rootGetters.selectedCompany.company.role &&
            rootGetters.selectedCompany.company.role.split(',');

        return getters.canWritePlace || (roles && roles.includes('personnel'));
    },
    permissions(state) {
        return state.permissions;
    },
};
export default {
    state,
    mutations,
    actions,
    getters,
};
