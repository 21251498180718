// Views
const ProductView = () =>
    import(/* webpackChunkName: "product-view-module" */ '@views/Production/views/Products/views/ProductView');
const TechnologicalCard = () =>
    import(
        /* webpackChunkName: "product-view-module" */ '@views/Production/views/Products/views/ProductView/views/TechnologicalCard'
    );
const TechnologicalDescription = () =>
    import(
        /* webpackChunkName: "product-view-module" */ '@views/Production/views/Products/views/ProductView/views/TechnologicalDescription'
    );
const PackageInfo = () =>
    import(
        /* webpackChunkName: "product-view-module" */ '@views/Production/views/Products/views/ProductView/views/PackageInfo'
    );
const Recipe = () =>
    import(
        /* webpackChunkName: "product-view-module" */ '@views/Production/views/Products/views/ProductView/views/Recipe'
    );
const Instructions = () => import('@views/Production/views/Products/views/CreateEditView/views/Instructions');

import { PRODUCTION_TRACEABILITY_ID } from '@common/constants';

export default [
    {
        path: 'products/view/:productId',
        name: 'production.products.view',
        components: { production: ProductView },
        redirect: 'products/view/:productId/technological-card',
        children: [
            {
                path: 'technological-card',
                meta: { noNav: true, root: '/production/products' },
                components: { productView: TechnologicalCard },
            },
            {
                path: 'technological-description',
                meta: { noNav: true, root: '/production/products' },
                components: { productView: TechnologicalDescription },
            },
            {
                path: 'package-info',
                meta: { noNav: true, root: '/production/products' },
                components: { productView: PackageInfo },
            },
            {
                path: 'instructions',
                meta: { noNav: true, root: '/production/products', blockEdit: true },
                components: { productView: Instructions },
                children: [
                    {
                        path: ':instructionId',
                        name: 'products.view.hazards',
                        component: () => import('@views/Plan/panels/ProductionProcessesSafetyInstructions.vue'),
                    },
                ],
            },
            {
                path: 'recipe',
                meta: {
                    noNav: true,
                    root: '/production/products',
                    deviceTypeId: PRODUCTION_TRACEABILITY_ID,
                    isProduction: true,
                },
                components: { productView: Recipe },
            },
        ],
    },
];
