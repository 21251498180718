<template>
    <nav class="navbar navbar-expand-lg navbar-light justify-content-between register-header">
        <div class="d-flex register-logo align-items-center">
            <div class="d-flex align-items-center">
                <Logo responsive />
            </div>
            <LanguageSwitcher
                class="pl-2 mr-auto"
                position="position-absolute"
            />
        </div>
        <button
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            data-target="#topRegisterNav"
            data-toggle="collapse"
            type="button"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div
            id="topRegisterNav"
            class="collapse navbar-collapse"
        >
            <div class="my-2 ml-auto dropdown-menu-left">
                <div class="d-flex justify-content-end">
                    <div class="mr-3">
                        <div class="btn-group">
                            <a
                                id="profileRegister"
                                aria-expanded="false"
                                aria-haspopup="true"
                                class="d-flex cursor-pointer user-logo align-items-center"
                                data-toggle="dropdown"
                            >
                                <i class="fd-user-circle mr-1"></i>
                                <span
                                    id="profileRegister-name"
                                    class="text-dark mr-1"
                                >
                                    {{ getUserDisplayName }}
                                </span>
                                <i class="fd-chevron-down font-size-sm"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button
                                    v-if="!companyExist.length || (!placeExist.length && !newCompanyPlace)"
                                    class="dropdown-item"
                                    type="button"
                                    @click="logout"
                                >
                                    {{ $t('log_out') }}
                                    <i class="fd-sign-out p-2"></i>
                                </button>
                                <button
                                    v-else
                                    class="dropdown-item"
                                    type="button"
                                    @click="backToSettings"
                                >
                                    {{ $t('close') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from '@components/Logo';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { mapGetters } from 'vuex';

export default {
    components: {
        Logo,
        LanguageSwitcher,
    },

    data() {
        return {
            newCompanyPlace: this.$route.params.newCompanyPlace,
        };
    },

    computed: {
        ...mapGetters(['getUserDisplayName']),
        companyExist() {
            return this.$store.getters.companies;
        },
        placeExist() {
            return this.$store.getters.places;
        },
    },

    methods: {
        backToSettings() {
            // TODO: Company or place deletion needs to be added
            this.navigateTo({ name: 'settings' });
        },
        logout() {
            this.$store.dispatch('auth/logout');
        },
    },
};
</script>

<style lang="scss" scoped>
.loco-divider {
    line-height: 80%;
    border-right: 2px solid $gray-500;
}

.register-header {
    @include media-breakpoint-up(xs) {
        background: $gray-100;
    }
    @include media-breakpoint-up(lg) {
        background: var(--white);
    }

    .register-logo {
        user-select: none;
    }
}
</style>
