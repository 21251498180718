export default {
    getProductInstructions(state) {
        return state.productInstructions;
    },
    productsList(state) {
        return state.data || [];
    },
    hasProducts(state, getters) {
        return !!getters.productsList.length;
    },
    getProductById: (state, getters) => (id) => {
        return getters.productsList.find((i) => i.id === id);
    },
    getProductIndexById: (state, getters) => (id) => {
        let itemIndex = null;

        getters.productsList.find((item, index) => {
            if (+item.id === +id) {
                itemIndex = index;
                return true;
            }
        });

        return itemIndex;
    },
    getProductByToken: (state, getters) => (token) => {
        return getters.productsList.find((product) => product.share_token === token);
    },
    getAdditivesAttachment: (state) => (productId, additivesId, attachmentId) => {
        let itemIndex = null;
        let data = null;
        state.data.find((product) => {
            if (product.id === productId) {
                product.esubstances.find((esubstance) => {
                    if (esubstance.id === additivesId) {
                        esubstance.files.find((attachment, index) => {
                            if (attachment.id === attachmentId) {
                                itemIndex = index;
                                data = attachment;
                                return true;
                            }
                        });

                        return true;
                    }
                });

                return true;
            }
        });

        if (data) {
            return {
                data,
                index: itemIndex,
            };
        }
    },
    getIngredientsAttachment: (state) => (productId, ingredientId, attachmentId) => {
        let itemIndex = null;
        let data = null;
        state.data.find((product) => {
            if (product.id === productId) {
                product.ingredients.find((ingredient) => {
                    if (ingredient.id === ingredientId) {
                        ingredient.files.find((attachment, index) => {
                            if (attachment.id === attachmentId) {
                                itemIndex = index;
                                data = attachment;
                                return true;
                            }
                        });

                        return true;
                    }
                });

                return true;
            }
        });

        if (data) {
            return {
                data,
                index: itemIndex,
            };
        }
    },
    getPropertiesAttachment: (state) => (productId, attachmentId) => {
        let itemIndex = null;
        let data = null;

        state.data.find((product) => {
            if (product.id === productId) {
                product.files.find((file, index) => {
                    if (file.id === attachmentId) {
                        itemIndex = index;
                        data = file;
                        return true;
                    }
                });

                return true;
            }
        });

        if (data) {
            return {
                data,
                index: itemIndex,
            };
        }
    },
    getPackageAttachment: (state) => (productId, attachmentId) => {
        let itemIndex = null;
        let data = null;

        state.data.find((product) => {
            if (product.id === productId) {
                product.packaging.files.find((file, index) => {
                    if (file.id === attachmentId) {
                        itemIndex = index;
                        data = file;
                        return true;
                    }
                });

                return true;
            }
        });

        if (data) {
            return {
                data,
                index: itemIndex,
            };
        }
    },
    getCustomIngredientsList: ({ customIngredients }) => {
        return customIngredients;
    },
    getIngredientsList: ({ ingredients }) => {
        return ingredients;
    },
    productionModuleProductionUnits(_, getters) {
        // TODO: Temporary solution which we will delete portion unit from DB
        const PORTION_ID = 3;

        return getters.productionUnits.filter((item) => item.id !== PORTION_ID);
    },
};
