import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import http from '@http';
import { retry } from '@services/helper.service';

const NO_OF_SECTIONS = 4;

function state() {
    return {
        selectedGroupId: null,
        data: [],
        selectedFoodGroups: [],
        statuses: {},
        hazardDisplayData: {},
    };
}

const getters = {
    data: (state) => state.data,
    hazardDisplayData: (state) => state.hazardDisplayData,
    statuses: (state) => state.statuses,
    get: (state) => (id) => state.data.find((d) => d.id === id),
    selectedGroupId: (state) => state.selectedGroupId,
    selectedFoodGroups: (state) => state.selectedFoodGroups,
    noOfCompleted: (state) => {
        return {
            completed:
                Object.values(state.statuses)?.filter((status: any) => status.length === NO_OF_SECTIONS)?.length || 0,
            count: state.data.length || 0,
        };
    },
};

const mutations = {
    setHazardDisplayData(state, payload) {
        state.hazardDisplayData = payload;
    },
    setSelectedGroup(state, { id, forceUpdate = false }) {
        if (state.selectedGroupId !== +id || forceUpdate) {
            state.selectedGroupId = +id;
            const group = state.data.find((d) => d.id === +state.selectedGroupId);
            state.selectedFoodGroups = group?.foodGroups;
        }
    },
    setAllStatuses(state, payload) {
        state.statuses = payload;
    },
    updateStatuses(state, { id, payload }) {
        state.statuses = { ...state.statuses, [id]: payload };
    },
    setData(state, payload) {
        state.data = payload;
    },
    deleteProductGroup(state, id) {
        state.data = state.data.filter((group) => +group.id !== +id);
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
    add(state, payload) {
        state.data.push({
            ...payload,
            foodGroups: [],
        });
    },
    update(state, payload) {
        const foodGroup = state.data.find((d) => +d.id === +payload.id);
        foodGroup.name = payload.name;
        foodGroup.products = payload.products;
    },
    updateSelectedProductGroupFoodGroups(state, foodGroups) {
        state.data.find((d) => d.id === +state.selectedGroupId).foodGroups = foodGroups;
        state.selectedFoodGroups = foodGroups;
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ rootGetters, commit, state, dispatch }) {
        if (state.data.length) {
            return;
        }

        try {
            await retry(
                async () => {
                    const { data } = await http.get(
                        `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups`
                    );
                    commit('setData', data.map(FspProductGroup.fromJSON));
                    commit('setSelectedGroup', { id: state.selectedGroupId, forceUpdate: true });

                    dispatch(
                        'getAllStatuses',
                        data.map((d) => d.id)
                    );
                },
                10,
                800,
                () => {
                    if (!rootGetters['plan/selectedPlanId']) {
                        throw new Error('Plan id not found!');
                    }
                },
                true
            );
        } catch (error) {
            console.error(error);
        }
    },
    async add({ rootGetters, commit, dispatch }, productGroup) {
        try {
            const { data } = await http.post(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups`,
                new FspProductGroup(productGroup).toJSON()
            );

            commit('add', FspProductGroup.fromJSON(data));

            dispatch('getStatuses', { productGroupId: data.id, isCommit: true });

            await dispatch(
                'plan/fetchPlanData',
                {
                    placeId: rootGetters.selectedPlaceId,
                    isPlanChanged: true,
                },
                { root: true }
            );
        } catch (error) {
            console.error(error);
        }
    },
    async update({ rootGetters, commit, dispatch }, productGroup) {
        try {
            const { data } = await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroup.id}`,
                new FspProductGroup(productGroup).toJSON()
            );

            commit('update', FspProductGroup.fromJSON(data));

            dispatch('getStatuses', { productGroupId: productGroup.id, isCommit: true });

            await dispatch(
                'plan/fetchPlanData',
                {
                    placeId: rootGetters.selectedPlaceId,
                    isPlanChanged: true,
                },
                { root: true }
            );
        } catch (error) {
            console.error(error);
        }
    },
    async delete({ rootGetters, commit, dispatch }, productGroupId) {
        try {
            const { data } = await http.delete(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}`
            );

            commit('deleteProductGroup', productGroupId);

            await dispatch(
                'plan/fetchPlanData',
                {
                    placeId: rootGetters.selectedPlaceId,
                    isPlanChanged: true,
                },
                { root: true }
            );

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async updateFoodGroups({ commit, rootGetters, dispatch }, { productGroupId, foodGroups }) {
        commit(START_VIEW_LOADER, null, { root: true });
        try {
            await http.put(
                `/api/place/${rootGetters.selectedPlaceId}/fsp-product-group/${productGroupId}/food-groups`,
                foodGroups
            );

            commit('updateSelectedProductGroupFoodGroups', foodGroups);

            dispatch('getStatuses', { productGroupId, isCommit: true });
        } finally {
            commit(STOP_VIEW_LOADER, null, { root: true });
        }
    },
    async getGroupFlowDiagrams({ rootGetters }, productGroupId) {
        try {
            const type = 'flow_diagram';
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}/files`,
                { params: { type } }
            );

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async getGroupProcesses({ rootGetters }, productGroupId) {
        try {
            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}/processes`
            );

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async addFileToGroup({ rootGetters, dispatch }, { productGroupId, file, type }) {
        try {
            await http.put(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}/files/${file.id}`,
                null,
                { params: { type } }
            );

            dispatch('getStatuses', { productGroupId, isCommit: true });
        } catch (error) {
            console.error(error);
        }
    },
    async getStatuses({ rootGetters, commit, dispatch }, { productGroupId, isCommit = false }) {
        try {
            if (!productGroupId) {
                return;
            }

            const { data } = await http.get(
                `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}/statuses`
            );

            if (isCommit) {
                commit('updateStatuses', { id: productGroupId, payload: data });
            }

            await dispatch('plan/getSelectedPlanStatuses', {}, { root: true });

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async getAllStatuses({ commit, dispatch, getters }, productGroupIds) {
        try {
            productGroupIds = productGroupIds || getters.data.map((d) => d.id);
            const res = {};
            const allStatuses = await Promise.all(
                productGroupIds.map((id) => dispatch('getStatuses', { productGroupId: id }))
            );
            allStatuses.forEach((statuses, i) => {
                res[productGroupIds[i]] = statuses;
            });
            commit('setAllStatuses', res);
        } catch (error) {
            console.error(error);
        }
    },
    async getHazardDisplayData({ rootGetters, getters, commit }, productGroupId) {
        try {
            await retry(
                async () => {
                    const { data } = await http.get(
                        `/api/places/${rootGetters.selectedPlaceId}/food-safety-plans/${rootGetters['plan/selectedPlanId']}/fsp-product-groups/${productGroupId}/haccp`
                    );

                    commit('setHazardDisplayData', data);
                },
                30,
                300,
                () => {
                    if (!(rootGetters.selectedPlaceId && rootGetters['plan/selectedPlanId'] && productGroupId)) {
                        throw new Error('All ids not present!');
                    }
                },
                true
            );
        } catch (error) {
            console.error(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

interface IFspProduct {
    id: number;
    isDefault: boolean;
    name: string;
    fspProductGroupId: number;
    productGroupId: number;
    productionAmountRangeId: number;
    productionUnitId: number;
    productionTimeUnitId: number;
}

class FspProduct implements IFspProduct {
    productGroupId: number;
    fspProductGroupId: number;
    id: number;
    isDefault: boolean;
    name: string;
    productionAmountRangeId: number;
    productionTimeUnitId: number;
    productionUnitId: number;

    constructor(data: IFspProduct) {
        this.productGroupId = data.productGroupId;
        this.fspProductGroupId = data.fspProductGroupId;
        this.id = data.id;
        this.isDefault = data.isDefault;
        this.name = data.name;
        this.productionAmountRangeId = data.productionAmountRangeId;
        this.productionTimeUnitId = data.productionTimeUnitId;
        this.productionUnitId = data.productionUnitId;
    }

    static fromJSON(json: any): FspProduct {
        return new FspProduct({
            productGroupId: json.product_group_id,
            fspProductGroupId: json.fsp_product_group_id,
            id: json.id,
            isDefault: json.is_default,
            name: json.name,
            productionAmountRangeId: json.production_amount_range_id,
            productionTimeUnitId: json.production_time_unit_id,
            productionUnitId: json.production_unit_id,
        });
    }

    toJSON() {
        return {
            product_group_id: this.productGroupId,
            fsp_product_group_id: this.fspProductGroupId,
            id: this.id,
            is_default: this.isDefault,
            name: this.name,
            production_amount_range_id: this.productionAmountRangeId,
            production_time_unit_id: this.productionTimeUnitId,
            production_unit_id: this.productionUnitId,
        };
    }
}

interface IFspProductGroup {
    id: number;
    name: string;
    isDefault: boolean;
    foodSafetyPlanId: number;
    originalId: number;
    products: Array<FspProduct>;
    foodGroups: any;
}

class FspProductGroup implements IFspProductGroup {
    foodGroups: any;
    foodSafetyPlanId: number;
    id: number;
    isDefault: boolean;
    name: string;
    originalId: number;
    products: Array<FspProduct>;

    constructor(data: IFspProductGroup) {
        this.foodGroups = data.foodGroups;
        this.foodSafetyPlanId = data.foodSafetyPlanId;
        this.id = data.id;
        this.isDefault = data.isDefault;
        this.name = data.name;
        this.originalId = data.originalId;
        this.products = data.products.map((p) => new FspProduct(p));
    }

    static fromJSON(json: any): FspProductGroup {
        return new FspProductGroup({
            foodGroups: json.food_groups,
            foodSafetyPlanId: json.food_safety_plan_id,
            id: json.id,
            isDefault: json.is_default,
            name: json.name,
            originalId: json.original_id,
            products: json.products.map(FspProduct.fromJSON),
        });
    }

    toJSON() {
        return {
            food_groups: this.foodGroups,
            food_safety_plan_id: this.foodSafetyPlanId,
            id: this.id,
            is_default: this.isDefault,
            name: this.name,
            original_id: this.originalId,
            products: this.products.map((prod) => prod.toJSON()),
        };
    }
}
