<template>
    <span
        class="badge badge-pill d-inline-flex align-items-center"
        :class="[variantClass, badgeType]"
    >
        {{ value }}
        <i
            v-if="deletable"
            class="fd-close ml-1 cursor-pointer"
            @click.stop="$emit('delete')"
        />
    </span>
</template>

<script>
export default {
    name: 'BaseBadge',

    props: {
        value: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: 'secondary',
        },
        small: Boolean,
        deletable: Boolean,
    },

    computed: {
        variantClass() {
            switch (this.variant) {
                case 'warning': {
                    return 'badge-warning';
                }
                case 'success':
                    return 'bg-success';
                case 'done':
                    return 'bg-success-light';
                case 'not done':
                    return 'bg-red-light';
                case 'secondary':
                default: {
                    return 'badge-secondary';
                }
            }
        },
        badgeType() {
            if (this.small) {
                return 'badge-small';
            }
            return null;
        },
    },
};
</script>

<style>
.badge-pill {
    border-radius: 14px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
</style>
