<template>
    <Portal
        to="sidemenu"
        class="bg-gray-200"
        :order="order"
    >
        <div
            class="sidemenu-wrapper bg-gray-200"
            :class="{
                'after-menu-floating': afterMenuFloating,
                'padding-top-sidemenu': paddingTop,
            }"
        >
            <button
                v-if="!isShown && !hideToggle"
                :class="{ active: isShown, 'btn-dark': !isShown }"
                class="sidemenu-action-btn btn btn-icon"
                @click="isShown = true"
            >
                <i class="fd-chevron-right"></i>
            </button>
            <div
                class="sidemenu-inner-wrapper bg-gray-200"
                :class="{
                    'after-menu-floating': afterMenuFloating,
                }"
            >
                <div
                    ref="sidemenu"
                    :class="{
                        active: isShown,
                        'after-menu-floating': afterMenuFloating,
                        'haccp-sidemenu': $route.name.includes('plan'),
                    }"
                    class="sidemenu bg-gray-200"
                >
                    <div
                        class="sidemenu-content overflow-y-scroll bg-gray-200 h-100"
                        :class="{
                            active: isShown,
                            'after-menu-floating': afterMenuFloating,
                        }"
                    >
                        <div
                            v-if="showHeader"
                            class="sidemenu-nav align-items-end d-lg-none align-items-center bg-gray-200"
                        >
                            <div class="d-flex align-items-center justify-content-between">
                                <button
                                    v-if="!$store.getters['shared-access/isSharedAccess']"
                                    class="btn btn-link"
                                    @click="back"
                                >
                                    <i class="fd-chevron-left text-light thin-icon"></i>
                                </button>
                                <!-- TODO: Bad stuf, unexpected translate -->
                                <span class="my-auto">{{ $t(title) }}</span>
                                <button
                                    class="btn btb-link"
                                    @click="close"
                                >
                                    <i class="fd-close text-light thin-icon"></i>
                                </button>
                            </div>
                        </div>
                        <ul
                            class="scroll-sidemenu-list px-0 mx-0"
                            :class="showHeader && 'showHeader'"
                            @scroll="handleScroll"
                        >
                            <Item
                                v-for="(item, index) in items"
                                :id="`sidemenu-item-${index}-${
                                    item.to && item.to.name && item.to.name.replace('.', '-')
                                }`"
                                :key="index"
                                :custom-label="customLabel"
                                :has-nested="hasNested"
                                :index="index"
                                :value="item"
                                @click="close"
                                @toggle-visibility="checkIsMenuScrollable"
                            >
                                <template #before-item="attr">
                                    <slot
                                        v-bind="attr"
                                        name="before-item"
                                    ></slot>
                                </template>
                                <template #after-item="attr">
                                    <slot
                                        v-bind="attr"
                                        name="after-item"
                                    ></slot>
                                    <span
                                        v-if="item.hasAttentionMarker"
                                        class="attention-marker-red ml-2"
                                    ></span>
                                </template>
                            </Item>
                        </ul>
                    </div>
                    <div
                        v-if="hasAfterMenu"
                        class="sidemenu-after-menu"
                        :class="{
                            'after-menu-floating': afterMenuFloating,
                        }"
                    >
                        <slot name="after-menu"></slot>
                    </div>
                </div>
                <div
                    v-if="!disableScrollIndicator && isMenuScrollable && !scrolledToEnd"
                    class="sidemenu-scroll"
                >
                    <i class="fd-arrows-down"></i>
                </div>
            </div>
        </div>
    </Portal>
</template>

<script>
import Item from './Item.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Sidemenu',

    components: {
        Item,
    },

    props: {
        afterMenuFloating: {
            type: Boolean,
            default: true,
        },
        showOnStart: Boolean,
        hideToggle: Boolean,
        hideOnBack: Boolean,
        value: Boolean,
        paddingTop: {
            type: Boolean,
            default: false,
        },
        disableScrollIndicator: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: '',
        },
        customLabel: {
            type: Function,
            default: (i) => i.title,
        },
        items: {
            type: Array,
            required: true,
            validator(value) {
                /*
                    {
                        title: String,
                        to: Object,
                        children: [
                            { title, to }
                            ...
                        ]
                    }
                */

                // TODO;
                return !!value;
            },
        },
    },

    data() {
        return {
            show: this.showOnStart,
            scrolledToEnd: false,
            isMenuScrollable: false,
        };
    },

    computed: {
        ...mapGetters({
            notMobileApp: 'global/showHeader',
        }),
        hasAfterMenu() {
            return !!this.$scopedSlots?.['after-menu'];
        },
        hasNested() {
            return !!this.items.find((item) => item?.children?.length);
        },
        isShown: {
            get() {
                return this.show || this.value;
            },
            set(val) {
                this.$emit('input', val);
                this.show = val;
            },
        },
        showHeader() {
            return this.$store.getters['global/showHeader'];
        },
    },

    watch: {
        items: {
            handler() {
                this.checkIsMenuScrollable();
            },
        },
    },

    mounted() {
        this.$store.commit('global/setMobileSubHeaderOpen', true);
    },

    methods: {
        back() {
            if (this.hideOnBack) {
                this.isShown = false;
            }
            this.$store.commit('global/setMobileHeaderState', true);
        },
        close() {
            this.checkIsMenuScrollable();
            this.$store.commit('global/setMobileSubHeaderOpen', false);
            this.isShown = false;
        },
        handleScroll(el) {
            this.scrolledToEnd = el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight;
        },
        checkIsMenuScrollable() {
            const sidemenus = Array.from(document.getElementsByClassName('sidemenu'));
            const sidemenu = sidemenus?.find((sm) => sm.scrollHeight > 0 && sm.clientHeight > 0);
            const sidemenuClientHeight = document.getElementsByClassName('scroll-sidemenu-list')[0].clientHeight;
            if (sidemenu && sidemenuClientHeight) {
                this.isMenuScrollable = sidemenu.scrollHeight < sidemenuClientHeight;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sidemenu-scroll {
    position: relative;
    bottom: 0;
    z-index: 3001;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2.5rem !important;
    margin-top: -5rem;
    text-align: center;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.4) 100%);

    i {
        width: 2rem;
        height: 2rem;
        background-color: $green;
    }

    .fd-arrows-down {
        font-size: 0.85rem;
        line-height: 2rem;
        color: $gray-100;
        border-radius: 50%;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.thin-icon {
    font-size: 1.4rem;
    font-weight: 100 !important;
}

.sidemenu-wrapper {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    background-color: $gray-200;

    @include media-breakpoint-down(md) {
        height: auto;
    }

    .sidemenu-action-btn {
        display: none;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        @include media-breakpoint-down(md) {
            display: block;

            &.active {
                position: fixed;
                top: $navbar-padding-y;
                right: $navbar-padding-y;
                z-index: $zindex-popover + 10;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        width: 100% !important;
        min-width: unset;
        max-width: unset;
    }
}

.sidemenu {
    width: 250px !important;
    height: 100%;
    padding: 0 !important;
    overflow: hidden;

    .after-menu-floating {
        height: unset;
        @include media-breakpoint-up(md) {
            height: 80vh !important;
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3001;
        display: none;
        width: 100vw !important;
        height: 100%;
        padding-right: 0 !important;

        padding-bottom: 6rem;
        padding-left: 0 !important;
        overflow: scroll;
        background-color: white;
        box-shadow: 6px 1px 5px rgba($black, 0.2);
        &.active {
            display: unset;
        }
    }

    ul {
        position: relative;
        z-index: 3000;

        padding: 0 0 6rem;
        margin: 0;
        overflow-y: scroll;

        &.showHeader {
            @include media-breakpoint-down(md) {
                margin: 5.5rem 0 0 0;
            }
        }
    }
}

.sidemenu-nav {
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 250px !important;
    padding: 2rem 0 0.5rem 0;
    margin: 0 !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 1.5rem;
    color: white;
    letter-spacing: 0.15px;
    background-color: $green-border;
    @media screen and (max-width: 1000px) {
        width: 100% !important;
        min-width: unset;
        max-width: unset;
    }
}

.sidemenu-inner-wrapper {
    width: 250px !important;
    padding-bottom: 10rem !important;
    margin-top: 6rem;
    margin-bottom: 5rem;
    background-color: $gray-200;

    @include media-breakpoint-up(md) {
        position: fixed;
        width: 250px;
        height: 100vh;
    }
    @include media-breakpoint-down(md) {
        display: contents;
    }

    .after-menu-floating {
        margin-bottom: 1rem !important;
    }
}

.sidemenu-after-menu {
    overflow-x: hidden;
    border-top: 1px solid $gray-500;

    &.after-menu-floating {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 80vh;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            background-color: $gray-200;
        }
    }
}
</style>

<style lang="scss">
.scroll-sidemenu-list {
    width: 250px !important;
    padding-right: -0.5rem !important;
    overflow-x: clip;
    background: {
    }
    @media screen and (max-width: 1000px) {
        z-index: 998 !important;
        width: 100% !important;
    }
}

.sidemenu-content {
    overflow-x: clip;
}

.submenu-item {
    padding: 0.5rem 0 0.5rem 2rem;
    font-size: 0.875rem;
    font-weight: 300 !important;

    &.router-link-exact-active {
        font-weight: 400 !important;
        color: $gray-700 !important;
        background-color: $gray-300 !important;
        @include media-breakpoint-up(md) {
            border-right: 4px solid $green;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $gray-200;
    }
}

.padding-top-sidemenu {
    margin-top: 5rem;
}
</style>
