<template>
    <ValidationObserver
        ref="form"
        v-slot="v"
        :name="name"
        :vid="name"
        tag="form"
        @submit.prevent="onSubmit"
    >
        <slot v-bind="v"></slot>
    </ValidationObserver>
</template>

<script>
import { scrollToError } from '@services/helper.service';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'BaseForm',

    components: {
        ValidationObserver,
    },

    props: {
        name: {
            type: String,
            default: null,
        },
    },

    computed: {
        validation() {
            return this.$refs?.form;
        },
        flags() {
            return this.validation?.flags;
        },
    },

    mounted() {
        this.$watch(
            () => {
                return this.flags;
            },
            (val) => {
                val && this.$emit('flags-changed', val);
            }
        );
    },

    methods: {
        onSubmit(e) {
            try {
                scrollToError(this.$refs.form);
            } catch (e) {
                console.error(e);
            }

            this.$emit('submit', { event: e, validation: this.validation });
        },
    },
};
</script>
