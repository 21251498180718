<template>
    <div v-if="layout === 'horizontal'">
        <div class="form-group row justify-content-center">
            <label
                v-if="title"
                :id="id"
                class="col-md-2 col-form-label text-md-right align-self-md-center"
                :for="id"
            >
                {{ title }}
            </label>
            <div class="col-md-4">
                <div class="text-right">
                    <button
                        v-for="item of langs"
                        :key="item"
                        type="button"
                        class="btn btn-sm text-uppercase"
                        :class="{ 'btn-primary': item === lang, 'border-danger': !data[lang] && item !== lang }"
                        @click="setLang(item)"
                    >
                        {{ item }}
                    </button>
                </div>
                <QuillEditor
                    :id="id"
                    v-model="data[lang]"
                    :class="inputClass"
                    @input="update"
                />
            </div>
        </div>
    </div>
    <div v-else>
        <div class="d-flex justify-content-between">
            <div>
                <label
                    v-if="title"
                    :for="id"
                    >{{ title }}</label
                >
                <!-- Slot Label -->
                <div
                    v-else
                    class="form-group mb-0"
                >
                    <label :for="id">
                        <slot name="label"></slot>
                    </label>
                </div>
                <!-- End of Slot Label -->
            </div>

            <div class="btn-group">
                <button
                    v-for="item of langs"
                    :key="item"
                    type="button"
                    class="btn btn-sm text-uppercase"
                    :class="{ 'btn-primary': item === lang, 'border-danger': !data[lang] && item !== lang }"
                    @click="setLang(item)"
                >
                    {{ item }}
                </button>
            </div>
        </div>
        <QuillEditor
            :id="id"
            v-model="data[lang]"
            :class="inputClass"
            :options="editorOption"
            @input="update"
            @blur="validation && validation.$touch()"
        />
        <span
            v-if="validation && validation.$error"
            class="invalid-feedback"
        >
            <slot
                name="error"
                :validation="validation"
            />
        </span>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
    name: 'MultilingualQuill',

    components: {
        QuillEditor: quillEditor,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        id: {
            type: [Number, String],
            default: '',
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        placeholderPrefix: {
            type: String,
            default: '',
        },
        inputClass: {
            type: [String, Object],
            default: '',
        },
        layout: {
            type: String,
            default: '',
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        langs: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            lang: this.$i18n.locale || 'en',
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['link', 'image'],
                    ],
                },
            },
        };
    },

    computed: {
        data: {
            get() {
                if (this.value && typeof this.value === 'object' && this.value.constructor === Object) {
                    return this.value;
                }

                return {};
            },
        },
        placeholder() {
            const placeholder = this.placeholderPrefix || '';

            return this.$t(`${placeholder}`);
        },
    },

    methods: {
        update() {
            this.$emit('input', this.data);
        },
        setLang(lang) {
            this.lang = lang;
        },
    },
};
</script>
