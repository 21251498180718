import store from '@/store';
import SettingUpGuard from '@common/guards/settingUp.guard';
import AuthService from '@services/auth.service';

const Register = () => import(/* webpackChunkName: "register-module" */ '@views/Auth/views/Register/index');
const Account = () => import(/* webpackChunkName: "register-module" */ '@views/Auth/views/Register/Account/index');
const Company = () => import(/* webpackChunkName: "register-module" */ '@views/Auth/views/Register/Company/index');
const FinalStep = () => import(/* webpackChunkName: "register-module" */ '@views/Auth/views/Register/FinalStep/index');
const SettingUp = () => import(/* webpackChunkName: "register-module" */ '@views/Auth/views/Register/SettingUp');

export default [
    {
        path: 'register',
        name: 'register',
        components: { unauthorized: Register },
        redirect: { name: 'register.account' },
        beforeEnter(to, from, next) {
            /**
             * If user has a place they have already completed their registration.
             */
            if (store.getters.selectedPlaceId) {
                next({ name: '' });
                return;
            }
            /**
             * If user profile is not exists but
             * user tries to navigate to some of the routes pages redirect him to account creation
             */
            if (!store.getters.hasProfile && !AuthService.isAuthorized && !to.name?.includes('register.account')) {
                next({ name: 'register.account' });
                return;
            }

            next();
        },
        children: [
            {
                path: 'account',
                name: 'register.account',
                component: Account,
            },
            {
                path: 'company',
                name: 'register.company',
                component: Company,
            },
            {
                path: 'final',
                name: 'register.final',
                component: FinalStep,
            },
        ],
    },
    {
        path: 'setting-up',
        name: 'register.setting-up',
        components: { unauthorized: SettingUp },
        beforeEnter: SettingUpGuard,
    },
];
