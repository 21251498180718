<template>
    <div
        v-if="layout === 'horizontal'"
        class="base-textarea"
    >
        <div
            :id="`${id}__container`"
            class="form-group row justify-content-center"
        >
            <label
                v-if="title"
                :id="id"
                class="col-md-2 col-form-label text-md-right align-self-md-center"
                :for="id"
                >{{ title }}</label
            >
            <div class="col-md-4">
                <div class="text-right">
                    <button
                        v-for="item of langsList"
                        :id="`multilingual__${item.key}`"
                        :key="item.key"
                        type="button"
                        class="btn btn-sm text-uppercase"
                        :class="{ 'btn-primary': item === lang }"
                        @click="setLang(item)"
                    >
                        {{ item.shortDisplay }}
                    </button>
                </div>
                <textarea
                    :id="id"
                    v-model="data[lang.key]"
                    class="form-control"
                    :class="inputClass"
                    :placeholder="placeholder"
                    @input="update"
                    @blur="validation && validation.$touch()"
                ></textarea>
            </div>
        </div>
    </div>
    <div
        v-else
        class="base-textarea"
    >
        <div
            :id="`${id}__container`"
            class="d-flex justify-content-md-between flex-column flex-md-row"
        >
            <div>
                <label
                    v-if="title"
                    :for="id"
                    >{{ title }}</label
                >
                <!-- Slot Label -->
                <div
                    v-else
                    class="form-group mb-0"
                >
                    <label :for="id">
                        <slot name="label"></slot>
                    </label>
                </div>
                <!-- End of Slot Label -->
            </div>

            <div class="btn-group">
                <button
                    v-for="item of langsList"
                    :id="`multilingual__${item.key}`"
                    :key="item.key"
                    type="button"
                    class="btn btn-sm text-uppercase"
                    :class="{ 'btn-primary': item === lang }"
                    @click="setLang(item)"
                >
                    {{ item.shortDisplay }}
                </button>
            </div>
        </div>
        <textarea
            :id="id"
            v-model="data[lang.key]"
            class="form-control"
            :class="inputClass"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="update"
            @blur="validation && validation.$touch()"
        ></textarea>
        <span
            v-if="validation && validation.$error"
            class="invalid-feedback"
        >
            <slot
                name="error"
                :validation="validation"
            />
        </span>
    </div>
</template>

<script>
import { LANG_LIST } from '@common/constants';

export default {
    name: 'MultilingualTextarea',

    props: {
        title: {
            type: String,
            default: '',
        },
        id: {
            type: [Number, String],
            default: '',
        },
        value: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        placeholderPrefix: {
            type: String,
            default: '',
        },
        inputClass: {
            type: [String, Object],
            default: '',
        },
        layout: {
            type: String,
            default: '',
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        disabled: Boolean,
    },

    data() {
        return {
            lang: {
                key: 'en',
                fullname: 'english',
            },
            langsList: LANG_LIST,
        };
    },

    computed: {
        data: {
            get() {
                if (this.value && typeof this.value === 'object' && this.value.constructor === Object) {
                    return this.value;
                }

                return {};
            },
        },
        placeholder() {
            const placeholder = this.placeholderPrefix || '';

            return this.$t(`${placeholder}`);
        },
    },

    mounted() {
        this.lang = this.langsList.find((item) => item.key === this.$i18n.locale);
    },

    methods: {
        update() {
            this.$emit('input', this.data);
        },
        setLang(lang) {
            this.lang = lang;
        },
    },
};
</script>

<style scoped lang="scss">
.base-textarea {
    textarea {
        background: $gray-200 !important;
        border-radius: 0.3rem;
        transition: border 0.3s ease-in-out;
        &:not(.is-invalid) {
            border: 2px solid transparent;
        }

        &:hover {
            background-color: $gray-300;
            border-color: $gray-400;
        }
    }

    label {
        font-size: 0.875rem;
        font-weight: normal;
    }
}
</style>
