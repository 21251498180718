<template>
    <BCard>
        <BCardHeader
            v-b-toggle="id"
            header-tag="header"
            role="tab"
        >
            <div class="d-flex align-items-center justify-content-between bg-transparent">
                <slot name="header"></slot>

                <i class="fd-chevron-down"></i>
            </div>
        </BCardHeader>
        <BCollapse
            :id="id"
            v-model="isOpen"
            :accordion="`accordion-${id}`"
            role="tabpanel"
            class="card-content"
        >
            <BCardBody>
                <slot name="body"></slot>
            </BCardBody>
        </BCollapse>
    </BCard>
</template>

<script>
export default {
    props: { id: { type: String, required: true }, visible: Boolean },

    computed: {
        isOpen: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('toggle', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    .card-body {
        padding: 0;

        .card-header {
            padding: 0.75rem 1.25rem;
            background: var(--white);
            border-bottom: 1px solid transparent;

            .fd-chevron-down {
                transition: transform 0.4s ease;
            }

            &:not(.collapsed) {
                border-color: var(--primary);

                .fd-chevron-down {
                    transform: rotate(180deg);
                }
            }
        }

        .card-body {
            padding: 0.75rem 1.25rem;
            background: var(--light);
        }
    }
}
</style>
