import http from '@http';
import { START_VIEW_LOADER, STOP_VIEW_LOADER } from '@store/loader/constants';
import { TeamTask } from '@common/types/TeamTask';

function state() {
    return {
        tasks: [],
    };
}

const mutations = {
    addAll(state, payload) {
        state.tasks = payload;
    },
    add(state, payload) {
        state.tasks.push(payload);
    },
    edit(state, payload) {
        const index = state.tasks.findIndex((task) => task.id === payload.id);
        if (index !== -1) {
            state.tasks[index] = payload;
        }
    },
    delete(state, id) {
        state.tasks = state.tasks.filter((task) => task.id !== id);
    },
    reset(storeState) {
        const s = state();
        Object.keys(s).forEach((key) => (storeState[key] = s[key]));
    },
};

const actions = {
    onPlaceChange: {
        root: true,
        handler({ commit }) {
            commit('reset');
        },
    },
    async getAll({ commit, rootGetters }): Promise<void> {
        try {
            const response = await http
                .get(`/api/places/${rootGetters.selectedPlaceId}/team-tasks`)
                .then(({ data }) => data);
            const tasks = response.map(TeamTask.fromJSON);
            commit('reset');
            commit('addAll', tasks);
        } catch (error) {
            console.error(error);
        }
    },
    updateAll({ dispatch, commit }, teamTasks: TeamTask[]): Promise<any> {
        commit(START_VIEW_LOADER, null, { root: true });

        const requests: any = [];

        teamTasks.forEach((item: TeamTask) => {
            if (!item.id) {
                requests.push(dispatch('create', item));
            } else if (item) {
                requests.push(dispatch('update', item));
            }
        });

        return Promise.all(requests).finally(() => {
            commit(STOP_VIEW_LOADER, null, { root: true });
        });
    },
    async create({ commit, rootGetters }, payload: TeamTask): Promise<TeamTask | undefined> {
        try {
            const response = await http
                .post(`/api/places/${rootGetters.selectedPlaceId}/team-tasks`, payload.toJSON())
                .then(({ data }) => data);
            commit('add', TeamTask.fromJSON(response));
            return response;
        } catch (error) {
            console.error(error);
        }
    },
    async update({ commit, rootGetters }, payload: TeamTask): Promise<TeamTask | undefined> {
        try {
            const response = await http
                .put(`/api/places/${rootGetters.selectedPlaceId}/team-tasks/${payload.id}`, payload.toJSON())
                .then(({ data }) => data);
            commit('edit', TeamTask.fromJSON(response));
            return response;
        } catch (error) {
            console.error(error);
        }
    },
    async delete({ commit, rootGetters }, id): Promise<void> {
        commit(START_VIEW_LOADER, null, { root: true });

        try {
            await http.delete(`/api/places/${rootGetters.selectedPlaceId}/team-tasks/${id}`);
            commit('delete', id);
        } catch (error) {
            console.error(error);
        }

        commit(STOP_VIEW_LOADER, null, { root: true });
    },
};

const getters = {
    getAll: (state) => state.tasks,
    getAllActives: (state) => state.tasks.filter((task) => !task.isDisabled),
    get: (state) => (id) => state.tasks.find((task) => task.id === id),
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
