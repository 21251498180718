import { vm } from '@/main';

function onBeforeUnload() {
    return vm.$t('You are leaving the page');
}

export default class AppService {
    static blockTab() {
        window.onbeforeunload = onBeforeUnload;
    }
    static unblockTab() {
        window.onbeforeunload = null;
    }
}
