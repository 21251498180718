var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item",class:{
        active: _vm.activeRoute,
        separator: _vm.value.separator,
        'space-related-out-y': _vm.hasNested,
        'content-separator-item': _vm.value.type === 'contentSeparator',
    }},[(_vm.value.type === 'contentSeparator')?_c('div',{staticClass:"content-separator space-related-out-t"},[_c('span',{staticClass:"content-separator-title info-text text-gray-600 letter-spacing-1-5"},[_vm._v(_vm._s(_vm.$t(_vm.value.title)))])]):(_vm.value.to)?_c('RouterLink',{staticClass:"item-title",class:{
            'with-marker': _vm.hasMarker,
            active: _vm.activeRoute,
            clickable: !(_vm.disableClickable && _vm.value.children && _vm.value.children.length),
        },attrs:{"data-cy":_vm.value.to.name,"to":_vm.value.to,"tag":"a"},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[(_vm.hasMarker)?_c('span',{staticClass:"item-marker"},[(_vm.hasChildren)?_c('button',{staticClass:"btn border-0 py-0 icon-dark",on:{"click":_vm.toggle}},[_c('i',{class:_vm.isOpen ? 'fd-chevron-down' : 'fd-chevron-right'})]):_vm._e(),_vm._t("before-item",null,{"index":_vm.index,"value":_vm.value})],2):_vm._e(),_c('span',{staticClass:"item-value d-inline-flex align-items-center",class:{ 'single-title': !_vm.hasNested }},[_vm._v(" "+_vm._s(_vm.label(_vm.value))+" "),_vm._t("after-item",null,{"index":_vm.index,"value":_vm.value})],2)]):_c('div',{staticClass:"item-title",class:{
            'with-marker': _vm.hasMarker,
            active: _vm.activeRoute,
            clickable: !(_vm.value.children && _vm.value.children.length),
        }},[(_vm.hasMarker)?_c('span',{staticClass:"item-marker"},[(_vm.hasChildren)?_c('button',{staticClass:"btn btn-icon border-0 py-0",on:{"click":_vm.toggle}},[_c('i',{class:_vm.isOpen ? 'fd-chevron-down' : 'fd-chevron-right'})]):_vm._e(),_vm._t("before-item",null,{"value":_vm.value,"index":_vm.index})],2):_c('span',{staticClass:"marker-placeholder"}),_c('span',{staticClass:"item-value top-level-title",class:{ 'single-title': !_vm.hasNested }},[_c('span',{staticClass:"cursor-pointer fs-sm",on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.label(_vm.value)))]),_vm._t("after-item",null,{"value":_vm.value,"index":_vm.index})],2)]),(_vm.value.children && _vm.value.children.length && _vm.isOpen)?_c('ul',{class:{
            'space-related-out-l': _vm.hasIndent,
        }},_vm._l((_vm.value.children),function(nestedItem,nestedIndex){return _c('li',{key:nestedIndex,staticClass:"item"},[(nestedItem.children)?_c('div',{staticClass:"nested-item inner"},[_c('SidemenuItem',{attrs:{"custom-label":_vm.customLabel,"has-nested":"","value":nestedItem,"index":_vm.customLabel(nestedItem),"has-indent":""}})],1):(nestedItem.to)?_c('RouterLink',{staticClass:"item-title clickable child-title d-flex",attrs:{"data-cy":nestedItem.to.name,"to":nestedItem.to,"tag":"div"},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"item-marker"},[_vm._t("before-item",null,{"index":nestedIndex,"value":nestedItem})],2),_c('div',{staticClass:"item-value child-item"},[_vm._v(" "+_vm._s(_vm.label(nestedItem))+" ")]),_c('span',{staticClass:"item-marker"},[_vm._t("after-item",null,{"index":nestedIndex,"value":nestedItem})],2)]):_c('div',{staticClass:"item-title clickable child-title",class:{ 'with-marker': _vm.hasMarker }},[_c('span',{staticClass:"item-marker"},[_vm._t("before-item",null,{"index":nestedIndex,"value":nestedItem})],2),_c('span',{staticClass:"item-value child-item"},[_c('span',[_vm._v(_vm._s(_vm.label(nestedItem)))]),_vm._t("after-item",null,{"index":nestedIndex,"value":nestedItem})],2),_c('span',{staticClass:"item-marker"},[_vm._t("after-item",null,{"index":nestedIndex,"value":nestedItem})],2)])],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }